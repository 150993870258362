import { Box, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.darker,
  },
  credits: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '4rem',
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
  },
}))

function Ticket({ ticket }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box mr={0.5}>
          <Typography variant="subtitle1">
            <Box component="span" color="primary.darker">
              {ticket.NbTickets}
            </Box>
          </Typography>
        </Box>
        <Icon icon="tickets" />
      </Box>
      <Typography variant="caption">
        <Box color="primary.darker">{t(ticket.Description)}</Box>
      </Typography>
      <Divider className={classes.divider} />
    </>
  )
}

function MyWallet() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { user, apiCall } = useContext(UserContext)
  const { society } = useContext(SocietyContext)

  const { data: tickets } = useSWR(`/usertickets`, () =>
    apiCall(apiActions.getUserTickets)
      .then((r) => {
        if (r.Error) {
          //
        } else {
          return r.data
        }
      })
      .catch((e) => {
        throw Error(e)
      })
  )

  if (!tickets) return <LoadingElem />

  return (
    <CustomPaper>
      <Box p={3}>
        {society.Payments.Credits && (
          <>
            <Box mb={2} textAlign="center">
              <Typography className={classes.title} variant="h4" gutterBottom>
                {t('my.wallet.title')}
              </Typography>
            </Box>
            <Box textAlign="center" mt={2} mb={2}>
              <Typography variant="h1" className={classes.credits}>
                {user.Credits}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {t('my.wallet.credits.subtitle')}
              </Typography>
            </Box>
            <Box mb={2}>
              <CustomButton href="/Profile" fullWidth variant="contained" color="secondary" size="large">
                {t('my.wallet.credits.btn')}
              </CustomButton>
            </Box>
          </>
        )}

        <Box mb={1}>
          <Typography variant="h6" className={classes.title}>
            {t('my.wallet.tickets.title')}
          </Typography>
        </Box>
        <div>
          {tickets.length === 0 ? (
            <Typography variant="subtitle1" color="textSecondary">
              {t('my.wallet.no.tickets')}
            </Typography>
          ) : (
            tickets.map((t, i) => t.NbTickets > 0 && <Ticket ticket={t} key={i} />)
          )}
        </div>
      </Box>
    </CustomPaper>
  )
}

export default MyWallet
