import {
  Stepper,
  StepperProps,
  alpha,
  darken,
  Step,
  Grid,
  Typography,
  Box,
  StepLabel,
  StepConnector,
  StepButton,
} from '@material-ui/core'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import Icon from 'components/IcoMoon/Icon'
import React from 'react'

const customPaperStyle = makeStyles((theme) => {
  const darker = darken(theme.palette.primary.main, 0.7)

  return {
    stepper: {
      padding: 0,
      backgroundColor: 'inherit',
      '& .MuiStep-root': {
        padding: 0,
      },
      '& .MuiStepLabel-iconContainer': {},
    },
    unactive: {
      fontWeight: 'normal',
      color: darker,
    },
    active: {
      border: '1px solid ' + theme.palette.secondary.dark,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    completed: {
      border: '1px solid ' + theme.palette.secondary.dark,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    root: {
      zIndex: 5,
      backgroundColor: theme.palette.background.default,
      borderRadius: '50%',
      width: 20,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dot: {
      fontSize: 10,
      color: theme.palette.secondary.dark,
    },
    divUnactive: {
      border: '1px solid ' + darker,
      borderRadius: '50%',
      width: 20,
      height: 20,
    },
    button: {
      borderRadius: theme.spacing(1),
    },
    noPointer: {
      '&:hover': {
        cursor: 'auto',
      },
    },
    withoutLabels: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
  }
})

interface CustomStepperProps extends StepperProps {
  className?: string
  totalStep: number
  activeStep: number
  labels?: Array<string>
  onChangeActive(step: number): void
  disableClick?: boolean
  fullWidth?: boolean
  hideStartEndStepIcons?: boolean
}

interface CustomIconProps {
  active: boolean
  completed: boolean
  icon: number
}

const CustomStepper: React.FC<CustomStepperProps> = function (props) {
  const classes = customPaperStyle()
  const {
    className,
    activeStep,
    totalStep,
    labels,
    onChangeActive,
    disableClick = false,
    fullWidth = false,
    hideStartEndStepIcons = false,
    ...rest
  } = props

  let classNames = [classes.stepper]
  if (className) classNames.push(className)
  if (fullWidth) classNames.push(classes.withoutLabels)

  const handleStepClick = (step: number) => {
    onChangeActive(step)
  }

  function CustomStepIcon(active: CustomIconProps) {
    return (
      <div
        className={
          classes.root +
          ' ' +
          (active.active ? classes.active : '') +
          ' ' +
          (active.completed ? classes.completed : '') +
          ' ' +
          (disableClick ? classes.noPointer : '')
        }
      >
        {active.completed || active.active ? (
          <Icon
            className={classes.dot}
            icon="dot"
            onClick={() => {
              if (!disableClick) handleStepClick(active.icon - 1)
            }}
          />
        ) : (
          <div className={classes.divUnactive} />
        )}
      </div>
    )
  }

  const Connector = withStyles((theme) => {
    let toret = {
      alternativeLabel: {
        top: theme.spacing(0.75),
        left: 'calc(-50%)',
        right: 'calc(50%)',
      },
      active: {
        '& $line': {
          borderColor: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.main,
        },
      },
      completed: {
        '& $line': {
          borderColor: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.main,
        },
      },
      line: {
        height: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        border: '1px solid ' + theme.palette.primary.darker,
        marginLeft: fullWidth ? theme.spacing(-1.5) : 0,
        marginRight: fullWidth ? theme.spacing(-0.5) : 0,
      },
    }
    return toret
  })(StepConnector)

  let steps = totalStep
  if (hideStartEndStepIcons) steps -= 2

  return (
    <>
      <Stepper
        {...rest}
        activeStep={activeStep}
        alternativeLabel={!fullWidth}
        className={classNames.join(' ')}
        connector={<Connector />}
      >
        {hideStartEndStepIcons && <Step></Step>}
        {[...Array(steps)].map((e, i) => {
          return (
            <Step>
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Box color="secondary.dark">
                  <Typography className={activeStep !== i ? classes.unactive : ''} variant="subtitle1">
                    {labels && labels[i] ? labels[i] : ''}
                  </Typography>
                </Box>
              </StepLabel>
            </Step>
          )
        })}
        {hideStartEndStepIcons && <Step></Step>}
      </Stepper>
    </>
  )
}

export default CustomStepper
