import { alpha, Box, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import { getActivityText } from 'components/views/CRMPages/Utils/Enums'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  pastActivity: {
    backgroundColor: alpha(theme.palette.error.light, 0.2),
    borderColor: theme.palette.error.main,
  },
  paper: {
    borderRadius: theme.shape.borderRadius,
  },
  borderBottom: {
    borderBottom: '1px dashed ' + theme.palette.secondary.main,
  },
  today: {
    backgroundColor: alpha(theme.palette.success.main, 0.2),
    borderColor: theme.palette.success.main,
  },
}))

const RenderItem = ({ colorTitle = 'textSecondary', colorValue = 'textPrimary', title, value, className }) => {
  const classes = useStyles()

  return (
    <Grid item xs={6} sm className={className ?? ''}>
      <Box mb={1}>
        <Typography variant="subtitle1" color={colorTitle}>
          {title}
        </Typography>
      </Box>
      <div>
        <Typography variant="subtitle1" color={colorValue}>
          {value}
        </Typography>
      </div>
    </Grid>
  )
}

function ActivityCard({ activity }) {
  const { Date, ProspectName, ProspectId, Type, Title } = activity
  const classes = useStyles()
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const { data: interests } = useSWR(`${ProspectId}/interests`, () =>
    apiCall(apiActions.getInterestsByProspectId, { ProspectId: ProspectId }).then((r) => r.data)
  )

  const pastActivity = Date && moment().startOf('day').isAfter(moment(Date).startOf('day'))
  const todayActivity = Date && moment().startOf('day').isSame(moment(Date).startOf('day'))

  return (
    <CustomPaper
      selectable
      className={
        classes.paper + ' ' + (pastActivity ? classes.pastActivity : '') + (todayActivity ? classes.today : '')
      }
    >
      <Box p={2}>
        <Grid container spacing={1}>
          <RenderItem
            className={isXsDown ? classes.borderBottom : null}
            colorTitle="textPrimary"
            colorValue="textSecondary"
            title={ProspectName}
            value={
              interests && interests.length > 0
                ? interests.map((i) => i.Name)?.join(', ')
                : t('crm.prospects.no.interests')
            }
          />
          <RenderItem
            className={isXsDown ? classes.borderBottom : null}
            title={t('crm.activities.tab.activity.title')}
            value={
              <Box component="span" display="flex">
                {isSmUp && (
                  <Box component="span" mr={1}>
                    <Icon icon="agenda-jour" />
                  </Box>
                )}
                <Box component="span">{moment(Date).format(isSmUp ? 'dddd DD MMMM' : 'DD/MM/YY')}</Box>
              </Box>
            }
            colorTitle={pastActivity ? 'error' : 'textSecondary'}
            colorValue={pastActivity ? 'error' : ''}
          />
          <RenderItem title={t('crm.activities.tab.type.title')} value={t(getActivityText(Type))} />
          <RenderItem title={t('crm.activities.tab.title.title')} value={Title} />
        </Grid>
      </Box>
    </CustomPaper>
  )
}

export default ActivityCard
