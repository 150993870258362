import { Box, Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CAEvolutions from 'area/owner/components/CAEvolutions'
import CARepartition from 'area/owner/components/CARepartition'
import Filters from 'area/owner/components/Filters'
import Layout from 'components/views/Layout'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    padding: theme.spacing(2),
  },
}))

const PaperContainer = ({ children }) => {
  const classes = useStyles()
  return <Paper className={classes.paper}>{children}</Paper>
}

function DashboardPage() {
  const { t } = useTranslation()
  const filtersLocalStorage = JSON.parse(sessionStorage.getItem('ownerfilters'))

  const [filters, setFilters] = React.useState({
    type: 'month',
    startDate: filtersLocalStorage?.startDate ?? moment().subtract(3, 'month'),
    endDate: filtersLocalStorage?.endDate ?? moment(),
    coworkingSpaces: filtersLocalStorage?.coworkingSpaces ?? [],
  })

  return (
    <Layout title={t('menu.owner.dashboard')}>
      <Box mb={2}>
        <PaperContainer>
          <Filters state={filters} onChange={setFilters} />
        </PaperContainer>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} lg={3}>
            <PaperContainer>
              <CARepartition filters={filters} />
            </PaperContainer>
          </Grid>
          <Grid item xs>
            <PaperContainer>
              <CAEvolutions filters={filters} />
            </PaperContainer>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}></Box>
    </Layout>
  )
}

export default DashboardPage
