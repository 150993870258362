import { Box, Typography } from '@material-ui/core'

function Title({ title }) {
  return (
    <Box mb={4}>
      <Typography align="center" gutterBottom variant="h3">
        <Box color="primary.darker" component="span" fontWeight="fontWeightBold" mb={4}>
          {title}
        </Box>
      </Typography>
    </Box>
  )
}

export default Title
