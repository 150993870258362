import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MediaImage } from 'components/CustomComponents/MediaImage'
import React from 'react'

const gridHeight = 35
const calcHeight = (val) => val + 'vh'

const imgContainer = {
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  minHeight: '100%',
  '& > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}

const useStyles = makeStyles((theme) => {
  const spacing = theme.spacing(2)
  return {
    grid: {
      display: 'flex',
      marginLeft: -spacing / 2,
      marginRight: -spacing / 2,
      alignItems: 'stretch',
      fontSize: 0,
      height: calcHeight(gridHeight),
      boxSizing: 'content-box',
      '& > div': {
        paddingLeft: spacing / 2,
        paddingRight: spacing / 2,
        fontSize: 0,
        display: 'flex',
        flexDirection: 'column',
        '&:first-child': {
          flex: 1,
        },
        '&:last-child': {
          width: calcHeight(gridHeight),
        },

        [theme.breakpoints.down('xs')]: {
          '&:first-child + div': {
            display: 'none',
          },
        },
      },
      '& .row': {
        display: 'flex',
        marginLeft: -spacing / 2,
        marginRight: -spacing / 2,
        flex: 1,
        '& > div': {
          flex: 1,
          paddingLeft: spacing / 2,
          paddingRight: spacing / 2,
          fontSize: 0,
        },
        '& + div': {
          marginTop: spacing,
        },
        '& .imgContainer': { ...imgContainer },
      },
      '&[nbimages="1"]': {
        marginLeft: 0,
        marginRight: 0,
        '& > div:first-child': {
          ...imgContainer,
        },
      },
      '&[nbimages="2"]': {
        '& > div:first-child': {
          flex: 1,
        },
        '& > div:last-child': {
          flex: 1,
        },
      },
      '&[nbimages="3"]': {
        '& > div:last-child': {
          width: calcHeight(gridHeight / 2),
        },
        '& .row': {
          flexDirection: 'column',
          '& > div + div': {
            marginTop: spacing,
          },
        },
      },
      '& img': {
        borderRadius: theme.shape.borderRadius,
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
    },
  }
})

const ImageContainer = ({ image }) => {
  if (!image) return null
  return (
    <div>
      {typeof image.url === 'string' ? (
        <div className="imgContainer">
          <MediaImage src={image.url} mediaParams={image.params} />
        </div>
      ) : (
        image
      )}
    </div>
  )
}

const ImagesRow = ({ imageLeft, imageRight }) => {
  if (!imageLeft && !imageRight) return null
  return (
    <div className="row">
      <ImageContainer image={imageLeft} />
      <ImageContainer image={imageRight} />
    </div>
  )
}

const morePhotoStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    // color:theme.palette.primary.main
  },
}))

const MorePhoto = ({ nb }) => {
  const classes = morePhotoStyle()
  return (
    <Box className={classes.container}>
      <Typography variant="h3">+ {nb}</Typography>
    </Box>
  )
}

function ItemImagesGrid({ images }) {
  const classes = useStyles()

  // const nbTest = 6
  // if(nbTest){
  //   images = ["https://media.fteledition.fr/Uploads/5e0ca20f-2c3c-4ada-ba83-1235c3914392/20191125_093433.jpg"]
  //   for(var i = 0; i < nbTest; i++){
  //     images[i] = images[0]
  //   }
  // }

  const nbImages = images.length

  if (nbImages === 4) {
    images = [images[0], images[1], null, images[2], images[3]]
  }

  const paramsImgLeft = {
    xs: { height: 330, width: 400, mode: 'crop', retina: true },
    sm: { height: 330, width: 600, mode: 'crop', retina: true },
    md: { height: 330, width: 500, mode: 'crop', retina: true },
  }
  const paramsImgRight =
    nbImages >= 3
      ? {
          xs: { height: 330, width: 330, mode: 'crop', retina: true },
        }
      : paramsImgLeft

  return (
    <div className={classes.grid} nbimages={nbImages}>
      <div>
        <MediaImage src={images[0]} mediaParams={paramsImgLeft} />
      </div>
      {images.length > 1 && (
        <div>
          <ImagesRow
            imageLeft={images[1] && { url: images[1], params: nbImages === 4 ? paramsImgLeft : paramsImgRight }}
            imageRight={images[2] && { url: images[2], params: paramsImgRight }}
          />
          <ImagesRow
            imageLeft={images[3] && { url: images[3], params: paramsImgRight }}
            imageRight={
              images.length > 5 ? (
                <MorePhoto nb={images.length - 4} />
              ) : (
                images[4] && { url: images[4], params: paramsImgRight }
              )
            }
          />
        </div>
      )}
    </div>
  )
}

export default ItemImagesGrid
