import { Box, Fade, makeStyles, Typography } from '@material-ui/core'
import FullHeightCenter from 'components/FullHeightCenter'
import { CustomThemeContext } from 'contexts/CustomThemeContext'
import React from 'react'
import { getSociety } from '_actions/society_actions'

const SocietyContext = React.createContext()
const get = getSociety().then((r) => r)

const imgWidth = 100
const useStyles = makeStyles((theme) => ({
  progressImg: {
    width: imgWidth,
  },
}))

const NotConfigured = () => {
  const classes = useStyles()
  const { logo } = React.useContext(CustomThemeContext)

  return (
    <FullHeightCenter>
      <Fade in={logo ? true : false}>
        <Box textAlign="center" p={2}>
          <Box mb={1}>
            <Box mb={1}>
              <img
                src={logo + `?upscale=false&animationprocessmode=first&autorotate=true&width=${imgWidth}&mode=max`}
                alt=""
                className={classes.progressImg}
              />
            </Box>
          </Box>
          <Typography variant="body2" color="textSecondary">
            La configuration de cette plateforme n'est pas terminée
          </Typography>
        </Box>
      </Fade>
    </FullHeightCenter>
  )
}

function SocietyProvider({ children }) {
  const [society, setSociety] = React.useState(null)

  get.then((r) => {
    setSociety(r)
  })

  return (
    <SocietyContext.Provider value={{ society }}>
      {!society || society.IsValid ? children : <NotConfigured />}
    </SocietyContext.Provider>
  )
}

export { SocietyProvider, SocietyContext }
