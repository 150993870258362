import { frFR } from '@material-ui/core/locale'
import { createTheme } from '@material-ui/core/styles'
import { alpha, darken, lighten } from '@material-ui/core/styles/colorManipulator'
import React from 'react'
import muiTheme, { primary, secondary, type } from 'styles/theme'
import { getTheme } from '_actions/society_actions.js'

const CustomThemeContext = React.createContext()
const defaultPrimary = primary
const defaultSecondary = secondary
const defaultType = type

const getFromLocalStorage = (item) => {
  const fromLocal = window.localStorage.getItem(item)
  if (fromLocal && fromLocal !== 'null') return fromLocal
  return null
}

const toLocalStorage = (item, value) => {
  if (value) window.localStorage.setItem(item, value)
}

const changeTagLink = (tag, link, width, height, attr) => {
  const updatedLink = `${link}?width=${width}&height=${height}&animationprocessmode=first&autorotate=true`

  let query = tag
  const attrKeys = Object.keys(attr)
  for (var i = 0; i < attrKeys.length; i++) {
    query += `[${attrKeys[i]}="${attr[attrKeys[i]]}"]`
  }

  let $favicon = document.querySelector(query)

  const updateFaviconLink = (newLink) => {
    if (tag === 'meta') {
      $favicon.content = newLink
    } else {
      $favicon.href = newLink
    }
  }

  if ($favicon !== null) {
    updateFaviconLink(updatedLink)
  } else {
    $favicon = document.createElement(tag)
    for (var i = 0; i < attrKeys.length; i++) {
      $favicon[attrKeys[i]] = attr[attrKeys[i]]
    }
    updateFaviconLink(updatedLink)
    document.head.appendChild($favicon)
  }
}

const changeFavicon = (link) => {
  const changeAppleTouchIcon = (size) => {
    changeTagLink('link', link, size, size, { rel: 'apple-touch-icon-precomposed', sizes: `${size}x${size}` })
  }

  const changeLink = (size) => {
    changeTagLink('link', link, size, size, { rel: 'icon', type: 'image/png', sizes: `${size}x${size}` })
  }

  const changeMs = (width, height) => {
    changeTagLink('meta', link, width, height, {
      name: `msapplication-${width === height ? 'square' : 'wide'}${width}x${height}logo`,
    })
  }

  changeAppleTouchIcon(57)
  changeAppleTouchIcon(114)
  changeAppleTouchIcon(72)
  changeAppleTouchIcon(144)
  changeAppleTouchIcon(60)
  changeAppleTouchIcon(120)
  changeAppleTouchIcon(76)
  changeAppleTouchIcon(152)

  changeLink(196)
  changeLink(96)
  changeLink(32)
  changeLink(16)
  changeLink(128)

  changeTagLink('meta', link, 144, 144, { name: `msapplication-TileImage` })
  changeMs(70, 70)
  changeMs(150, 150)
  changeMs(310, 150)
  changeMs(310, 310)
}

function CustomThemeProvider({ children }) {
  const [primary, setPrimary] = React.useState(getFromLocalStorage('theme-primary'))
  const [secondary, setSecondary] = React.useState(getFromLocalStorage('theme-secondary'))
  const [favicon, setFavicon] = React.useState(getFromLocalStorage('theme-favicon'))
  const [logo, setLogo] = React.useState(getFromLocalStorage('theme-logo'))
  const [loginImage, setLoginImage] = React.useState(getFromLocalStorage('theme-loginImage'))
  const [theme, setTheme] = React.useState()

  React.useEffect(() => {
    getTheme().then((r) => {
      if (r && r.PrimaryColor) setPrimary(r.PrimaryColor)
      if (r && r.SecondaryColor) setSecondary(r.SecondaryColor)
      if (r && r.FaviconURL) setFavicon(r.FaviconURL)
      if (r && r.LogoURL) setLogo(r.LogoURL)
      if (r && r.LoginImageURL) setLoginImage(r.LoginImageURL)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (primary && secondary) {
      updateTheme(primary, secondary)
    }
  }, [primary, secondary])

  React.useEffect(() => {
    toLocalStorage('theme-primary', primary)
  }, [primary])

  React.useEffect(() => {
    toLocalStorage('theme-secondary', secondary)
  }, [secondary])

  React.useEffect(() => {
    toLocalStorage('theme-logo', logo)
  }, [logo])

  React.useEffect(() => {
    if (favicon) {
      toLocalStorage('theme-favicon', favicon)
      changeFavicon(favicon)
    }
  }, [favicon])

  React.useEffect(() => {
    toLocalStorage('theme-loginImage', loginImage)
  }, [loginImage])

  const updateTheme = (
    primaryColor,
    secondaryColor,
    type = window.localStorage.getItem('theme-type') ?? defaultType
  ) => {
    let theme = { ...muiTheme }
    const newPrimaryColor = primaryColor && primaryColor !== 'null' ? primaryColor : theme.palette.primary.main
    const newSecondaryColor =
      secondaryColor && secondaryColor !== 'null' ? secondaryColor : theme.palette.secondary.main

    const isDark = type === 'dark'

    const coefLighter = 0.95
    const coefLight = 0.8
    const coefSemiLight = 0.6
    const coefDark = 0.2
    const coefDarker = 0.7

    theme.palette.type = type
    theme.palette.primary.lighter = lighten(newPrimaryColor, coefLighter)
    theme.palette.primary.light = lighten(newPrimaryColor, coefLight)
    theme.palette.primary.semilight = lighten(newPrimaryColor, coefSemiLight)
    theme.palette.primary.main = newPrimaryColor
    theme.palette.primary.dark = darken(newPrimaryColor, coefDark)
    theme.palette.primary.darker = darken(newPrimaryColor, coefDarker)

    theme.palette.secondary.lighter = lighten(newSecondaryColor, coefLighter)
    theme.palette.secondary.light = lighten(newSecondaryColor, coefLight)
    theme.palette.secondary.semilight = lighten(newSecondaryColor, coefSemiLight)
    theme.palette.secondary.main = newSecondaryColor
    theme.palette.secondary.dark = darken(newSecondaryColor, coefDark)
    theme.palette.secondary.darker = darken(newSecondaryColor, coefDarker)

    theme.palette.background.default = isDark ? '#303030' : lighten(newSecondaryColor, 0.95)
    theme.palette.background.paper = isDark ? '#424242' : '#ffffff'

    theme.overrides['MuiButton']['contained'] = {
      '&:not(.MuiButton-containedSecondary):not(.MuiButton-containedPrimary)': {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid ' + theme.palette.primary.light,
        '&:hover': {
          backgroundColor: theme.palette.primary.lighter,
        },
      },
    }

    theme.overrides['MuiButton']['outlined'] = {
      '&:not(.MuiButton-outlinedSecondary):not(.MuiButton-outlinedPrimary)': {
        color: theme.palette.background.paper,
        border: '2px solid ' + theme.palette.primary.light,
        '&:hover': {
          backgroundColor: theme.palette.primary.lighter,
        },
      },
    }

    theme = createTheme(theme, frFR)

    theme.shadows[1] = '-0px 6px 58px 0px ' + alpha(theme.palette.grey[600], 0.1)
    theme.shadows[2] = '-6px 6px 20px 0px ' + alpha(theme.palette.primary.main, 0.1)
    theme.shadows[3] = '0px 2px 8px 0px ' + alpha(theme.palette.grey[600], 0.4)

    const fontSizes = (param, { xs, sm, md, lg, xl }) => {
      theme.typography[param] = {
        fontSize: xs,
        [theme.breakpoints.up('sm')]: sm
          ? {
              fontSize: sm,
            }
          : {},
        [theme.breakpoints.up('md')]: md
          ? {
              fontSize: md,
            }
          : {},
        [theme.breakpoints.up('lg')]: lg
          ? {
              fontSize: lg,
            }
          : {},
        [theme.breakpoints.up('xl')]: xl
          ? {
              fontSize: xl,
            }
          : {},
      }
    }

    fontSizes('h1', {
      xs: theme.typography.pxToRem(27),
      sm: theme.typography.pxToRem(35),
    })

    fontSizes('h2', {
      xs: theme.typography.pxToRem(24),
      sm: theme.typography.pxToRem(30),
    })

    fontSizes('h3', {
      xs: theme.typography.pxToRem(20),
    })

    fontSizes('h4', {
      xs: theme.typography.pxToRem(20),
    })

    fontSizes('h5', {
      xs: theme.typography.pxToRem(18),
    })

    fontSizes('h6', {
      xs: theme.typography.pxToRem(theme.typography.htmlFontSize),
    })

    toLocalStorage('theme-type', type)
    setTheme(theme)
  }

  const updateLogo = (logoUrl) => {
    setLogo(logoUrl ?? logo)
  }

  const updateFavicon = (faviconUrl) => {
    setFavicon(faviconUrl ?? favicon)
  }

  const updateColors = (primary, secondary) => {
    setPrimary(primary ?? defaultPrimary)
    setSecondary(secondary ?? defaultSecondary)
  }

  return (
    <CustomThemeContext.Provider
      value={{
        primary: primary,
        secondary: secondary,
        favicon: favicon,
        logo: logo,
        loginImage: loginImage,
        theme: theme,
        updateTheme: updateTheme,
        updateLogo: updateLogo,
        updateFavicon: updateFavicon,
        updateColors: updateColors,
      }}
    >
      {children}
    </CustomThemeContext.Provider>
  )
}

export { CustomThemeProvider, CustomThemeContext }
