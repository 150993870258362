import ByCategoriesPage from 'area/owner/views/ByCategoriesPage'
import ByResourceRentPage from 'area/owner/views/ByResourceRentPage'
import DashboardPage from 'area/owner/views/DashboardPage'
import PlanPage from 'area/owner/views/PlanPage'
import PlansPage from 'area/owner/views/PlansPage'
import ActivitiesPage from 'components/views/CRMPages/ActivitiesPage/ActivitiesPage'
import ProspectsPage from 'components/views/CRMPages/ProspectsPage/ProspectsPage'
import DirectoryPage from 'components/views/DirectoryPage/DirectoryPage.js'
import HelpPage from 'components/views/HelpPage/HelpPage'
import KitchenPage from 'components/views/KitchenPage/KitchenPage'
import LoginPage from 'components/views/LoginPage/LoginPage.js'
import MessagesPage from 'components/views/MessagesPage/MessagesPage.js'
import MyReservationsPage from 'components/views/MyReservationsPage/MyReservationsPage.js'
import NewReservationPage from 'components/views/NewReservationPage/NewReservationPage.js'
import NewsPage from 'components/views/NewsPage/NewsPage.js'
import ConfirmationPage from 'components/views/PaymentPage/ConfirmationPage'
import ErrorPage from 'components/views/PaymentPage/ErrorPage'
import OnSiteConfirmationPage from 'components/views/PaymentPage/OnSiteConfirmationPage'
import PaymentPage from 'components/views/PaymentPage/PaymentPage'
import RegisterPage from 'components/views/RegisterPage/RegisterPage'
import UserBillsPage from 'components/views/UserBillsPage/UserBillsPage'
import UserCartPage from 'components/views/UserCartPage/UserCartPage'
import UserHistoryPage from 'components/views/UserHistoryPage/UserHistoryPage'
import UserNotificationsPage from 'components/views/UserNotificationsPage/UserNotificationsPage'
import UserProfilePage from 'area/social/views/UserProfilePage'
import TransformProspectPage from 'area/crm/views/TransformProspectPage'
import PhotoGroupPage from 'area/social/views/PhotoGroupPage'
import AdminPlansPage from 'area/admin/views/AdminPlansPage'
import MyAccountPage from 'area/social/views/MyAccountPage'
import MyInvoicesPage from 'area/social/views/MyInvoicesPage'
import EventsPage from 'area/social/views/EventsPage'
import EventPage from 'area/social/views/EventPage'
import Auth from 'hoc/auth'
import PublicationsPage from 'area/social/views/PublicationsPage'
import HomePage from 'area/home/views/HomePage'
import PublicProfilePage from 'area/social/views/PublicProfilePage'
import WifiPage from 'area/wifi/WifiPage'
import PartnersPage from 'area/partenaires/views/PartnersPage'
import WalletTransfertPage from 'area/wallet/views/WalletTransfertPage'
import DocumentsToValidate from 'area/user/components/DocumentsToValidate'
import PrintPage from 'area/print/PrintPage'

export const routes = [
  // { exact: true, name: 'index', path: '/', component: Auth(HomePage, true) },
  { exact: true, name: 'login', path: '/login', component: Auth(LoginPage, false) },
  { exact: true, name: 'register', path: '/register', component: Auth(RegisterPage, false) },

  // { exact: true, name: 'coworkingspaces', path: '/new-reservation', component: Auth(NewReservationPage, null) },
  // {
  //   exact: true,
  //   name: 'newreservation',
  //   path: '/new-reservation/:coworkingspace',
  //   component: Auth(NewReservationPage, null),
  // },
  // {
  //   exact: true,
  //   name: 'newreservation-category',
  //   path: '/new-reservation/:coworkingspace/:cwcategory',
  //   component: Auth(NewReservationPage, null),
  // },
  // {
  //   exact: true,
  //   name: 'newreservation-item',
  //   path: '/new-reservation/:coworkingspace/:cwcategory/:cwitem',
  //   component: Auth(NewReservationPage, null),
  // },
  // { exact: true, name: 'cart', path: '/cart', component: Auth(UserCartPage, null) },
  // { exact: true, name: 'payment', path: '/cart/validate', component: Auth(PaymentPage, null) },
  // { exact: true, name: 'payment-success', path: '/payment/success', component: Auth(ConfirmationPage, null) },
  // { exact: true, name: 'payment-error', path: '/payment/error', component: Auth(ErrorPage, null) },
  // { exact: true, name: 'payment-onsite', path: '/payment/onsite', component: Auth(OnSiteConfirmationPage, null) },

  // { exact: true, name: 'kitchen', path: '/kitchen', component: Auth(KitchenPage, null) },

  // { exact: true, name: 'myreservations', path: '/my-reservations', component: Auth(MyReservationsPage, true) },
  // { exact: true, name: 'directory', path: '/directory', component: Auth(DirectoryPage, true) },
  // { exact: true, name: 'messages', path: '/messages', component: Auth(MessagesPage, true) },
  // { exact: true, name: 'news', path: '/news', component: Auth(NewsPage, true) },

  // { exact: true, name: 'notifications', path: '/notifications', component: Auth(UserNotificationsPage, true) },
  // { exact: true, name: 'history', path: '/history', component: Auth(UserHistoryPage, true) },
  // { exact: true, name: 'bills', path: '/bills', component: Auth(UserBillsPage, true) },
  // { exact: true, name: 'help', path: '/help', component: Auth(HelpPage, true) },

  // /* Partie CRM */
  // { exact: true, name: 'prospects', path: '/prospects', component: Auth(ProspectsPage, true) },
  // { exact: true, name: 'prospect-detail', path: '/prospects/:prospect', component: Auth(ProspectsPage, true) },
  // { exact: true, name: 'activities', path: '/activities', component: Auth(ActivitiesPage, true) },

  // /* Partie Owner */
  // { exact: true, name: 'owner-dashboard', path: '/owner-dashboard', component: Auth(DashboardPage, true) },
  // { exact: true, name: 'owner-by-rr', path: '/owner-rr', component: Auth(ByResourceRentPage, true) },
  // { exact: true, name: 'owner-plans', path: '/owner-plans', component: Auth(PlansPage, true) },
  // { exact: true, name: 'owner-by-categories', path: '/owner-categories', component: Auth(ByCategoriesPage, true) },
  // { exact: true, name: 'owner-plan', path: '/owner-plans/:coworkingspace/:planpart', component: Auth(PlanPage, true) },

  // /* Partie CRM Transform */
  // { exact: true, name: 'transform', path: '/transform', component: Auth(TransformProspectPage, true) },

  /* Partie sociale */
  { exact: true, name: 'userprofile', path: '/users/:user', component: Auth(PublicProfilePage, true) },

  { exact: true, name: 'photogroup', path: '/photogroup', component: Auth(PhotoGroupPage, true) },
  { exact: true, name: 'photogroup-user', path: '/photogroup/:user', component: Auth(PublicProfilePage, true) },
  { exact: true, name: 'profile', path: '/profile', component: Auth(UserProfilePage, true) },
  { exact: true, name: 'my-account', path: '/profile/my-account', component: Auth(MyAccountPage, true) },
  // { exact: true, name: 'my-invoices', path: '/profile/my-invoices', component: Auth(MyInvoicesPage, true) },

  // { exact: true, name: 'events', path: '/events', component: Auth(EventsPage, null) },
  // { exact: true, name: 'event', path: '/events/:coworkingspace/:category/:event', component: Auth(EventPage, null) },

  { exact: true, name: 'publications', path: '/publications', component: Auth(PublicationsPage, true) },
  { exact: true, name: 'publications-group', path: '/publications/:group', component: Auth(PublicationsPage, true) },
  {
    exact: true,
    name: 'publications-channel',
    path: '/publications/:group/:channel',
    component: Auth(PublicationsPage, true),
  },

  /* Partie administration */
  // { exact: true, name: 'admin-plans', path: '/admin-plans', component: Auth(AdminPlansPage, true) },
  // { exact: true, name: 'admin-plan', path: '/admin-plans/:coworkingspace/:planpart', component: Auth(PlanPage, true) },

  // { exact: true, name: 'papercut', path: '/papercut', component: Auth(PrintPage, true) },
  // { exact: true, name: 'wifi', path: '/wifi', component: Auth(WifiPage, true) },
  // { exact: true, name: 'partners', path: '/partners', component: Auth(PartnersPage, true) },
  // { exact: true, name: 'wallet-transfert', path: '/wallet-transfert', component: Auth(WalletTransfertPage, true) },
  // {
  //   exact: true,
  //   name: 'documents-to-validate',
  //   path: '/documents-to-validate',
  //   component: Auth(DocumentsToValidate, true),
  // },
]

/**
 * Retourne le path d'une route
 * @param {string} name Le nom de la route a récupérer
 * @param {object} toReplace Un objet contenant les paramètres de la route à remplacer au format
 * @returns Le path de la route demandée
 * @example getRoutePath("user", { ":id": "17" }) => "/user/17"
 */
export const getRoutePath = (name, toReplace) => {
  const filtered = routes.filter((r) => r.name === name)
  if (filtered.length === 0) return null

  let path = filtered[0].path
  if (toReplace) {
    let keys = Object.keys(toReplace)
    for (let i = 0; i < keys.length; i++) {
      path = path.replaceAll(keys[i], toReplace[keys[i]])
    }
  }

  return path ?? '/publications'
}
