import Layout from 'components/views/Layout'
import { useTranslation } from 'react-i18next'
import image from 'assets/images/bravo.svg'
import {
  alpha,
  Box,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import { DEFAULT_IMG } from 'config'
import CustomLabel from 'components/CustomLabel'
import CustomTextField from 'components/CustomTextField'
import CustomButton from 'components/CustomComponents/CustomButton'
import React, { useContext } from 'react'
import { UserContext } from 'contexts/UserContext'
import { apiActions } from '_actions/api_actions'
import { getRoutePath } from 'routes'
import { useHistory } from 'react-router-dom'
import { formatCost } from 'components/ShowPrice'
import SquaredImg from 'area/social/components/SquaredImg'

const useStyles = makeStyles((theme) => ({
  image: {
    maxHeight: '60vh',
    maxWidth: '100%',
  },
  profileImg: {
    borderRadius: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}))

function TransformConfirmation({ coworker, methods, reservations, prospect }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)
  const history = useHistory()

  const [loading, setLoading] = React.useState(true)
  const [login, setLogin] = React.useState(null)
  const [password, setPassword] = React.useState(null)
  const [finalBalance, setFinalBalance] = React.useState(null)
  const [loadingMessage, setLoadingMessage] = React.useState(null)
  const [userId, setUserId] = React.useState(coworker?.CoworkerId ?? null)

  const theme = useTheme()

  const mapReservations = (res) => {
    // Crée le json à envoyer dans le body pour réserver les ressources
    const json = []
    res.map((r) => {
      r.startenddate?.map((dates) => {
        json.push({
          ResourceRentId: dates.id,
          StartDate: dates.start,
          EndDate: dates.end,
        })
        return
      })
      r.dateandduration?.map((dates) => {
        json.push({
          ResourceRentId: r.itemId,
          StartDate: dates.start,
          EndDate: dates.end,
        })
        return
      })
    })
    return {
      booking: json,
    }
  }

  async function closeOngoingActivities() {
    const activities = prospect?.OnGoingActivities
    ;(await activities) &&
      activities.map((a) => {
        apiCall(
          apiActions.closeActivity,
          {
            ProspectId: prospect.Id,
            ActivityId: a.Id,
          },
          'put'
        ).then((r) => {
          if (r.Error) {
            setLoading(false)
            return
          }
        })
      })
    setLoading(false)
  }

  const getBalanceAndArchive = (userId) => {
    // Renvoie le nombre de crédits du coworker, et archive le prospect en "gagné"
    setLoadingMessage(t('prospect.transform.loading.balance'))
    apiCall(apiActions.getWalletBalance, { coworkerId: userId }, 'get').then((r) => {
      setFinalBalance(r.Balance)
      setLoadingMessage(t('prospect.transform.loading.archive'))
      apiCall(apiActions.transformProspect, { ProspectId: prospect.Id, ProspectStatus: 210 }, 'put', {
        ProspectStatus: 210,
        Reason: '',
      }).then((r) => {
        if (!r.Error) {
          setLoadingMessage(t('prospect.transform.loading.close.activities'))
          closeOngoingActivities()
        }
      })
    })
  }

  const book = (res, userId) => {
    // Réserve les ressources si il y en a
    setLoadingMessage(t('prospect.transform.loading.book'))
    apiCall(apiActions.bookResources, { coworkerId: userId, prospectId: userId }, 'post', res).then((result) => {
      if (!result.Error) {
        getBalanceAndArchive(userId)
      }
    })
  }

  React.useEffect(() => {
    if (!coworker) {
      // On créer un nouveau coworker
      setLoadingMessage(t('prospect.transform.loading.create'))
      apiCall(apiActions.createCoworkerFromProspectData, {}, 'put', methods.getValues()).then((r) => {
        if (!r.Error) {
          setLogin(r.Login)
          setPassword(r.Password)
          setUserId(r.UserId)
          let userId = r.UserId
          if (reservations) {
            let reservationsToSend = mapReservations(reservations)
            book(reservationsToSend, userId)
          } else {
            getBalanceAndArchive(userId)
          }
        } else {
        }
      })
    } else {
      // On a un coworker existant, donc on modifie le coworker
      let userId = coworker.CoworkerId
      setLoadingMessage(t('prospect.transform.loading.update'))
      apiCall(apiActions.updateCoworkerFromProspectData, { coworkerId: userId }, 'post', methods.getValues()).then(
        (r) => {
          if (!r.Error) {
            if (reservations) {
              let reservationsToSend = mapReservations(reservations)
              book(reservationsToSend, userId)
            } else {
              getBalanceAndArchive(userId)
            }
          }
        }
      )
    }
  }, [])

  if (loading)
    return (
      <Box display="flex" height="100%" alignItems="center" justifyContent="center">
        <div>
          <Box mb={2} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
          <Typography align="center" variant="subtitle2">
            <Box component="span" color="primary.darker">
              {loadingMessage}
            </Box>
          </Typography>
        </div>
      </Box>
    )

  return (
    <Layout pageTitle={t('prospect.transform.over.title')} pageDescription={t('prospect.transform.over.subtitle')}>
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" mb={4}>
          <img className={classes.image} src={image} alt="" width={350} height={350} />
        </Box>
        <Box mb={2}>
          <Typography align="center" variant="h3">
            {coworker ? t('prospect.transform.confirmation.old.title') : t('prospect.transform.confirmation.new.title')}
          </Typography>
          <Typography align="center" variant="h3">
            {reservations &&
              (coworker
                ? t('prospect.transform.confirmation.old.subtitle')
                : t('prospect.transform.confirmation.new.subtitle'))}
          </Typography>
        </Box>
        {!coworker && (
          <Box mb={2}>
            <CustomPaper>
              <Box p={3}>
                <Box display="flex" alignItems="center">
                  <Box mr={2}>
                    <SquaredImg src={methods.getValues().PictureProfileUrl ?? DEFAULT_IMG} width={180} />
                  </Box>
                  <Box width="100%">
                    <Box mb={2}>
                      <CustomLabel>{t('prospect.transform.over.login')}</CustomLabel>
                      <CustomTextField disabled fullWidth value={login ?? ''} />
                    </Box>
                    <CustomLabel>{t('prospect.transform.over.password')}</CustomLabel>
                    <CustomTextField disabled fullWidth value={password ?? ''} />
                  </Box>
                </Box>
              </Box>
            </CustomPaper>
          </Box>
        )}
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={7}>
            <Paper
              style={{
                backgroundColor:
                  finalBalance <= 0 ? alpha(theme.palette.error.main, 0.3) : alpha(theme.palette.success.main, 0.3),
              }}
            >
              <Box p={2}>
                <Typography align="center" variant="h6">
                  {t('prospect.transform.final.balance', { amount: formatCost(finalBalance) })}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={7}>
            {finalBalance > 0 ? (
              <CustomButton
                onClick={() => history.push(getRoutePath('profile'))}
                variant="outlined"
                fullWidth
                color="secondary"
              >
                <Box color="primary.darker" component="span" p={1}>
                  {t('prospect.transform.show.account')}
                </Box>
              </CustomButton>
            ) : (
              <CustomButton
                onClick={() => (window.location = `/Admin/CreditPackage/FromCoworker/${userId}`)}
                variant="outlined"
                fullWidth
                color="secondary"
              >
                <Box color="primary.darker" component="span" p={1}>
                  {t('prospect.transform.credit.account')}
                </Box>
              </CustomButton>
            )}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default TransformConfirmation
