import { makeStyles } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import SearchBar from 'material-ui-search-bar'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  button: {
    transform: 'inherit',
    padding: 0,
    margin: 0,
    marginRight: theme.spacing(-2),
  },
  container: {
    '& > button:last-child': {
      visibility: 'hidden',
    },
  },
}))

function SearchBarItems({ handleRequestSearch, reset }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [value, setValue] = React.useState('')

  React.useEffect(() => {
    setValue('')
  }, [reset])

  return (
    <CustomPaper>
      <SearchBar
        className={classes.container}
        classes={{ searchIconButton: classes.button }}
        value={value}
        cancelOnEscape
        onChange={(v) => setValue(v)}
        placeholder={t('filters.searchbar.placeholder')}
        onRequestSearch={(value) => handleRequestSearch(value)}
        closeIcon={<></>}
      />
    </CustomPaper>
  )
}

export default SearchBarItems
