import { Box, makeStyles } from '@material-ui/core'
import Title from 'area/social/components/Title'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import CustomButton from './CustomButton'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
}))

function CustomForm({ title, children, methods, validationButtonText, onFormSubmit }) {
  const classes = useStyles()

  return (
    <form onSubmit={methods.handleSubmit(onFormSubmit)}>
      <CustomPaper className={classes.container}>
        <Title title={title} />
        {children}
        {methods.formState.dirtyFields && Object.entries(methods.formState.dirtyFields).length > 0 && (
          <Box mt={2} display="flex" justifyContent="center">
            <CustomButton variant="contained" color="secondary" type="submit">
              {validationButtonText}
            </CustomButton>
          </Box>
        )}
      </CustomPaper>
    </form>
  )
}

export default CustomForm
