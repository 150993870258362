import { Box, makeStyles, Typography, Button, Select, MenuItem, Hidden, Grid, Link as MuiLink } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Layout from 'components/views/Layout'
import CreateProspect from 'components/views/CRMPages/ProspectsPage/CreateProspect'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'contexts/UserContext'
import { SocietyContext } from 'contexts/SocietyContext'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import { apiActions } from '_actions/api_actions'
import CustomTextField from 'components/CustomTextField'
import CustomChoiceButtons from 'components/CustomComponents/CustomChoiceButton'
import { getActivityText, getConversionText } from '../Utils/Enums'
import moment from 'moment'
import Icon from 'components/IcoMoon/Icon'
import { useConfirm } from 'material-ui-confirm'
import EndActivity from '../ProspectPage/EndActivity'
import { useCustomTable } from 'components/CustomComponents/CustomTable'
import { getRoutePath } from 'routes'
import { Link } from 'react-router-dom'
import useSWR from 'swr'

const headerButtonWidth = 200
const buttonFilterWidth = 120
const useStyles = makeStyles((theme) => ({
  button: {
    visibility: 'hidden',
  },
  container: {
    '& > button:last-child': {
      visibility: 'hidden',
    },
    width: 250,
  },
  createButton: {
    height: '100%',
    width: headerButtonWidth,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  filterContainer: {
    '& .MuiGrid-item': {
      width: '100%',
      '&:nth-child(2n+1)': {
        borderRight: '1px solid ' + theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
          borderRight: 'none',
        },
      },
    },
  },
  buttonFilter: {
    height: '100%',
    width: buttonFilterWidth,
  },
  actionButton: {
    minWidth: 'inherit',
    padding: theme.spacing(2),
  },
  line: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  link: {
    color: 'inherit',
    display: 'block',
  },
}))

function AllProspects({ onProspectClick }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { apiCall, user } = useContext(UserContext)
  const { society } = useContext(SocietyContext)
  const confirm = useConfirm()

  const filtersSession = JSON.parse(sessionStorage.getItem('crmfilters'))
  if (!filtersSession)
    sessionStorage.setItem(
      'crmfilters',
      JSON.stringify({ searchText: '', owner: ['owner'], status: ['200'], csId: user?.CoworkingSpaceId ?? '' })
    )

  const [openMobileFilter, setOpenMobileFilter] = React.useState(false)
  const [createProspectDialog, setCreateProspectDialog] = React.useState(false)
  const [searchText, setSearchText] = React.useState(filtersSession?.searchText ?? '')
  const [csId, setCsId] = React.useState(filtersSession?.csId ?? user?.CoworkingSpaceId ?? '')
  const [filterShowProspects, setFilterShowProspect] = React.useState(filtersSession?.owner ?? ['owner'])
  const [filterShowStatus, setFilterShowStatus] = React.useState(filtersSession?.status ?? ['200'])
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [prospectFocused, setProspectFocused] = React.useState(null)
  const [archive, setArchive] = React.useState(null)

  const { data: cSpaces } = useSWR(`coworkingSpaces`, () => apiCall(apiActions.getCoworkingSpaces).then((r) => r.data))

  const columnNames = ['name', 'society', 'interest', 'date', 'title', 'coworkingspace', 'owner', 'conversion']
  let columns = []
  columns.push({ name: 'id', options: { filter: false, sort: false, display: 'excluded', download: false } })
  columnNames.map((cName) =>
    columns.push({
      name: cName,
      label: t('crm.allprospects.tab.' + cName + '.title'),
      options: {
        filter: cName !== 'title' && cName !== 'conversion',
        sort: true,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.tableData[tableMeta.rowIndex][0]
          if (cName === 'name')
            return (
              <MuiLink
                className={classes.link}
                component={Link}
                to={getRoutePath('prospect-detail', { ':prospect': id })}
                onClick={(e) => e.stopPropagation()}
              >
                {value}
              </MuiLink>
            )
          else return value
        },
      },
    })
  )

  React.useEffect(() => {
    refresh()
  }, [csId, searchText, filterShowStatus, filterShowProspects])

  const handleCSChange = (e) => {
    setCsId(e.target.value)
    changeSessionFilter('csId', e.target.value)
  }

  const handleTextChange = (e) => {
    if (e.target.value !== searchText) {
      setSearchText(e.target.value)
      changeSessionFilter('searchText', e.target.value)
    }
  }

  const changeSessionFilter = (filterName, value) => {
    let old = JSON.parse(sessionStorage.getItem('crmfilters'))
    old[filterName] = value
    sessionStorage.setItem('crmfilters', JSON.stringify(old))
  }

  const callGetProspects = (thisFilters) => {
    const { pageSize, page, filters, sortOrder } = thisFilters
    let filtersWithIndex = []
    filters && filters.map((f) => filtersWithIndex.push({ ...f, index: columnNames.indexOf(f.column) }))
    if (sortOrder) sortOrder.index = columnNames.indexOf(sortOrder.name)
    return apiCall(apiActions.getAllProspect, {}, 'post', {
      PerPage: pageSize,
      Page: page,
      CoworkingSpaceId: csId,
      QueryName: searchText,
      ProspectStatus: parseInt(filterShowStatus[0]),
      OwnerOnly: filterShowProspects[0] === 'owner',
      Filters: filtersWithIndex.length > 0 ? filtersWithIndex : null,
      SortOrder: sortOrder,
    }).then((r) => r)
  }

  const transformData = (data) => {
    let formattedData = []
    data &&
      data.map((p) => {
        formattedData.push({
          id: p.Id,
          name: p.FirstName + ' ' + p.LastName,
          society: p.SocietyName ?? t('prospects.society.none'),
          interest:
            p.Interests?.length > 0 ? p.Interests?.map((i) => i.Name)?.join(', ') : t('crm.prospects.no.interests'),
          date: p.ActivityDate ? moment(p.ActivityDate).format('DD/MM/YYYY') : t('crm.prospects.no.activity'),
          title: p.ActivityType !== -1 ? t(getActivityText(p.ActivityType)) : t('crm.prospects.no.activity'),
          coworkingspace: p.CoworkingSpace?.Name,
          owner: p.Owner?.Name,
          conversion: t(getConversionText(p.Conversion)),
        })
      })
    return formattedData
  }

  const archiveProspect = (id) => {
    apiCall(apiActions.getProspectById, { ProspectId: id }).then((r) => {
      setProspectFocused(r)
      setOpenDeleteDialog(true)
    })
  }

  const unarchive = (prospectId, resolve) => {
    confirm({ title: '', description: t('prospects.unarchive.confirm') }).then(() => {
      apiCall(apiActions.unarchiveProspect, { ProspectId: prospectId }, 'post').then((r) => {
        if (!r.Error) {
          resolve()
        }
      })
    })
  }

  const handleDeleteClick = (id) => {
    return new Promise((resolve, reject) => {
      if (filterShowStatus[0] === '230') {
        unarchive(id, resolve)
      } else if (filterShowStatus[0] === '220') {
        setArchive(id)
      } else {
        archiveProspect(id)
      }
    })
  }

  const handleReactiveProspect = () => {
    apiCall(apiActions.transformProspect, { ProspectId: archive, ProspectStatus: 200 }, 'put', {
      ProspectStatus: 200,
      Reason: '',
    }).then((r) => {
      setArchive(null)
      refresh()
    })
  }

  const handleRowClick = (rowData) => {
    const prospectId = rowData[0]
    onProspectClick(null, prospectId)
  }

  const { render, refresh } = useCustomTable({
    columns: columns,
    onRowClick: handleRowClick,
    read: callGetProspects,
    customTransformData: transformData,
    customAction: (id) => {
      return <Icon icon="archiver" />
    },
    remove: handleDeleteClick,
    hasCustomDelete: true,
    iconDelete: filterShowStatus[0] === '230' ? 'annuaire' : filterShowStatus[0] === '220' ? 'liste' : 'supprimer',
  })

  const SelectSociety = () => {
    return (
      <Box mr={1}>
        <Select onChange={(e) => handleCSChange(e)} value={csId} variant="outlined" displayEmpty>
          <MenuItem value="">Tous</MenuItem>
          {cSpaces?.map((c) => {
            return (
              <MenuItem key={c.Id} value={c.Id}>
                {c.Name}
              </MenuItem>
            )
          })}
        </Select>
      </Box>
    )
  }

  const FilterName = () => {
    return (
      <Box mr={2}>
        <CustomTextField
          fullWidth
          variant="outlined"
          defaultValue={searchText}
          onBlur={handleTextChange}
          label={t('crm.allprospects.search.label')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleTextChange(e)
          }}
          placeholder={t('crm.allprospects.search.placeholder')}
        />
      </Box>
    )
  }

  const Filters = () => {
    return (
      <Box mb={2}>
        <CustomPaper>
          <Box p={3}>
            <Box mb={4}>
              <Typography align="center" variant="h3">
                Filtres
              </Typography>
            </Box>
            <Grid container spacing={4} className={classes.filterContainer}>
              <Grid item xs={12} md={6}>
                <FilterName />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box ml={2}>
                  <CustomChoiceButtons
                    buttonLabels={['Dont je suis responsable', 'Tout le monde']}
                    buttonNames={['owner', 'all']}
                    label="Voir les prospects"
                    value={filterShowProspects}
                    onClickButton={(name) => {
                      setFilterShowProspect([name[0]])
                      changeSessionFilter('owner', [name[0]])
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomChoiceButtons
                  buttonLabels={[
                    t('crm.prospect.won'),
                    t('crm.prospect.lost'),
                    t('crm.prospect.ongoing.title'),
                    t('crm.prospect.archived'),
                  ]}
                  buttonNames={['210', '220', '200', '230']}
                  label={t('crm.allprospects.see.prospects')}
                  value={filterShowStatus}
                  onClickButton={(names) => {
                    setFilterShowStatus(names)
                    changeSessionFilter('status', names)
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </CustomPaper>
      </Box>
    )
  }

  return (
    <Layout
      pageTitle={t('crm.allprospects.title')}
      pageDescription={t('crm.allprospects.description')}
      titleComponent={
        <Button
          className={classes.createButton}
          variant="outlined"
          color="secondary"
          onClick={() => setCreateProspectDialog(true)}
        >
          <Box color="primary.darker">{t('crm.allprospects.create')}</Box>
        </Button>
      }
    >
      <Hidden smDown>
        <Filters />
      </Hidden>
      <Hidden mdUp>
        <Box mb={1}>
          <Box display="flex">
            <Box mr={1} display="flex" alignItems="center">
              <SelectSociety />
              <Button
                variant="outlined"
                color="secondary"
                className={classes.buttonFilter}
                onClick={() => setOpenMobileFilter(true)}
              >
                Filtres
              </Button>
              <CustomDialog open={openMobileFilter} handleClose={() => setOpenMobileFilter(false)} fullScreen>
                <Filters />
              </CustomDialog>
            </Box>
          </Box>
        </Box>
      </Hidden>
      <CustomPaper>
        <Box p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <div>
              <Box mb={1}>
                <Typography variant="h5">
                  <Box component="span" color="primary.darker" fontWeight="fontWeightBold">
                    {t('crm.allprospects.tab.title')}
                  </Box>
                </Typography>
              </Box>
              <Typography variant="caption" color="textSecondary">
                {t('crm.allprospects.tab.description')}
              </Typography>
            </div>
            <Hidden smDown>
              <div>
                <Box display="flex">
                  <SelectSociety />
                </Box>
              </div>
            </Hidden>
          </Box>
          {csId !== null && searchText !== null && render}
        </Box>
      </CustomPaper>
      <CustomDialog
        title={t('crm.prospect.create.title')}
        open={createProspectDialog}
        maxWidth="sm"
        fullWidth
        handleClose={() => setCreateProspectDialog(false)}
      >
        <CreateProspect handleClose={() => setCreateProspectDialog(false)} />
      </CustomDialog>
      {openDeleteDialog && (
        <CustomDialog
          open={openDeleteDialog}
          maxWidth="lg"
          fullWidth
          handleClose={() => setOpenDeleteDialog(false)}
          title={t('crm.prospect.lost.title')}
        >
          <EndActivity
            status="230"
            prospect={prospectFocused}
            isWon={false}
            handleClose={() => setOpenDeleteDialog(false)}
            refreshData={() => refresh()}
          />
        </CustomDialog>
      )}
      <CustomDialog
        open={archive !== null}
        handleClose={() => setArchive(null)}
        maxWidth="sm"
        fullWidth
        title={t('crm.prospect.archive.title')}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box mr={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                archiveProspect(archive)
                setArchive(null)
              }}
            >
              {t('crm.prospect.archive')}
            </Button>
          </Box>
          <Button variant="outlined" color="secondary" onClick={handleReactiveProspect}>
            {t('crm.prospect.reactive')}
          </Button>
        </Box>
      </CustomDialog>
    </Layout>
  )
}

export default AllProspects
