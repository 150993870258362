import { Paper, PaperProps } from '@material-ui/core'
import { lighten, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const customPaperStyle = makeStyles((theme) => {
  return {
    paper: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.light,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    },
    selectable: {
      borderColor: lighten(theme.palette.secondary.main, 0.6),
      cursor: 'pointer',
    },
  }
})

interface CustomPaperProps extends PaperProps {
  selectable?: boolean
  className?: string
}

const CustomPaper: React.FC<CustomPaperProps> = function (props) {
  const classes = customPaperStyle()
  const { className, selectable, ...rest } = props

  let classNames = [classes.paper]
  if (className) classNames.push(className)
  if (selectable) classNames.push(classes.selectable)

  return <Paper {...rest} className={classNames.join(' ')} />
}

export default CustomPaper
