import { Accordion, AccordionDetails, Box, Button, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DynamicForm from 'components/DynamicForm/DynamicForm'
import Icon from 'components/IcoMoon/Icon'
import SquareButton from 'components/SquareButton'
import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  details: {
    position: 'relative',
    paddingTop: theme.spacing(2),
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& > .MuiCollapse-container': {
      flexGrow: 1,
    },
  },
  dialogValidateForm: {
    zIndex: theme.zIndex.modal + 1,
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    '&.ghost': {
      position: 'relative',
      visibility: 'hidden',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      position: 'relative',
      padding: 0,
      boxShadow: 'none',
      '&.ghost': {
        display: 'none',
      },
    },
  },
}))

function ItemsFilters({
  csId,
  categoryId,
  onFiltersChange,
  promFilters = [],
  onExitDialog,
  dialogIsOpen,
  resetFilters,
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = React.useState(true)

  const defaultValues = {}
  promFilters.forEach((filter) => (defaultValues[filter.Name] = filter.Value ? filter.Value : null))

  let methods = useForm({
    defaultValues: defaultValues,
    shouldUnregister: true,
  })

  const watchFields = useWatch({
    control: methods.control,
  })

  React.useEffect(() => {
    methods.reset()
  }, [resetFilters])

  React.useEffect(() => {
    if (dialogIsOpen) return

    const formTimeout = setTimeout(() => {
      onFiltersChange(watchFields)
    }, 600)

    return () => clearTimeout(formTimeout)
  }, [watchFields])

  const handleValidateMobile = () => {
    onFiltersChange(watchFields)
    onExitDialog()
  }

  const handleResetForm = () => {
    methods.reset(defaultValues)
  }

  const BottomActionBtn = ({ ghost }) => {
    const compClasses = [classes.dialogValidateForm]
    if (ghost) {
      compClasses.push('ghost')
    }

    return (
      <Box className={compClasses}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs="auto">
            <Button size="small" startIcon={<Icon icon="supprimer" />} onClick={handleResetForm}>
              {t('common.delete')}
            </Button>
          </Grid>
          <Grid item xs>
            <Button variant="contained" color="secondary" onClick={handleValidateMobile} fullWidth>
              {t('common.validate')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Accordion
      expanded={dialogIsOpen ? true : isExpanded}
      defaultExpanded={true}
      className={dialogIsOpen ? classes.dialog : ''}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        {dialogIsOpen && (
          <SquareButton onClick={onExitDialog} size="small">
            <Icon icon="close" />
          </SquareButton>
        )}
        <Typography variant="h4">
          <Box color="primary.darker" component="span">
            {t('filters.title')}
          </Box>
        </Typography>
        <Button variant="outlined" color="secondary" onClick={() => setIsExpanded(!isExpanded)}>
          {t('filter.active.text', { count: Object.keys(methods.formState.dirtyFields).length })}
        </Button>
      </Box>
      <AccordionDetails className={classes.details}>
        <Box width="100%">
          <Divider className={classes.divider} />
          {promFilters && (
            <DynamicForm
              csId={csId}
              categoryId={categoryId}
              methods={methods}
              inputs={promFilters}
              reset={resetFilters}
            />
          )}
          {dialogIsOpen && (
            <>
              <BottomActionBtn />
              <BottomActionBtn ghost />
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default ItemsFilters
