import { Box, Button, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SocietyContext } from 'contexts/SocietyContext'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  btn: {
    minWidth: 0,
  },
}))

export default function LangsSwitch() {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState(i18n.language)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()
  const { society } = useContext(SocietyContext)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeLanguage = (key) => {
    setLang(key)
    moment.locale(key)
    i18n.changeLanguage(key)
    window.localStorage.setItem('lang', key)
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        className={classes.btn}
        variant="outlined"
        color="secondary"
        size="small"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {lang.split('-')[0]}
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {society?.Langs?.map((item) => (
          <MenuItem onClick={() => changeLanguage(item)} key={item}>
            {item.split('-')[0]}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
