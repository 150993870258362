let consoleHolder = window.console
function debug(bool) {
  if (!bool) {
    consoleHolder = window.console
    window.console = {}
    Object.keys(consoleHolder).forEach(function (key) {
      window.console[key] = function () {}
    })
  } else {
    window.console = consoleHolder
  }
}
debug(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
