import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import { UserContext } from 'contexts/UserContext'
import 'helper/number'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  csContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(-1),
    '& > button': {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  rotateIcon: {
    transform: 'rotate(90deg)',
  },
  icon: {
    color: theme.palette.primary.darker,
    fontSize: 70,
  },
}))

function OccupancyRate({ filters }) {
  const { t } = useTranslation()
  const { apiCall } = React.useContext(UserContext)
  const { type = 'month' } = filters
  const isMonth = type === 'month'
  const classes = useStyles()

  const { data } = useSWR('getOccupancyRate/' + JSON.stringify(filters), () =>
    apiCall(apiActions.ownerGetOccupancyRate, {}, 'post', filters).then((r) => {
      if (r.Error) {
        //
      } else {
        return {
          rate: r.Rate,
          evolution: r.Evolution,
        }
      }
    })
  )

  const infos = data ?? {}
  const { rate, evolution } = infos

  return (
    <Box textAlign="center">
      <Typography variant="h4" gutterBottom>
        <Box color="primary.darker" component="span">
          {t('owner-dashboard.occupationrate.title')}
        </Box>
      </Typography>
      {data ? (
        <div>
          <Box p={2}>
            <Box mt={4} mb={1}>
              <Icon className={classes.icon} icon="agenda-proprio" />
            </Box>
            <Typography variant="h1">
              <Box color="primary.darker" component="span">
                {rate !== 'NaN' && rate.formatMoney()} %
              </Box>
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {isMonth
                ? t('owner-dashboard.occupationrate.rate.current.month')
                : t('owner-dashboard.occupationrate.rate.current.year')}
            </Typography>
          </Box>
          <Typography variant="h6">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={evolution > 0 ? 'success.main' : evolution === 0 ? 'primary.main' : 'error.main'}
              component="span"
            >
              <Icon
                fontSize="small"
                className={evolution < 0 ? classes.rotateIcon : ''}
                icon={evolution === 0 ? 'idicateur2' : 'idicateur1'}
              />
              <Box component="span" ml={1}>
                {evolution !== 'NaN' ? evolution.formatMoney() : ''} %
              </Box>
            </Box>
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {isMonth
              ? t('owner-dashboard.occupationrate.rate.previous.month')
              : t('owner-dashboard.occupationrate.rate.previous.year')}
          </Typography>
        </div>
      ) : (
        <Box p={2}>
          <LoadingElem minHeight="50px" />
        </Box>
      )}
    </Box>
  )
}

export default OccupancyRate
