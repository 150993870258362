import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Layout from 'components/views/Layout'
import { DEFAULT_IMG } from 'config'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { UserContext } from 'contexts/UserContext'
import { apiActions } from '_actions/api_actions'
import LoadingElem from 'components/LoadingElem'
import TransformProspectForm from 'area/crm/views/TransformProspectPage/TransformProspectForm'
import { useTranslation } from 'react-i18next'
import Icon from 'components/IcoMoon/Icon'
import image from 'assets/images/doublon.svg'

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: theme.shape.borderRadius,
  },
  captiontext: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    fontFamily: theme.typography.subtitle2.fontFamily,
    color: theme.palette.text.secondary,
  },
  container: {
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  boxSmallIcon: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid ' + theme.palette.text.secondary,
    borderRadius: '50%',
  },
  smallIcon: {
    padding: theme.spacing(0.5),
  },
}))

function TransformProspectPage() {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const prospect = history.location.state?.prospect

  const { apiCall } = useContext(UserContext)
  const [infosCoworker, setInfoCoworker] = React.useState(null)
  const [showForm, setShowForm] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [ready, setReady] = React.useState(false)

  React.useEffect(() => {
    if (prospect && prospect.Email) {
      apiCall(apiActions.coworkerExists, {}, 'post', { email: prospect.Email }).then((r) => {
        if (r.Error) {
          //
        } else {
          const coworker = r.data[0]
          if (!coworker) {
            setShowForm(true)
          } else {
            setInfoCoworker(coworker)
          }
        }
        setLoading(false)
      })
    } else {
      setLoading(false)
      setShowForm(true)
    }
  }, [prospect])

  const continueWithCoworker = (use) => {
    if (!use) {
      setInfoCoworker(null)
      setShowForm(true)
    } else {
      apiCall(apiActions.getCoworker, { coworkerId: infosCoworker.coworkerId }, 'get').then((r) => {
        setInfoCoworker(r)
        setShowForm(true)
      })
    }
  }

  if (loading) return <LoadingElem />

  if (showForm) return <TransformProspectForm defaultValues={infosCoworker} prospect={prospect} />

  return (
    <Layout
      pageTitle={t('prospect.transform.already.coworker.page.title')}
      pageDescription={t('prospect.transform.already.coworker.page.subtitle')}
    >
      {infosCoworker && (
        <Grid container justify="center" className={classes.container} spacing={2}>
          <Grid item xs={12}>
            <Box mb={2} mt={2}>
              <img className={classes.img} src={image} height={350} width={350} />
            </Box>
          </Grid>
          <Box mb={4}>
            <Grid item xs={12}>
              <Typography variant="h5">
                <Box component="span" color="primary.darker">
                  {t('prospect.transform.already.coworker.email')}
                </Box>
              </Typography>
            </Grid>
          </Box>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomPaper>
                <Box p={0.5}>
                  <Box display="flex" alignItems="center">
                    <Box mr={1} display="flex" alignItems="center">
                      <img className={classes.img} src={infosCoworker.ImageUrl ?? DEFAULT_IMG} width={40} height={40} />
                    </Box>
                    <Box display="block" mr={4}>
                      <div>
                        <Typography variant="h5">
                          <Box component="span" color="primary.darker">
                            {infosCoworker.FirstName + ' ' + infosCoworker?.LastName}
                          </Box>
                        </Typography>
                      </div>
                      <div>
                        <Box color="text.secondary" display="flex" alignItems="center">
                          <Box className={classes.boxSmallIcon}>
                            <Icon className={classes.smallIcon} icon="deconnexion" />
                          </Box>
                          <Typography variant="subtitle2">&nbsp; • {infosCoworker.Job}</Typography>
                        </Box>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </CustomPaper>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <CustomButton color="secondary" onClick={() => continueWithCoworker(false)} fullWidth variant="outlined">
              <Box color="primary.darker">{t('prospect.transform.already.coworker.new')}</Box>
            </CustomButton>
          </Grid>
          <Grid item xs={3}>
            <CustomButton color="secondary" onClick={() => continueWithCoworker(true)} fullWidth variant="contained">
              {t('prospect.transform.already.coworker.continue')}
            </CustomButton>
          </Grid>
        </Grid>
      )}
    </Layout>
  )
}

export default TransformProspectPage
