import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import NotFoundPage from 'components/views/NotFoundPage/NotFoundPage'
import { basename } from 'config'
import { ConfirmProvider } from 'material-ui-confirm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { routes } from 'routes'
import 'styles/global.scss'
import { SWRConfig } from 'swr'

function AppContent() {
  const { t } = useTranslation()
  const routeComponents = routes.map(({ exact, path, component }, key) => (
    <Route exact={exact} path={path} component={component} key={key} />
  ))

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ConfirmProvider
        defaultOptions={{
          title: t('common.confirm.dialog.title'),
          confirmationText: t('common.confirm.dialog.button.ok'),
          cancellationText: t('common.confirm.dialog.button.nok'),
        }}
      >
        <SWRConfig
          value={{
            revalidateOnFocus: false,
          }}
        >
          <BrowserRouter basename={basename}>
            <Switch>
              {routeComponents}
              <Route component={NotFoundPage} />
            </Switch>
          </BrowserRouter>
        </SWRConfig>
      </ConfirmProvider>
    </MuiPickersUtilsProvider>
  )
}

export default AppContent
