import { Box, Button, Container, Fade, Grid, Hidden, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomErrorMessage from 'components/CustomComponents/CustomErrorMessage'
import CustomStepper from 'components/CustomComponents/CustomStepper'
import { MediaImage } from 'components/CustomComponents/MediaImage'
import LoadingPage from 'components/LoadingPage'
import RegisterForm from 'components/views/RegisterPage/RegisterForm'
import { CustomThemeContext } from 'contexts/CustomThemeContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { getRoutePath } from 'routes'
import { authUser, loginUser, registerUser } from '_actions/auth_actions'
import image from 'assets/images/bravo.svg'
import MuiLink from '@material-ui/core/Link'
import Icon from 'components/IcoMoon/Icon'
import CustomButton from 'components/CustomComponents/CustomButton'

const mobileHeroHeight = '18vh'
const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  leftColumn: {
    zIndex: 1,
    padding: theme.spacing(4),
    display: 'flex',
    flex: 4,
    alignItems: 'center',
    '& > div': {
      margin: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: `calc(${mobileHeroHeight} - ${4 * theme.shape.borderRadius}px)`,
    },
  },
  rightColumn: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    width: '100%',
    padding: theme.spacing(8),
    '& > div': {
      textAlign: 'center',
      width: '100%',
    },
    flex: '1 1 100%',
    [theme.breakpoints.up('sm')]: {
      flex: '0 1 50%',
      marginTop: 0,
      marginLeft: -theme.shape.borderRadius,
    },
    [theme.breakpoints.up('md')]: {
      flex: '1 1 350px',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
    },
  },
  leftColumnContent: {
    position: 'relative',
    zIndex: 10,
    '& .MuiTypography-root': {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    '& .MuiButton-root': {
      maxWidth: 200,
    },
  },
  bgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    '& > img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      opacity: 0.35,
    },
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(4),
  },
  dot: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    fontSize: 10,
  },
}))

function ConfirmRegistered({ user }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const query = new URLSearchParams(location.search)
  const redirectUrl = query.get('r')

  let buttonText = null
  if (redirectUrl) {
    switch (redirectUrl) {
      case getRoutePath('cart'):
        buttonText = t('register.confirm.back.cart')
        break
      case getRoutePath('payment'):
        buttonText = t('register.confirm.back.payment')
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      loginUser({ email: user.email, password: user.password }).then((response) => {
        if (response.isAuth) {
          authUser().then((r) => {
            history.push(getRoutePath('index'))
            history.go(0)
          })
        }
      })
    }, [3000])
  })

  return (
    <>
      <Box mb={8}>
        <Typography align="center" variant="h1">
          <Box component="span" color="primary.darker">
            {t('register.confirm.title')}
          </Box>
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          <Box component="span" display="flex" alignItems="center" justifyContent="center">
            {t('register.confirm.subtitle')}
          </Box>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={8}>
        <img src={image} height={300} width={300} />
      </Box>
      <Paper className={classes.paper}>
        <Container maxWidth="md">
          <Typography align="center" variant="subtitle1">
            <Box component="span" color="primary.darker">
              {t('register.confirm.text')}
            </Box>
          </Typography>
        </Container>
      </Paper>
      {buttonText && redirectUrl && (
        <Box mt={2} display="flex" justifyContent="center">
          <CustomButton onClick={() => history.push(redirectUrl)} variant="outlined" color="secondary">
            <Box color="primary.darker">{buttonText}</Box>
          </CustomButton>
        </Box>
      )}
    </>
  )
}

function RegisterPage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const customTheme = React.useContext(CustomThemeContext)
  const location = useLocation()
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [currentStep, setCurrentStep] = React.useState(0)
  const [registered, setRegistered] = React.useState(null)

  const query = new URLSearchParams(location.search)
  const returnUrl = query.get('r')

  const handleRegister = (values, additionalFields) => {
    setLoading(true)
    if (additionalFields) {
      values.AdditionalDatas = []
      Object.entries(additionalFields).map(([key, _]) => {
        values.AdditionalDatas.push({ Id: key, Value: values[key] })
      })
    }
    return new Promise((resolve, reject) => {
      registerUser(values).then((r) => {
        if (r.Error) {
          setError(t(r.Error))
        } else {
          setRegistered(values)
          setError(null)
        }
        setLoading(false)
        resolve()
      })
    })
  }

  const handleLogin = () => {
    history.push(getRoutePath('login') + history.location.search)
  }

  if (loading) return <LoadingPage />

  return (
    <Fade in={true}>
      <Box className={classes.container}>
        <Paper elevation={0} className={classes.leftColumn}>
          <Container maxWidth="md">
            {customTheme.logo && (
              <Box mb={4} display="flex" justifyContent="center">
                <MediaImage alt="logo" src={customTheme.logo} mediaParams={{ xs: { height: 80 } }} />
              </Box>
            )}
            {!registered && (
              <Box mb={2}>
                <Typography gutterBottom align="center" variant="h1">
                  <Box component="span" color="primary.darker">
                    {t('register.title')}
                  </Box>
                </Typography>
                <Grid container justify="center">
                  <Grid item xs={12} lg={8}>
                    <CustomStepper
                      totalStep={2}
                      activeStep={currentStep}
                      labels={[t('register.form.step.first'), t('register.form.step.second')]}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            {error && (
              <Box mb={2}>
                <CustomErrorMessage message={error} />
              </Box>
            )}
            {registered ? (
              <ConfirmRegistered user={registered} />
            ) : (
              <RegisterForm nextStep={() => setCurrentStep(1)} onRegister={handleRegister} />
            )}
          </Container>
        </Paper>
        <Box className={classes.rightColumn}>
          <Box>
            {customTheme.loginImage && (
              <Box className={classes.bgImage}>
                <MediaImage
                  src={customTheme.loginImage}
                  mediaParams={{
                    xs: { width: 425, heightratio: 0.5, mode: 'max', retina: true },
                    sm: { width: 400, heightratio: 2, mode: 'max', retina: true },
                    xl: { width: 1400, heightratio: 1, mode: 'max', retina: true },
                  }}
                />
              </Box>
            )}
            <Hidden xsDown>
              <Box className={classes.leftColumnContent}>
                {/* {!registered && (
                  <Box mb={2} display="flex" alignItems="center" justifyContent="center">
                    <Icon icon="dot" className={classes.dot} />
                    <Box ml={1}>
                      <MuiLink
                        component={Link}
                        variant="body2"
                        href=""
                        to={`${returnUrl}?from=${getRoutePath('register')}` ?? getRoutePath('index')}
                      >
                        {t('login.continue.text')}
                      </MuiLink>
                    </Box>
                  </Box>
                )} */}
                <Box mb={3}>
                  <Typography variant="h1">
                    <Box fontWeight="600" component="span">
                      {registered ? t('register.registered.hello') : t('register.login.title')}
                    </Box>
                  </Typography>
                  {!registered && <Typography variant="subtitle1">{t('register.login.text')}</Typography>}
                </Box>
                <Button
                  onClick={() => (registered ? history.push(getRoutePath('index')) : handleLogin())}
                  fullWidth
                  color="secondary"
                  variant="contained"
                  size="large"
                >
                  {registered ? t('register.registered.go.home') : t('register.login.btn')}
                </Button>
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}

export default RegisterPage
