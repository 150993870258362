import Layout from 'components/views/Layout'
import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import { Box, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Filters from 'area/owner/components/Filters'
import { apiActions } from '_actions/api_actions'
import useSWR from 'swr'
import randomColor from 'randomcolor'
import LoadingElem from 'components/LoadingElem'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, Title, Tooltip, Legend, LineElement } from 'chart.js'
import ArrayRecap from './ArrayRecap'
import Icon from 'components/IcoMoon/Icon'

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend)

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    padding: theme.spacing(2),
    height: 350,
    '& > canvas': {
      width: '100% !important',
      height: '100% !important',
    },
  },
  boxLegend: {
    border: '1px solid ' + theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1) + 'px ' + theme.spacing(2) + 'px',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      display: 'inherit',
    },
  },
  icon: {
    color: theme.palette.primary.light,
  },
  legendhover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hidden: {
    textDecoration: 'line-through',
  },
  legendContainer: {
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}))

let options = {
  animation: {
    duration: 0,
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (tooltipItems, data) => {
          return (
            tooltipItems[0].dataset.stack +
            ' : ' +
            tooltipItems[0].dataset.totalValue[tooltipItems[0].dataIndex] +
            ' € HT'
          )
        },
        label: function (context) {
          return ' ' + context.dataset.label + ' : ' + context.parsed.y + ' € HT'
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

function ByCategoriesPage() {
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)
  const classes = useStyles()

  const [csNames, setCSNames] = React.useState(null)
  const [labels, setLabels] = React.useState(null)
  const [datasets, setDatasets] = React.useState(null)
  const [dataChart, setDataChart] = React.useState(null)
  const [datasEvolution, setDatasEvolution] = React.useState(null)

  const [legend, setLegend] = React.useState(null)
  const ref = React.useRef(null)

  const filtersLocalStorage = JSON.parse(sessionStorage.getItem('ownerfilters'))

  const [filters, setFilters] = React.useState({
    type: 'month',
    startDate: filtersLocalStorage?.startDate ?? moment().subtract(3, 'month'),
    endDate: filtersLocalStorage?.endDate ?? moment(),
    coworkingSpaces: filtersLocalStorage?.coworkingSpaces ?? [],
  })

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const { data } = useSWR('getCAByCategories/' + JSON.stringify(filters), () =>
    apiCall(apiActions.ownerGetCAByCategories, {}, 'post', filters).then((r) => {
      if (r.Error) {
        //
      } else {
        setDatasEvolution(r.categoriescaevolutions)
        return r.series
      }
    })
  )

  React.useEffect(() => {
    if (legend) {
      let oldlegend = [...legend]
      oldlegend.map((l) => (l.hidden = false))
      setLegend(oldlegend)
    }

    if (filters.coworkingSpaces.length === 0) {
      setDataChart(null)
    }
  }, [filters])

  React.useEffect(() => {
    if (data) {
      setCSNames(data.map((d) => d.name).filter(onlyUnique))
      setLabels(data.map((d) => d.periods.map((p) => p.categories.map((c) => c.category)))[0]?.filter(onlyUnique)[0])

      if (!legend) {
        let legends = data.map((cs) => {
          return {
            text: cs.name,
            fillStyle: cs.GraphColor ?? randomColor({ seed: cs.name }),
            lineWidth: 0,
            hidden: false,
          }
        })
        setLegend(legends)
      }
    }
  }, [data])

  React.useEffect(() => {
    if (data && csNames && labels) {
      let newDataSets = []
      data.map((cs) =>
        cs.periods.map((per) => {
          newDataSets.push({
            label: cs.name,
            data: per.categories.map((cat) => cat.value),
            backgroundColor: cs.GraphColor ?? randomColor({ seed: cs.name }),
            stack: per.period,
            totalValue: per.categories.map((cat) => cat.totalValue),
          })
        })
      )
      setDatasets(newDataSets)
    }
  }, [data, csNames, labels])

  React.useEffect(() => {
    if (labels && datasets && datasets.length > 0) {
      setDataChart({
        labels: labels,
        datasets: datasets,
      })
    }
  }, [labels, datasets])

  const handleClick = (legendItem) => {
    const label = legendItem.text
    let newlegend = [...legend]
    const ci = ref.current

    ci.data?.datasets?.map((ds, index) => {
      if (ds.label === label) {
        if (ci.isDatasetVisible(index)) {
          ci.hide(index)
          newlegend.filter((l) => l.text === label)[0].hidden = true
        } else {
          ci.show(index)
          newlegend.filter((l) => l.text === label)[0].hidden = false
        }
      }
    })

    setLegend(newlegend)
  }

  return (
    <Layout pageTitle={t('owner-by-categories.page.title')} pageDescription={t('owner-by-categories.page.subtitle')}>
      <Box mb={2}>
        <CustomPaper>
          <Box p={2}>
            <Filters state={filters} onChange={setFilters} />
          </Box>
        </CustomPaper>
      </Box>
      <CustomPaper>
        <Box p={2}>
          <Typography variant="h4" gutterBottom>
            <Box color="primary.darker" component="span">
              {t('owner-by-categories.graph.title')}
            </Box>
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {t('owner-by-categories.graph.subtitle')}
          </Typography>
        </Box>
        <Box mb={2} p={2}>
          {data ? (
            dataChart ? (
              <>
                <div className={classes.graphContainer}>
                  <Bar options={options} data={dataChart} ref={ref} />
                </div>
                <Box className={classes.boxLegend}>
                  <Box display="flex" alignItems="center" mb={isSmDown ? 1 : 0}>
                    <Box mr={1} display="flex" alignItems="center">
                      <Icon icon="legende" className={classes.icon} />
                    </Box>
                    <Box mr={4} display="flex" alignItems="center">
                      <Typography variant="subtitle1">
                        <Box component="span" color="primary.darker">
                          {t('owner-by-categories.chart.legend')}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                  {legend && (
                    <Grid container spacing={isXsDown ? 1 : 4} justify="flex-start" className={classes.legendContainer}>
                      {legend.map((l, index) => {
                        return (
                          <Grid item>
                            <Box display="flex" alignItems="center" whiteSpace="nowrap">
                              <Box mr={1}>
                                <Icon
                                  className={classes.legendhover}
                                  icon="dot"
                                  onClick={() => handleClick(l, index)}
                                  style={{ color: l.fillStyle, fontSize: 12 }}
                                />
                              </Box>
                              <Typography
                                className={classes.legendhover + ' ' + (l.hidden ? classes.hidden : '')}
                                onClick={() => handleClick(l, index)}
                                variant="subtitle1"
                                color="textSecondary"
                              >
                                {l.text}
                              </Typography>
                            </Box>
                          </Grid>
                        )
                      })}
                    </Grid>
                  )}
                </Box>
              </>
            ) : (
              <Box p={2}>{t('owner-dashboard.evolutions.noserie')}</Box>
            )
          ) : (
            <Box p={2}>
              <LoadingElem minHeight="50px" />
            </Box>
          )}
        </Box>
        {datasEvolution && datasEvolution.length > 0 && (
          <Box p={2}>
            <Grid container spacing={2} alignItems={isXsDown ? 'center' : 'flex-end'}>
              <ArrayRecap datas={datasEvolution} />
            </Grid>
          </Box>
        )}
      </CustomPaper>
    </Layout>
  )
}

export default ByCategoriesPage
