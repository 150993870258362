import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Fade, Hidden, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import CustomTextField from 'components/CustomTextField'
import LangsSwitch from 'components/LangsSwitch'
import LoadingElem from 'components/LoadingElem'
// import BackCustomer from 'components/views/BackCustomer'
import BackToV1 from 'components/views/BackToV1'
import { basename } from 'config'
import { UserContext } from 'contexts/UserContext'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { getRoutePath } from 'routes'
import * as Yup from 'yup'
import { loginUser } from '_actions/auth_actions'

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  form: {
    maxHeight: '100vh',
    transition: 'max-height 0.2s ease-out',
    opacity: 1,
  },
  formSubmit: {
    overflow: 'hidden',
    '& form': {
      opacity: 0,
      maxHeight: 0,
    },
  },
  // backCustomer: {
  //   backgroundColor: theme.palette.primary.darker,
  //   borderRadius: theme.shape.borderRadius + 'px !important',
  //   '& .MuiPaper-root': {
  //     backgroundColor: theme.palette.primary.darker,
  //     color: 'white',
  //     '& .MuiIconButton-root': {
  //       backgroundColor: 'white',
  //       color: theme.palette.primary.darker,
  //     },
  //   },
  // },
}))

function LoginForm({ title = '', showLangs = false }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const { authUser } = React.useContext(UserContext)

  const query = new URLSearchParams(location.search)
  const returnUrl = query.get('r')

  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('login.email.not.valid')).required(t('common.field.required')),
    password: Yup.string().required(t('common.field.required')),
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      email: isDev ? 'ftel@cosoft.fr' : '',
      password: isDev ? 'edcrfv.7410*8520' : '',
    },
    resolver: yupResolver(validationSchema),
  })

  const onFormSubmit = (values) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        let dataToSubmit = {
          email: values.email,
          password: values.password,
        }

        loginUser(dataToSubmit)
          .then((response) => {
            if (response.isAuth) {
              authUser().then((r) => {
                if (r?.User?.MustValidateDocuments) {
                  history.push(getRoutePath('documents-to-validate'))
                } else {
                  history.push(returnUrl ?? getRoutePath('index'))
                }
              })
            } else {
              setFormErrorMessage(t('login.invalid.credentials'))
              resolve()
            }
          })
          .catch((err) => {
            setFormErrorMessage(t('login.invalid.credentials'))
            resolve()
          })
      }, 250)
    })
  }

  return (
    <Box className={isSubmitting ? classes.formSubmit : ''}>
      {(title || showLangs) && (
        <Box display="flex">
          {title && (
            <Typography className={classes.title} variant="h5" gutterBottom>
              {t('login.title')}
            </Typography>
          )}
          {showLangs && (
            <Box ml={1}>
              <LangsSwitch />
            </Box>
          )}
        </Box>
      )}
      <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
        <Box mb={2}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <CustomTextField
                placeholder="exemple@email.com"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('login.email')}
                type="email"
                variant="outlined"
                fullWidth
              />
            )}
          />

          {errors && errors.email && (
            <Box mt={1}>
              <Alert severity="error">{errors.email.message}</Alert>
            </Box>
          )}
        </Box>
        <Box mb={2}>
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <CustomTextField
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('login.password')}
                type="password"
                variant="outlined"
                fullWidth
              />
            )}
          />

          {errors && errors.password && (
            <Box mt={1}>
              <Alert severity="error">{errors.password.message}</Alert>
            </Box>
          )}

          {/* <Box mt={1}>
            <Link color="textSecondary" href={`/ForgotPassword?ReturnUrl=${basename}/`}>
              {t('login.password.forgot')}
            </Link>
          </Box> */}
        </Box>

        {formErrorMessage && (
          <Box mb={2}>
            <Alert severity="error">{formErrorMessage}</Alert>
          </Box>
        )}

        <Box mt={4}>
          <Button fullWidth size="large" variant="contained" color="primary" disabled={isSubmitting} type="submit">
            {t('login.connect')}
          </Button>
        </Box>

        <Hidden smUp>
          <Box mt={2} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              {t('login.register.text')}
            </Typography>
            <Button
              onClick={() => history.push(getRoutePath('register') + history.location.search)}
              fullWidth
              color="secondary"
              variant="contained"
              size="large"
            >
              {t('login.register.btn')}
            </Button>
          </Box>
        </Hidden>
      </form>
      {/* <Hidden mdUp>
        <Box mt={2}>
          {/* <Box mb={1}>
            <BackCustomer propClasses={classes.backCustomer} />
          </Box> */}
      {/* <BackToV1 propClasses={classes.backCustomer} /> */}
      {/* </Box> */}
      {/* </Hidden> */}

      <Fade unmountOnExit={true} in={isSubmitting}>
        <Box display="flex" justifyContent="center">
          <LoadingElem style={{ minHeight: '100px' }} />
        </Box>
      </Fade>
    </Box>
  )
}

export default LoginForm
