import { Box, makeStyles } from '@material-ui/core'
import CustomTextField from 'components/CustomTextField'
import { Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  error: {
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      margin: 0,
    },
  },
}))

function RenderField({ methods, name, label, placeholder, spacing = 0.5, type = 'text', ...rest }) {
  const classes = useStyles()

  const hasError = methods.formState.errors && methods.formState.errors[name]

  return (
    <Box my={spacing}>
      <Controller
        control={methods.control}
        name={name}
        render={({ field }) => (
          <CustomTextField
            onChange={(e) =>
              rest.onChange ? rest.onChange(e) : field.onChange(e.target.value === '' ? null : e.target.value)
            }
            fullWidth
            label={label}
            placeholder={placeholder}
            helperText={hasError ? methods.formState.errors[name]?.message : ''}
            type={type}
            inputRef={field.ref}
            defaultValue={field.value}
            error={hasError}
            className={hasError ? classes.error : ''}
            value={field.value}
            {...rest}
          />
        )}
      />
      {/* {methods.formState.errors && methods.formState.errors[name] && (
        <Box mt={1}>
          <Alert severity="error">{methods.formState.errors[name].message}</Alert>
        </Box>
      )} */}
    </Box>
  )
}

export default RenderField
