import { Box, Button, Grid, Paper, Typography, alpha, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import LoadingPage from 'components/LoadingPage'
import { DEFAULT_IMG } from 'config'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'
import Layout from 'components/views/Layout'
import Icon from 'components/IcoMoon/Icon'
import { getRoutePath } from 'routes'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import CustomSnackBar from 'components/CustomComponents/CustomSnackBar'
import CustomErrorMessage from 'components/CustomComponents/CustomErrorMessage'
import moment from 'moment'
import { MoneyContext } from 'contexts/MoneyContext'
import { SocietyContext } from 'contexts/SocietyContext'
import { formatCost } from 'components/ShowPrice'
import LoadingElem from 'components/LoadingElem'
import SquareButton from 'components/SquareButton'
import { downloadFile } from 'components/downloadFile'
import InfosDocuments from 'area/social/components/InfosDocuments'

const imgSize = 230
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.darker,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  img: {
    height: imgSize,
    width: imgSize,
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    border: '3px solid white',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  name: {
    color: theme.palette.getContrastText(theme.palette.primary.darker),
  },
  contrast: {
    color: theme.palette.getContrastText(theme.palette.primary.darker),
  },
  container: {
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  strengthContainer: {
    backgroundColor: alpha(theme.palette.primary.light, 0.4),
    marginBottom: theme.spacing(2),
  },
  paddingBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  title: {
    color: theme.palette.primary.darker,
    fontWeight: 'bold',
  },
  item: {
    '& .MuiGrid-item': {
      height: '100%',
    },
  },
  linearProgress: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiLinearProgress-root': {
      height: '10px',
      borderRadius: theme.shape.borderRadius,
    },
  },
  icon: {
    fontSize: 40,
    color: theme.palette.primary.darker,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  gridContainer: {
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  borderDark: {
    '& .MuiButton-outlined:not(.MuiButton-outlinedSecondary):not(.MuiButton-outlinedPrimary)': {
      borderColor: theme.palette.primary.darker,
      color: theme.palette.primary.darker,
    },
  },
  qrcode: {
    height: 250,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 100,
    },
    [theme.breakpoints.down('xs')]: {
      height: 100,
      width: 100,
    },
  },
  iconMoney: {
    fontSize: 12,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  lastInvoices: {
    padding: theme.spacing(0.5),
    '& *': {
      wordBreak: 'break-word',
    },
  },
  downloadbutton: {
    padding: theme.spacing(1),
    height: 40,
  },
  toValidate: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  documentButton: {
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
}))

const RenderItem = ({ title, content }) => {
  const classes = useStyles()

  return (
    <Grid item xs={6} md={3}>
      <Typography gutterBottom className={classes.contrast} variant="h6">
        {title}
      </Typography>
      {content}
    </Grid>
  )
}

const RenderSubInfo = ({ text }) => {
  return (
    <div>
      <Typography variant="body2">
        <Box component="span" color="primary.light">
          {text}
        </Box>
      </Typography>
    </div>
  )
}

const RenderBox = ({ title, description, textButton, content, onClick, topRightComponent }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} lg={6}>
      <CustomPaper selectable>
        <Box className={classes.paddingBox}>
          <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <div>
              <Typography variant="h2" gutterBottom className={classes.title}>
                {title}
              </Typography>
            </div>
            {topRightComponent && topRightComponent}
          </Box>
          <Box mb={2}>
            <Typography variant="caption" color="textSecondary">
              {description}
            </Typography>
          </Box>
          {textButton && (
            <Button variant="contained" color="secondary" onClick={onClick}>
              {textButton}
            </Button>
          )}
          {content}
        </Box>
      </CustomPaper>
    </Grid>
  )
}

function UserProfilePage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { user, apiCall, apiUrl } = useContext(UserContext)
  const [openDialogPassword, setOpenDialogPassword] = React.useState(false)
  const [message, setMessage] = React.useState(null)
  const [showDocumentsInfos, setShowDocumentsInfos] = React.useState(false)

  const history = useHistory()

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const { data: userProfile } = useSWR(`${user?.Id}/profile`, () => apiCall(apiActions.getUserProfile).then((r) => r))

  if (!userProfile) return <LoadingPage />

  return (
    <Layout>
      <Paper className={classes.paper}>
        <Box p={3}>
          <Box display={isXsDown ? 'block' : 'flex'}>
            <Box mr={2}>
              <img className={classes.img} src={userProfile.PictureProfileUrl ?? DEFAULT_IMG} alt="" />
            </Box>
            <Box width="100%">
              <Box mt={2} mb={4} className={classes.name}>
                <Typography variant="h2">{userProfile.FirstName + ' ' + userProfile.LastName}</Typography>
                {apiUrl(apiActions.getUserById) && (
                  <Link className={classes.contrast} to={getRoutePath('userprofile', { ':user': userProfile.Id })}>
                    <Typography variant="subtitle1">
                      <Box component="span" fontWeight="normal">
                        {t('profile.show.public')}
                      </Box>
                    </Typography>
                  </Link>
                )}
              </Box>
              <Grid container justify="space-between" spacing={2}>
                <RenderItem
                  title={t('profile.user.personal.title')}
                  content={
                    <>
                      <RenderSubInfo text={userProfile.MobilePhone} />
                      <RenderSubInfo text={userProfile.Email} />
                    </>
                  }
                />
                <RenderItem
                  title={t('profile.user.specific.title')}
                  content={
                    <RenderSubInfo
                      text={
                        <Box display="flex" component="span" mb={1}>
                          <Box mr={1} component="span" display="flex" alignItems="center">
                            <Icon icon="favori" />
                          </Box>
                          {t('profile.user.coworkingspace', { name: userProfile?.CoworkingSpaceName })}
                        </Box>
                      }
                    />
                  }
                />
              </Grid>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Box display={isXsDown ? 'block' : 'flex'}>
        {/* <Grid container className={classes.item} spacing={2}>
          <Grid item xs={12}>
            {(userProfile?.QRCode || userProfile?.SwipeCardId || userProfile?.PrintCode) && (
              <Box mr={isXsDown ? 0 : 2} mb={isXsDown ? 2 : 0}>
                <CustomPaper className={classes.fullHeight}>
                  <Box className={classes.paddingBox}>
                    {userProfile?.QRCode && (
                      <>
                        <Typography align="center" variant="h4" gutterBottom className={classes.title}>
                          {t('profile.user.qrcode')}
                        </Typography>
                        <Box width="100%" display="flex">
                          <img src={`data:image/jpg;base64,${userProfile?.QRCode}`} height={'100%'} width={'100%'} />
                        </Box>
                      </>
                    )}
                    <Box mb={2}>
                      <Typography align="center" variant="subtitle1" gutterBottom className={classes.title}>
                        {t('profile.user.qrcode.name')}
                      </Typography>
                      <Typography align="center" variant="subtitle2" color="textSecondary">
                        todo
                        </Typography>
                      </Box>
                    {userProfile?.SwipeCardId && (
                      <Box mb={2}>
                        <Typography align="center" variant="h4" gutterBottom className={classes.title}>
                          {t('profile.user.badge')}
                        </Typography>
                        <Typography align="center" variant="subtitle2" color="textSecondary">
                          {userProfile.SwipeCardId}
                        </Typography>
                      </Box>
                    )}
                    {userProfile?.PrintCode && (
                      <Box mb={2}>
                        <Typography align="center" variant="h4" gutterBottom className={classes.title}>
                          {t('profile.user.printcode')}
                        </Typography>
                        <Typography align="center" variant="subtitle2" color="textSecondary">
                          {userProfile.PrintCode}
                        </Typography>
                      </Box>
                    )}
                    {userProfile?.ReferralCode && (
                      <Box mb={2}>
                        <Typography align="center" variant="h4" gutterBottom className={classes.title}>
                          {t('profile.user.referralcode')}
                        </Typography>
                        <Typography align="center" variant="subtitle2" color="textSecondary">
                          {userProfile.ReferralCode}
                        </Typography>
                      </Box>
                    )}
                    {userProfile.FreeHours !== null && (
                      <div>
                        <Typography align="center" variant="h4" className={classes.title}>
                          {t('included.hours', { count: userProfile.FreeHours })}
                        </Typography>
                      </div>
                    )}
                  </Box>
                </CustomPaper>
              </Box>
            )}
          </Grid>
        </Grid> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <RenderBox
                title={t('profile.user.account')}
                description={t('profile.user.account.subtitle')}
                textButton={t('profile.user.edit.button')}
                onClick={() => history.push(getRoutePath('my-account'))}
              />
              {/* <RenderBox
                title={t('profile.user.security')}
                description={t('profile.user.security.subtitle')}
                textButton={t('profile.user.edit.button')}
                onClick={() => setOpenDialogPassword(true)}
              />
              {userProfile.CanTransfertCredits && (
                <RenderBox
                  title={t('profile.user.wallet.transfert')}
                  description={t('profile.user.wallet.transfert.subtitle')}
                  textButton={t('profile.user.wallet.transfert.button')}
                  onClick={() => history.push(getRoutePath('wallet-transfert'))}
                />
              )} */}
            </Grid>
          </Grid>
          {/* <CustomSnackBar open={message} autoHideDuration={2000} onClose={() => setMessage(null)} message={message} /> */}
        </Grid>
      </Box>
    </Layout>
  )
}

export default UserProfilePage
