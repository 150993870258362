import { Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Icon from 'components/IcoMoon/Icon'
import React from 'react'

const useStyles = makeStyles((theme) => {
  return {
    checkbox: {
      color: theme.palette.primary.darker,
      '&.Mui-checked': {
        color: theme.palette.secondary.main,
      },
    },
    checkedIcon: {
      position: 'relative',
      fontSize: 0,
      '& > svg:first-child': {
        fontSize: '1.25rem',
      },
      '& > svg:last-child': {
        position: 'absolute',
        top: -4,
        left: -2,
        right: 0,
        bottom: 0,
      },
    },
    outline: {
      color: theme.palette.primary.darker,
    },
    check: {
      color: theme.palette.secondary.dark,
      transform: 'scale(0.58)',
    },
  }
})

function CustomCheckbox(props) {
  const classes = useStyles()

  return (
    <Checkbox
      {...props}
      icon={<Icon icon="case-cocher" />}
      checkedIcon={
        <span className={classes.checkedIcon}>
          <Icon fontSize="medium" icon="case-cocher" className={classes.outline} />
          <Icon fontSize="medium" icon="check" className={classes.check} />
        </span>
      }
      className={classes.checkbox + ' ' + (props.className ?? '')}
    />
  )
}

export default CustomCheckbox
