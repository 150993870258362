import { Box } from '@material-ui/core'
import React from 'react'
import { Alert } from '@material-ui/lab'

interface CustomErrorMessageProps {
  message?: string
}

const CustomErrorMessage: React.FC<CustomErrorMessageProps> = (props) => {
  const { message, ...rest } = props

  return (
    <Box mt={1}>
      <Alert severity="error" {...rest}>
        {message}
      </Alert>
    </Box>
  )
}

export default CustomErrorMessage
