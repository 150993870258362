import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import LoadingElem from 'components/LoadingElem'
import { UserContext } from 'contexts/UserContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'
import OrderDetailPlan from 'area/owner/components/OrderDetailPlan'
import moment from 'moment'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import useSWR from 'swr'
import PopOverContent from './PopOverContent'
import CustomButton from 'components/CustomButton'
import CenteredBox from 'components/CenteredBox'

const useStyles = makeStyles((theme) => ({
  fixedContent: {
    position: 'sticky',
    bottom: theme.spacing(0),
    padding: theme.spacing(4),
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8),
  },
}))

function OrdersList({ infos, serverData, handleClose, handleChangeRoom, canEdit }) {
  const { apiCall } = React.useContext(UserContext)
  const { t } = useTranslation()
  const classes = useStyles()

  const [list, setList] = React.useState(null)
  const [loading, setLoading] = React.useState(null)
  const [showBackButton, setShowBackButton] = React.useState(false)
  const [openPopover, setOpenPopover] = React.useState(false)

  const rrId = infos?.rrIds[0]
  const { data, mutate } = useSWR(`getPlanOrderResourceRentList/${rrId}`, () => {
    setLoading(true)
    return apiCall(apiActions.getPlanOrderResourceRentList, { rrId: rrId }).then((r) => {
      setLoading(false)
      setShowBackButton(false)
      return r
    })
  })

  React.useEffect(() => {
    setList(data)
  }, [data])

  const closeDialog = () => {
    handleClose()
  }

  const handleRowClick = (row) => {
    setList([row])
    setShowBackButton(true)
  }

  const onClosePopover = () => {
    setOpenPopover(false)
    handleClose()
  }

  let title = ''
  let comp = null

  if (!list) return <LoadingElem />
  if (list.length === 0) {
    title = t('plan.edit.room.no.reservation')
  }

  if (list.length === 1) {
    title = t('plan.edit.room.order.title', {
      rrname: list[0].ResourceRentPartName,
      name: list[0].FirstName + ' ' + list[0].LastName,
    })
    comp = <OrderDetailPlan order={list[0]} showBackButton={showBackButton} onBackClick={mutate} canEdit={canEdit} />
  }

  if (list.length > 1) {
    title = list[0].ResourceRentName
    comp = list && list.length > 0 && (
      <Grid container spacing={2}>
        {list.map((l) => {
          return (
            <Grid key={l.ResourceRentPartName} item xs={12} sm={6} md={4}>
              <CustomPaper selectable onClick={() => handleRowClick(l)}>
                <Box p={2}>
                  <Box mb={2} color="primary.darker" width="100%">
                    <Typography variant="h3" align="center">
                      {l.ResourceRentPartName}
                    </Typography>
                  </Box>
                  <Box color="primary.darker">
                    <Typography variant="subtitle1">
                      {t('plan.edit.room.ordered.by', { name: l.FirstName + ' ' + l.LastName })}
                    </Typography>
                  </Box>
                  <div>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t('plan.edit.room.order.startdate', { date: moment(l.StartDate).format('DD/MM/YY HH:mm') })}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t('plan.edit.room.order.enddate', {
                        date: l.EndDate ? moment(l.StartDate).format('DD/MM/YY HH:mm') : t('common.no.date'),
                      })}
                    </Typography>
                  </div>
                </Box>
              </CustomPaper>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return (
    <CustomDialog open maxWidth="lg" fullWidth handleClose={closeDialog} title={title} withFooter={canEdit}>
      {loading ? (
        <LoadingElem />
      ) : (
        <>
          {comp && comp}
          {canEdit && (
            <>
              <Paper className={classes.fixedContent}>
                <CenteredBox>
                  <CustomButton variant="contained" color="secondary" onClick={() => setOpenPopover(true)}>
                    {t('plan.edit.room.button')}
                  </CustomButton>
                </CenteredBox>
              </Paper>
              <CustomDialog open={openPopover} maxWidth="sm" fullWidth handleClose={onClosePopover} withFooter>
                <CenteredBox p={2}>
                  <PopOverContent serverData={serverData} infos={infos} onChange={handleChangeRoom} />
                </CenteredBox>
                <CenteredBox>
                  <CustomButton variant="contained" color="secondary" onClick={onClosePopover}>
                    {t('common.ok')}
                  </CustomButton>
                </CenteredBox>
              </CustomDialog>
            </>
          )}
        </>
      )}
    </CustomDialog>
  )
}

export default OrdersList
