import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import SquaredImg from 'area/social/components/SquaredImg'
import { CSSGrid } from 'components/CustomComponents/CSSGrid'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import LoadingElem from 'components/LoadingElem'
import Layout from 'components/views/Layout'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  card: {
    card: {
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
      position: 'relative',
    },
  },
}))

const CardPartner = ({ partner }) => {
  return (
    <Box p={2}>
      <Typography gutterBottom variant="h3">
        <Box component="span" color="primary.darker">
          {partner.Title}
        </Box>
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        <Box dangerouslySetInnerHTML={{ __html: partner.ShortDescription }} />
      </Typography>
    </Box>
  )
}

const Partner = ({ partner }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Box display="flex" justifyContent="center" mb={2}>
        <SquaredImg image={partner.Image} width={200} />
      </Box>
      <Typography gutterBottom variant="h3">
        <Box component="span" color="primary.darker" fontWeight="fontWeightBold">
          {t('common.description')}
        </Box>
      </Typography>
      <Box dangerouslySetInnerHTML={{ __html: partner.LongDescription }} />
    </div>
  )
}

function PartnersPage() {
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)
  const classes = useStyles()

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const [partners, setPartners] = React.useState(null)
  const [openDialogPartner, setOpenDialogPartner] = React.useState(null)

  React.useEffect(() => {
    apiCall(apiActions.getAllPartners).then((r) => setPartners(r))
  }, [])

  return (
    <Layout pageTitle={t('partners.page.title')}>
      {partners ? (
        partners.length > 0 ? (
          <CSSGrid spacing={2} colWidth={isXsDown ? 180 : 230}>
            {partners.map((p, index) => {
              return (
                <CustomPaper selectable onClick={() => setOpenDialogPartner(p)} className={classes.card}>
                  <Box p={2} pb={0}>
                    <SquaredImg image={p.Image} container />
                  </Box>
                  <CardPartner key={index} partner={p} />
                </CustomPaper>
              )
            })}
          </CSSGrid>
        ) : (
          <Typography variant="h6" color="textSecondary">
            Aucun élément à afficher
          </Typography>
        )
      ) : (
        <LoadingElem />
      )}
      {openDialogPartner && (
        <CustomDialog
          open
          maxWidth="sm"
          fullWidth
          handleClose={() => setOpenDialogPartner(null)}
          title={openDialogPartner?.Title}
        >
          <Partner partner={openDialogPartner} />
        </CustomDialog>
      )}
    </Layout>
  )
}

export default PartnersPage
