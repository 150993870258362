import { Box, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import ShowPrice from 'components/ShowPrice'
import { DEFAULT_IMG } from 'config'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const imgWidth = 50

const useStyles = makeStyles((theme) => ({
  image: {
    width: imgWidth,
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
  },
  container: {
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  priceContainer: {
    color: theme.palette.primary.darker,
    fontSize: theme.typography.pxToRem(24),
    '& svg, div': {
      color: theme.palette.secondary.main,
    },
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    '& > span > span > span': {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
}))

const RenderColumn = ({ value, title }) => {
  return (
    <div>
      <div>
        <Typography variant="subtitle1" color="primary.dark">
          {value}
        </Typography>
      </div>
      {title && (
        <div>
          <Typography variant="body2" color="textSecondary">
            {title}
          </Typography>
        </div>
      )}
    </div>
  )
}
function LineCard({ order, csIds }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Box mb={1}>
      <CustomPaper>
        <Box p={2}>
          <Grid container spacing={2} justifyContent={isXsDown && 'space-between'} className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Box display="flex" alignItems="center">
                <img className={classes.image} src={DEFAULT_IMG} />
                <Box display="block">
                  <Typography variant="h6">
                    <Box component="span" color="primary.darker" fontWeight="fontWeightBold">
                      {order.ResourceRentName}
                    </Box>
                  </Typography>
                  <Typography variant="h6">
                    <Box component="span" color="primary.darker" fontWeight="fontWeightBold">
                      {t(order.ResourceRentPartName)}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {csIds && csIds.length > 1 && (
              <Grid item xs={5} sm>
                <RenderColumn title={t('Espace')} value={order.CoworkingSpaceName} />
              </Grid>
            )}
            <Grid item xs={5} sm>
              <RenderColumn title={t('owner-by-rr.cards.user')} value={order.CoworkerName} />
            </Grid>
            <Grid item xs={5} sm>
              <Box display="flex" alignItems="center">
                <Box mr={1} color="primary.darker" display="flex" alignItems="center">
                  <Icon icon="agenda-jour" />
                </Box>
                <RenderColumn
                  title={t('owner-by-rr.cards.startdate')}
                  value={moment(order.StartDate).format(isXsDown ? 'DD/MM/YYYY' : 'DD MMMM YYYY')}
                />
              </Box>
            </Grid>
            <Grid item xs={5} sm={2}>
              <Typography variant="h4" className={classes.priceContainer}>
                <ShowPrice credits={order.Prices.Credits} ht={order.Prices.EuroHT} ttc={order.Prices.EuroTTC} />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CustomPaper>
    </Box>
  )
}

export default LineCard
