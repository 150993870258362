import { basename, SERVER_URL } from 'config'
import 'helper/string'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'
import { apiActions } from '_actions/api_actions'
import { auth, deleteWithAuth, getWithAuth, postWithAuth, putWithAuth, tempAuth } from '_actions/auth_actions'

const UserContext = React.createContext()
const storageItemsName = 'cartItems'
const cartToLocalStorage = (items) => window.localStorage.setItem(storageItemsName, JSON.stringify(items))
const cartFromLocalStorage = () => {
  const local = window.localStorage.getItem(storageItemsName)
  if (!local) return []
  return JSON.parse(local)
}

function UserContextProvider({ children }) {
  const history = useHistory()

  const [user, setUser] = React.useState(undefined)
  const [infos, setInfos] = React.useState(tempAuth.auth)
  const [cartItems, setCartItems] = React.useState(cartFromLocalStorage())
  const [actions, setActions] = React.useState(undefined)

  const setAndResolve = (resolver, response) => {
    setUser(response && response.User ? response.User : null)
    setInfos(response)
    setActions(response.Actions)
    resolver(response ?? tempAuth.auth)
    if (
      response.User?.MustValidateDocuments &&
      history.location.pathname !== basename + getRoutePath('documents-to-validate')
    ) {
      history.push(basename + getRoutePath('documents-to-validate'))
    }
  }

  const authUser = () =>
    new Promise((resolve) => {
      if (tempAuth.auth) {
        setAndResolve(resolve, tempAuth.auth)
      } else if (!user) {
        auth().then((r) => {
          setAndResolve(resolve, r)
        })
      } else {
        setAndResolve(resolve, infos)
      }
    })

  React.useEffect(() => {
    authUser()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const cart = {
    get: () => cartItems,
    set: (items) => setCartItems([...items]),
    add: (item) => {
      const items = [...cartItems]
      items.push(item)
      setCartItems(items)
    },
    reset: () => setCartItems([]),
  }

  React.useEffect(() => {
    cartToLocalStorage(cartItems)
  }, [cartItems])

  const apiUrl = (key, params = {}, actionsList = undefined) => {
    let userActions = actionsList ?? actions
    if (!userActions || Object.keys(userActions).indexOf(key) === -1) return null

    let url = SERVER_URL(userActions[key])
    if (params) {
      Object.keys(params).forEach((pKey) => (url = url.replaceAllInsensitive('{' + pKey + '}', params[pKey])))
    }

    if (url === null || url === '') return null

    return url
  }

  const apiCall = (key, params = {}, type = 'get', data = {}, opt) =>
    new Promise((resolve, reject) => {
      return authUser().then((r) => {
        let url = apiUrl(key, params, r.Actions)
        if (!url) {
          reject()
        } else {
          switch (type.toLowerCase()) {
            case 'delete':
              deleteWithAuth(url, opt).then((response) => resolve(response.data))
              break
            case 'put':
              putWithAuth(url, data, opt).then((response) => resolve(response.data))
              break
            case 'post':
              postWithAuth(url, data, opt)
                .then((response) => {
                  if (response.data instanceof Blob) resolve(response)
                  else resolve(response.data)
                })
                .catch(() => {
                  if (key === apiActions.getCart) cart.reset()
                  return
                })
              break
            case 'get':
            default:
              getWithAuth(url, opt).then((response) => resolve(response.data))
              break
          }
        }
      })
    })

  return (
    <UserContext.Provider value={{ user, infos, cart, actions, authUser, apiCall, apiUrl }}>
      {children}
    </UserContext.Provider>
  )
}

export { UserContextProvider, UserContext }
