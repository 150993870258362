import { SvgIcon, SvgIconProps } from '@material-ui/core'
import React from 'react'
import IcoMoon from 'react-icomoon'
const iconSet = require('components/IcoMoon/selection.json')

interface IconProps extends SvgIconProps {
  icon: string
}

const Icon = (props: IconProps) => {
  const { fontSize, icon, ...rest } = props
  return (
    <SvgIcon
      {...rest}
      fontSize={fontSize ?? 'small'}
      component={(compProps) => <IcoMoon {...compProps} iconSet={iconSet} icon={icon} removeInlineStyle />}
    />
  )
}

export default Icon
