import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Grid, makeStyles, MenuItem, Select, Switch } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import CustomErrorMessage from 'components/CustomComponents/CustomErrorMessage'
import CustomLabel from 'components/CustomLabel'
import CustomTextField from 'components/CustomTextField'
import { UserContext } from 'contexts/UserContext'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import * as Yup from 'yup'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  switchRoot: {
    marginTop: theme.spacing(1),
  },
}))

function ActivityDialog(props) {
  const classes = useStyles()

  const { t } = useTranslation()
  const { apiCall, user } = useContext(UserContext)

  const { edit } = props //Définit le type d'appel à faire (edit: modifier, rien: création d'une activité)
  const { prospect } = props //Le prospect
  const { activityId, defaultValues, textValidateButton, handleClose, refreshData, noCancelButton } = props
  const prospectId = prospect?.Id

  const { data: adminLists } = useSWR(`prospects-detail/getAdminLists`, () =>
    apiCall(apiActions.getAdminLists, 'get').then((r) => {
      return r.data
    })
  )

  const methods = useForm({
    defaultValues: {
      date: defaultValues?.Date ?? null,
      type: defaultValues?.Type ?? 5,
      status: defaultValues?.Status ?? 100,
      title: defaultValues?.Title ?? null,
      newOwnerId: defaultValues?.ActivityOwner?.Id ?? user?.Id ?? null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        date: Yup.date().required(t('common.field.required')).typeError(t('common.field.required')),
        type: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        status: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        title: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        newOwnerId: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
      })
    ),
  })

  const onFormSubmit = (values) => {
    if (edit && activityId) {
      apiCall(apiActions.editActivity, { ProspectId: prospectId, ActivityId: activityId }, 'put', values).then(() => {
        handleClose()
        refreshData()
      })
    } else {
      apiCall(apiActions.createActivity, { ProspectId: prospectId }, 'post', values).then((r) => {
        handleClose()
        refreshData()
      })
    }
  }

  return (
    <div>
      <form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <Box mb={3}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                control={methods.control}
                name="date"
                render={({ field }) => (
                  <>
                    <DatePicker
                      fullWidth
                      inputRef={field.ref}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.toDate())
                      }}
                      format="DD MMMM YYYY"
                      TextFieldComponent={CustomTextField}
                      variant="inline"
                      autoOk
                      label={t('crm.activity.add.form.date')}
                    />
                    {methods.formState.errors[field.name] && (
                      <CustomErrorMessage message={methods.formState.errors[field.name].message} />
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CustomLabel>{t('crm.activity.add.form.type')}</CustomLabel>
              <Controller
                control={methods.control}
                name="type"
                render={({ field }) => (
                  <Select
                    value={field.value}
                    inputRef={field.ref}
                    onChange={(e) => field.onChange(e)}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value={5}>Prendre une visite</MenuItem>
                    <MenuItem value={15}>Faire visiter</MenuItem>
                    <MenuItem value={25}>Envoyer une proposition</MenuItem>
                    <MenuItem value={35}>Envoyer un mail</MenuItem>
                    <MenuItem value={45}>Appeler</MenuItem>
                    <MenuItem value={55}>Rejet du prospect</MenuItem>
                    <MenuItem value={65}>Signature du prospect</MenuItem>
                    <MenuItem value={75}>Changement d'informations</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            {edit && (
              <Grid item xs={12} sm={6} md={3}>
                <CustomLabel>Responsable</CustomLabel>
                <Controller
                  control={methods.control}
                  name="newOwnerId"
                  render={({ field }) => (
                    <Select
                      variant="outlined"
                      fullWidth
                      disableUnderline
                      defaultValue={field.value}
                      onChange={field.onChange}
                    >
                      {adminLists &&
                        adminLists.length > 0 &&
                        Object.entries(adminLists).map(([key, value]) => (
                          <MenuItem key={key} value={value.Id}>
                            {value.Name}
                          </MenuItem>
                        ))}
                      {adminLists.map((a) => a.Id)?.indexOf(defaultValues?.ActivityOwner?.Id) === -1 && (
                        <MenuItem value={defaultValues?.ActivityOwner?.Id}>
                          {defaultValues?.ActivityOwner?.Name}
                        </MenuItem>
                      )}
                    </Select>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <CustomLabel>{t('crm.activity.add.form.status')}</CustomLabel>
              <Box display="flex" alignItems="center">
                <Box mt={0.75}>{t('crm.activity.add.form.status.todo')}</Box>
                <Controller
                  control={methods.control}
                  name="status"
                  render={({ field }) => (
                    <Switch
                      className={classes.switchRoot}
                      value={field.value}
                      color="primary"
                      onChange={(e, v) => field.onChange(v ? 110 : 100)}
                    />
                  )}
                />
                <Box mt={0.75}>{t('crm.activity.add.form.status.done')}</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <Controller
            control={methods.control}
            name="title"
            render={({ field }) => (
              <>
                <CustomTextField
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value === '' ? null : e.target.value)}
                  variant="outlined"
                  placeholder={t('crm.activity.add.form.comment.placeholder')}
                  fullWidth
                  type="text"
                  multiline
                />
                {methods.formState.errors[field.name] && (
                  <CustomErrorMessage message={methods.formState.errors[field.name].message} />
                )}
              </>
            )}
          />
        </Box>
        <Grid container justifyContent="center" alignItems="stretch" spacing={2}>
          {!noCancelButton && (
            <Grid item md={2} xs={4}>
              <Box height="100%" display="flex" alignItems="stretch">
                <Button onClick={handleClose} fullWidth variant="outlined" color="secondary">
                  <Box component="span" color="primary.darker">
                    {t('crm.activity.add.cancel')}
                  </Box>
                </Button>
              </Box>
            </Grid>
          )}
          <Grid item xs={4} md={noCancelButton ? 4 : 2}>
            <Box height="100%" display="flex" alignItems="stretch">
              <Button type="submit" fullWidth variant="contained" color="secondary">
                {textValidateButton}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default ActivityDialog
