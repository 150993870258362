import CustomTextField from 'components/CustomTextField'
import React from 'react'
import { useTranslation } from 'react-i18next'

function InfoInput({ filter, textLabel }) {
  const { t } = useTranslation()

  return (
    <CustomTextField
      disabled
      fullWidth
      name={filter.Name}
      label={textLabel ? textLabel : t(filter.Key)}
      value={filter.Value ? filter.Value : ''}
    />
  )
}

export default InfoInput
