import { Box, makeStyles, Switch } from '@material-ui/core'
import CustomLabel from 'components/CustomLabel'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  label: {
    margin: 0,
  },
  switch: {
    '& :not(.Mui-checked)': {
      color: theme.palette.grey[600],
    },
    '& .Mui-checked .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
    },
  },
}))

function CustomSwitch({ methods, filter, reset }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [value, setValue] = React.useState(false)

  React.useEffect(() => {
    setValue(false)
  }, [reset])

  const handleChange = (field) => (e, v) => {
    setValue(v)
    field.onChange(!v ? null : v)
  }

  return (
    <Box display="flex" alignItems="center">
      <Box pr={1}>
        <CustomLabel className={classes.label}>{t(filter.Key)}:</CustomLabel>
      </Box>
      <div>
        <Controller
          control={methods.control}
          name={filter.Name}
          render={({ field }) => (
            <Switch
              className={classes.switch}
              size="small"
              color="primary"
              checked={value}
              onChange={handleChange(field)}
            />
          )}
        />
      </div>
    </Box>
  )
}

export default CustomSwitch
