import { Box, Grid, Typography, makeStyles, Select, MenuItem, Button, Link as MuiLink } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import LoadingElem from 'components/LoadingElem'
import Layout from 'components/views/Layout'
import MyReservationsScheduler from 'components/views/MyReservationsPage/MyReservationsScheduler'
import { CoworkingSpaceContext } from 'contexts/CoworkingSpaceContext'
import { UserContext } from 'contexts/UserContext'
import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'
import EventCard from 'area/social/views/EventsPage/EventCard'
import ShowMoreButton from 'area/home/components/ShowMoreButton'
import SelectCoworkingSpaces from 'area/home/components/SelectCoworkingSpaces'
import { Link } from 'react-router-dom'
import { getRoutePath } from 'routes'
import SquaredImg from 'area/social/components/SquaredImg'
import { CSSGrid } from 'components/CustomComponents/CSSGrid'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  link: {
    display: 'block',
    height: '100%',
    '&, &:hover': {
      textDecoration: 'none',
    },
    flex: 1,
    '& > .MuiCardActionArea-root': {
      '&, & > .MuiCardContent-root': {
        //height:"100%"
      },
    },
  },
  showMore: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'sticky',
    padding: theme.spacing(1),
  },
  hiddenDiv: {
    position: 'absolute',
    visibility: 'hidden',
    height: 0,
  },
}))

const messageSize = 300
function HomePage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const ref = useRef(null)
  const { user, apiCall, apiUrl } = useContext(UserContext)

  const [message, setMessage] = React.useState(null)
  const [fullDisplay, setFullDisplay] = React.useState(false)
  const [noneMessages, setNoneMessages] = React.useState(false)
  const [events, setEvents] = React.useState(null)
  const [csId, setCsId] = React.useState(null)
  const [showMore, setShowMore] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const callMessage = () => {
    return new Promise((resolve, reject) => {
      if (apiUrl(apiActions.getHomeMessage)) {
        apiCall(apiActions.getHomeMessage, {}, 'post', {
          CoworkingSpaceId: csId,
        }).then((r) => {
          if (!r.Error) {
            setMessage(r.Content)
            setFullDisplay(r.FullDisplay)
            setNoneMessages(r.NoneMessages)
            resolve()
          } else reject()
        })
      } else resolve()
    })
  }

  const classEvents = () => {
    return new Promise((resolve, reject) => {
      if (apiUrl(apiActions.getEventsLastChances)) {
        apiCall(apiActions.getEventsLastChances, {}, 'post', {
          CoworkingSpaceId: csId,
        }).then((r) => {
          if (!r.Error) {
            setEvents(r)
            resolve()
          } else reject()
        })
      } else resolve()
    })
  }

  React.useEffect(() => {
    const t = async () => {
      if (csId) {
        setLoading(true)
        await callMessage().then(() => setShowMore(false))
        await classEvents().then(() => setLoading(false))
      }
    }
    t()
  }, [csId])

  let nameTitle = t('home.page.title', { name: user ? user?.FirstName : '' })
  if (!user) {
    nameTitle = nameTitle.replace(/ /g, '')
  }

  return (
    <Layout pageTitle={nameTitle} pageDescription={t('home.page.description')}>
      <Grid container spacing={2}>
        <Grid item xs={12} md>
          {apiUrl(apiActions.getHomeMessage) && !noneMessages && (
            <Box mb={2}>
              <Typography variant="h3">
                <Box color="primary.darker" component="span" fontWeight="fontWeightBold">
                  {t('home.message.title')}
                </Box>
              </Typography>
              <Typography gutterBottom variant="subtitle2" color="textSecondary">
                {t('home.message.description')}
              </Typography>
              <CustomPaper>
                <Box p={2} pb={0}>
                  <SelectCoworkingSpaces onChange={(v) => setCsId(v)} />
                  {message && (
                    <div
                      ref={ref}
                      className={classes.hiddenDiv}
                      dangerouslySetInnerHTML={{
                        __html: message,
                      }}
                    />
                  )}
                  {loading ? (
                    <LoadingElem />
                  ) : (
                    <>
                      {!message ? (
                        <Box py={2}>
                          <Typography variant="subtitle1">Pas de message</Typography>
                        </Box>
                      ) : (
                        <>
                          <Box
                            height={
                              !fullDisplay && !showMore && ref?.current?.scrollHeight > messageSize && messageSize
                            }
                            dangerouslySetInnerHTML={{
                              __html: message,
                            }}
                          />
                        </>
                      )}
                      {ref?.current?.scrollHeight > messageSize && !fullDisplay && !loading && (
                        <div className={classes.showMore}>
                          <ShowMoreButton onClick={(v) => setShowMore(v)} />
                        </div>
                      )}
                    </>
                  )}
                </Box>
              </CustomPaper>
            </Box>
          )}
          {user && (
            <div>
              <Typography variant="h3">
                <Box color="primary.darker" component="span" fontWeight="fontWeightBold">
                  {t('home.calendar.title')}
                </Box>
              </Typography>
              <Typography gutterBottom variant="subtitle2" color="textSecondary">
                {t('home.calendar.description')}
              </Typography>
              <MyReservationsScheduler withoutTitle />
            </div>
          )}
        </Grid>
        {events && events.length > 0 && (
          <Grid item xs={12} md={3}>
            <Typography variant="h3">
              <Box color="primary.darker" component="span" fontWeight="fontWeightBold">
                {t('home.chances.title')}
              </Box>
            </Typography>
            <Typography gutterBottom variant="subtitle2" color="textSecondary">
              {t('home.chances.description')}
            </Typography>
            {events && (
              <CSSGrid colWidth={200} spacing={2}>
                {events &&
                  events.map((e, index) => {
                    return (
                      <CustomPaper key={index} selectable className={classes.card}>
                        <MuiLink
                          className={classes.link}
                          component={Link}
                          to={getRoutePath('event', {
                            ':coworkingspace': e.CoworkingSpaceId,
                            ':category': e.CategoryId,
                            ':event': e.Id,
                          })}
                        >
                          <Box mb={1}>
                            <SquaredImg image={e.Image} container />
                          </Box>
                          <EventCard event={e} />
                        </MuiLink>
                      </CustomPaper>
                    )
                  })}
              </CSSGrid>
            )}
          </Grid>
        )}
      </Grid>
    </Layout>
  )
}

export default HomePage
