import { ThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppContent from 'components/AppContent'
import { CustomThemeContext } from 'contexts/CustomThemeContext'
import { LoadingComp } from 'contexts/LoadingContext'
import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import 'styles/global.scss'

function App() {
  const { theme, logo } = React.useContext(CustomThemeContext)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<LoadingComp image={logo} />}>
        <Helmet>
          <meta name="theme-color" content={theme.palette.background.default} />
          <meta name="msapplication-TileColor" content={theme.palette.background.default} />
        </Helmet>
        <AppContent />
      </Suspense>
    </ThemeProvider>
  )
}

export default App
