import { Box, Button, ButtonProps, darken } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomButton from 'components/CustomButton'
import CustomLabel from 'components/CustomLabel'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    color: darken(theme.palette.primary.main, 0.7),
    transition: 'none',
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    border: '2px solid ' + theme.palette.primary.main,
  },
}))

export { useStyles }

interface CustomChoiceButtonsProps extends ButtonProps {
  className?: string
  label?: string
  buttonNames: Array<string>
  buttonLabels: Array<string>
  onClickButton(names: Array<string>): any
  multiple?: boolean
  value: Array<string>
}

const CustomChoiceButtons: React.FC<CustomChoiceButtonsProps> = function (props) {
  const classes = useStyles()
  const {
    className,
    size,
    variant = 'outlined',
    label,
    buttonNames,
    color = 'primary',
    buttonLabels,
    onClickButton,
    value,
    multiple = false,
    disabled = false,
    ...rest
  } = props

  const [selected, setSelected] = React.useState(value)

  const handleClick = (name: string) => {
    let newSelected = [...value]
    if (multiple) {
      if (newSelected.indexOf(name) > -1) {
        newSelected.splice(newSelected.indexOf(name), 1)
      } else {
        newSelected.push(name)
      }
    } else {
      newSelected = [name]
    }
    setSelected(newSelected)
    onClickButton(newSelected)
  }

  if (!buttonNames) return <></>
  return (
    <div>
      <CustomLabel>{label}</CustomLabel>
      <Box display="flex">
        {buttonNames.map((name, index) => {
          return (
            <CustomButton
              key={index}
              {...rest}
              className={classes.button + ' ' + (selected.indexOf(name) > -1 ? classes.selected : '')}
              onClick={() => handleClick(name)}
              size={size}
              variant={variant}
              color={color}
              name={name}
              disabled={disabled}
            >
              {buttonLabels[index]}
            </CustomButton>
          )
        })}
      </Box>
    </div>
  )
}

export default CustomChoiceButtons
