import { yupResolver } from '@hookform/resolvers/yup'
import { Box, CircularProgress, Grid, ListSubheader, makeStyles, MenuItem } from '@material-ui/core'
import CustomButton from 'components/CustomButton'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import CustomTextField from 'components/CustomTextField'
import LoadingElem from 'components/LoadingElem'
import RenderField from 'components/views/PaymentPage/RenderField'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import * as Yup from 'yup'
import TextWithAttachments from 'area/social/components/TextWithAttachments'
import { apiActions } from '_actions/api_actions'
import React, { useContext } from 'react'
import { UserContext } from 'contexts/UserContext'

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backdropFilter: 'blur(1px)',
    zIndex: 99,
  },
}))

function PublicationDialog({ handleClose, menu, post, edit }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const match = useRouteMatch()
  const { apiCall } = useContext(UserContext)
  const [loading, setLoading] = React.useState(false)

  let title = t('publications.create.title')
  let confirmButton = t('publications.create.confirm')

  if (edit) {
    title = t('publications.edit.title')
    confirmButton = t('publications.edit.confirm')
  }

  const methods = useForm({
    defaultValues: {
      channelId: match?.params?.channel ?? post?.ChannelId ?? null,
      title: post?.Title ?? null,
      content: post?.DecodedContent ?? post?.Content ?? null,
      attachments: post?.Attachments
        ? post.Attachments.map((a) => {
            return { url: a.Url, name: a.Name }
          })
        : [],
    },
    resolver: yupResolver(
      Yup.object().shape({
        channelId: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        title: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
        content: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
      })
    ),
  })

  const onFormSubmit = (values) => {
    if (edit) {
      // Modifier une publication
      setLoading(true)
      apiCall(apiActions.editPost, { postid: post.PostId }, 'post', {
        title: values.title,
        content: values.content,
        attachments: {
          value: '',
          documents: values.attachments,
        },
      }).then((r) => {
        if (r === 'ok') handleClose()
        setLoading(false)
      })
    } else {
      // Créer une publication
      setLoading(true)
      apiCall(apiActions.addPost, {}, 'put', {
        title: values.title,
        content: values.content,
        channelId: values.channelId,
        attachments: {
          value: '',
          documents: values.attachments,
        },
      }).then((r) => {
        if (!r.Error) handleClose()
        setLoading(false)
      })
    }
  }

  if (!menu) return <LoadingElem />
  return (
    <>
      <CustomDialog maxWidth="lg" fullWidth open handleClose={handleClose} title={title}>
        <form onSubmit={methods.handleSubmit(onFormSubmit)}>
          <Box mb={2}>
            <Controller
              name="channelId"
              control={methods.control}
              render={({ field }) => (
                <CustomTextField
                  fullWidth
                  name="channelId"
                  select
                  disabled={edit}
                  value={field.value}
                  label={t('publications.create.channel.label')}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                >
                  {menu &&
                    menu.map((m, i) => [
                      <ListSubheader key={i}>{m.GroupName}</ListSubheader>,
                      m.Channels &&
                        m.Channels.map((c, index) => (
                          <MenuItem value={c.ChannelId} key={index}>
                            {m.GroupName + ' / ' + c.ChannelName}
                          </MenuItem>
                        )),
                    ])}
                </CustomTextField>
              )}
            />
          </Box>
          <Box mb={2}>
            <RenderField methods={methods} name="title" label={t('publications.create.title.label') + '*'} />
          </Box>
          <Box mb={2}>
            <TextWithAttachments
              methods={methods}
              multiline
              textName="content"
              attachmentsName="attachments"
              label={t('publications.create.content.label') + '*'}
              edit={edit}
            />
          </Box>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={3}>
              <CustomButton fullWidth variant="outlined" color="secondary" onClick={handleClose}>
                <Box color="primary.darker">{t('publications.create.cancel')}</Box>
              </CustomButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomButton fullWidth variant="contained" color="secondary" type="submit">
                {confirmButton}
              </CustomButton>
            </Grid>
          </Grid>
        </form>
        {loading && (
          <Box className={classes.loading}>
            <Box display="flex" height="100%" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          </Box>
        )}
      </CustomDialog>
    </>
  )
}

export default PublicationDialog
