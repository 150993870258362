import { Box, Divider, Grid, Hidden, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import Layout from 'components/views/Layout'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { apiActions } from '_actions/api_actions'
import ProfileActivities from './ProfileActivities'
import ProfileInfos from './ProfileInfos'

const useStyles = makeStyles((theme) => ({
  img: {
    height: 50,
    width: 40,
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('md')]: {
      width: 100,
      height: 100,
    },
  },
  iconSize: {
    fontSize: 15,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  boxActions: {
    width: 'max-content',
    cursor: 'pointer',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
}))

const ActionUser = ({ icon, content, onClick }) => {
  const classes = useStyles()

  return (
    <Box className={classes.boxActions} onClick={onClick}>
      <Box mr={1} alignItems="center" display="flex">
        <Icon icon={icon} color="disabled" className={classes.iconSize} />
      </Box>
      <Typography variant="subtitle1" color="textSecondary">
        {content}
      </Typography>
    </Box>
  )
}

function PublicProfilePage() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const match = useRouteMatch()
  const userId = match.params.user

  const [user, setUser] = React.useState(null)
  const [showPublications, setShowPublications] = React.useState(false)

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    apiCall(apiActions.getUserById, { UserId: userId }).then((r) => setUser(r))
  }, [userId])

  const handleShowActivities = () => {
    if (isSmDown) setShowPublications(true)
  }

  if (!user) return <LoadingElem />

  if (showPublications) {
    return (
      <Layout breadcrumbsInfos={{ userName: user.FirstName + ' ' + user.LastName }}>
        <ProfileActivities userProfile={user} handleBackClick={() => setShowPublications(false)} />
      </Layout>
    )
  } else {
    return (
      <Layout breadcrumbsInfos={{ userName: user.FirstName + ' ' + user.LastName }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <Hidden smDown>
              <CustomPaper>
                <Box p={2}>
                  <ProfileInfos user={user} />
                </Box>
              </CustomPaper>
            </Hidden>
            <Hidden mdUp>
              <ProfileInfos user={user} />
              <Divider className={classes.divider} />
              <Typography gutterBottom variant="subtitle1" color="textSecondary">
                {t('userprofile.actions')}
              </Typography>
              <ActionUser icon="annuaire" content={t('userprofile.show.activities')} onClick={handleShowActivities} />
              {/* <ActionUser icon="annuaire" content={t('userprofile.share')} onClick={() => console.log('')} /> */}
            </Hidden>
          </Grid>
          {!isSmDown && (
            <Grid item xs>
              <ProfileActivities userProfile={user} />
            </Grid>
          )}
        </Grid>
      </Layout>
    )
  }
}

export default PublicProfilePage
