import { Grid, makeStyles } from '@material-ui/core'
import Layout from 'components/views/Layout'
import MyPastReservations from 'components/views/MyReservationsPage/MyPastReservations'
import MyReservations from 'components/views/MyReservationsPage/MyReservations'
import MyReservationsScheduler from 'components/views/MyReservationsPage/MyReservationsScheduler'
import MyWallet from 'components/views/MyReservationsPage/MyWallet'
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  myReservationContainer: {
    '& > *': {
      minHeight: '100%',
    },
  },
}))

function MyReservationsPage({ history }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { user } = useContext(UserContext)
  const { society } = useContext(SocietyContext)

  return (
    <Layout
      title={t('my.reservations')}
      pageTitle={t('my.reservations.page.title')}
      pageDescription={t('my.reservations.page.description')}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyReservationsScheduler />
        </Grid>
        <Grid className={classes.myReservationContainer} item xs={12} md>
          <MyReservations />
        </Grid>
        {user && society && society.Credits && (
          <Grid item xs={12} md="auto">
            <MyWallet />
          </Grid>
        )}
        <Grid item xs={12}>
          <MyPastReservations />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default MyReservationsPage
