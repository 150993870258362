import { Box, Button, ButtonProps } from '@material-ui/core'
import { darken, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  tag: {
    borderRadius: 50,
    color: darken(theme.palette.primary.main, 0.7),
    border: '1px solid ' + darken(theme.palette.primary.main, 0.7),
    fontWeight: 400,
    background: theme.palette.background.paper,
    '&, &:hover': {
      borderWidth: 1,
    },
  },
  tagSelected: {
    border: '1px solid ' + theme.palette.primary.main + ' !important',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    fontWeight: 700,
    background: theme.palette.background.paper,
  },
  square: {
    minWidth: theme.spacing(6),
    padding: theme.spacing(1.5),
  },
  smallIcon: {
    '& > *:first-child': {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  mediumIcon: {
    '& > *:first-child': {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  largeIcon: {
    '& > *:first-child': {
      fontSize: theme.typography.pxToRem(35),
    },
  },
}))

export { useStyles }

interface CustomButtonProps extends ButtonProps {
  className?: string
  square?: boolean
  tag?: boolean
  isSelected?: boolean
  iconSize?: string
  twoTones?: boolean
}

const CustomButton: React.FC<CustomButtonProps> = function (props) {
  const classes = useStyles()
  const { className, children, square, tag, twoTones, iconSize, size, isSelected, variant, ...rest } = props
  const cNames = className ? (Array.isArray(className) ? className : [className]) : []

  if (tag) {
    cNames.push(classes.tag)
    if (isSelected) {
      cNames.push(classes.tagSelected)
    }
  }
  if (square) {
    cNames.push(classes.square)
  }

  let customIconSize
  let iconClass

  switch (iconSize) {
    case 'small': {
      iconClass = classes.smallIcon
      break
    }
    case 'large': {
      iconClass = classes.largeIcon
      break
    }
    case 'medium':
    default: {
      iconClass = classes.mediumIcon
      break
    }
  }

  switch (size) {
    case 'small': {
      customIconSize = {
        iconSizeSmall: iconClass,
      }
      break
    }
    case 'large': {
      customIconSize = {
        iconSizeLarge: iconClass,
      }
      break
    }
    case 'medium':
    default: {
      customIconSize = {
        iconSizeMedium: iconClass,
      }
    }
  }

  if (twoTones) {
    return (
      <Button
        {...rest}
        size={size}
        classes={customIconSize}
        className={cNames.join(' ')}
        variant="outlined"
        color="secondary"
      >
        <Box component="span" color="primary.darker">
          {children}
        </Box>
      </Button>
    )
  }

  return (
    <Button
      {...rest}
      size={size}
      classes={customIconSize}
      variant={tag ? 'contained' : variant}
      className={cNames.join(' ')}
    >
      {children}
    </Button>
  )
}

export default CustomButton
