import { yupResolver } from '@hookform/resolvers/yup'
import {
  alpha,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Menu,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import PublicationsMenu from 'area/social/components/PublicationsMenu'
import CustomButton from 'components/CustomButton'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import SquareButton from 'components/SquareButton'
import Layout from 'components/views/Layout'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'
import CreateGroup from 'area/social/views/PublicationsPage/CreateGroup'
import PublicationDialog from 'area/social/views/PublicationsPage/PublicationDialog'
import Publication from 'area/social/components/Publication'
import { getRoutePath } from 'routes'

const useStyles = makeStyles((theme) => ({
  searchBar: {
    '& input': {
      padding: theme.spacing(1.5),
    },
  },
  addButton: {
    padding: theme.spacing(1.5),
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
  boxCapacity: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.darker,
    padding: theme.spacing(2),
    '& *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: '100%',
      fontSize: 20,
    },
  },
  pubContainer: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: theme.spacing(10),
    left: 0,
    right: 0,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

const elementsStep = 20
function PublicationsPage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const match = useRouteMatch()
  const history = useHistory()

  const { apiCall } = useContext(UserContext)

  const [publications, setPublications] = React.useState(null)
  const [nbElements, setNbElements] = React.useState(elementsStep)
  const [loading, setLoading] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [scrollLoading, setScrollLoading] = React.useState(false)
  const [disabledScrollEvent, setDisabledScrollEvent] = React.useState(false)
  const [openPubDialog, setOpenPubDialog] = React.useState(false)
  const [openCreateGroup, setOpenCreateGroup] = React.useState(false)

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const showMenu = !isSmDown || (isSmDown && !match?.params?.group && !match?.params?.channel)
  const showPublications = !isSmDown || (isSmDown && (match?.params?.group || match?.params?.channel))

  const { data: menu } = useSWR(`publications/menu`, () => apiCall(apiActions.getPublicationsMenu).then((r) => r))

  let groupName = null
  let channelName = null
  let totalUsers = null

  if (match.params?.group) {
    menu &&
      menu.map((d) => {
        if (d.GroupId === match.params.group) {
          groupName = d.GroupName
          totalUsers = d.TotalUsers
        }
      })
  }

  if (match.params?.group && match.params?.channel) {
    menu &&
      menu.map((d) => {
        if (d.GroupId === match.params.group) {
          d.Channels?.map((c) => {
            if (c.ChannelId === match.params.channel) channelName = c.ChannelName
          })
        }
      })
  }

  //Charge des nouveaux elements lors du scroll en bas de page
  window.onscroll = () => {
    if (
      publications &&
      publications.length >= nbElements &&
      !disabledScrollEvent &&
      window.innerHeight + window.scrollY + 50 >= document.documentElement.scrollHeight &&
      !loading &&
      showPublications
    ) {
      setNbElements(nbElements + elementsStep)
      setRefresh(!refresh)
      setScrollLoading(true)
    }
  }

  React.useEffect(() => {
    setLoading(true)
    setDisabledScrollEvent(true)
    apiCall(apiActions.getPublications, {}, 'post', {
      GroupId: match?.params?.group ?? null,
      ChannelId: match?.params?.channel ?? null,
      Skip: nbElements - elementsStep,
      Take: elementsStep,
    }).then((r) => {
      if (nbElements - elementsStep === 0) {
        setPublications(r)
      } else {
        let newData = publications ? [...publications] : []
        r.map((a) => newData.push(a))
        setPublications(newData)
      }
      setLoading(false)
      setScrollLoading(false)
      setDisabledScrollEvent(false)
    })
  }, [refresh])

  const handleMenuClick = () => {
    setNbElements(elementsStep)
    setRefresh(!refresh)
  }

  const AddPubComponent = () => {
    return (
      <CustomButton variant="contained" color="primary" onClick={() => setOpenPubDialog(true)}>
        {t('publications.add.text')}
      </CustomButton>
    )
  }

  const handleAddPub = () => {
    setOpenPubDialog(false)
    setRefresh(!refresh)
  }

  return (
    <Layout
      pageTitle={t('publications.page.title')}
      pageDescription={t('publications.page.description')}
      breadcrumbsInfos={{ GroupName: groupName, ChannelName: channelName }}
      titleComponent={isSmDown ? null : AddPubComponent}
    >
      <Hidden mdUp>
        {showMenu && (
          <Box display="flex" alignItems="center" mb={1}>
            <Box mr={1} width="100%">
              <CustomTextField
                className={classes.searchBar}
                fullWidth
                placeholder={t('publications.search.placeholder')}
              />
            </Box>
            <Box>
              <SquareButton
                onClick={() => setOpenPubDialog(true)}
                className={classes.addButton}
                variant="outlined"
                color="secondary"
              >
                <Icon icon="message" />
              </SquareButton>
            </Box>
          </Box>
        )}
      </Hidden>
      <Grid container spacing={1}>
        {showMenu && (
          <Grid item xs={12} md={4} lg={3}>
            <Hidden smDown>
              <Box mb={2}>
                <CustomButton
                  fullWidth
                  variant={!match.params.channel && !match.params.group ? 'contained' : 'outlined'}
                  color="secondary"
                  onClick={() => history.push(getRoutePath('publications'))}
                >
                  {t('publications.see.last')}
                </CustomButton>
              </Box>
            </Hidden>
            {/* <Box mb={1}>
              <CustomButton fullWidth variant="outlined" color="secondary" onClick={() => setOpenCreateGroup(true)}>
                <Box color="primary.darker">{t('publications.group.create')}</Box>
              </CustomButton>
            </Box> */}
            <PublicationsMenu data={menu} onMenuClick={handleMenuClick} />
          </Grid>
        )}
        {showPublications && (
          <Grid item xs={12} md={8} lg={9} className={classes.pubContainer}>
            {(groupName || channelName) && (
              <Box mb={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="block">
                    <Typography variant="h4">
                      <Box color="primary.darker" component="span">
                        {groupName}
                      </Box>
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {channelName}
                    </Typography>
                  </Box>
                  <Typography variant="h3">
                    <Box component="span" fontWeight="fontWeightBold">
                      {totalUsers}&nbsp;
                      <Icon icon="mon-profil" />
                    </Box>
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
              </Box>
            )}
            {loading && !scrollLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              publications &&
              publications.map((p, index) => {
                return (
                  <Box mb={4} key={index}>
                    <Publication post={p} key={index} menu={menu} refresh={() => setRefresh(!refresh)} />
                  </Box>
                )
              })
            )}
          </Grid>
        )}
        {scrollLoading && showPublications && (
          <Box display="flex" justifyContent="center" width="100%">
            <CircularProgress />
          </Box>
        )}
      </Grid>
      {openPubDialog && <PublicationDialog handleClose={handleAddPub} menu={menu} />}
      {/* {openCreateGroup && <CreateGroup handleClose={() => setOpenCreateGroup(false)} />} */}
    </Layout>
  )
}

export default PublicationsPage
