import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import SeparatorBox from 'components/CustomComponents/SeparatorBox'
import Icon from 'components/IcoMoon/Icon'
import ShowPrice from 'components/ShowPrice'
import SquareButton from 'components/SquareButton'
import { RenderInfo, RentCard } from 'components/views/UserCartPage/CardCart'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  borderLeft: {
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderColor: theme.palette.secondary.main,
    borderLeft: '2px dashed',
    [theme.breakpoints.only('xs')]: {
      borderLeft: 'none',
      borderTop: '2px dashed',
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceContainer: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(24),
    '& svg, div': {
      color: theme.palette.secondary.dark,
    },
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },
}))

function CancelOrder({ item, handleClose, dateFormat = 'DD MMMM YYYY', hourFormat = 'HH:mm' }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const [alert, setAlert] = React.useState(null)
  const { apiCall } = React.useContext(UserContext)

  const cancelCostIsEffective = item.CancelCostDate && moment() > moment(item.CancelCostDate)

  const handleCancel = () => {
    apiCall(apiActions.cancelReservationOrder, {}, 'post', { Id: item.OrderResourceRentId })
      .then((r) => {
        handleClose()
        history.go(0)
      })
      .catch((e) => {
        setAlert("Erreur lors de l'annulation")
      })
  }

  return (
    <Box p={3}>
      <Typography variant="subtitle2" color="textSecondary">
        {t('reservation.cancel.order.subtitle')}
      </Typography>
      <Box my={2}>
        <SeparatorBox text={t('reservation.cancel.order.recap')} />
      </Box>
      <CustomPaper elevation={0}>
        <Box p={2}>
          <Grid spacing={2} container alignItems="center">
            <RentCard
              item={item}
              dateFormat={dateFormat}
              hourFormat={hourFormat}
              leftSize={true}
              showCancelCost={false}
            />
          </Grid>
        </Box>
      </CustomPaper>
      <Box my={2}>
        <SeparatorBox text={t('reservation.cancel.order.refund')} />
      </Box>
      <CustomPaper elevation={0}>
        <Box p={2}>
          <Grid spacing={2} container alignItems="center">
            <Grid item xs>
              <RenderInfo
                title={t('reservation.cancel.order.refund.price')}
                description={[
                  <Box display="flex" alignItems="center">
                    <Typography>{item.Prices.Credits}&nbsp;</Typography>
                    <Icon icon="credits" />
                  </Box>,
                ]}
              />
            </Grid>
            <Grid item xs>
              <RenderInfo
                title={t('reservation.cancel.order.refund.cancel.price')}
                description={[
                  <Box display="flex" alignItems="center">
                    <Typography>{cancelCostIsEffective ? item.CancelCost.Credits : 0}&nbsp;</Typography>
                    <Icon icon="credits" />
                  </Box>,
                ]}
              />
            </Grid>
            <Grid item xs={12} sm lg={2}>
              <Box className={classes.borderLeft}>
                <div>
                  <Typography variant="h4" className={classes.priceContainer}>
                    <ShowPrice
                      ht={cancelCostIsEffective ? item.Prices.EuroHT - item.CancelCost.EuroHT : item.Prices.EuroHT}
                      ttc={cancelCostIsEffective ? item.Prices.EuroTTC - item.CancelCost.EuroTTC : item.Prices.EuroTTC}
                      credits={
                        cancelCostIsEffective ? item.Prices.Credits - item.CancelCost.Credits : item.Prices.Credits
                      }
                      force="credits"
                    />
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomPaper>

      <Box className={classes.btnContainer} justifyContent="center" mt={2}>
        <CustomButton twoTones onClick={handleClose}>
          {t('reservation.cancel.order.cancel')}
        </CustomButton>
        <CustomButton variant="contained" color="secondary" onClick={handleCancel}>
          {t('reservation.cancel.order.confirm')}
        </CustomButton>
      </Box>
      {alert && (
        <Box display="flex" justifyContent="center" width="100%" mt={2}>
          <Alert severity="error">{alert}</Alert>
        </Box>
      )}
    </Box>
  )
}

export default CancelOrder
