import { makeStyles, MenuItem } from '@material-ui/core'
import CustomTextField from 'components/CustomTextField'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: theme.palette.text.secondary,
  },
}))

function TypeSelect({ methods, filter, fields, textLabel }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Controller
      name={filter.Name}
      control={methods.control}
      render={({ field }) => (
        <CustomTextField
          fullWidth
          select
          name={filter.Name}
          label={textLabel ? textLabel : t(filter.Key)}
          onChange={field.onChange}
          onBlur={field.onBlur}
        >
          {fields &&
            fields.map((f, index) => (
              <MenuItem key={index} value={f.Name} className={!f.IsBookable ? classes.disabled : ''}>
                {(t(f.OverrideTypeName) ?? t(f.Key)) +
                  (f.Value && f.Value !== '0'
                    ? t('reservations.details.field.duration.tickets', { nbTickets: f.Value })
                    : '')}
              </MenuItem>
            ))}
        </CustomTextField>
      )}
    ></Controller>
  )
}

export default TypeSelect
