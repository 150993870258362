import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import AccountSwitch from 'area/social/components/AccountSwitch'
import Title from 'area/social/components/Title'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import React, { useContext } from 'react'
import { UserContext } from 'contexts/UserContext'
import { apiActions } from '_actions/api_actions'
import CustomSnackBar from 'components/CustomComponents/CustomSnackBar'

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
}))

const CardParameters = ({ methods, title, switches }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChange = (e, name) => {
    methods.setValue(name, e.target.checked, { shouldDirty: true })
  }

  return (
    <CustomPaper className={classes.fullHeight}>
      <Box p={4}>
        <Title title={title} />
        {switches &&
          Object.entries(switches).map(([key, value]) => {
            return (
              <Box key={key} display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="subtitle1">{t('my-account.parameters.mailing.' + value.toLowerCase())}</Typography>
                <AccountSwitch value={methods.getValues()[value]} onChange={(e) => handleChange(e, value)} />
              </Box>
            )
          })}
      </Box>
    </CustomPaper>
  )
}

function AccountParameters({ defaultValues }) {
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)
  const [message, setMessage] = React.useState(null)

  const methods = useForm({
    defaultValues: {
      DailyMailing: defaultValues?.DailyMailing,
      WeeklyMailing: defaultValues?.WeeklyMailing,
      EventMailing: defaultValues?.EventMailing,
      PubMailing: defaultValues?.PubMailing,
      PubTagMailing: defaultValues?.PubTagMailing,
      UnreadChatMailing: defaultValues?.UnreadChatMailing,
    },
  })

  const watchFields = useWatch(methods)

  React.useEffect(() => {
    if (methods.formState.isDirty) {
      apiCall(apiActions.editUserNotifications, {}, 'post', watchFields).then((r) => {
        methods.reset(watchFields)
        setMessage(t('common.saved'))
      })
    }
  }, [methods.formState.isDirty])

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs>
        <CardParameters
          methods={methods}
          title={t('my-account.parameters.newsletters.title')}
          switches={['DailyMailing', 'WeeklyMailing']}
        />
      </Grid> */}
      <Grid item xs>
        <CardParameters
          methods={methods}
          title={t('my-account.parameters.notifications.title')}
          switches={['EventMailing', 'PubMailing']}
        />
      </Grid>
      <Grid item xs>
        <CardParameters
          methods={methods}
          title={t('my-account.parameters.others.title')}
          switches={['PubTagMailing', 'UnreadChatMailing']}
        />
      </Grid>
      <CustomSnackBar
        open={message !== null}
        message={message}
        autoHideDuration={2000}
        onClose={() => setMessage(null)}
      />
    </Grid>
  )
}

export default AccountParameters
