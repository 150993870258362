import { Box, Chip, Container, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import SquareButton from 'components/SquareButton'
import React, { useCallback, useContext } from 'react'
import Title from 'area/social/components/Title'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import RenderField from 'components/views/PaymentPage/RenderField'
import YouTubeIcon from '@material-ui/icons/YouTube'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import LanguageIcon from '@material-ui/icons/Language'
import { UserContext } from 'contexts/UserContext'
import CustomSnackBar from 'components/CustomComponents/CustomSnackBar'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { apiActions } from '_actions/api_actions'
import Uploady, { UploadyContext, useItemFinishListener } from '@rpldy/uploady'
import ImageIcon from '@material-ui/icons/Image'
import UploadDropZone from '@rpldy/upload-drop-zone'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomForm from 'components/CustomComponents/CustomForm'
import AccountSwitch from 'area/social/components/AccountSwitch'

const imgSize = 150
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  img: {
    width: imgSize,
    height: imgSize,
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
  },
  chipSelected: {
    backgroundColor: theme.palette.secondary.light,
    border: '1px solid ' + theme.palette.secondary.main,
  },
  deleteIcon: {
    padding: theme.spacing(1),
    paddingLeft: 0,
    '& svg': {
      fontSize: 12,
      color: theme.palette.secondary.dark,
    },
  },
  visible: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  boxIcon: {
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: 5,
    marginRight: theme.spacing(0.5),
  },
  imageIcon: {
    width: imgSize,
    height: imgSize,
    border: '1px dashed ' + theme.palette.grey['400'],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey['400'],
  },
  photoButton: {
    marginTop: theme.spacing(-2.5),
    zIndex: 10,
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1.5),
  },
}))

const UploadyComponent = ({ methods }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const uploady = useContext(UploadyContext)
  const { apiCall } = useContext(UserContext)

  const [imgUrl, setImgUrl] = React.useState(methods.getValues().PictureProfileUrl ?? null)
  const [message, setMessage] = React.useState(null)

  const onClick = useCallback(() => {
    uploady.showFileUpload()
  }, [uploady])

  useItemFinishListener((item) => {
    let url = item.uploadResponse.data
    setImgUrl(url)
    methods.setValue('PictureProfileUrl', url)
    apiCall(apiActions.editUserProfilePicture, {}, 'post', {
      PictureProfileUrl: url,
    }).then((r) => {
      setMessage(t('profile.edit.picture.confirm'))
    })
  })

  const deleteImage = () => {
    setImgUrl(null)
    methods.setValue('PictureProfileUrl', null)
  }

  return (
    <div>
      <Box display="flex" justifyContent="center">
        {imgUrl ? <img src={imgUrl} className={classes.img} alt="" /> : <ImageIcon className={classes.imageIcon} />}
      </Box>
      <Box display="flex" justifyContent="center" ml={-4}>
        <div className={classes.photoButton}>
          <SquareButton size="small" color="secondary" onClick={onClick}>
            <Icon icon="modifier" />
          </SquareButton>
        </div>
        <div className={classes.photoButton}>
          <SquareButton size="small" onClick={() => deleteImage()} color="secondary">
            <Icon icon="supprimer" />
          </SquareButton>
        </div>
      </Box>
      <CustomSnackBar open={message} message={message} autoHideDuration={2000} onClose={() => setMessage(null)} />
    </div>
  )
}

const Profile = ({ defaultValues }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        Job: Yup.string().nullable(),
        Description: Yup.string().nullable(),
        Tags: Yup.array().nullable(),
        VideoDescription: Yup.string().nullable(),
      })
    ),
    shouldFocusError: false,
  })

  const [selectedChips, setSelectedChips] = React.useState(defaultValues?.Tags)
  const [chips, setChips] = React.useState(defaultValues?.Tags)
  const [message, setMessage] = React.useState(null)

  let uploadUrl = 'https://localhost/media/media/save'
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'production')
    uploadUrl = 'https://media.fteledition.fr/media/save'

  const handleDeleteChips = (chip) => {
    const index = selectedChips.indexOf(chip)
    let newSelected = [...selectedChips]
    newSelected.splice(index, 1)
    setSelectedChips(newSelected)
  }

  const handleChipClick = (chip) => {
    let newSelected = [...selectedChips]
    newSelected.push(chip)
    setSelectedChips(newSelected)
  }

  const handleAddChip = (e) => {
    e.stopPropagation()
    let chip = e.target.value
    if (chip !== null && chip !== '' && chip.trim() !== '') {
      if (chips.indexOf(chip) === -1) {
        let newChips = [...chips]
        newChips.push(chip)
        setChips(newChips)
        handleChipClick(chip)
      }
    }
  }

  const handleSwitchChange = (switchName, value) => {
    methods.setValue(switchName, value, { shouldDirty: true })
  }

  React.useEffect(() => {
    methods.setValue('Tags', selectedChips, { shouldDirty: true })
  }, [selectedChips])

  const DeleteIcon = ({ onClick }) => {
    return (
      <IconButton onClick={onClick} className={classes.deleteIcon}>
        <Icon icon="quantite" />
      </IconButton>
    )
  }

  const onFormSubmit = (values) => {
    apiCall(apiActions.editUserFociAndDescription, {}, 'post', {
      Job: values.Job,
      HidePresence: values.HidePresence,
      ProfileVisibleInPhotoGroup: values.ProfileVisibleInPhotoGroup,
      Tags: selectedChips,
      Description: values.Description,
      VideoDescription: values.VideoDescription,
    }).then((r) => {
      methods.reset(values)
      setMessage(t('common.saved'))
    })
  }

  return (
    <CustomForm
      title={t('my-account.profile.title')}
      methods={methods}
      validationButtonText={t('common.form.validate.change')}
      onFormSubmit={onFormSubmit}
    >
      <Uploady destination={{ url: uploadUrl }}>
        <UploadDropZone>
          <UploadyComponent methods={methods} />
        </UploadDropZone>
      </Uploady>
      {/* <Box mb={2} mt={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box mr={1} color="primary.darker">
          <Typography variant="h6">{t('my-account.trombi.hide.presence')}</Typography>
        </Box>
        <AccountSwitch
          value={methods.getValues().HidePresence}
          onChange={(e) => handleSwitchChange('HidePresence', e.target.checked)}
        />
      </Box> */}
      <Box mb={2} mt={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box mr={1} color="primary.darker">
          <Typography variant="h6">{t('my-account.trombi.visible.photogroup')}</Typography>
        </Box>
        <AccountSwitch
          value={methods.getValues().ProfileVisibleInPhotoGroup}
          onChange={(e) => handleSwitchChange('ProfileVisibleInPhotoGroup', e.target.checked)}
        />
      </Box>
      <Box mb={2}>
        <RenderField
          name="Job"
          methods={methods}
          fullWidth
          placeholder={t('my-account.profile.job.placeholder')}
          label={t('my-account.profile.professional.function.label')}
        />
      </Box>
      <Box mb={2}>
        <RenderField
          name="Description"
          methods={methods}
          fullWidth
          multiline
          placeholder={t('my-account.profile.description.placeholder')}
          label={t('my-account.profile.description.label')}
        />
      </Box>
      <Box mb={2}>
        <RenderField
          name="VideoDescription"
          methods={methods}
          fullWidth
          placeholder={t('my-account.profile.videodescription.placeholder')}
          label={t('my-account.profile.videodescription.label')}
        />
      </Box>
      <Box mb={2}>
        <CustomTextField
          fullWidth
          onBlur={(e) => handleAddChip(e)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleAddChip(e)
          }}
          placeholder={t('my-account.profile.interests.placeholder')}
          label={t('my-account.profile.interests.label')}
        />
      </Box>
      <Box display="flex" flexWrap="wrap" maxWidth="100%">
        {chips &&
          chips.map((c, index) => {
            if (selectedChips && selectedChips.indexOf(c) !== -1) {
              return (
                <Box p={0.5} key={index}>
                  <Chip
                    label={c}
                    className={classes.chipSelected}
                    onDelete={() => handleDeleteChips(c)}
                    deleteIcon={<DeleteIcon onClick={() => handleDeleteChips(c)} />}
                  />
                </Box>
              )
            } else {
              return (
                <Box key={index} p={0.5}>
                  <Chip label={c} onClick={() => handleChipClick(c)} />
                </Box>
              )
            }
          })}
      </Box>
      <CustomSnackBar open={message} message={message} autoHideDuration={2000} onClose={() => setMessage(null)} />
    </CustomForm>
  )
}

const TrombiPreferences = ({ defaultValues }) => {
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)
  const [message, setMessage] = React.useState(null)
  const classes = useStyles()

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      EmailVisible: defaultValues.EmailVisible,
      MobilePhoneVisible: defaultValues.MobilePhoneVisible,
      SocietyVisible: defaultValues.SocietyVisible,
      Brand: defaultValues.Brand ?? defaultValues.SocietyName,
    },
    shouldFocusError: false,
  })

  const onFormSubmit = (values) => {
    apiCall(apiActions.editTrombinoscopePreferences, {}, 'post', {
      EmailVisible: values.EmailVisible,
      MobilePhoneVisible: values.MobilePhoneVisible,
      SocietyVisible: values.SocietyVisible,
      Brand: values.Brand ?? values.SocietyName,
    }).then((r) => {
      methods.reset(values)
      setMessage(t('common.saved'))
    })
  }

  return (
    <CustomForm
      title={t('my-account.trombi.title')}
      methods={methods}
      validationButtonText={t('common.form.validate.change')}
      onFormSubmit={onFormSubmit}
    >
      <Container maxWidth="sm">
        <Box mb={2}>
          <Typography gutterBottom variant="subtitle2" color="textSecondary">
            {t('my-account.profile.professional.caption')}
          </Typography>
          <Box display="flex" alignItems="center" mb={1}>
            <Icon icon="non-visible" color="primary" className={classes.boxIcon} />
            <Typography variant="subtitle2" color="textSecondary">
              {t('my-account.profile.professional.unvisible')}
            </Typography>
            <Box ml={1} display="flex" alignItems="center">
              <Icon icon="visible" color="primary" className={classes.visible + ' ' + classes.boxIcon} />
            </Box>
            <Typography variant="subtitle2" color="textSecondary">
              {t('my-account.profile.professional.visible')}
            </Typography>
          </Box>
        </Box>
        <Line
          disabled
          methods={methods}
          label={t('my-account.trombi.email.label')}
          name="Email"
          visibleName="EmailVisible"
        />
        <Line
          disabled
          methods={methods}
          label={t('my-account.phone.label')}
          name="MobilePhone"
          visibleName="MobilePhoneVisible"
        />
        <Line methods={methods} label={t('my-account.society.label')} name={'Brand'} visibleName="SocietyVisible" />
      </Container>
      <CustomSnackBar open={message} message={message} autoHideDuration={2000} onClose={() => setMessage(null)} />
    </CustomForm>
  )
}

const Line = ({ methods, name, label, visibleName, ...rest }) => {
  const classes = useStyles()
  const [visible, setVisible] = React.useState(methods.getValues()[visibleName])

  React.useEffect(() => {
    if (visible !== methods.getValues()[visibleName]) methods.setValue(visibleName, visible, { shouldDirty: true })
  }, [visible])

  return (
    <Box mb={2}>
      <Box display="flex" alignItems="center">
        <Box mr={2} flex="2">
          <RenderField {...rest} name={name} methods={methods} label={label} />
        </Box>
        <Box mt={3}>
          <SquareButton onClick={() => setVisible(!visible)} color="primary" className={visible ? classes.visible : ''}>
            <Icon icon={visible ? 'visible' : 'non-visible'} />
          </SquareButton>
        </Box>
      </Box>
    </Box>
  )
}

const SocialNetworks = ({ defaultValues }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const [selectedButtons, setSelectedButtons] = React.useState([])

  const [message, setMessage] = React.useState(null)

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        Youtube: Yup.string().nullable(),
        Twitter: Yup.string().nullable(),
        Website: Yup.string().nullable(),
        Instagram: Yup.string().nullable(),
        Facebook: Yup.string().nullable(),
        Linkedin: Yup.string().nullable(),
      })
    ),
    shouldFocusError: false,
  })

  const onFormSubmit = (values) => {
    apiCall(apiActions.editUserSocialInfos, {}, 'post', {
      Youtube: values.Youtube,
      Twitter: values.Twitter,
      Website: values.Website,
      Instagram: values.Instagram,
      Facebook: values.Facebook,
      Linkedin: values.Linkedin,
    }).then((r) => {
      methods.reset(values)
      setMessage(t('common.saved'))
    })
  }

  React.useEffect(() => {
    let selected = []
    const values = methods.getValues()
    if (values.Website) selected.push({ name: 'Website', label: 'Site web' })
    if (values.Linkedin) selected.push({ name: 'Linkedin', label: 'Linkedin' })
    if (values.Twitter) selected.push({ name: 'Twitter', label: 'Twitter' })
    if (values.Instagram) selected.push({ name: 'Instagram', label: 'Instagram' })
    if (values.Facebook) selected.push({ name: 'Facebook', label: 'Facebook' })
    if (values.Youtube) selected.push({ name: 'Youtube', label: 'Youtube' })
    setSelectedButtons(selected)
  }, [])

  const handleClickButton = (button, label) => {
    let newButtons = [...selectedButtons]
    const isIn = newButtons.some((a) => a.name === button)
    if (isIn) {
      let index = newButtons.findIndex((e) => e.name === button && e.label === label)
      newButtons.splice(index, 1)
    } else {
      newButtons.push({ name: button, label: label })
    }
    setSelectedButtons(newButtons)
  }

  const Button = ({ icon, name, selected, label }) => {
    return (
      <Box p={1}>
        <SquareButton
          onClick={() => handleClickButton(name, label)}
          color="primary"
          className={selected ? classes.visible : ''}
        >
          {icon}
        </SquareButton>
      </Box>
    )
  }

  return (
    <CustomForm
      title={t('my-account.profile.networks.title')}
      methods={methods}
      validationButtonText={t('common.form.validate.change')}
      onFormSubmit={onFormSubmit}
    >
      <Box display="flex" alignItems="center" justifyContent={'center'} mb={2} flexWrap="wrap">
        <Button
          icon={<LanguageIcon />}
          name="Website"
          label="Site web"
          selected={selectedButtons.some((a) => a.name === 'Website')}
        />
        <Button
          icon={<LinkedInIcon />}
          name="Linkedin"
          label="Linkedin"
          selected={selectedButtons.some((a) => a.name === 'Linkedin')}
        />
        <Button
          icon={<TwitterIcon />}
          name="Twitter"
          label="Twitter"
          selected={selectedButtons.some((a) => a.name === 'Twitter')}
        />
        <Button
          icon={<InstagramIcon />}
          name="Instagram"
          label="Instagram"
          selected={selectedButtons.some((a) => a.name === 'Instagram')}
        />
        <Button
          icon={<FacebookIcon />}
          name="Facebook"
          label="Facebook"
          selected={selectedButtons.some((a) => a.name === 'Facebook')}
        />
        <Button
          icon={<YouTubeIcon />}
          name="Youtube"
          label="Youtube"
          selected={selectedButtons.some((a) => a.name === 'Youtube')}
        />
      </Box>
      {selectedButtons && selectedButtons.length > 0 ? (
        selectedButtons.map((button, index) => {
          return (
            <Box key={index} mb={2}>
              <RenderField methods={methods} name={button.name} label={button.label} />
            </Box>
          )
        })
      ) : (
        <Typography align="center" variant="h6">
          <Box color="primary.darker" component="span">
            {t('my-account.profile.networks.none')}
          </Box>
        </Typography>
      )}
      <CustomSnackBar
        open={message !== null}
        message={message}
        autoHideDuration={2000}
        onClose={() => setMessage(null)}
      />
    </CustomForm>
  )
}

function ProfileAccount({ defaultValues }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7}>
        <Profile defaultValues={defaultValues} />
      </Grid>
      <Grid item xs={12} md={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mb={2}>
              <TrombiPreferences defaultValues={defaultValues} />
            </Box>
            <SocialNetworks defaultValues={defaultValues} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProfileAccount
