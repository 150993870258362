import { Box, CardActionArea, CardContent, Grid, Link as MuiLink, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CSSGrid } from 'components/CustomComponents/CSSGrid'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import { useTransformUrlForMedia } from 'components/CustomComponents/MediaImage'
import LoadingElem from 'components/LoadingElem'
import Layout from 'components/views/Layout'
import { DEFAULT_IMG } from 'config'
import { UserContext } from 'contexts/UserContext'
import Image from 'material-ui-image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const colWidth = 310

const useStyles = makeStyles((theme) => ({
  category: {
    height: '100%',
  },
  image: {
    borderRadius: theme.shape.borderRadius,
  },
  link: {
    '&, &:hover': {
      textDecoration: 'none',
    },
    flex: 1,
    '& > .MuiCardActionArea-root': {
      '&, & > .MuiCardContent-root': {
        height: '100%',
      },
    },
  },
  itemName: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(22),
    color: theme.palette.primary.darker,
    lineHeight: 1,
  },
  cardContent: {
    display: 'flex',
  },
}))

export function Category({ coworkingSpaceId, id, name, description, imageurl, imageProps, onClick }) {
  const classes = useStyles()

  const imageURL = useTransformUrlForMedia(imageurl, {
    xs: {
      // width: 100,
      // height: 100,
      mode: 'crop',
      retina: true,
      cx: imageProps.CropX,
      cy: imageProps.CropY,
      cWidth: imageProps.CropWidth,
      cHeight: imageProps.CropHeight,
    },
  })

  return (
    <CustomPaper selectable className={classes.category}>
      <MuiLink
        className={classes.link}
        component={Link}
        onClick={(e) => onClick(e, id)}
        to={getRoutePath('newreservation-category', { ':coworkingspace': coworkingSpaceId, ':cwcategory': id })}
      >
        <CardActionArea>
          <CardContent className={classes.cardContent}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs>
                <Typography variant="body1" className={classes.itemName} gutterBottom>
                  <Box color="primary.darker">{name}</Box>
                </Typography>
                {description && (
                  <Box mt={1}>
                    <Typography variant="subtitle2" color="textSecondary" component="div">
                      <Box dangerouslySetInnerHTML={{ __html: description }}></Box>
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={4}>
                <Image
                  className={classes.image}
                  animationDuration={0}
                  color="transparent"
                  imageStyle={{ objectFit: 'cover' }}
                  src={imageURL}
                />
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </MuiLink>
    </CustomPaper>
  )
}

function CategoriesPage({ onCategoryClick, coworkingSpaceId }) {
  const { t } = useTranslation()
  const history = useHistory()
  const { apiCall, user } = React.useContext(UserContext)

  const getAllCategories = () =>
    apiCall(apiActions.getAllCategories, {
      coworkingSpaceId,
    }).then((r) => {
      if (r.Error) {
        //
      } else {
        return r.data
      }
    })

  const { data } = useSWR(`getAllCategories/${coworkingSpaceId}`, getAllCategories)

  if (!data)
    return (
      <Layout
        title={t('menu.reservations.new')}
        pageTitle={t('reservations.categories.page.title')}
        pageDescription={t('reservations.categories.page.subtitle')}
      >
        <LoadingElem />
      </Layout>
    )

  if (data.length === 1) {
    history.push(
      getRoutePath('newreservation-category', { ':coworkingspace': coworkingSpaceId, ':cwcategory': data[0].Id })
    )
  }

  if (data.length === 0 && !user) {
    history.push(getRoutePath('login'))
  }

  return (
    <Layout
      title={t('menu.reservations.new')}
      pageTitle={t('reservations.categories.page.title')}
      pageDescription={t('reservations.categories.page.subtitle')}
    >
      <CSSGrid colWidth={colWidth} spacing={1}>
        {data.map((item) => (
          <div key={item.Id}>
            <Category
              coworkingSpaceId={coworkingSpaceId}
              onClick={onCategoryClick}
              id={item.Id}
              name={item.Name}
              description={item.Description}
              imageurl={item.Image?.ImageURL ?? DEFAULT_IMG}
              imageProps={item.Image}
            />
          </div>
        ))}
      </CSSGrid>
    </Layout>
  )
}

export default CategoriesPage
