import { Box, Grid, makeStyles, MenuItem, Select } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import CustomCheckbox from 'components/CustomCheckbox'
import CustomLabel from 'components/CustomLabel'
import CustomTextField from 'components/CustomTextField'
import moment from 'moment'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import RenderField from '../PaymentPage/RenderField'

const useStyles = makeStyles((theme) => ({
  selectSpace: {
    '& > label': {
      color: theme.palette.error.main,
    },
    '& fieldset': {
      borderColor: theme.palette.error.main,
    },
    '& *': {
      color: theme.palette.error.main,
    },
  },
}))

function AdditionalDatas({ additionalDatas, methods }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const isPerson = methods.getValues().isPerson

  return (
    additionalDatas &&
    additionalDatas.map((d) => {
      if (d.ShowOnlyWhenSociety && isPerson) return null
      const isRequired = d.IsRequired
      return (
        <Grid item xs={12} md={6} className={methods?.formState?.errors[d.Id] ? classes.selectSpace : ''}>
          {d.Type !== 2 && <CustomLabel>{`${d.Name}${isRequired ? '*' : ''}`}</CustomLabel>}
          <Controller
            name={d.Id}
            control={methods.control}
            render={({ field }) => {
              switch (d.Type) {
                case 0:
                case 1:
                  return <RenderField methods={methods} name={d.Id} type={d.Type === 1 && 'number'} />
                case 2:
                  return (
                    <Box display="flex" alignItems="center" height="100%">
                      <CustomCheckbox
                        checked={field.value || false}
                        onChange={(_, v) => {
                          field.onChange(v)
                        }}
                      />
                      <Box dangerouslySetInnerHTML={{ __html: d.Name }} />
                      {isRequired && '*'}
                    </Box>
                  )
                case 3:
                  return (
                    <DatePicker
                      autoOk
                      fullWidth
                      color="primary"
                      clearable="true"
                      variant="inline"
                      format="DD/MM/yy"
                      value={field.value || null}
                      onChange={(e) => field.onChange(e)}
                      TextFieldComponent={CustomTextField}
                    />
                  )
                case 4:
                default:
                  return (
                    <Select
                      displayEmpty={!isRequired}
                      defaultValue=""
                      fullWidth
                      variant="outlined"
                      inputRef={field.ref}
                      onChange={(e) => field.onChange(e)}
                    >
                      {!d.IsRequired && <MenuItem value="">{t('register.form.no.cs')}</MenuItem>}
                      {d.DropDownValues.map((value, index) => {
                        return (
                          <MenuItem key={index} value={value.Id}>
                            {value.Value}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  )
              }
            }}
          />
        </Grid>
      )
    })
  )
}

export default AdditionalDatas
