import { Box, Button, Collapse, Grid, IconButton, OutlinedInput, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomLabel from 'components/CustomLabel'
import Icon from 'components/IcoMoon/Icon'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  validDate: {
    borderRadius: theme.shape.borderRadius,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    backgroundColor: theme.palette.primary.main,
  },
}))

function DateSelect({ methods, filter }) {
  const classes = useStyles()

  const { t } = useTranslation()
  const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ]
  const [monthIndex, setMonthIndex] = React.useState(0)
  const [dayIndex, setDayIndex] = React.useState(0)
  const [nbDays, setNbDays] = React.useState(30)
  const [selectedDate, setSelectedDate] = React.useState('')
  const [labelDate, setLabelDate] = React.useState('')
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    setNbDays(new Date(2021, monthIndex + 1, 0).getDate())
  }, [monthIndex])

  React.useEffect(() => {
    methods.setValue(filter.Name, selectedDate)
  }, [selectedDate])

  const handleLessDayClick = () => {
    dayIndex - 1 === -1 ? setDayIndex(nbDays - 1) : setDayIndex(dayIndex - 1)
  }

  const handleMoreDayClick = () => {
    setDayIndex((dayIndex + 1) % nbDays)
  }

  const transformDate = (date) => {
    const jours = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
    const day = date.getUTCDay() - 1 === -1 ? jours[6] : jours[date.getUTCDay() - 1]

    return day + ' ' + date.getUTCDate() + ' ' + months[date.getMonth()]
  }

  const handleClick = () => {
    setOpen(false)
    let date = new Date()
    date.setMonth(monthIndex, dayIndex + 1)
    setLabelDate(transformDate(date))
    setSelectedDate(date)
  }

  return (
    <Box>
      <CustomLabel>{t(filter.Key)}</CustomLabel>
      <OutlinedInput
        onClick={() => setOpen(!open)}
        fullWidth
        placeholder={t('')}
        readOnly
        value={labelDate}
        endAdornment={
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <Icon icon="fleche-haut" /> : <Icon icon="fleche-bas" />}
          </IconButton>
        }
      />
      <Collapse in={open}>
        <Paper>
          <Box pt={2}>
            <Grid container justifyContent="space-evenly" alignItems="center">
              <Grid item xs={2}>
                <Box display="flex" justifyContent="center">
                  <IconButton
                    onClick={() => (monthIndex - 1 === -1 ? setMonthIndex(11) : setMonthIndex((monthIndex - 1) % 12))}
                    variant="outlined"
                  >
                    <Icon icon="fleche-gauche" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Typography align="center">{months[monthIndex]}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="center">
                  <IconButton onClick={() => setMonthIndex((monthIndex + 1) % 12)} variant="outlined">
                    <Icon icon="fleche-droite" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container justifyContent="space-evenly" alignItems="center">
              <Grid item xs={2}>
                <Box display="flex" justifyContent="center">
                  <IconButton onClick={handleLessDayClick} variant="outlined">
                    <Icon icon="fleche-gauche" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Typography align="center">{dayIndex + 1}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="center">
                  <IconButton onClick={handleMoreDayClick} variant="outlined">
                    <Icon icon="right" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Button className={classes.validDate} fullWidth onClick={handleClick}>
              Valider cette date
            </Button>
          </Box>
        </Paper>
      </Collapse>
    </Box>
  )
}

export default DateSelect
