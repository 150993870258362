import { yupResolver } from '@hookform/resolvers/yup'
import {
  alpha,
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  Link as MuiLink,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import TextWithAttachments from 'area/social/components/TextWithAttachments'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import { DEFAULT_IMG } from 'config'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PostCommentaries from 'area/social/views/PublicationsPage/PostCommentaries'
import * as Yup from 'yup'
import PublicationDialog from 'area/social/views/PublicationsPage/PublicationDialog'
import { useConfirm } from 'material-ui-confirm'
import { apiActions } from '_actions/api_actions'
import CustomButton from 'components/CustomComponents/CustomButton'
import { getRoutePath } from 'routes'
import { Link } from 'react-router-dom'
import ShowMoreButton from 'area/home/components/ShowMoreButton'

const useStyles = makeStyles((theme) => ({
  img: {
    height: 50,
    width: 50,
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
  },
  paperPub: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  moreButton: {
    textDecoration: 'underline',
  },
  imgLinks: {
    height: 300,
    width: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      height: 150,
    },
  },
  attachmentsBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'max-content',
  },
  imgAttachment: {
    cursor: 'pointer',
  },
  imgLink: {
    borderRadius: theme.shape.borderRadius,
    height: 100,
    width: 100,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  answer: {
    backgroundColor: alpha(theme.palette.primary.light, 0.5),
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  alignEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconPoints: {
    fontSize: 15,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    '& *': {
      wordBreak: 'break-all',
    },
  },
}))

export const Attachments = ({ attachments, openAttachments }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const length = attachments && attachments.length
  const [open, setOpen] = React.useState(true)

  React.useEffect(() => {
    setOpen(!open)
  }, [openAttachments])

  const handleClick = (url) => {
    window.open(url, '_blank')
  }

  return (
    <div>
      <div className={classes.attachmentsBox} onClick={() => setOpen(!open)}>
        <Icon color="primary" icon={open ? 'fleche-bas' : 'fleche-droite'} />
        <Typography variant="caption" color="primary">
          {t('publications.nb.attachments', { count: length })}
        </Typography>
      </div>
      {open && (
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {attachments &&
            attachments.map((a, index) => {
              const extension = a.Extension
              return (
                <Box mr={1} key={index}>
                  <img
                    onClick={() => handleClick(a.Url)}
                    src={
                      extension && (extension.toLowerCase() === 'jpg' || extension.toLowerCase() === 'png')
                        ? a.Url
                        : DEFAULT_IMG
                    }
                    className={classes.img + ' ' + classes.imgAttachment}
                  />
                </Box>
              )
            })}
        </Box>
      )}
    </div>
  )
}

const LinksPublication = ({ links }) => {
  const classes = useStyles()

  const handleClick = (url) => {
    window.open(url, '_blank')
  }

  return (
    <div className={classes.content}>
      {links.map((l, index) => {
        return (
          <Box mb={1} key={index}>
            <CustomPaper selectable onClick={() => handleClick(l.LinkUrl)} className={classes.imgAttachment}>
              <Box display="flex" alignItems="center">
                <Box p={1} display="flex" alignItems="center">
                  <img src={l.ImageUrl ?? DEFAULT_IMG} className={classes.img + ' ' + classes.imgLink} />
                </Box>
                <Box display="block" p={1}>
                  <Typography gutterBottom variant="h5">
                    <Box color="primary.darker" fontWeight="fontWeightBold" component="span">
                      {l.Title}
                    </Box>
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    <Box color="primary.darker" component="span">
                      {l.Description}
                    </Box>
                  </Typography>
                  <Typography variant="subtitle2">{l.LinkUrl}</Typography>
                </Box>
              </Box>
            </CustomPaper>
          </Box>
        )
      })}
    </div>
  )
}

function Publication({ post, menu, refresh }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user, apiCall } = useContext(UserContext)

  const [showMore, setShowMore] = React.useState(false)
  const [openAttachments, setOpenAttachments] = React.useState(false)
  const [openCommentaries, setOpenCommentaries] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [openAnswer, setOpenAnswer] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [editPost, setEditPost] = React.useState(false)
  const confirm = useConfirm()

  const nbAttachments = post.Attachments && post.Attachments.length
  const nbCommentaries = post.Commentaries && post.Commentaries.length

  const methods = useForm({
    defaultValues: {
      content: null,
      attachments: [],
    },
    resolver: yupResolver(
      Yup.object().shape({
        content: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
      })
    ),
  })

  const handleEditPost = () => {
    setEditPost(true)
    setAnchorEl(null)
  }

  const handleDeletePost = () => {
    confirm({ title: '', description: t('publications.delete.message') }).then(() => {
      setLoading(true)
      apiCall(apiActions.deletePost, { postId: post.PostId }, 'delete').then((r) => {
        if (r === 'ok') setAnchorEl(null)
        setLoading(false)
        refresh()
      })
    })
  }

  const handleDeleteCommentary = (id) => {
    confirm({ title: '', description: t('publications.commentary.delete.message') }).then(() => {
      setLoading(true)
      apiCall(apiActions.deleteComment, { postId: post.PostId, commentId: id }, 'delete').then((r) => {
        if (r === 'ok') setAnchorEl(null)
        setLoading(false)
        refresh()
      })
    })
  }

  const handleClose = () => {
    setEditPost(false)
    refresh()
  }

  const onFormSubmit = (values) => {
    setLoading(true)
    apiCall(apiActions.addComment, { postid: post.PostId }, 'put', {
      content: values.content,
      attachments: {
        value: '',
        documents: values.attachments,
      },
    }).then((r) => {
      if (!r.Error) refresh()
      setOpenAnswer(false)
      methods.reset()
      setLoading(false)
      setOpenCommentaries(true)
    })
  }
  return (
    <div>
      <Box display="flex" alignItems="center" mb={1}>
        <Box mr={1} display="flex">
          <MuiLink component={Link} to={getRoutePath('userprofile', { ':user': post.AuthorId })}>
            <img src={post.AuthorImageURL ?? DEFAULT_IMG} className={classes.img} />
          </MuiLink>
        </Box>
        <Box display="block">
          <Typography variant="subtitle1">
            <Box component="span" color="primary.darker">
              {post.AuthorFirstName + ' ' + post.AuthorLastName}
            </Box>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {moment(post.Date).format('DD MMMM YYYY, HH:mm')}
          </Typography>
        </Box>
      </Box>
      <CustomPaper className={classes.paperPub}>
        <Box display="flex" justifyContent="space-between">
          <Typography gutterBottom variant="h5">
            <Box component="span" color="primary.darker">
              {post.Title}
            </Box>
          </Typography>
          {(post.CanEdit || post.CanDelete) && (
            <div>
              <IconButton color="primary" onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Icon className={classes.iconPoints} color="primary" icon="trois-petits-points" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                transformOrigin={{
                  horizontal: 'center',
                  vertical: 'top',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {post.CanEdit && (
                  <MenuItem onClick={handleEditPost}>
                    <Icon icon="modifier" />
                    &nbsp;{t('publications.edit')}
                  </MenuItem>
                )}
                {post.CanDelete && (
                  <MenuItem onClick={handleDeletePost}>
                    <Icon icon="supprimer" />
                    &nbsp;{t('publications.delete')}
                  </MenuItem>
                )}
              </Menu>
            </div>
          )}
        </Box>
        {editPost && <PublicationDialog edit menu={menu} post={post} handleClose={handleClose} />}
        <Typography variant="body2">
          <Box
            name="content"
            color="primary.darker"
            component="span"
            dangerouslySetInnerHTML={{
              __html: showMore
                ? post.Content
                : post.Content.length > 1000
                ? post.Content.substring(0, 1000) + '...'
                : post.Content,
            }}
            height={post.Content.length > 1000 ? '100%' : 200}
          />
        </Typography>
        {post.Content?.length > 1000 && (
          <Box flex="1" align="center" mb={2}>
            <ShowMoreButton onClick={(v) => setShowMore(v)} />
          </Box>
        )}
        {post.Links && post.Links.length > 0 && (
          <Box mb={2}>
            <LinksPublication links={post.Links} />
          </Box>
        )}
        {post.Attachments && (
          <Grid container spacing={1}>
            {nbAttachments <= 4 ? (
              post.Attachments.filter(
                (a) => a.Extension.toLowerCase() === 'jpg' || a.Extension.toLowerCase() === 'png'
              )?.map((a, index) => {
                return (
                  <Grid item xs={12} sm={6} key={index}>
                    <img src={a.Url} className={classes.imgLinks} onClick={() => window.open(a.Url, '_blank')} />
                  </Grid>
                )
              })
            ) : (
              <>
                {post.Attachments.slice(0, 3)
                  .filter((a) => a.Extension.toLowerCase() === 'jpg' || a.Extension.toLowerCase() === 'png')
                  ?.map((a, i) => {
                    return (
                      <Grid item xs={12} sm={6} key={i}>
                        <img src={a.Url} className={classes.imgLinks} onClick={() => window.open(a.Url, '_blank')} />
                      </Grid>
                    )
                  })}
                <Grid item xs={12} sm={6}>
                  <CustomPaper
                    className={classes.imgLinks + ' ' + classes.center + ' ' + classes.imgAttachment}
                    onClick={() => setOpenAttachments(!openAttachments)}
                  >
                    <Typography variant="h1">
                      <Box component="span" color="primary.darker">
                        {'+ ' + (nbAttachments - 3).toString()}
                      </Box>
                    </Typography>
                  </CustomPaper>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {post.Attachments && post.Attachments.length > 0 && (
          <Attachments attachments={post.Attachments} openAttachments={openAttachments} />
        )}
        <Box width="100%" display="flex">
          {nbCommentaries !== null && nbCommentaries > 0 && (
            <Box width="100%" flex="1" align="right" mt={2}>
              <Divider className={classes.divider} />
              <Button className={classes.alignEnd} onClick={() => setOpenCommentaries(!openCommentaries)}>
                <Typography variant="caption" color="primary">
                  {t('publications.nb.commentaries', { count: nbCommentaries })}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </CustomPaper>
      {post.Commentaries && post.Commentaries.length > 0 && openCommentaries && (
        <Box mt={1}>
          <PostCommentaries commentaries={post.Commentaries} onDelete={handleDeleteCommentary} />
        </Box>
      )}
      <CustomPaper className={classes.answer}>
        <Box display="flex" onClick={() => setOpenAnswer(!openAnswer)} className={classes.imgAttachment}>
          <Box mr={1} display="flex" alignItems="center">
            <Icon icon="message" />
          </Box>
          <Typography variant="subtitle2">{t('publications.answer.text')}</Typography>
        </Box>
        {openAnswer && (
          <form onSubmit={methods.handleSubmit(onFormSubmit)}>
            <Box display="flex" width="100%" mt={1}>
              <Box mr={2} mt={0.5}>
                <img src={user.ImageURL ?? DEFAULT_IMG} className={classes.img} />
              </Box>
              <TextWithAttachments fullWidth methods={methods} textName="content" attachmentsName="attachments" />
            </Box>
            <Box display="flex" justifyContent="center">
              <CustomButton variant="contained" type="submit" color="secondary">
                {t('Confirmer')}
              </CustomButton>
            </Box>
          </form>
        )}
      </CustomPaper>
    </div>
  )
}

export default Publication
