import { Box, Breadcrumbs, Grid, Hidden } from '@material-ui/core'
import MuiLink from '@material-ui/core/Link'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import Spot from 'components/Spot'
import { SocietyContext } from 'contexts/SocietyContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'
import { getRoutePath, routes } from 'routes'

const useStyles = makeStyles((theme) => ({
  spot: {
    display: 'inline-block',
    background:
      theme.palette.type === 'dark'
        ? theme.palette.getContrastText(theme.palette.background.paper)
        : theme.palette.primary.main,
    opacity: 0.5,
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
  },
  breadcrumbs: {
    '& .MuiBreadcrumbs-separator, & .MuiTypography-root': {
      opacity: theme.palette.type === 'dark' ? 0.85 : 1,
      display: 'block',
      fontWeight: 600,
    },
    '& .MuiBreadcrumbs-separator': {
      marginLeft: 0,
      marginRight: 0,
      color:
        theme.palette.type === 'dark'
          ? theme.palette.getContrastText(theme.palette.background.paper)
          : theme.palette.primary.main,
    },
    '& .MuiTypography-root': {},
  },
  padded: {
    padding: theme.spacing(1.5),
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
}))

export const getBreadcrumbsRoutes = (match, t, pageRoutes = [], infos = {}, society) => {
  const splitPath = match.path.split('/').filter((x) => x)
  const splitUrl = match.url.split('/').filter((x) => x)

  splitPath.forEach((val, index) => {
    let toPath = `/${splitPath.slice(0, index + 1).join('/')}`
    let toUrl = `/${splitUrl.slice(0, index + 1).join('/')}`
    let route = routes.filter((r) => r.path === toPath || r.url === match.url)

    if (route.length > 0) {
      let name = t(route[0].name)
      let component = 'link'

      switch (toPath) {
        case getRoutePath('coworkingspaces'):
          name = t(name + '.breadcrumb.title')
          component = society && society.CoworkingSpaces.length === 1 ? 'text' : 'link'
          break
        case getRoutePath('newreservation'):
          const csId = splitUrl[1]
          let thisIndex = 0
          society &&
            society.CoworkingSpaces &&
            society.CoworkingSpaces.map((c, index) => {
              if (c.Id === csId) thisIndex = index
            })
          name = infos.coworkingSpaceName ?? name
          component =
            society &&
            society.CoworkingSpaces.length > 0 &&
            society.CoworkingSpaces[thisIndex].CategoriesIds.length === 1
              ? 'text'
              : 'link'
          // component = society && society.CoworkingSpaces.length === 1 && society.CoworkingSpaces[0].CategoriesIds.length === 1 ? "text" : "link"

          break
        case getRoutePath('newreservation-category'):
          // const coworkingSpaces = society.CoworkingSpaces;
          // const thisCS = coworkingSpaces ? coworkingSpaces.filter(c => c.Id === match.params.coworkingspace) : null;
          // const thisCat = thisCS && thisCS[0] && thisCS[0].Categories ? thisCS[0].Categories.filter(cat => cat.Id == match.params.cwcategory) : null;

          name = infos.categoryName ?? name
          component =
            society && society.CoworkingSpaces.length === 1 && society.CoworkingSpaces[0].CategoriesIds.length === 1
              ? 'text'
              : 'link'
          // component = (society && society.CoworkingSpaces.length === 1 && society.CoworkingSpaces[0].Categories.length === 1)
          //   || (thisCat && thisCat[0] ? thisCat[0].NbItems === 1 : false) ? "text" : "link"
          break
        case getRoutePath('newreservation-item'):
          name = infos.itemName ?? name
          break
        case getRoutePath('prospect-detail'):
          name = infos.prospectName ?? name
          break
        case getRoutePath('event'):
          name = infos.EventName ?? name
          break
        case getRoutePath('publications-group'):
          name = infos.GroupName ?? name
          break
        case getRoutePath('publications-channel'):
          name = infos.ChannelName ?? name
          break
        case getRoutePath('userprofile'):
          name = infos.userName ?? name
          break
        case getRoutePath('photogroup-user'):
          name = infos.userName ?? name
          break
        default:
          name = t(name + '.breadcrumb.title')
          break
      }

      pageRoutes.push({
        path: toUrl,
        name: name,
        component: component,
      })
    }
  })

  if (pageRoutes.length === 0) {
    if (match.path === '/') {
      pageRoutes.push({
        path: '/',
        name: t('home.breadcrumb.title'),
        component: 'text',
      })
    } else {
      pageRoutes.push({
        path: '',
        name: t('error.breadcrumb.title'),
        component: 'text',
      })
    }
  }
  return pageRoutes
}

function SimpleBreadcrumbs({ pageRoutes = [], infos, showCurrent = true, forLayout = false }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const match = useRouteMatch()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { society } = React.useContext(SocietyContext)

  pageRoutes = getBreadcrumbsRoutes(match, t, pageRoutes, infos, society)

  const isFirstPage = pageRoutes.length === 1
  const beforeLastRoute = pageRoutes[pageRoutes.length - 1]
  const showBackBtn = !isFirstPage && beforeLastRoute && beforeLastRoute.component === 'link'

  if (isSmDown && isFirstPage) return null

  if (infos && infos.suffix) {
    infos.suffix.forEach((s) => pageRoutes.push(s))
  }

  const CompoText = ({ text }) => (
    <Typography variant="body2" color="textPrimary" className={classes.padded}>
      {text}
    </Typography>
  )
  const CompoLink = ({ path, text }) => (
    <MuiLink variant="body2" component={Link} to={path} className={classes.padded}>
      {text}
    </MuiLink>
  )

  const bread = (
    <>
      {showBackBtn && (
        <Hidden smUp>
          <MuiLink
            color="inherit"
            to={pageRoutes[Math.max(0, pageRoutes.length - 2)].path}
            component={Link}
            className={[classes.backLink, classes.padded].join(' ')}
          >
            <Icon icon="fleche-gauche" />
          </MuiLink>
        </Hidden>
      )}
      <Hidden xsDown>
        <Box pl={2}>
          <Spot />
        </Box>
        <Breadcrumbs
          className={classes.breadcrumbs}
          separator={
            <Typography variant="body2" fontSize="small">
              -
            </Typography>
          }
        >
          {pageRoutes.map((r, i) => {
            const last = i === pageRoutes.length - 1
            return last ? (
              showCurrent ? (
                <CompoText text={r.name} key={i} />
              ) : null
            ) : r.component === 'link' ? (
              <CompoLink text={r.name} path={r.path} key={i} />
            ) : (
              <CompoText text={r.name} key={i} />
            )
          })}
        </Breadcrumbs>
      </Hidden>
    </>
  )

  const breadComp = (
    <Box display="flex" alignItems="center">
      {bread}
    </Box>
  )
  if (forLayout) {
    return (
      <Grid item xs="auto">
        <CustomPaper elevation={0}>{breadComp}</CustomPaper>
      </Grid>
    )
  }

  return breadComp
}

export default SimpleBreadcrumbs
