import { Box, Grid, makeStyles, MenuList, Popover, Typography } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import ListView from 'components/CustomComponents/ListView'
import PopoverElem from 'components/CustomComponents/PopoverElem'
import { downloadFile } from 'components/downloadFile'
import Icon from 'components/IcoMoon/Icon'
import SquareButton from 'components/SquareButton'
import { IconAndText } from 'components/views/UserCartPage/CardCart'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.darker,
  },
  titleAndDescription: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  line: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.secondary.main,
    '&:first-child': {
      borderTopStyle: 'solid',
      borderTopWidth: 1,
      borderTopColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}))

const Line = ({ item, dateFormat = 'DD MMMM YYYY', hourFormat = 'HH:mm' }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { apiCall } = React.useContext(UserContext)

  const Date = () => {
    let content = ''

    if (item.IsOrder) {
      if (item.Start) {
        content = <>{t('cart.date', { date: moment(item.Start).format(dateFormat) })}</>
      }
    } else {
      const start = item.Dates[0] && item.Dates[0].StartDate
      const end = item.Dates[0] && item.Dates[0].EndDate

      if (!end) {
        content = <>{t('cart.date.from', { date: moment(start).format(dateFormat) })}</>
      } else if (end && moment(start).format(dateFormat) !== moment(end).format(dateFormat)) {
        content = (
          <>
            Du {moment(start).format(dateFormat)} au {moment(end).format(dateFormat)}
          </>
        )
      } else {
        content = <>{t('cart.date', { date: moment(start).format(dateFormat) })}</>
      }
    }
    return content
  }

  const Hour = ({ start, end }) => {
    let content = ''

    if (item.IsOrder) {
      content = <>{moment(item.Start).format('HH[h]mm')}</>
    } else {
      if (!end) {
        content = <>{t('cart.date.end.none')}</>
      } else if (end && moment(start).format(dateFormat) !== moment(end).format(dateFormat)) {
        content = <></>
      } else {
        content = (
          <>{t('cart.fromto', { from: moment(start).format(hourFormat), to: moment(end).format(hourFormat) })}</>
        )
      }
    }

    return content
  }

  const handleGetInvoice = () => {
    apiCall(apiActions.getReservationInvoice, {}, 'post', { Id: item.InvoiceId }, { responseType: 'blob' }).then(
      (response) => {
        try {
          const suggestedFileName = response.headers['content-disposition']
            .split(';')[1]
            .trim()
            .split('=')[1]
            .replace(/"/g, '')
          const effectiveFileName = suggestedFileName === undefined ? 'invoice.pdf' : suggestedFileName
          downloadFile(response.data, effectiveFileName, response.headers['content-type'])
          setAnchorEl(null)
        } catch (e) {
          console.error(e)
        }
      }
    )
  }

  const handleItemClick = () => {
    history.push(
      getRoutePath('newreservation-item', {
        ':coworkingspace': item.CoworkingSpaceId,
        ':cwcategory': item.CategoryId,
        ':cwitem': item.ItemId,
      })
    )
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm>
        <Typography variant="h6">
          <Box component="span" fontWeight="600">
            {item.ItemName}
          </Box>
        </Typography>
      </Grid>
      {item.Dates && (
        <Grid item xs sm={4} md={3} lg={2}>
          <IconAndText icon="agenda-jour" text={<Date />} />
          {item.Dates.map((value, index) => (
            <span key={index}>
              <IconAndText icon="horloge" text={<Hour key={index} start={value.StartDate} end={value.EndDate} />} />
            </span>
          ))}
        </Grid>
      )}
      {item.IsOrder && item.Start && (
        <Grid item xs sm={4} md={3} lg={2}>
          <IconAndText icon="agenda-jour" text={<Date />} />
          <IconAndText icon="horloge" text={<Hour start={item.Start} end={item.Start} />} />
        </Grid>
      )}
      <Grid item xs="auto">
        <SquareButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Icon icon="trois-petits-points" />
        </SquareButton>
        <Popover
          open={anchorEl ? true : false}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          elevation={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuList>
            {item.InvoiceId && (
              <PopoverElem icon="facture" text={t('reservation.see.bill')} onClick={handleGetInvoice} />
            )}
            <PopoverElem onClick={handleItemClick} icon="recommander" text={t('reservation.same.order')} />
          </MenuList>
        </Popover>
      </Grid>
    </Grid>
  )
}

function MyPastReservations() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { apiCall } = React.useContext(UserContext)

  return (
    <CustomPaper>
      <Box p={3}>
        <ListView
          component={(item) => (
            <Box className={classes.line}>
              <Line item={item} dateFormat="DD/MM/YY" />
            </Box>
          )}
          read={(opt) => {
            return new Promise((resolve) => {
              apiCall(apiActions.getPastReservations, {}, 'post', {
                PerPage: opt.perPage,
                Page: opt.page,
              }).then((r) => {
                if (!r.data) {
                  resolve({
                    page: 0,
                    total: 0,
                    data: [],
                  })
                } else {
                  resolve(r)
                }
              })
            })
          }}
          topLeftComponent={() => (
            <Box className={classes.titleAndDescription}>
              <Typography className={classes.title} variant="h4" gutterBottom>
                {t('my.past.reservations.title')}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {t('my.past.reservations.description')}
              </Typography>
            </Box>
          )}
        />
      </Box>
    </CustomPaper>
  )
}

export default MyPastReservations
