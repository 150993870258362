import { IconButton, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useStyles as labelUseStyles } from 'components/CustomLabel'
import Icon from 'components/IcoMoon/Icon'
import React from 'react'

const useStyles = makeStyles((theme) => {
  return {
    textfield: {
      '& > label': {
        '&.MuiInputLabel-shrink': {
          transform: 'none',
          position: 'relative',
        },
      },
      '& > div': {
        '& > fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.light,
          '&  > legend > span': {
            display: 'none',
          },
        },
        '&:hover > fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.dark,
        },
        '&.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.darker,
        },
        '& .MuiInputBase-input': {
          fontWeight: 600,
          color: theme.palette.primary.darker,
        },
        '& .MuiOutlinedInput-input': {
          '&:not(.MuiOutlinedInput-inputAdornedStart)': {
            paddingLeft: theme.spacing(2),
          },
          '&:not(.MuiOutlinedInput-inputAdornedEnd)': {
            paddingRight: theme.spacing(2),
          },
          '&.MuiSelect-outlined.MuiSelect-outlined': {
            paddingRight: theme.spacing(5),
          },
        },
        '& .Mui-disabled': {
          color: theme.palette.text.secondary,
        },
        '&:hover > .Mui-disabled + fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: 'inherit',
        },
      },
      '& .MuiOutlinedInput-root': {
        background: theme.palette.background.paper,
      },
      '& .MuiSelect-iconOutlined': {
        right: theme.spacing(1.5),
      },
      '& > .MuiInputBase-root': {
        overflow: 'hidden',
        '& fieldset': {
          top: 0,
        },
      },
      '& > label + .MuiInputBase-root': {
        overflow: 'visible',
        '& fieldset': {
          top: -5,
        },
      },
    },
    select: {
      '& > .MuiOutlinedInput-inputMarginDense': {
        minHeight: '1.4em',
      },
    },
  }
})

function CustomTextField(props) {
  const classes = useStyles()
  const labelClasses = labelUseStyles()
  const [showPassword, setShowPassword] = React.useState(false)

  const { className, select, shrink = true, disabled, type, ...rest } = props

  let labelProps = {
    classes: {
      root: labelClasses.label,
    },
  }

  if (shrink) {
    labelProps['shrink'] = true
    labelProps['disableAnimation'] = true
  }

  return (
    <TextField
      type={type && (showPassword ? 'text' : type)}
      disabled={disabled}
      select={select}
      InputLabelProps={labelProps}
      SelectProps={{
        className: classes.select,
        IconComponent: (props) => <Icon icon="fleche-bas" fontSize="medium" {...props} />,
      }}
      InputProps={{
        endAdornment: type && type === 'password' && (
          <IconButton onClick={() => setShowPassword(!showPassword)} tabIndex="-1">
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ),
      }}
      className={classes.textfield + ' ' + (className ?? '') + ' ' + (shrink ? 'shrinked' : '') + (disabled ?? '')}
      {...rest}
      variant="outlined"
    />
  )
}

export default CustomTextField
