import { faDrawPolygon, faExpand, faHandPointer } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, ButtonGroup, makeStyles, Paper, Popper, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import imagemapper from '@overlapmedia/imagemapper'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import CustomLabel from 'components/CustomLabel'
import CustomTextField from 'components/CustomTextField'
import LoadingElem from 'components/LoadingElem'
import { formatCost } from 'components/ShowPrice'
import { UserContext } from 'contexts/UserContext'
import polylabel from 'polylabel'
import React from 'react'
import { PanZoom } from 'react-easy-panzoom'
import { useTranslation } from 'react-i18next'
import utilsClasses from 'styles/utils.module.scss'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'
import OrderDetailPlan from 'area/owner/components/OrderDetailPlan'
import OrdersList from './OrdersList'
import PopOverContent from './PopOverContent'

const autoCenterZoomLevel = 0.95
const SVG_NS = 'http://www.w3.org/2000/svg'
const defaultSVGStyle = {
  'font-size': 12,
  fill: '#000000',
  'font-weight': 'normal',
  'dominant-baseline': 'middle',
  'text-anchor': 'middle',
}

const actionZIndex = 10
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  colActionsLeft: {
    display: 'flex',
    position: 'absolute',
    zIndex: 10,
    top: theme.spacing(4),
    left: theme.spacing(4),
    opacity: 0.9,
    color: theme.palette.text.primary,
  },
  colActionsRight: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: actionZIndex,
    top: theme.spacing(4),
    right: theme.spacing(4),
    opacity: 0.9,
    color: theme.palette.text.primary,
  },
  zoomContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  fontsize: {
    marginLeft: theme.spacing(0.5),
    width: 80,
    '& input': {
      padding: theme.spacing(1),
    },
  },
}))

const getTextWidth = (text, font) => {
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}

const createSVGtext = (caption, maxWidth, style) => {
  style = { ...defaultSVGStyle, ...style }

  const svgText = document.createElementNS(SVG_NS, 'text')
  const styleKeys = Object.keys(style)
  for (let i = 0; i < styleKeys.length; i++) {
    svgText.setAttributeNS(null, styleKeys[i], style[styleKeys[i]])
  }

  const words = caption.split(' ')
  let line = ''
  const addTSpan = (line) => {
    let svgTSpan = document.createElementNS(SVG_NS, 'tspan')
    svgTSpan.setAttributeNS(null, 'x', 0)
    svgTSpan.setAttributeNS(null, 'dy', svgText.children.length > 0 ? style['font-size'] : style['dy'] ?? 0)

    let tSpanTextNode = document.createTextNode(line)
    svgTSpan.appendChild(tSpanTextNode)
    svgText.appendChild(svgTSpan)
  }

  for (var n = 0; n < words.length; n++) {
    let testLine = line + words[n] + ' '
    let testWidth = getTextWidth(testLine, `${style['font-weight']} ${style['font-size']} lato`)

    if (line !== '' && testWidth >= maxWidth) {
      addTSpan(line)
      line = words[n] + ' '
    } else {
      line = testLine
    }
  }

  addTSpan(line)
  return svgText
}

const randId = () => '_' + Math.random().toString(36).substring(2, 9)

const moveCptDelay = 10 // Si ce nombre de mouvement est dépassé, on n'ouvre pas la popup de changement de pièce
const Plan = ({ planPartId, coworkingSpaceId, edit = true, onChange }) => {
  const classes = useStyles()
  const { apiCall, apiUrl } = React.useContext(UserContext)
  const zoomRef = React.useRef(null)
  const svgRef = React.useRef(null)
  const [mode, setMode] = React.useState('select')
  const [editor, setEditor] = React.useState()
  const [view, setView] = React.useState()
  const [editRoom, setEditRoom] = React.useState(null)
  const [data, setData] = React.useState([])
  const [init, setInit] = React.useState(false)
  const [svgLoaded, setSvgLoaded] = React.useState(false)
  const [fireChange, setFireChange] = React.useState()
  const [fireEditRoom, setFireEditRoom] = React.useState()
  const [dataPulled, setDataPulled] = React.useState(false)

  const [fontsize, setFontSize] = React.useState()
  const { t } = useTranslation()

  const src = apiUrl(apiActions.ownerGetPlanPartImage, { Id: planPartId, CoworkingSpaceId: coworkingSpaceId })
  let cursorPosition

  const { data: getServerData } = useSWR(`serverData/${coworkingSpaceId}/${planPartId}`, () =>
    apiCall(apiActions.ownerGetPlanPartResourceRents, { Id: planPartId, CoworkingSpaceId: coworkingSpaceId })
  )

  const { data: getPlanPartMap } = useSWR(`planpartMap/${coworkingSpaceId}/${planPartId}`, () =>
    apiCall(apiActions.ownerGetPlanPartMap, { Id: planPartId, CoworkingSpaceId: coworkingSpaceId })
  )

  const serverData = getServerData
  const planPartMap = getPlanPartMap

  React.useEffect(() => {
    if (!dataPulled || !svgRef.current || init) return
    svgRef.current.innerHTML = ''

    const toImport = planPartMap.map((p) => ({
      id: p.HtmlId,
      rrIds: p.ResourceIds,
      type: 'polygon',
      data: p.Points,
    }))

    const toImportJSON = JSON.stringify({ idCounter: 0, components: toImport })

    if (edit) {
      let newEditor = imagemapper.editor(svgRef.current.getAttribute('id'))
      newEditor.loadImage(src)
      newEditor.registerComponent = (component, id) => {
        id = id || component.element.getAttribute('id') || randId()
        newEditor._cacheElementMapping[id] = component
        component.element.id = id
        return component
      }

      if (toImport && toImport.length > 0) newEditor.import(toImportJSON)
      setEditor(newEditor)
    } else {
      let newView = imagemapper.view(svgRef.current.getAttribute('id'))
      newView.loadImage(src)
      if (toImport && toImport.length > 0) newView.import(toImportJSON)
      setView(newView)
    }

    setData(toImport)
    setInit(true)
  }, [dataPulled, edit, svgRef, init, planPartMap])

  React.useEffect(() => {
    setDataPulled(serverData && planPartMap ? true : false)
    if (serverData) setFontSize(serverData[0].FontSize)
  }, [serverData, planPartMap])

  React.useEffect(() => {
    if (!init) return
    const svg = svgRef.current
    let moveCpt = 0

    const handleMove = (event) => {
      let rect = svg.getBoundingClientRect()
      if (event.pageX == null && event.clientX != null) {
        event.pageX = event.clientX
        event.pageY = event.clientY
      }

      cursorPosition = {
        x: Math.max(0, event.pageX - rect.left),
        y: Math.max(0, event.pageY - rect.top),
      }
    }

    const handleClick = (event) => {
      const target = event.target
      const tagName = target.tagName.toLowerCase()

      if (tagName === 'polygon' || tagName === 'text' || tagName === 'tspan') {
        if (moveCpt <= moveCptDelay) {
          const id = target.getAttribute('id') ?? target.closest('[data-for]').getAttribute('data-for') ?? null
          handleEditRoom(event, id)
          event.preventDefault()
        }
      } else {
        fireEditRoomEvent(event, '', true)
      }
    }

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if ((mutation.type === 'attributes') & (mutation.target.tagName === 'polygon')) {
          fillSVGText(mutation.target)
        }

        mutation.removedNodes.forEach(function (removed_node) {
          setEditRoom(null)
          removeSVGText(removed_node.id)
        })
      })
    })

    observer.observe(svg.querySelector('image + g'), {
      attributes: true,
      subtree: true,
      childList: true,
    })

    const whileMove = () => {
      moveCpt++
    }

    const endMove = () => {
      svg.removeEventListener('mousemove', whileMove)
      svg.removeEventListener('mouseup', endMove)
      if (moveCpt > 0) {
        fireChangeEvent()
      }
      moveCpt = 0
    }

    const mouseDown = () => {
      svg.addEventListener('mousemove', whileMove)
      svg.addEventListener('mouseup', endMove)
    }

    const handleHover = (event) => {
      const target = event.target
      const tagName = target.tagName.toLowerCase()

      if (tagName === 'polygon' || tagName === 'text' || tagName === 'tspan') {
        if (moveCpt === 0) {
          const id = target.getAttribute('id') ?? target.closest('[data-for]').getAttribute('data-for') ?? null
          if (id) {
            let comp = editor.getComponentById(id)
            let points = comp.points
            for (let i = 0; i < points.length; i++) {
              let point = points[i]
              let handle = point.handle
              handle.moveHandler = (dx, dy) => {
                let scale = zoomRef.current.state.scale
                let x = cursorPosition.x / scale
                let y = cursorPosition.y / scale

                point.x = x
                point.y = y
                handle.element.setAttribute('cx', x)
                handle.element.setAttribute('cy', y)
              }
            }

            comp.move = (deltaX, deltaY) => {
              let scale = zoomRef.current.state.scale
              comp.points.forEach((p) => {
                p.x += deltaX / scale
                p.y += deltaY / scale
              })
              return comp
            }
          }
        }
      }
    }

    svg.addEventListener('mousedown', mouseDown)
    svg.addEventListener('mouseup', handleClick)
    svg.addEventListener('mousemove', handleMove)
    if (edit) svg.addEventListener('mouseover', handleHover)
    return () => {
      svg.removeEventListener('mousedown', mouseDown)
      svg.removeEventListener('mouseup', handleClick)
      svg.removeEventListener('mousemove', handleMove)
      if (edit) svg.removeEventListener('mouseover', handleHover)
      observer.disconnect()
    }
  }, [init, data, editor, view])

  React.useEffect(() => {
    if (!init || !edit || !editor) return

    switch (mode) {
      case 'rect':
        editor.rect()
        break
      case 'ellipse':
        editor.ellipse()
        break
      case 'circle':
        editor.circle()
        break
      case 'polygon':
        editor.polygon()
        break
      default:
      case 'select':
        editor.selectMode()
        break
    }
  }, [init, edit, editor, mode])

  React.useEffect(() => {
    if (!init || !svgRef.current) return
    const image = svgRef.current.firstElementChild
    image.setAttribute('externalResourcesRequired', 'true')
    image.addEventListener('load', function () {
      setSvgLoaded(true)
    })
  }, [init, svgRef])

  React.useEffect(() => {
    if (!init || !svgLoaded) return
    resizeSVG()
    centerSVG()
    fillSVG()
  }, [init, svgLoaded])

  React.useEffect(() => {
    if (!init || !fireChange) return
    let comp = convertToObj(data)
    fillSVG()

    if (edit) {
      apiCall(
        apiActions.ownerUpdatePlanPartMap,
        {
          CoworkingSpaceId: coworkingSpaceId,
          Id: planPartId,
        },
        'put',
        {
          id: planPartId,
          map: comp,
        }
      )
    }

    if (onChange) {
      onChange(comp)
    }
  }, [init, fireChange])

  React.useEffect(() => {
    if (fireEditRoom) {
      if (fireEditRoom.forceClose) {
        setEditRoom(null)
        return
      }
      if (editRoom && editRoom.htmlId === fireEditRoom.htmlId) {
        setEditRoom(null)
      } else {
        setEditRoom(fireEditRoom)
      }
    }
  }, [fireEditRoom])

  const fireChangeEvent = () => {
    setFireChange(randId())
  }

  const fillSVG = () => {
    const svg = svgRef.current
    const gText = getGText()
    gText.innerHTML = ''
    data.map((m) => {
      let csInfo = serverData.filter((c) => m.rrIds && m.rrIds.indexOf(c.Id) > -1)
      const polygon = svg.querySelector(`#${m.id}`)

      if (polygon) {
        if (csInfo.length > 0) {
          const info = csInfo[0]
          polygon.setAttribute('fill', info.Color)
          polygon.setAttribute('stroke', info.Color)
          fillSVGText(polygon, m)
        } else {
          polygon.setAttribute('fill', 'rgb(102, 102, 102)')
          polygon.setAttribute('stroke', 'rgb(51, 51, 51)')
        }
      }
    })
  }

  const fillSVGText = (polygon, polygon_data) => {
    if (!init) return

    const id = polygon.getAttribute('id')
    const m = polygon_data ?? data.filter((m) => m.id === id)[0]
    if (!m) return

    const svg = svgRef.current
    const gText = getGText()

    const group = svg.querySelector(`svg[data-for='${id}']`) ?? window.document.createElementNS(SVG_NS, 'svg')

    const centerPoints = polylabel([[...polygon.points].map((pt) => [pt.x, pt.y])], 100)
    const x = centerPoints[0]
    const y = centerPoints[1]
    const maxWidth = centerPoints.distance * 2 - 16 // distance = distance from cell center to polygon

    const csInfo = serverData.filter((c) => m.rrIds && m.rrIds.findIndex((rr) => rr === c.Id) > -1)

    group.innerHTML = ''
    if (csInfo.length > 0) {
      const info = csInfo[0]

      group.setAttributeNS(null, 'data-for', m.id)
      group.setAttributeNS(null, 'overflow', 'visible')
      group.setAttributeNS(null, 'x', x)
      group.setAttributeNS(null, 'y', y)

      gText.appendChild(group)

      let csName = createSVGtext(
        `${info.Name}`.trim(), //`${info.Name} ${info.NbMaxCoworkers ? `(${info.NbMaxCoworkers}p)` : ''}`.trim()
        maxWidth,
        {
          'font-size': info.FontSize,
          'font-weight': 'bold',
        }
      )

      group.appendChild(csName)

      if (info.ShowPrice)
        group.appendChild(
          createSVGtext(formatCost(info.Cost) + t('rec.currency.symbol'), maxWidth, {
            dy: csName.getBBox().height,
            fill: 'green',
            'font-size': info.FontSize - 1,
            'font-weight': 'bold',
          })
        )

      group.setAttributeNS(null, 'y', y - group.getBBox().height / 4)
    }
  }

  const fireEditRoomEvent = (e, htmlId, forceClose = false) => {
    const filteredRR = data.filter((d) => d.id === htmlId)
    const rrIds = filteredRR.length > 0 && filteredRR[0].rrIds
    setFireEditRoom({
      rand: '_' + Math.random().toString(36).substring(2, 9),
      target: e.target,
      htmlId: htmlId,
      rrIds: rrIds,
      forceClose: forceClose,
    })
  }

  const convertToObj = (toConvert) => {
    let components = []
    if (editor) {
      components = JSON.parse(editor.export()).components
      for (let i = 0; i < components.length; i++) {
        let indexOf = toConvert.findIndex((d) => d.id === components[i].id)
        if (indexOf > -1) {
          let rrIds = toConvert[indexOf].rrIds
          if (rrIds) {
            components[i].rrIds = rrIds
          }
        }
      }
    }

    return components
  }

  const resizeSVG = () => {
    const svg = svgRef.current
    const image = svg.firstElementChild

    const imageReact = image.getBBox()
    const wImage = imageReact.width
    const hImage = imageReact.height

    svg.setAttribute('viewBox', `0 0 ${wImage} ${hImage}`)
    svg.setAttribute('height', hImage)
    svg.setAttribute('width', wImage)
  }

  const centerSVG = () => {
    zoomRef.current.autoCenter(autoCenterZoomLevel)
  }

  const handleEditRoom = (e, htmlId) => {
    fireEditRoomEvent(e, htmlId)
  }

  const getGText = () => {
    const svg = svgRef.current

    let gText = svg.querySelector(`g[data-texts]`)
    if (!gText) {
      gText = window.document.createElementNS(SVG_NS, 'g')
      gText.setAttribute('data-texts', '')
      svg.appendChild(gText)
    }

    return gText
  }

  const removeSVGText = (polygon_id) => {
    const svg = svgRef.current
    const group = svg.querySelector(`svg[data-for='${polygon_id}']`)
    if (group) {
      group.remove()
      fireChangeEvent()
    }
  }

  const preventPan = (e, x, y) => {
    let tagName = e.target.tagName
    let isPolygon = tagName === 'polygon' || tagName === 'circle'
    let isMoveMode = mode === 'select'
    return isPolygon || !isMoveMode
  }

  const handleChangeRoomLink = (htmlId, newCsIds) => {
    let newData = [...data]
    let indexOf = newData.findIndex((d) => d.id === htmlId)
    if (indexOf === -1) {
      const toPush = JSON.parse(editor.export()).components.filter((d) => d.id === htmlId)[0]
      newData.push(toPush)
      indexOf = newData.length - 1
    }

    let newIds = newData[indexOf].rrIds
    newIds = newCsIds
    newData[indexOf].rrIds = newIds

    setData(newData)
    fireChangeEvent()
    // setEditRoom(null)
  }

  const handleChangeFontSize = (value) => {
    if (value.toString() !== fontsize.toString() && value.toString() !== '') {
      setFontSize(value)
      apiCall(
        apiActions.editFontSizePlan,
        {
          CoworkingSpaceId: coworkingSpaceId,
          PlanPartId: planPartId,
        },
        'post',
        {
          FontSize: value,
        }
      ).then((r) => {
        if (r === 'ok') window.location.reload()
      })
    }
  }

  const editOpen = Boolean(editRoom)
  return (
    <div className={classes.root + ' ' + utilsClasses.fullHeight + ' ' + utilsClasses.fullWidth}>
      {init && editor && (
        <>
          <Paper className={classes.colActionsLeft}>
            <ButtonGroup disableElevation color="primary" variant="contained">
              <Button color={mode === 'select' ? 'secondary' : 'primary'} onClick={() => setMode('select')}>
                <FontAwesomeIcon size="lg" icon={faHandPointer} />
              </Button>
              <Button color={mode === 'polygon' ? 'secondary' : 'primary'} onClick={() => setMode('polygon')}>
                <FontAwesomeIcon size="lg" icon={faDrawPolygon} />
              </Button>
            </ButtonGroup>
            <Box ml={2} display="flex" alignItems="center" color="primary.darker">
              <Typography variant="subtitle1">{t('plan.fontsize')}</Typography>
              <CustomTextField
                className={classes.fontsize}
                defaultValue={fontsize}
                type="number"
                onBlur={(e) => handleChangeFontSize(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleChangeFontSize(e.target.value)
                }}
              />
            </Box>
          </Paper>
        </>
      )}
      {init && (
        <Paper className={classes.colActionsRight}>
          <ButtonGroup disableElevation color="primary" variant="contained">
            <Button color="primary" onClick={() => centerSVG()}>
              <FontAwesomeIcon size="lg" icon={faExpand} />
            </Button>
          </ButtonGroup>
        </Paper>
      )}
      {!svgLoaded && <LoadingElem />}
      <PanZoom
        style={!svgLoaded && { display: 'none' }}
        ref={zoomRef}
        preventPan={preventPan}
        noStateUpdate
        className={classes.zoomContainer}
      >
        <svg id="plan_svg" ref={svgRef} xmlns={SVG_NS}></svg>
      </PanZoom>
      {editOpen &&
        mode === 'select' &&
        (!Boolean(editRoom.rrIds) || (Boolean(editRoom.rrIds) && editRoom.rrIds.length === 0) ? (
          editor && (
            <Popper
              className={classes.popper}
              open={editOpen}
              anchorEl={editOpen ? editRoom.target : null}
              disablePortal
              placement="left"
            >
              <Box mr={1}>
                <Paper elevation={4}>
                  <PopOverContent serverData={serverData} infos={editRoom} onChange={handleChangeRoomLink} />
                </Paper>
              </Box>
            </Popper>
          )
        ) : (
          <OrdersList
            serverData={serverData}
            infos={editRoom}
            handleClose={() => setEditRoom(null)}
            handleChangeRoom={handleChangeRoomLink}
            canEdit={edit}
          />
        ))}
    </div>
  )
}

export default React.memo(Plan)
