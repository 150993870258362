import { Box, Button, Grid, Link as MuiLink, makeStyles, Typography } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import SquareButton from 'components/SquareButton'
import RenderCards from 'components/views/UserCartPage/RenderCards'
import { UserContext } from 'contexts/UserContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getRoutePath } from 'routes'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  add: {
    textAlign: 'center',
    border: '1px dashed',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  toCartLink: {
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
}))

function RecapOrder({ closeDialog, coworkingSpaceId, categoryId, categoryType, itemId, values }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [item, setItem] = React.useState(null)
  const { apiCall } = React.useContext(UserContext)

  React.useEffect(() => {
    apiCall(apiActions.getCart, {}, 'post', {
      orders: [values],
    }).then((response) => {
      setItem(response)
    })
  }, [values])

  const handleAdd = () => {
    window.location.reload()
  }

  if (!item) return <LoadingElem />

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <div>
          <Typography variant="h3">{t('reservations.recap.title')}</Typography>
        </div>
        <div>
          <SquareButton onClick={closeDialog} size="small">
            <Icon icon="close" />
          </SquareButton>
        </div>
      </Box>
      {item.ItemsCategory.map((category, index) => (
        <RenderCards popup key={index} category={category} />
      ))}
      {categoryType === 10 && (
        <Box mb={4}>
          <MuiLink
            underline="none"
            component={Link}
            to={getRoutePath('newreservation-item', {
              ':coworkingspace': coworkingSpaceId,
              ':cwcategory': categoryId,
              ':cwitem': itemId,
            })}
            onClick={handleAdd}
          >
            <CustomPaper className={classes.add}>
              <div>
                <Typography variant="subtitle2">
                  <Box color="primary.dark" component="span">
                    +&nbsp;{t('reservations.recap.add')}
                  </Box>
                </Typography>
              </div>
            </CustomPaper>
          </MuiLink>
        </Box>
      )}
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={12} sm="auto" className={classes.toCartLink}>
          <MuiLink underline="none" component={Link} to={getRoutePath('cart')}>
            <Button fullWidth variant="contained" color="secondary">
              {t('reservations.recap.terminate')}
            </Button>
          </MuiLink>
        </Grid>
        <Grid item xs={12} sm="auto">
          <MuiLink
            underline="none"
            component={Link}
            to={getRoutePath('newreservation-category', {
              ':coworkingspace': coworkingSpaceId,
              ':cwcategory': categoryId,
            })}
          >
            <Button fullWidth variant="outlined" color="secondary">
              {t('reservations.recap.continue')}
            </Button>
          </MuiLink>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RecapOrder
