import { Hidden, makeStyles, MenuItem } from '@material-ui/core'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomTextField from 'components/CustomTextField'
import Layout from 'components/views/Layout'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import AccountParameters from 'area/social/views/MyAccountPage/AccountParameters'
import ProfileAccount from 'area/social/views/MyAccountPage/ProfileAccount'
import Informations from 'area/social/views/MyAccountPage/Informations'
import LoadingElem from 'components/LoadingElem'
import { UserContext } from 'contexts/UserContext'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  tabs: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > div': {
      position: 'relative',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      '& + div:before': {
        content: "''",
        height: '50%',
        position: 'absolute',
        margin: 'auto',
        top: 0,
        left: 0,
        bottom: 0,
        right: 'auto',
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  select: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
}))

function MyAccountPage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { apiCall } = useContext(UserContext)

  const [menuSelected, setMenuSelected] = React.useState('profile')
  const { data, mutate } = useSWR(`profile`, () => apiCall(apiActions.getUserProfile).then((r) => r))

  const allKeys = [/* 'informations',  */ 'profile', 'parameters']

  let comp
  switch (menuSelected) {
    // case 'information':
    // default:
    //   comp = <Informations defaultValues={data} />
    //   break
    case 'profile':
    default:
      comp = <ProfileAccount defaultValues={data} />
      break
    case 'parameters':
      comp = <AccountParameters defaultValues={data} />
      break
  }

  React.useEffect(() => {
    mutate()
  }, [menuSelected])

  if (!data) return <LoadingElem />
  return (
    <Layout pageTitle={t('my-account.page.title')} pageDescription={t('my-account.page.description')}>
      <Hidden mdUp>
        <CustomTextField
          select
          margin="dense"
          value={menuSelected}
          onChange={(e) => setMenuSelected(e.target.value)}
          className={classes.select}
        >
          {allKeys.map((c) => (
            <MenuItem key={'menu_' + c} value={c}>
              {t('my-account.menu.' + c)}
            </MenuItem>
          ))}
        </CustomTextField>
      </Hidden>
      <Hidden smDown>
        <div className={classes.tabs}>
          {allKeys.map((c) => (
            <div key={'menu_tabs_' + c}>
              <CustomButton color={menuSelected === c ? 'secondary' : 'primary'} onClick={() => setMenuSelected(c)}>
                {t('my-account.menu.' + c)}
              </CustomButton>
            </div>
          ))}
        </div>
      </Hidden>
      {comp}
    </Layout>
  )
}

export default MyAccountPage
