import { Box, Dialog, Grid, makeStyles, MenuList, Popover, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import ListView from 'components/CustomComponents/ListView'
import PopoverElem from 'components/CustomComponents/PopoverElem'
import { downloadFile } from 'components/downloadFile'
import Icon from 'components/IcoMoon/Icon'
import SquareButton from 'components/SquareButton'
import CancelOrder from 'components/views/MyReservationsPage/CancelOrder'
import { OrderCard, RentCard } from 'components/views/UserCartPage/CardCart'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.darker,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  html: {
    fontSize: theme.typography.pxToRem(13),
    '& p': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& > p': {
      '&:first-child': {
        marginTop: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  capacity: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      width: 35,
      height: 35,
      '& > svg': {
        marginLeft: theme.spacing(0.25),
      },
    },
  },
  prices: {
    fontWeight: theme.typography.subtitle1.fontWeight,
  },
  borderLeft: {
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderColor: theme.palette.secondary.main,
    borderLeft: '2px dashed',
    [theme.breakpoints.only('xs')]: {
      borderLeft: 'none',
      borderTop: '2px dashed',
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceContainer: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(24),
    '& svg, div': {
      color: theme.palette.secondary.dark,
    },
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },
}))

const ListViewComp = ({ item, dateFormat = 'DD MMMM YYYY', hourFormat = 'HH:mm' }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dialog, setDialog] = React.useState(null)
  const { apiCall } = React.useContext(UserContext)

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const handleClickMore = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleOpenDialog = (content) => {
    setAnchorEl(null)
    setDialog(content)
  }

  const handleCloseDialog = () => {
    setDialog(null)
  }

  const handleGetInvoice = () => {
    apiCall(
      apiActions.getReservationInvoice,
      {},
      'post',
      { Id: item.InvoiceId },
      { headers: { responseType: 'blob' } }
    ).then((response) => {
      try {
        const suggestedFileName = response.headers['content-disposition']
          .split(';')[1]
          .trim()
          .split('=')[1]
          .replace(/"/g, '')
        const effectiveFileName = suggestedFileName === undefined ? 'invoice.pdf' : suggestedFileName
        downloadFile(response.data, effectiveFileName, response.headers['content-type'])
        setAnchorEl(null)
      } catch (e) {
        console.error(e)
      }
    })
  }

  const handleClickItem = () => {
    history.push(
      getRoutePath('newreservation-item', {
        ':coworkingspace': item.CoworkingSpaceId,
        ':cwcategory': item.CategoryId,
        ':cwitem': item.ItemId,
      })
    )
  }

  let newItem = { ...item }
  if (item.OrderResourceRentId) {
    newItem = {
      ...newItem,
      Dates: [
        {
          DurationType: null,
          StartDate: moment(item.Dates[0]?.StartDate),
          EndDate: item.Dates[0].EndDate ? moment(item.Dates[0]?.EndDate) : null,
        },
      ],
    }
  }

  return (
    <Box mb={1}>
      <CustomPaper elevation={0}>
        <Box p={2}>
          <Grid spacing={2} container alignItems="center" justifyContent="flex-end">
            {item.OrderResourceRentId && (
              <RentCard item={newItem} dateFormat={dateFormat} hourFormat={hourFormat} leftSize />
            )}
            {item.OrderResourceOrderId && <OrderCard item={item} />}
            <Grid item xs="auto">
              <SquareButton size="small" onClick={handleClickMore}>
                <Icon icon="trois-petits-points" />
              </SquareButton>

              <Popover
                open={anchorEl ? true : false}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                elevation={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuList>
                  {/* <PopoverElem icon="inviter" text={t("reservation.invite.users")} onClick={() => handleOpenDialog(<div>Inviter</div>)} /> */}
                  {item.InvoiceId && (
                    <PopoverElem icon="facture" text={t('reservation.see.bill')} onClick={handleGetInvoice} />
                  )}
                  <PopoverElem icon="info" text={t('reservation.show.item')} onClick={handleClickItem} />
                  {item.InvoiceId || !item.Prices || item.NbTickets > 0 ? (
                    <PopoverElem disabled text={t('cancel.order.impossible')} />
                  ) : (
                    <PopoverElem
                      icon="supprimer"
                      text={t('reservation.cancel')}
                      onClick={() => handleOpenDialog(<CancelOrder item={item} handleClose={handleCloseDialog} />)}
                    />
                  )}
                </MenuList>
              </Popover>

              <Dialog fullScreen={isXsDown} open={dialog} maxWidth="md" fullWidth onClose={handleCloseDialog}>
                {dialog}
              </Dialog>
            </Grid>
          </Grid>
        </Box>
      </CustomPaper>
    </Box>
  )
}

function MyReservations() {
  const { t } = useTranslation()
  const classes = useStyles()
  const [active, setActive] = React.useState('ongoing')
  const { apiCall } = React.useContext(UserContext)

  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  const perPage = 5

  return (
    <CustomPaper>
      <Box p={3}>
        <Box mb={2}>
          <Typography className={classes.title} variant="h4" gutterBottom>
            {t('my.reservations.title')}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {t('my.reservations.description')}
          </Typography>
        </Box>

        <Box mb={1} className={classes.btnContainer}>
          <CustomButton tag onClick={() => setActive('ongoing')} isSelected={active === 'ongoing'}>
            {t('my.reservations.tag.ongoing')}
          </CustomButton>
          <CustomButton tag onClick={() => setActive('incoming')} isSelected={active === 'incoming'}>
            {t('my.reservations.tag.futur')}
          </CustomButton>
        </Box>

        {active === 'incoming' && (
          <ListView
            perPage={perPage}
            showPerPageSelect={false}
            component={(item) => <ListViewComp item={item} dateFormat={isLg ? 'DD MMM YYYY' : 'DD/MM/YY'} />}
            read={(opt) => {
              return new Promise((resolve) => {
                apiCall(apiActions.getIncomingReservations, {}, 'post', {
                  PerPage: opt.perPage,
                  Page: opt.page,
                }).then((r) => {
                  if (!r.data) {
                    resolve({
                      page: 0,
                      total: 0,
                      data: [],
                    })
                  } else {
                    resolve(r)
                  }
                })
              })
            }}
          />
        )}

        {active === 'ongoing' && (
          <ListView
            perPage={perPage}
            showPerPageSelect={false}
            component={(item) => <ListViewComp item={item} dateFormat={isLg ? 'DD MMM YYYY' : 'DD/MM/YY'} />}
            read={(opt) => {
              return new Promise((resolve) => {
                apiCall(apiActions.getOngoingReservations, {}, 'post', {
                  PerPage: opt.perPage,
                  Page: opt.page,
                }).then((r) => {
                  if (!r.data) {
                    resolve({
                      page: 0,
                      total: 0,
                      data: [],
                    })
                  } else {
                    resolve(r)
                  }
                })
              })
            }}
          />
        )}
      </Box>
    </CustomPaper>
  )
}

export default MyReservations
