import { Box, Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AppsIcon from '@material-ui/icons/Apps'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import ColorPicker from 'components/ColorPicker'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomLabel from 'components/CustomLabel'
import Icon from 'components/IcoMoon/Icon'
import SquareButton from 'components/SquareButton'
import Layout from 'components/views/Layout'
import { Category } from 'components/views/NewReservationPage/CategoriesPage'
import { CoworkingSpace } from 'components/views/NewReservationPage/CoworkingSpacesPage'
import ItemsFilters from 'components/views/NewReservationPage/ItemsFilters'
import ItemsGrid from 'components/views/NewReservationPage/ItemsGrid'
import ItemsScheduler from 'components/views/NewReservationPage/ItemsScheduler'
import { CustomThemeContext } from 'contexts/CustomThemeContext'
import React from 'react'
import { useTranslation } from 'react-i18next'

const promFilters = [
  { ID: null, Name: 'date', Key: 'filters.date', Type: 'date', Value: null, Fields: null },
  { ID: null, Name: 'duration', Key: 'filters.duration', Type: 'duration', Value: null, Fields: null },
  {
    ID: null,
    Name: 'price',
    Key: 'filters.price',
    Type: 'doubleslider',
    Value: null,
    Fields: [
      { ID: null, Name: 'min', Key: 'filters.min.placeholder', Prices: { Credits: 5.0, EuroHT: 5.0, EuroTTC: 6.0 } },
      {
        ID: null,
        Name: 'max',
        Key: 'filters.max.placeholder',
        Prices: { Credits: 150.0, EuroHT: 150.0, EuroTTC: 180.0 },
      },
    ],
  },
  { ID: null, Name: 'capacity', Key: 'filters.capacity', Type: 'number', Value: null, Fields: null },
]
const items = {
  VisitedItems: [
    {
      Id: '361e7956-d9ae-4f5c-a17a-a6980085606c',
      Name: 'Salle de réunion 12 personnes',
      ShortDescription: '<div>Equipement : vid&eacute;oprojecteur - visio conf&eacute;rence</div>',
      ImagesURL: ['http://media.fteledition.fr/Uploads/8bcea397-06f0-4973-a09d-26b52decff23/image.jpg'],
      NbUsers: 12,
      Prices: [{ Credits: 50.0, EuroHT: 50.0, EuroTTC: 60.0, Description: 'Par heure', DurationType: 'hour' }],
      Equipments: [],
      Type: 10,
    },
    {
      Id: 'e8e81223-568c-4e0b-9650-a6cd008ae4c1',
      Name: 'Salle de réunion 8 personnes',
      ShortDescription: 'Salle de r&eacute;union pour 8 personnes<br />',
      ImagesURL: ['http://media.fteledition.fr/Uploads/2f3f0708-6a27-4638-bdbd-8b402081ccbd/office-1516329_1280.jpg'],
      NbUsers: 8,
      Prices: [{ Credits: 20.0, EuroHT: 20.0, EuroTTC: 24.0, Description: 'Par heure', DurationType: 'hour' }],
      Equipments: [],
      Type: 10,
    },
  ],
  UnvisitedItems: [
    {
      Id: '361e7956-d9ae-4f5c-a17a-a6980085606c',
      Name: 'Salle de réunion 12 personnes',
      ShortDescription: '<div>Equipement : vid&eacute;oprojecteur - visio conf&eacute;rence</div>',
      ImagesURL: ['http://media.fteledition.fr/Uploads/8bcea397-06f0-4973-a09d-26b52decff23/image.jpg'],
      NbUsers: 12,
      Prices: [{ Credits: 50.0, EuroHT: 50.0, EuroTTC: 60.0, Description: 'Par heure', DurationType: 'hour' }],
      Equipments: [],
      Type: 10,
    },
    {
      Id: 'e8e81223-568c-4e0b-9650-a6cd008ae4c1',
      Name: 'Salle de réunion 8 personnes',
      ShortDescription: 'Salle de r&eacute;union pour 8 personnes<br />',
      ImagesURL: ['http://media.fteledition.fr/Uploads/2f3f0708-6a27-4638-bdbd-8b402081ccbd/office-1516329_1280.jpg'],
      NbUsers: 8,
      Prices: [{ Credits: 20.0, EuroHT: 20.0, EuroTTC: 24.0, Description: 'Par heure', DurationType: 'hour' }],
      Equipments: [],
      Type: 10,
    },
  ],
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    '& > *': {
      marginRight: theme.spacing(),
    },
  },
  container: {
    marginBottom: theme.spacing(4),
    '& > h1': {
      marginBottom: theme.spacing(2),
    },
  },
  colorContainer: {
    marginTop: theme.spacing(1),
    '& .color': {
      height: 20,
    },
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
  },
}))

const Pickers = () => {
  const { primary, secondary, updateColors, updateTheme } = React.useContext(CustomThemeContext)
  const [primaryColor, setPColor] = React.useState(primary)
  const [secondaryColor, setSColor] = React.useState(secondary)
  const classes = useStyles()
  const isDarkTheme = window.localStorage.getItem('theme-type') === 'dark'

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      updateColors(primaryColor, secondaryColor)
    }, 250)

    return () => {
      clearTimeout(timeout)
    }
  }, [primaryColor, secondaryColor])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md="auto">
        <ColorPicker variant="outlined" label="Principale" value={primary} onChange={(val) => setPColor(val)} />

        <Grid container className={classes.colorContainer}>
          <Grid item xs>
            <Box className="color" bgcolor="primary.lighter"></Box>
          </Grid>
          <Grid item xs>
            <Box className="color" bgcolor="primary.light"></Box>
          </Grid>
          <Grid item xs>
            <Box className="color" bgcolor="primary.main"></Box>
          </Grid>
          <Grid item xs>
            <Box className="color" bgcolor="primary.dark"></Box>
          </Grid>
          <Grid item xs>
            <Box className="color" bgcolor="primary.darker"></Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md="auto">
        <ColorPicker variant="outlined" label="Secondaire" value={secondary} onChange={(val) => setSColor(val)} />

        <Grid container className={classes.colorContainer}>
          <Grid item xs>
            <Box className="color" bgcolor="secondary.lighter"></Box>
          </Grid>
          <Grid item xs>
            <Box className="color" bgcolor="secondary.light"></Box>
          </Grid>
          <Grid item xs>
            <Box className="color" bgcolor="secondary.main"></Box>
          </Grid>
          <Grid item xs>
            <Box className="color" bgcolor="secondary.dark"></Box>
          </Grid>
          <Grid item xs>
            <Box className="color" bgcolor="secondary.darker"></Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md="auto">
        <SquareButton onClick={() => updateTheme(primary, secondary, isDarkTheme ? 'light' : 'dark')}>
          {isDarkTheme ? <Brightness7Icon /> : <Brightness4Icon />}
        </SquareButton>
      </Grid>
    </Grid>
  )
}

function KitchenPage() {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Layout title={t('menu.reservations.mines')}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.container}>
            <Typography variant="h1" gutterBottom>
              Couleurs
            </Typography>
            <Pickers />
          </Box>
          <Box className={classes.container}>
            <Typography variant="h1" gutterBottom>
              Typographie
            </Typography>
            <Box mb={2}>
              <Typography variant="h1">h1 - Quelle offre vous correspond ?</Typography>
              <Typography variant="h2">h2 - Quelle offre vous correspond ?</Typography>
              <Typography variant="h3">h3 - Quelle offre vous correspond ?</Typography>
              <Typography variant="h4">h4 - Quelle offre vous correspond ?</Typography>
              <Typography variant="h5">h5 - Quelle offre vous correspond ?</Typography>
              <Typography variant="h6">h6 - Quelle offre vous correspond ?</Typography>
              <Typography variant="subtitle1">subtitle1 - Sous H1</Typography>
              <Typography variant="subtitle2">subtitle2 - Sous H3</Typography>
            </Box>
            <Box>
              <Typography variant="body1">body1 - Texte normal</Typography>
              <Typography variant="body2">body2 - Sous-texte normal</Typography>
              <Box>
                <Typography variant="button">button - Bouton</Typography>
              </Box>
              <Typography variant="overline">overline - Catégorie menu</Typography>
              <CustomLabel>{'<CustomLabel/>'} - Label de formulaire</CustomLabel>
            </Box>
          </Box>
          <Box className={classes.container}>
            <Typography variant="h1" gutterBottom>
              Boutons
            </Typography>
            <Box className={classes.buttons} mb={1}>
              <Button variant="contained">Sans bordure</Button>
              <Button variant="outlined">Avec bordure</Button>
              <Button variant="outlined" disabled>
                Avec bordure
              </Button>
            </Box>
            <Box className={classes.buttons} mb={1}>
              <Button variant="contained" color="primary">
                Couleur principale
              </Button>
              <Button variant="outlined" color="primary">
                Couleur principale
              </Button>
              <Button variant="outlined" color="primary" disabled>
                Couleur principale
              </Button>
            </Box>
            <Box className={classes.buttons} mb={1}>
              <Button variant="contained" color="secondary">
                Couleur secondaire
              </Button>
              <Button variant="outlined" color="secondary">
                Couleur secondaire
              </Button>
              <Button variant="outlined" color="secondary" disabled>
                Couleur secondaire
              </Button>
            </Box>
            <Box className={classes.buttons} mb={1}>
              <CustomButton startIcon={<AppsIcon />} tag>
                Onglet
              </CustomButton>
              <CustomButton startIcon={<AppsIcon />} tag isSelected>
                Onglet actif
              </CustomButton>
            </Box>
            <Box className={classes.buttons}>
              <SquareButton onClick={() => null}>
                <Icon icon="trois-petits-points" />
              </SquareButton>
              <SquareButton onClick={() => null} color="primary">
                <Icon icon="favori" />
              </SquareButton>
              <SquareButton onClick={() => null} color="secondary">
                <Icon icon="map" />
              </SquareButton>
            </Box>
          </Box>
          <Box className={classes.container}>
            <Typography variant="h1" gutterBottom>
              Espaces de coworking
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CoworkingSpace
                  item={{
                    Id: '',
                    CityLocation: 'Rouen',
                    CityName: 'Rouen',
                    Description: 'Ouvert du lundi au vendredi de 09h à 18h',
                    ImageURL: ['https://media.fteledition.fr/Uploads/ff9578c0-dab7-42b7-9b84-acfab0a01da4/image2.jpg'],
                    Name: 'Rouen',
                    Address: 'Quai du Havre',
                  }}
                  onClick={() => null}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CoworkingSpace
                  item={{
                    Id: '',
                    CityLocation: 'Rouen',
                    CityName: 'Rouen',
                    Description: 'Ouvert du lundi au vendredi de 09h à 18h',
                    ImageURL: ['https://media.fteledition.fr/Uploads/ff9578c0-dab7-42b7-9b84-acfab0a01da4/image2.jpg'],
                    Name: 'Rouen',
                    Address: 'Quai du Havre',
                  }}
                  onClick={() => null}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.container}>
            <Typography variant="h1" gutterBottom>
              Catégories
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Category
                  id=""
                  name="Bureau"
                  description="Super bureaux hyper confortables"
                  imageurl="https://media.fteledition.fr/Uploads/ff9578c0-dab7-42b7-9b84-acfab0a01da4/image2.jpg"
                  onClick={() => null}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Category
                  id=""
                  name="Salles de réunions"
                  description="Disponibles toute la journée"
                  imageurl="https://media.fteledition.fr/Uploads/ff9578c0-dab7-42b7-9b84-acfab0a01da4/image2.jpg"
                  onClick={() => null}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Category
                  id=""
                  name="Nourriture"
                  description="A consommer sur place"
                  imageurl="https://media.fteledition.fr/Uploads/ff9578c0-dab7-42b7-9b84-acfab0a01da4/image2.jpg"
                  onClick={() => null}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.container}>
            <Typography variant="h1" gutterBottom>
              Filtres
            </Typography>
            <Grid container>
              <Grid item xs={12} lg={3}>
                <ItemsFilters onFiltersChange={() => null} promFilters={promFilters} />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.container}>
            <Typography variant="h1" gutterBottom>
              Grille d'items
            </Typography>
            <ItemsGrid items={items} onItemClick={() => null} />
          </Box>
          <Box className={classes.container}>
            <Typography variant="h1" gutterBottom>
              Agenda d'items
            </Typography>
            <ItemsScheduler items={items} onItemClick={() => null} categoryId="" coworkingSpaceId="" />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default KitchenPage
