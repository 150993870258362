import { Box, Button, Slide } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Icon from 'components/IcoMoon/Icon'
import React from 'react'
import { useTranslation } from 'react-i18next'

const width = 250
const titleWidth = 40

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: 165,
      width: titleWidth + width,
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.getContrastText(theme.palette.secondary.dark),
      display: 'flex',
      position: 'fixed',
      right: -width,
      bottom: theme.spacing(6),
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      zIndex: 2,
      transition: theme.transitions.create(['right'], {
        duration: theme.transitions.duration.standard,
      }),
      '&.opened': {
        right: '0',
      },
    },
  },
  btn: {
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      minWidth: titleWidth,
      width: titleWidth,
      whiteSpace: 'nowrap',
      '& > :first-child': {
        transform: 'rotate(90deg)',
      },
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  content: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: width,
    },
  },
}))

const BackToV1 = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const [open, setOpen] = React.useState(false)
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box className={classes.box + (open ? ' opened' : '')}>
      <Button
        fullWidth
        startIcon={open ? <Icon icon="fleche-haut" /> : <Icon icon="fleche-bas" />}
        className={classes.btn}
        color="secondary"
        variant="contained"
        onClick={() => setOpen(!open)}
      >
        {t('v1.back')}
      </Button>
      <Box className={classes.content}>
        <Slide direction="down" in={open || isMd} mountOnEnter unmountOnExit>
          <Box p={1}>
            <Box mb={2}>
              <Typography variant="caption">{t('v1.back.description')}</Typography>
            </Box>
            <Button variant="contained" color="primary" fullWidth onClick={() => window.open('/', '_self')}>
              {t('v1.back.btn')}
            </Button>
          </Box>
        </Slide>
      </Box>
    </Box>
  )
}

export default BackToV1
