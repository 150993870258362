import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  tag: {
    borderRadius: 50,
    color: theme.palette.primary.darker,
    border: '1px solid ' + theme.palette.primary.darker,
    fontWeight: 400,
    background: theme.palette.background.paper,
    '&, &:hover': {
      borderWidth: 1,
    },
    '&.selected': {
      border: '1px solid ' + theme.palette.primary.main + ' !important',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      fontWeight: 700,
      background: theme.palette.background.paper,
    },
  },
  square: {
    minWidth: theme.spacing(6),
    padding: theme.spacing(1.5),
  },
  smallIcon: {
    '& > *:first-child': {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  mediumIcon: {
    '& > *:first-child': {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  largeIcon: {
    '& > *:first-child': {
      fontSize: theme.typography.pxToRem(35),
    },
  },
  disabled: {
    color: theme.palette.secondary.light,
  },
}))

export { useStyles }

function CustomButton(props) {
  const classes = useStyles()
  const { className, children, square, tag, iconSize, size, variant, disabled, ...rest } = props
  const cNames = className ? (Array.isArray(className) ? className : [className]) : []

  if (tag) {
    cNames.push(classes.tag)
  }
  if (square) {
    cNames.push(classes.square)
  }
  if (disabled) {
    cNames.push(classes.disabled)
  }

  let customIconSize
  let iconClass

  switch (iconSize) {
    case 'small': {
      iconClass = classes.smallIcon
      break
    }
    case 'large': {
      iconClass = classes.largeIcon
      break
    }
    case 'medium': {
      iconClass = classes.mediumIcon
      break
    }
    case 'inherit':
    default: {
      break
    }
  }

  switch (size) {
    case 'small': {
      customIconSize = {
        iconSizeSmall: iconClass,
      }
      break
    }
    case 'large': {
      customIconSize = {
        iconSizeLarge: iconClass,
      }
      break
    }
    case 'medium':
    default: {
      customIconSize = {
        iconSizeMedium: iconClass,
      }
    }
  }

  return (
    <Button
      {...rest}
      size={size}
      disabled={disabled}
      classes={customIconSize}
      variant={tag ? 'contained' : variant}
      className={cNames.join(' ')}
    >
      {children}
    </Button>
  )
}

export default CustomButton
