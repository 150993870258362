import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Grid, Hidden, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import SeparatorBox from 'components/CustomComponents/SeparatorBox'
import CustomChoiceButton from 'components/CustomComponents/CustomChoiceButton'
import CustomLabel from 'components/CustomLabel'
import LoadingElem from 'components/LoadingElem'
import RenderField from 'components/views/PaymentPage/RenderField'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { getRoutePath } from 'routes'
import useSWR from 'swr'
import * as Yup from 'yup'
import { apiActions } from '_actions/api_actions'
import { DatePicker } from '@material-ui/pickers'
import CustomTextField from 'components/CustomTextField'
import moment from 'moment'
import AdditionalDatas from './AdditionalDatas'

const useStyles = makeStyles((theme) => ({
  selectSpace: {
    '& > label': {
      color: theme.palette.error.main,
    },
    '& fieldset': {
      borderColor: theme.palette.error.main,
    },
  },
}))

const FirstForm = ({ methods, cwSpaces, onValidate, alert }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const multipleCS = cwSpaces && cwSpaces.length > 1

  return (
    <>
      <Box mb={2}>
        <SeparatorBox text={t('register.form.general.title')} />
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomLabel>{t('register.form.civility') + '*'}</CustomLabel>
            <Controller
              name="Civility"
              control={methods.control}
              render={({ field }) => (
                <CustomChoiceButton
                  variant="outlined"
                  color="primary"
                  value={[field.value]}
                  buttonNames={['', 'Mme', 'Mr']}
                  buttonLabels={['Non précisé', t('register.form.civility.miss'), t('register.form.civility.mister')]}
                  onClickButton={(name) => field.onChange(name[0])}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomLabel>{t('register.form.sex') + '*'}</CustomLabel>
            <Controller
              name="Sexe"
              control={methods.control}
              render={({ field }) => (
                <CustomChoiceButton
                  variant="outlined"
                  color="primary"
                  value={[field.value]}
                  buttonNames={['', 'F', 'M']}
                  buttonLabels={['Non précisé', t('register.form.sex.female'), t('register.form.sex.male')]}
                  onClickButton={(name) => field.onChange(name[0])}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RenderField
              methods={methods}
              name="firstName"
              placeholder={t('register.form.firstname.placeholder')}
              label={t('register.form.firstname') + '*'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RenderField
              methods={methods}
              name="lastName"
              placeholder={t('register.form.lastname.placeholder')}
              label={t('register.form.name') + '*'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomLabel>{t('register.form.birthdate') + '*'}</CustomLabel>
            <Controller
              name="Birthdate"
              control={methods.control}
              render={({ field }) => (
                <DatePicker
                  value={field.value}
                  format="DD/MM/YYYY"
                  variant="inline"
                  TextFieldComponent={(params) => <CustomTextField {...params} variant="outlined" />}
                  views={['year', 'month', 'date']}
                  openTo="year"
                  placeholder={t('register.form.birthdate.placeholder')}
                  fullWidth
                  autoOk
                  onChange={(date) => field.onChange(date)}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <SeparatorBox text={t('register.form.cwspace.title')} />
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={methods?.formState?.errors?.CoworkingSpaceId ? classes.selectSpace : ''}>
            <CustomLabel>{t('register.form.coworkingspace')}</CustomLabel>
            <Controller
              name="CoworkingSpaceId"
              control={methods.control}
              render={({ field }) => (
                <Select
                  fullWidth
                  variant="outlined"
                  inputRef={field.ref}
                  onChange={(e) => field.onChange(e)}
                  displayEmpty={multipleCS}
                  defaultValue={multipleCS ? '' : cwSpaces[0]?.Id}
                >
                  {multipleCS && <MenuItem value="">{t('register.form.no.cs')}</MenuItem>}
                  {cwSpaces &&
                    Object.entries(cwSpaces).map(([index, value]) => {
                      return (
                        <MenuItem key={index} value={value.Id}>
                          {value.Name}
                        </MenuItem>
                      )
                    })}
                </Select>
              )}
            />
            {methods?.formState?.errors?.CoworkingSpaceId && (
              <Typography variant="caption" color="error">
                {t('common.field.required')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <SeparatorBox text={t('register.form.security.title')} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <RenderField type="password" methods={methods} name="password" label={t('register.form.password') + '*'} />
        </Grid>
        <Grid item xs={12} md={6}>
          <RenderField
            type="password"
            methods={methods}
            name="confirmPassword"
            label={t('register.form.password.confirm') + '*'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant="subtitle2" color="textSecondary">
            {t('register.form.password.rules.title')}
          </Typography>
          {t('register.form.password.rules.content')
            .split('-')
            .map((t) => {
              if (t !== '')
                return (
                  <Box ml={2}>
                    <Typography variant="subtitle2" color="textSecondary">
                      -&nbsp;{t}
                    </Typography>
                  </Box>
                )
            })}
        </Grid>
      </Grid>
      {alert && (
        <Grid item xs={12}>
          <Alert severity="error">{alert}</Alert>
        </Grid>
      )}
      <Box mt={4} width="100%">
        <Button onClick={() => onValidate()} fullWidth size="large" variant="contained" color="secondary">
          {t('register.button.continue')}
        </Button>
      </Box>
    </>
  )
}

const SecondForm = ({ methods, alert, additionalDatas }) => {
  const { t } = useTranslation()

  const [isPerson, setIsPerson] = React.useState(true)

  React.useEffect(() => {
    methods.setValue('isPerson', isPerson)
  }, [isPerson])

  return (
    <>
      <Box mb={2}>
        <SeparatorBox text={t('register.form.type.title')} />
      </Box>
      <Box mb={2}>
        <CustomLabel>{t('register.form.account.title') + '*'}</CustomLabel>
        <Controller
          name="isPerson"
          control={methods.control}
          render={({ field }) => (
            <CustomChoiceButton
              variant="outlined"
              color="primary"
              value={[field.value]}
              buttonNames={[true, false]}
              buttonLabels={[t('register.form.switch.customer'), t('register.form.switch.society')]}
              onClickButton={(name) => {
                setIsPerson(name[0])
                field.onChange(name[0])
              }}
            />
          )}
        />
      </Box>
      {!isPerson && (
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <RenderField methods={methods} name="SocietyName" label={t('register.form.society.name') + '*'} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RenderField
                methods={methods}
                name="SocietyStatus"
                placeholder={t('register.form.status.placeholder')}
                label={t('register.form.society.status') + '*'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RenderField methods={methods} name="SocietySiret" label={t('register.form.society.siret') + '*'} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RenderField
                methods={methods}
                name="SocietyTVA"
                placeholder={t('register.form.tva.placeholder')}
                label={t('register.form.society.tva') + '*'}
              />
            </Grid>
            {alert && (
              <Grid item xs={12}>
                <Alert severity="error">{alert}</Alert>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      <Box mb={2}>
        <SeparatorBox text={t('register.form.invoice.title')} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <RenderField methods={methods} name="email" label={t('register.form.email') + '*'} />
        </Grid>
        <Grid item xs={12} md={6}>
          <RenderField methods={methods} name="SocietyPhoneNumber" label={t('register.form.society.phone') + '*'} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RenderField
                methods={methods}
                label={t('register.form.address') + '*'}
                placeholder={t('register.form.address.placeholder')}
                name="SocietyAddress"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RenderField
                methods={methods}
                name="SocietyZipcode"
                placeholder={t('register.form.zipcode.placeholder')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RenderField methods={methods} name="SocietyCity" placeholder={t('register.form.city.placeholder')} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {additionalDatas && additionalDatas.length > 0 && (
        <>
          {!(isPerson && additionalDatas.filter((a) => !a.ShowOnlyWhenSociety).length === 0) && (
            <Box mb={2} mt={2}>
              <SeparatorBox text={t('register.form.additional.fields')} />
            </Box>
          )}
          <Grid container spacing={2}>
            <AdditionalDatas additionalDatas={additionalDatas} methods={methods} />
          </Grid>
        </>
      )}
      <Box mt={4} width="100%">
        <Button fullWidth size="large" variant="contained" color="secondary" type="submit">
          {t('register.btn')}
        </Button>
      </Box>
    </>
  )
}

function RegisterForm({ onRegister, nextStep }) {
  const { t } = useTranslation()
  const [alert, setAlert] = React.useState(null)
  const [step, setStep] = React.useState(0)
  const history = useHistory()
  const { apiCall } = useContext(UserContext)

  const { data: cwSpaces } = useSWR('coworkingSpaces/', () =>
    apiCall(apiActions.getCoworkingSpaces).then((r) => r.data)
  )

  const { data: additionalDatas } = useSWR('additional-datas/', () =>
    apiCall(apiActions.getRegisterAdditionalDatas).then((r) => r)
  )

  const requiredForFalse = { is: false, then: Yup.string().required(t('register.form.required.error')).nullable() }

  let additionalFields = {}
  let defaultValuesAdditional = {}
  if (additionalDatas && additionalDatas.length > 0) {
    additionalDatas.map((d) => {
      if (d.IsRequired && d.Type !== 2) {
        if (d.ShowOnlyWhenSociety) additionalFields[d.Id] = Yup.string().when('isPerson', requiredForFalse)
        else
          additionalFields[d.Id] = Yup.string()
            .required(t('register.form.required.error'))
            .typeError(t('register.form.required.error'))
      } else if (d.IsRequired && d.Type === 2) {
        if (d.ShowOnlyWhenSociety)
          additionalFields[d.Id] = Yup.boolean()
            .when('isPerson', requiredForFalse)
            .oneOf([true], t('register.form.required.error'))
        else additionalFields[d.Id] = Yup.boolean().required().oneOf([true], t('register.form.required.error'))
      } else additionalFields[d.Id] = Yup.string().nullable()

      defaultValuesAdditional[d.Id] = d.Type === 2 ? false : ''
    })
  }

  const methods = useForm({
    defaultValues: {
      isPerson: true,
      Civility: '',
      Sexe: '',
      Birthdate: moment().toDate(),
      ...defaultValuesAdditional,
    },
    resolver: yupResolver(
      Yup.object().shape({
        CoworkingSpaceId: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        Civility: Yup.string().nullable(),
        Sexe: Yup.string().nullable(),
        firstName: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        lastName: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
        Birthdate: Yup.date().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
        email: Yup.string()
          .email(t('register.form.email.error'))
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        password: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error'))
          .min(8, t('register.form.error.length', { nb: 8 }))
          .matches(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/, t('register.form.regex.password.error')),
        confirmPassword: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        SocietyName: Yup.string().when('isPerson', requiredForFalse),
        SocietyAddress: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        SocietyCity: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        SocietyZipcode: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        SocietySiret: Yup.string().when('isPerson', requiredForFalse),
        SocietyPhoneNumber: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        SocietyStatus: Yup.string().when('isPerson', requiredForFalse),
        SocietyTVA: Yup.string().when('isPerson', requiredForFalse),
        ...additionalFields,
      })
    ),
  })

  const watchFields = useWatch(methods)

  const onFormSubmit = (values) => {
    onRegister(values, additionalFields)
  }

  React.useEffect(() => {
    if (cwSpaces && cwSpaces.length === 1) methods.setValue('CoworkingSpaceId', cwSpaces[0].Id)
  }, [cwSpaces])

  async function handleClick() {
    const firstFormNames = [
      'CoworkingSpaceId',
      'Civility',
      'Sexe',
      'firstName',
      'lastName',
      'Birthdate',
      'password',
      'confirmPassword',
    ]

    await methods.trigger(firstFormNames)

    if (Object.keys(methods.formState.errors).length > 0) {
      return
    } else {
      methods.clearErrors()
      if (watchFields.password !== watchFields.confirmPassword) {
        setAlert(t('register.form.password.error'))
        return
      } else {
        setStep(1)
        nextStep()
        setAlert(null)
      }
    }
  }

  if (!cwSpaces) return <LoadingElem />
  return (
    <form onSubmit={methods.handleSubmit(onFormSubmit)}>
      {step === 0 && <FirstForm onValidate={handleClick} alert={alert} methods={methods} cwSpaces={cwSpaces} />}
      {step === 1 && <SecondForm methods={methods} additionalDatas={additionalDatas} />}
      <Hidden smUp>
        <Box mt={2} textAlign="center">
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            {t('register.login.text')}
          </Typography>
          <Button
            onClick={() => history.push(getRoutePath('login') + history.location.search)}
            fullWidth
            color="secondary"
            variant="contained"
            size="large"
          >
            {t('header.btn.login')}
          </Button>
        </Box>
      </Hidden>
    </form>
  )
}

export default RegisterForm
