import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { alpha, lighten, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import ItemsGrid from 'components/views/NewReservationPage/ItemsGrid'
import { CoworkingSpaceContext } from 'contexts/CoworkingSpaceContext'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'

const localizer = momentLocalizer(moment)
const useStyles = makeStyles((theme) => {
  const borderColor = theme.palette.divider
  const timeSlotBg = theme.palette.background.paper
  const disabled = alpha(theme.palette.primary.main, 0.06)
  const stripeBg = alpha(theme.palette.secondary.dark, 0.06)
  const stripeMain = theme.palette.secondary.main

  return {
    toolbar: {
      marginBottom: theme.spacing(1),
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiFormControl-marginDense': {
        margin: 0,
      },
    },
    toolbarActions: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        order: 1,
      },
    },
    rangeContainer: {
      backgroundColor: lighten(theme.palette.secondary.main, 0.7),
      color: theme.palette.secondary.contrastText,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      textAlign: 'center',
      '& > div': {
        maxWidth: 500,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      '& input': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    dialogTitle: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '& > .MuiTypography-root': {
        flex: 1,
      },
      '& svg': {
        color: theme.palette.grey[500],
      },
    },
    calendar: {
      '& .rbc-current-time-indicator': {
        backgroundColor: theme.palette.secondary.main,
        height: 2,
      },
      '& .rbc-event-day-header': {
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
          '& > div': {
            display: 'inline-block',
            '& + div': {
              marginLeft: theme.spacing(0.25),
            },
          },
        },
      },
      '& .rbc-time-gutter, & .rbc-event-day-header > div:first-child': {
        fontSize: theme.typography.pxToRem(12),
        [theme.breakpoints.up('sm')]: {
          fontSize: theme.typography.pxToRem(14),
        },
      },
      '& .rbc-time-view': {
        '&, & .rbc-time-content, & .rbc-timeslot-group, & .rbc-time-header.rbc-overflowing, & .rbc-day-slot .rbc-events-container, & .rbc-month-view':
          {
            border: 0,
          },
        '& .rbc-header': {
          border: 0,
          background: 'none',
          color: theme.palette.text.secondary,
          paddingBottom: theme.spacing(3),
          fontSize: theme.typography.body2.fontSize,
          '&.rbc-today': {
            color: theme.palette.secondary.main,
          },
        },
        '& .rbc-day-bg': {
          borderLeft: '1px solid ' + borderColor,
          borderRight: '1px solid ' + borderColor,
          borderTop: '1px solid ' + borderColor,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
        },
        '& .rbc-time-header-content': {
          borderLeft: 0,
          borderRight: 0,
          '& .rbc-time-header-cell-single-day + .rbc-allday-cell .rbc-day-bg': {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            borderTop: '1px solid ' + borderColor,
          },
        },
        '& .rbc-header, & .rbc-day-bg, & .rbc-day-slot': {
          marginRight: theme.spacing(0.25),
          marginLeft: theme.spacing(0.25),
          [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(0.5),
            marginLeft: theme.spacing(0.5),
          },
        },
        '& .rbc-day-slot': {
          borderLeft: '1px solid ' + borderColor,
          borderRight: '1px solid ' + borderColor,
          borderBottom: '1px solid ' + borderColor,
          overflow: 'hidden',
          '&, & .rbc-events-container': {
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
          },
          '& .rbc-time-slot': {
            borderTopColor: alpha(borderColor, 0.06),
          },
        },
      },
      '& .rbc-month-view': {
        minHeight: 500,
        borderRadius: theme.shape.borderRadius,
      },
      '& .rbc-day-bg': {
        '&.rbc-past': {
          background: disabled,
        },
        '&.rbc-today': {
          background: disabled,
        },
        '&.rbc-futur': {
          background: timeSlotBg,
        },
        '&.rbc-selected-cell': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
      '& .rbc-day-slot': {
        '&.rbc-today': {
          background: 'none',
        },
        '& .rbc-past-slot': {
          background: disabled,
        },
        '& .rbc-now-slot': {
          background: timeSlotBg,
        },
        '& .rbc-futur-slot': {
          background: timeSlotBg,
        },
      },
      '& .rbc-slot-selection': {
        borderRadius: theme.shape.borderRadius,
        background: alpha(theme.palette.secondary.main, 0.35),
        color: theme.palette.secondary.contrastText,
        border: 0,
      },
      '& .rbc-events-container': {
        marginRight: 0,
        marginLeft: 0,
        '& .rbc-event': {
          borderRadius: theme.shape.borderRadius,
          background: stripeBg,
          color: theme.palette.secondary.contrastText,
          backgroundImage: `linear-gradient(45deg, ${stripeMain} 4%, transparent 4%, transparent 50%, ${stripeMain} 50%, ${stripeMain} 54%, transparent 54%, transparent 100%)`,
          backgroundSize: '21.00px 21.00px',
          border: 0,
          '& .rbc-event-label': {
            display: 'none',
          },
          '& .rbc-event-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .rbc-event-content-title': {
              padding: theme.spacing(1),
              borderRadius: theme.shape.borderRadius,
              background: theme.palette.background.paper,
              color: theme.palette.getContrastText(theme.palette.background.paper),
            },
          },
        },
      },
    },
  }
})

const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
}

const Toolbar = (props) => {
  const classes = useStyles()

  React.useEffect(() => {
    props.onView(props.view)
  }, [])

  return (
    <Box className={classes.toolbar}>
      <Grid container alignItems="center">
        <Grid item xs>
          <Grid container alignItems="center">
            <Grid item xs={12} sm="auto" className={classes.toolbarActions}>
              <IconButton
                size="small"
                onClick={() => props.onNavigate(navigate.PREVIOUS)}
                disabled={moment(props.date).startOf('day') <= moment().startOf('day')}
              >
                <Icon icon="fleche-gauche" fontSize="medium" />
              </IconButton>
              <Button color="primary" onClick={() => props.onNavigate(navigate.TODAY)}>
                Aujourd'hui
              </Button>
              <IconButton size="small" onClick={() => props.onNavigate(navigate.NEXT)}>
                <Icon icon="fleche-droite" fontSize="medium" />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm>
              <Typography variant="h6">{props.label}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CustomTextField select onChange={(e) => props.onView(e.target.value)} value={props.view} margin="dense">
            {props.views.map((v) => (
              <MenuItem key={'view_' + v} value={v}>
                {props.localizer.messages[v]}
              </MenuItem>
            ))}
          </CustomTextField>
        </Grid>
      </Grid>
    </Box>
  )
}

const Event = (props) => {
  return <div className="rbc-event-content-title">{props.title}</div>
}

const DayHeader = ({ date, label, localizer }) => {
  return (
    <div className="rbc-event-day-header">
      <div>{localizer.format(date, 'ddd')}</div>
      <div>{localizer.format(date, 'DD')}</div>
    </div>
  )
}

const ItemsDialog = ({ onItemClick, defaultRange, categoryId, coworkingSpaceId, filters }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { apiCall } = React.useContext(UserContext)

  const [date, setDate] = React.useState(moment(defaultRange.start).startOf('days').toDate())
  const [start, setStart] = React.useState(defaultRange.start)
  const [end, setEnd] = React.useState(defaultRange.end)
  const [items, setItems] = React.useState()

  React.useEffect(() => {
    apiCall(apiActions.getAllItems, { coworkingSpaceId, categoryId }, 'post', {
      startDate: moment(start).toDate(),
      endDate: moment(end).toDate(),
      ...filters,
    }).then((r) => setItems(r))
  }, [start, end])

  const handleChangeTime = (val, isStart = false) => {
    if (isStart) {
      const diff = moment(end).diff(moment(start), 'm')
      setStart(val)
      setEnd(moment(val).add(diff, 'm').toDate())
    } else {
      setEnd(val)
    }
  }

  const handleItemClick = (e, id) => {
    localStorage.setItem('startdateitem', start)
    localStorage.setItem('enddateitem', end)
    localStorage.setItem('startduration', moment(start).format('HH:mm:ss'))
    localStorage.setItem('endduration', moment(end).format('HH:mm:ss'))
    onItemClick(e, id)
  }

  return (
    <Box>
      <Box className={classes.rangeContainer}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={1} sm="auto">
            <Box>Le</Box>
          </Grid>
          <Grid item xs={11} sm>
            <DatePicker
              autoOk
              fullWidth
              disablePast
              placeholder={t('filters.date.placeholder')}
              inputVariant="outlined"
              variant="inline"
              format="DD/MM/yy"
              value={date}
              onChange={(e) => setDate(moment(e).startOf('days').toDate())}
              InputProps={{
                endAdornment: <Icon icon="agenda-jour" color="action" />,
              }}
            />
          </Grid>
          <Grid item xs={1} sm="auto">
            <Box>de</Box>
          </Grid>
          <Grid item xs md={3}>
            <TimePicker
              autoOk
              fullWidth
              disablePast
              placeholder={t('filters.date.placeholder')}
              inputVariant="outlined"
              variant="inline"
              ampm={false}
              minutesStep={30}
              value={start}
              onChange={(e) => handleChangeTime(e, true)}
              maxDate={end}
            />
          </Grid>
          <Grid item xs={1} sm="auto">
            <Box>à</Box>
          </Grid>
          <Grid item xs md={3}>
            <TimePicker
              autoOk
              fullWidth
              disablePast
              placeholder={t('filters.date.placeholder')}
              inputVariant="outlined"
              variant="inline"
              ampm={false}
              minutesStep={30}
              value={end}
              onChange={(e) => handleChangeTime(e)}
              minDate={start}
            />
          </Grid>
        </Grid>
      </Box>
      <ItemsGrid
        visited={items && (items.VisitedItems ?? [])}
        unvisited={items && (items.UnvisitedItems ?? [])}
        onItemClick={(e, id) => handleItemClick(e, id)}
        coworkingSpaceId={coworkingSpaceId}
        categoryId={categoryId}
        catType="rent"
      />
    </Box>
  )
}

export const onlyUnique = (value, index, self) => {
  return value && self.indexOf(value) === index
}

const hourformat = 'HH:mm:ss'
const step = 30
let timeout
function ItemsScheduler({ onItemClick, categoryId, coworkingSpaceId, filters }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const now = moment()
  const previousDay = moment().subtract(1, 'days').startOf('days')
  const [range, setRange] = React.useState({ start: moment().toDate(), end: moment().toDate() })
  const [events, setEvents] = React.useState([])
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const { coworkingSpace } = React.useContext(CoworkingSpaceContext)
  const { apiCall } = React.useContext(UserContext)
  // const [bounds, setBounds] = React.useState({start: moment().toDate(), end: moment().toDate()})
  const [refresh, setRefresh] = React.useState(null)
  const [view, setView] = React.useState('week')

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    if (isSmDown) setView('work_week')
    if (isXsDown) setView('day')
    if (!isXsDown && !isSmDown) setView('week')
  }, [isXsDown, isSmDown])

  React.useEffect(() => {
    clearTimeout(timeout)
    if (refresh) {
      timeout = setTimeout(() => {
        let newEvents = []
        apiCall(apiActions.getAllBusyTimes, { coworkingSpaceId, categoryId }, 'post', {
          startDate: moment(refresh.start).toDate(),
          endDate: moment(refresh.end).toDate(),
          ...filters,
        }).then((r) => {
          if (r.Error) {
            //
          } else {
            r.data.map((e) => {
              newEvents.push({
                start: moment(e.Start).toDate(),
                end: moment(e.End).toDate(),
                title: e.Title,
              })
            })
            setEvents(newEvents)
          }
        })
      }, 500)
    }
  }, [filters, refresh])

  if (!coworkingSpace) return <LoadingElem />

  const startsHour = coworkingSpace.OpeningTimes.map((m) => m.Start).filter(onlyUnique)
  const endsHour = coworkingSpace.OpeningTimes.map((m) => m.End).filter(onlyUnique)

  let minStartHour = startsHour.sort((a, b) => moment(a, hourformat).diff(moment(b, hourformat), 'hour'))[0]
  let maxEndHour = endsHour.sort((a, b) => -moment(a, hourformat).diff(moment(b, hourformat), 'hour'))[0]
  if (minStartHour.length === 0) minStartHour = '00:00:00'
  if (maxEndHour.length === 0) maxEndHour = '00:00:00'
  if (moment(maxEndHour, hourformat).format(hourformat) === '00:00:00')
    maxEndHour = moment().set({ hour: 23, minute: 59, second: 59 }).format(hourformat)

  const handleSelect = (range) => {
    const diff =
      view === 'month' ? moment(range.start).diff(previousDay, 'days') : moment(range.start).diff(now, 'minutes')
    if (diff > -step) {
      setRange(range)
      setDialogOpen(true)
    }
  }

  if (!minStartHour || !maxEndHour)
    return (
      <Box display="flex" justifyContent="center">
        <Typography variant="h3" color="error">
          {t('coworkingspace.closed.everyday')}
        </Typography>
      </Box>
    )

  return (
    <Box>
      <Calendar
        className={classes.calendar}
        localizer={localizer}
        events={events}
        view={view}
        onView={(v) => setView(v)}
        views={{
          day: true,
          work_week: true,
          week: true,
          month: true,
        }}
        formats={{
          dayFormat: (date, culture, localizer) => localizer.format(date, 'DD', culture),
          dayRangeHeaderFormat: (date, culture, localizer) =>
            localizer.format(date.start, 'Do MMMM', culture) + ' — ' + localizer.format(date.end, 'Do MMMM', culture),
          dayHeaderFormat: (date, culture, localizer) => localizer.format(date, 'dddd Do MMMM YYYY', culture),
        }}
        min={moment(minStartHour ?? moment().startOf('W'), hourformat).toDate()}
        max={moment(maxEndHour ?? moment().endOf('W'), hourformat).toDate()}
        messages={{
          day: t('scheduler.day'),
          work_week: t('scheduler.workweek'),
          week: t('scheduler.week'),
          month: t('scheduler.month'),
        }}
        step={step}
        startAccessor="start"
        endAccessor="end"
        titleAccessor="title"
        selectable
        onSelecting={(range) => {
          const diff = moment(range.start).diff(now, 'minutes')
          if (diff < -step) return false

          const sameDateEvents = events.filter(
            (e) =>
              (range.start >= e.start && range.end <= e.end) ||
              (range.start < e.start && range.end > e.start) ||
              (range.start >= e.start && range.start < e.end && range.end >= e.end)
          )
          if (sameDateEvents.length > 0) return false

          return true
        }}
        onSelectSlot={handleSelect}
        components={{
          toolbar: Toolbar,
          event: Event,
          week: {
            header: DayHeader,
          },
          work_week: {
            header: DayHeader,
          },
        }}
        slotPropGetter={(date, resourceId) => {
          let className = ''
          const start = moment(date)
          const end = now
          const diff = start.diff(end, 'minutes')

          if (diff < 0) {
            if (diff > -step) className = 'rbc-futur-slot'
            else className = 'rbc-past-slot'
          } else if (diff === 0) {
            className = 'rbc-now-slot'
          } else {
            className = 'rbc-futur-slot'
          }

          return {
            className: className,
          }
        }}
        dayPropGetter={(date) => {
          let className = ''
          const start = moment(date).startOf('day')
          const end = previousDay
          const diff = start.diff(end, 'days')

          if (diff <= 0) {
            className = 'rbc-past'
          } else if (diff === 0) {
            className = 'rbc-today'
          } else {
            className = 'rbc-futur'
          }

          return {
            className: className,
          }
        }}
        onRangeChange={(range) => {
          let newRange
          if (range.start && range.end) {
            // Mensuel
            newRange = { start: range.start, end: range.end }
          } else if (range.length === 1) {
            // Journée
            newRange = {
              start: moment(range[0]).set('h', 0).set('m', 0).toDate(),
              end: moment(range[0]).set('h', 23).set('m', 59).toDate(),
            }
          } else {
            // Semaine
            newRange = {
              start: moment(range[0]).set('h', 0).set('m', 0).toDate(),
              end: moment(range[range.length - 1])
                .set('h', 23)
                .set('m', 59)
                .toDate(),
            }
          }

          setRefresh(newRange)
        }}
      />
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth={'md'} fullWidth fullScreen={isXsDown}>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h5">{t('reservations.scheduler.dialog.title')}</Typography>
          <IconButton onClick={() => setDialogOpen(false)} size="small">
            <Icon icon="close" fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <ItemsDialog
              filters={filters}
              defaultRange={range}
              onItemClick={onItemClick}
              categoryId={categoryId}
              coworkingSpaceId={coworkingSpaceId}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ItemsScheduler
