import { Box, Button, Fade, Hidden, Paper, Typography } from '@material-ui/core'
import MuiLink from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { MediaImage } from 'components/CustomComponents/MediaImage'
import Spot from 'components/Spot'
import LoginForm from 'components/views/LoginPage/LoginForm'
import { CustomThemeContext } from 'contexts/CustomThemeContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { getRoutePath } from 'routes'

const mobileHeroHeight = '15vh'
const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  leftColumn: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    width: '100%',
    flex: 4,
    padding: theme.spacing(4),
    '& > div': {
      textAlign: 'center',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: mobileHeroHeight,
    },
  },
  rightColumn: {
    zIndex: 1,
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      margin: 'auto',
    },
    flex: '1 1 100%',
    [theme.breakpoints.up('sm')]: {
      flex: '0 1 50%',
      marginTop: 0,
      marginLeft: -theme.shape.borderRadius,
    },
    [theme.breakpoints.up('md')]: {
      flex: '1 1 350px',
    },
    [theme.breakpoints.up('md')]: {
      flex: '1 1 350px',
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: `calc(${mobileHeroHeight} - ${theme.shape.borderRadius}px)`,
    },
  },
  leftColumnContent: {
    position: 'relative',
    zIndex: 10,
    '& .MuiTypography-root': {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    '& .MuiButton-root': {
      maxWidth: 200,
    },
  },
  bgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    '& > img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      opacity: 0.35,
    },
  },
}))

function LoginPage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const customTheme = React.useContext(CustomThemeContext)
  const location = useLocation()
  const history = useHistory()

  const query = new URLSearchParams(location.search)
  const returnUrl = query.get('r')

  const handleRegister = () => {
    history.push(getRoutePath('register') + history.location.search)
  }

  return (
    <Fade in={true}>
      <Box className={classes.container}>
        <Box className={classes.leftColumn}>
          <Box>
            {customTheme.loginImage && (
              <Box className={classes.bgImage}>
                <MediaImage
                  src={customTheme.loginImage}
                  mediaParams={{
                    xs: { width: 425, heightratio: 0.5, mode: 'max', retina: true },
                    sm: { width: 400, heightratio: 2, mode: 'max', retina: true },
                    xl: { width: 1400, heightratio: 1, mode: 'max', retina: true },
                  }}
                />
              </Box>
            )}
            <Hidden xsDown>
              <Box className={classes.leftColumnContent}>
                <Box mb={3}>
                  <Typography variant="h1">
                    <Box fontWeight="600" component="span">
                      {t('login.register.title')}
                    </Box>
                  </Typography>
                  <Typography variant="subtitle1">{t('login.register.text')}</Typography>
                </Box>
                <Button onClick={handleRegister} fullWidth color="secondary" variant="contained" size="large">
                  {t('login.register.btn')}
                </Button>
              </Box>
            </Hidden>
          </Box>
        </Box>
        <Paper elevation={0} className={classes.rightColumn}>
          <Box>
            {/* <Box mb={2}>
              <Spot mr={1}></Spot>
              <MuiLink
                component={Link}
                variant="body2"
                href=""
                to={`${returnUrl}?from=${getRoutePath('login')}` ?? getRoutePath('index')}
              >
                {t('login.continue.text')}
              </MuiLink>
            </Box> */}
            {customTheme.logo && (
              <Box mb={4}>
                <MediaImage alt="logo" src={customTheme.logo} mediaParams={{ xs: { height: 80 } }} />
              </Box>
            )}
            <Box mb={4}>
              <Typography variant="h2">
                <Box component="span" fontWeight="600" color="primary.darken">
                  {t('login.welcome.title')}
                </Box>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {t('login.welcome.text')}
              </Typography>
            </Box>
            <LoginForm />
          </Box>
        </Paper>
      </Box>
    </Fade>
  )
}

export default LoginPage
