import { Box, Hidden, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import ListView from 'components/CustomComponents/ListView'
import CustomTextField from 'components/CustomTextField'
import Layout from 'components/views/Layout'
import LineCard from 'area/owner/views/ByResourceRentPage/LineCard'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'
import React, { useContext } from 'react'
import { UserContext } from 'contexts/UserContext'
import CoworkingSpacesButtons from 'area/owner/components/CoworkingSpacesButtons'

function ByResourceRentPage() {
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const [csIds, setCsIds] = React.useState([])
  const [reload, setReload] = React.useState(false)
  const [searchText, setSearchText] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChangeCsId = (ids) => {
    setCsIds(ids)
    let newLocalStorageFilters = sessionStorage.getItem('ownerfilters')
    if (newLocalStorageFilters) {
      newLocalStorageFilters = JSON.parse(newLocalStorageFilters)
      newLocalStorageFilters.coworkingSpaces = ids
      sessionStorage.setItem('ownerfilters', JSON.stringify(newLocalStorageFilters))
    } else {
      sessionStorage.setItem('ownerfilters', JSON.stringify({ coworkingSpaces: ids }))
    }
    setReload(!reload)
  }

  const handleTextChange = (e) => {
    setSearchText(e.target.value)
    setReload(!reload)
  }

  const SearchBar = () => {
    return (
      <CustomTextField
        defaultValue={searchText}
        fullWidth={isSmDown}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleTextChange(e)
        }}
        placeholder={t('owner-by-rr.search.placeholder')}
      />
    )
  }

  return (
    <Layout pageTitle={t('owner-by-rr.page.title')} pageDescription={t('owner-by-rr.page.subtitle')}>
      <CustomPaper>
        <Box p={3}>
          <Box display={isSmDown ? 'block' : 'flex'} justifyContent="space-between" alignItems="center" mb={2}>
            <Box mb={isSmDown ? 1 : 0}>
              <CoworkingSpacesButtons onChange={handleChangeCsId} />
            </Box>
            <SearchBar />
          </Box>
          {csIds && csIds.length > 0 ? (
            <ListView
              perPage={20}
              showPerPageSelect={false}
              component={(p) => <LineCard csIds={csIds} order={p} />}
              read={(opt) => {
                return new Promise((resolve) => {
                  setLoading(true)
                  apiCall(apiActions.ownerGetOrdersByCoworkingSpaceId, {}, 'post', {
                    PerPage: opt.perPage,
                    Page: opt.page,
                    CoworkingSpaces: csIds,
                    QueryName: searchText,
                  }).then((r) => {
                    setLoading(false)
                    resolve(r)
                  })
                })
              }}
              reload={reload}
            />
          ) : (
            <Typography variant="subtitle1" color="textSecondary">
              {t('owner-by-rr.no.cs')}
            </Typography>
          )}
        </Box>
      </CustomPaper>
    </Layout>
  )
}

export default ByResourceRentPage
