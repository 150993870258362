import { yupResolver } from '@hookform/resolvers/yup'
import { alpha, Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import RenderField from 'components/views/PaymentPage/RenderField'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { apiActions } from '_actions/api_actions'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'
import useSWR from 'swr'
import { useConfirm } from 'material-ui-confirm'

const useStyles = makeStyles((theme) => ({
  lost: {
    border: '1px solid ' + theme.palette.error.main,
    borderRadius: theme.shape.borderRadius,
    width: 120,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.error.main,
    '& .MuiButton-label': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.light, 0.2),
      borderColor: theme.palette.error.main,
    },
  },
  won: {
    borderRadius: theme.shape.borderRadius,
    width: 120,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: theme.palette.success.main,
    backgroundColor: theme.palette.success.main,
    '& .MuiButton-label': {
      color: theme.palette.primary.darker,
    },
    '&:hover': {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.main,
    },
  },
  archived: {
    borderRadius: theme.shape.borderRadius,
    width: 120,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid ' + theme.palette.info.main,
    '& .MuiButton-label': {
      color: theme.palette.primary.darker,
    },
  },
  container: {
    padding: theme.spacing(5) + 'px ' + theme.spacing(10) + 'px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    marginRight: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
  },
  separator: {
    borderRight: '2px dashed ' + theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      borderBottom: '2px dashed ' + theme.palette.secondary.main,
    },
  },
  gridButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function EndActivity({ prospect, handleClose, isWon, refreshData, status }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { apiCall } = useContext(UserContext)
  const confirm = useConfirm()

  const { data: interests } = useSWR(`${prospect.Id}/getInterests`, () =>
    apiCall(apiActions.getInterestsByProspectId, { ProspectId: prospect.Id }, 'get').then((r) => {
      return r.data
    })
  )

  const lastActivity = prospect?.PassedActivities?.length > 0 ? prospect?.PassedActivities[0] : null

  let button = <></>
  switch (status) {
    case '210':
      button = (
        <Typography align="center" variant="button" className={classes.won}>
          {t('crm.prospect.won')}
        </Typography>
      )
      break
    case '220':
      button = (
        <Typography align="center" variant="button" className={classes.lost}>
          {t('crm.prospect.lost')}
        </Typography>
      )
      break
    case '230':
    default:
      button = (
        <Typography align="center" variant="button" className={classes.archived}>
          {t('crm.prospect.archived')}
        </Typography>
      )
      break
  }

  const methods = useForm({
    defaultValues: {
      isWon: status === '210',
      prospectStatus: parseInt(status),
      reason: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        reason: Yup.string().when('isWon', {
          is: false,
          then: Yup.string().required(t('common.field.required')).nullable(),
        }),
      })
    ),
  })

  const goToTransform = () => {
    history.push(getRoutePath('transform'), { prospect: prospect })
  }

  const onFormSubmit = (values) => {
    if (status === '210') {
      if (interests && interests.length === 0) {
        confirm({ title: '', description: t('transform.confirm.title') }).then(() => {
          goToTransform()
        })
      } else {
        goToTransform()
      }
    } else if (status === '220') {
      apiCall(
        apiActions.transformProspect,
        {
          ProspectId: prospect.Id,
          ProspectStatus: parseInt(status),
        },
        'put',
        {
          ProspectStatus: parseInt(status),
          Reason: values.reason,
        }
      ).then(() => {
        handleClose()
        refreshData()
      })
    } else {
      apiCall(apiActions.archiveProspect, { ProspectId: prospect.Id }, 'post', {
        Reason: values.reason,
        ForceDelete: false,
      }).then((r) => {
        if (!r.Error) {
          handleClose()
          refreshData()
        }
      })
    }
  }

  return (
    <form onSubmit={methods.handleSubmit(onFormSubmit)}>
      <Box mb={3}>
        <CustomPaper>
          <Box p={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <Typography gutterBottom variant="subtitle1">
                  <Box component="span" color="primary.darker">
                    {t('crm.prospect.end.infos')}
                  </Box>
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {prospect.FirstName + ' ' + prospect.LastName}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {prospect.ProspectTag}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {prospect.ProspectInterest}
                </Typography>
              </Grid>
              <Grid item xs={12} md className={classes.separator}>
                <Typography gutterBottom variant="subtitle1">
                  <Box component="span" color="primary.darker">
                    {t('crm.prospect.end.last.activity')}
                  </Box>
                </Typography>
                {lastActivity ? (
                  <div>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t(lastActivity.Title)}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {moment(lastActivity.Date).format('DD/MM/YYYY')}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {lastActivity.ActivityOwner?.Name}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="subtitle2" color="textSecondary">
                    Aucune
                  </Typography>
                )}
              </Grid>
              <Grid item xs className={classes.gridButton}>
                {button}
              </Grid>
            </Grid>
          </Box>
        </CustomPaper>
      </Box>
      {!isWon ? (
        <Box mb={2}>
          <RenderField
            methods={methods}
            name="reason"
            label={status === '220' ? t('crm.prospect.lost.reasons.title') : t('crm.prospect.archived.reason.title')}
          />
        </Box>
      ) : (
        <Box textAlign="center" mb={2}>
          <Typography variant="subtitle1">{t('crm.prospect.end.comment')}</Typography>
        </Box>
      )}
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={6} md={3}>
          <Button onClick={handleClose} fullWidth variant="outlined" color="secondary">
            <Box component="span" color="primary.darker">
              {t('crm.prospect.end.button.cancel')}
            </Box>
          </Button>
        </Grid>
        <Grid item xs={6} md={3}>
          <Button type="submit" fullWidth variant="contained" color="secondary">
            {isWon ? t('crm.prospect.transform.go') : t('crm.prospect.end.button.confirm')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default EndActivity
