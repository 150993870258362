import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import Icon from 'components/IcoMoon/Icon'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: 'underline',
  },
}))

function ShowMoreButton({ onClick, style = {}, variant = 'h6', ...rest }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [showMore, setShowMore] = React.useState(false)

  const handleClick = () => {
    setShowMore(!showMore)
    onClick(!showMore)
  }

  return (
    <Button className={classes.root + ' ' + style} color="primary" onClick={handleClick} {...rest}>
      <Box display="flex" alignItems="center">
        <Typography variant={variant}>
          {showMore ? t('publications.show.less') : t('publications.show.more')}
        </Typography>
        <Icon icon={showMore ? 'fleche-haut' : 'fleche-bas'} color="primary" />
      </Box>
    </Button>
  )
}

export default ShowMoreButton
