import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { Replay } from '@material-ui/icons'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CloseIcon from '@material-ui/icons/Close'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import Icon from 'components/IcoMoon/Icon'
import { useConfirm } from 'material-ui-confirm'
import MUIDataTable, { TableBody, TableFilterList } from 'mui-datatables'
import React from 'react'
import { useTranslation } from 'react-i18next'

type CustomTableProps = {
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  title?: string
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  pageSize: number
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  rowsPerPageOptions: Array<number>
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  selectable?: string
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  viewColumns?: boolean
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  filterable?: boolean
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  downloadCsv?: boolean
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  print?: boolean
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  enableNestedDataAccess?: string
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  columns: any
  /**
   * paramètre par defaut de mui datatable voir doc : https://github.com/gregnb/mui-datatables
   */
  onRowClick: any
  /**
   * appel serveur pour avoir les datas
   */
  read: any
  /**
   * optionnel, appelé après l'appel serveur
   */
  afterRead?: any
  /**
   * optionnel, appel serveur pour modifier une ligne
   */
  update?: any
  /**
   * optionnel, appelé avant l'appel serveur
   */
  beforeUpdate?: any
  /**
   * optionnel, appelé après l'appel serveur
   */
  afterUpdate?: any
  /**
   * optionnel, appel serveur pour create une ligne
   */
  create?: any
  /**
   * optionnel, appelé avant l'appel serveur
   */
  beforeCreate?: any
  /**
   * optionnel, appelé après l'appel serveur
   */
  afterCreate?: any
  /**
   * optionnel, appel serveur pour supprimer une ligne
   */
  remove?: any
  /**
   * optionnel, appelé avant l'appel serveur
   */
  beforeDelete?: any
  /**
   * optionnel, appelé après l'appel serveur
   */
  afterDelete?: any
  /**
   * correspond au hook du useForm
   */
  methods: any
  /**
   * formulaire affiché dans la popup
   */
  FormCreateUpdate?: any
  /**
   * correspond a defaultValues du useForm (utiliser lors de la creation)
   */
  defaultValues?: object
  /**
   * parametre de dialog de mui
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
  /**
   * parametre de dialog de mui
   */
  fullWidth?: boolean
  /**
   * parametre de dialog de mui
   */
  fullScreen?: boolean
  /**
   * fonction exécutée après la sélection de ligne
   */
  afterRowSelectionChange?: any
  /**
   * paramètre supplémentaire donné à la fonction read
   */
  readAdditionalData?: string | object
  /**
   * fonction pour surcharger la création ou redirection url
   */
  customCreateAction?: any
  /**
   * fonction pour surcharger la modification ou redirection url
   */
  customEditAction?: any
  /**
   * parametre a utiliser avec le useCustomTable
   */
  refresh?: number
  /**
   * fonction pour afficher le detail d'une ligne, si le parametre est renseigné la fèche de détails s'affiche automatiquement
   */
  renderExpandableRow?: any
  /**
   * Ajouter des actions supplementaires aux lignes
   */
  customAction?: any
  /**
   * ferme la popup de création/modification quand l'action a été effectué
   */
  closeAfterCreateOrUpdate?: boolean
  /**
   * Ajouter des actions supplementaires a la barre d'action
   */
  customToolbarAction?: React.ReactNode
  /**
   * parametre de card de mui
   */
  elevation?: number
  /**
   * a utiliser si l'uniqueidentifier de la grid n'est pas id
   */
  uniqueidentifier?: string
  /**
   * permet de gérer les propriété d'une ou plusieurs lignes
   */
  setRowProps?: any
  /**
   * utilisé par useCustomTable, afin de récupérer les filtres appliqués
   */
  onChangeRef?: Function
  /**
   * permet de modifier le json des data renvoyées par le serveur
   */
  customTransformData?: any
  /**
   * permet de personnaliser l'affichage après un clic sur la suppression
   */
  hasCustomDelete?: boolean
  /**
   * icone à utiliser pour l'action delete
   */
  iconDelete?: string
}

type CustomTableFilterProps = {
  column: string
  value: string
}

type CustomTableFiltersProps = {
  filters: CustomTableFilterProps[]
  sortOrder?: string
  page: number
  pageSize: number
  search: string
}

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableCell-head': {
      '& *': {
        textAlign: 'left',
      },
    },
    '& tbody tr': {
      cursor: 'pointer',
      '& td:first-child': {
        fontWeight: 'bold',
      },
      [theme.breakpoints.down('sm')]: {
        border: '2px dashed ' + theme.palette.primary.main,
        borderLeft: 'none',
        borderRight: 'none',
      },
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
    '& .MuiToolbar-root': {
      minHeight: 20,
    },
    '& .MuiTable-root': {
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
    '& th': {
      zIndex: 1,
    },
    '& thead tr th ': {
      borderBottom: '1px solid ' + theme.palette.secondary.main,
    },
    '& tbody tr td ': {
      borderBottom: '1px solid ' + theme.palette.secondary.main,
    },
  },
  title: {
    flexGrow: 1,
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
    borderBottom: 'none',
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid ' + theme.palette.secondary.main,
      '& > .MuiTablePagination-root': {
        flex: 4,
      },
      '& > .MuiButtonBase-root': {
        flex: 1,
      },
      '& .MuiTablePagination-caption': {
        display: 'none',
      },
      '& .MuiTablePagination-selectRoot, & .MuiTablePagination-actions': {
        margin: 0,
      },
    },
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    background: alpha(theme.palette.background.paper, 0.5),
    zIndex: 111,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionCell: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
}))

const CustomTable: React.FC<CustomTableProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const confirm = useConfirm()

  const {
    title,
    pageSize = 50,
    rowsPerPageOptions = [50, 100, 200],
    selectable = 'none',
    onRowClick,
    viewColumns = true,
    filterable = true,
    downloadCsv = false,
    print = false,
    enableNestedDataAccess = '',
    columns,
    read,
    afterRead,
    update,
    beforeUpdate,
    afterUpdate,
    create,
    beforeCreate,
    afterCreate,
    remove,
    beforeDelete,
    afterDelete,
    methods,
    FormCreateUpdate,
    defaultValues = {},
    maxWidth,
    fullWidth,
    fullScreen,
    afterRowSelectionChange,
    readAdditionalData,
    customCreateAction,
    customEditAction,
    refresh,
    renderExpandableRow,
    customAction,
    closeAfterCreateOrUpdate = true,
    customToolbarAction,
    elevation = 1,
    uniqueidentifier = 'id',
    setRowProps,
    onChangeRef,
    customTransformData,
    hasCustomDelete,
    iconDelete,
  } = props

  const defaultFilterData: CustomTableFiltersProps = {
    filters: [] as { column: string; value: string }[],
    sortOrder: undefined,
    page: 0,
    pageSize: pageSize,
    search: '',
  }

  const [isLoading, setIsLoading] = React.useState(false)
  const [rowsSelected, setRowsSelected] = React.useState(Array)
  const [page, setPage] = React.useState(0)
  const [totalCount, setTotalCount] = React.useState(0)
  const [rowsPerPages, setRowsPerPages] = React.useState(pageSize)
  const [data, setData] = React.useState<any>(Array)
  const [edit, setEdit] = React.useState<any | null>(null)
  const [dialog, setDialog] = React.useState(false)
  const [creation, setCreation] = React.useState(false)
  const [filter, setFilter] = React.useState(defaultFilterData)

  const editable = typeof update !== 'undefined' || typeof customEditAction !== 'undefined'
  const deletable = typeof remove !== 'undefined'
  const creatable = typeof create !== 'undefined' || typeof customCreateAction !== 'undefined'
  let useFilter = false

  const toggleDialog = () => {
    setDialog(!dialog)
  }

  const getData = (filterData: object, additionalData: string | any) => {
    setIsLoading(true)
    read(filterData, additionalData).then((r: any) => {
      setPage(r.page)
      setTotalCount(r.total)
      if (customTransformData) setData(customTransformData(r.data))
      else setData(r.data)
      setIsLoading(false)
      if (afterRead) afterRead(r.data)
    })
  }

  const onEditClick = (rowData: object) => {
    if (customEditAction) customEditAction(rowData)
    else {
      setCreation(false)
      setEdit(rowData)
      toggleDialog()
    }
  }

  React.useEffect(() => {
    getData(filter, readAdditionalData)
    setRowsSelected(Array)
  }, [refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onChangeRef && onChangeRef(filter, data)
  }, [filter, data]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (edit) {
      methods.clearErrors()
      methods.reset(edit)
    }
  }, [edit]) // eslint-disable-line react-hooks/exhaustive-deps

  const textLabels = {
    body: {
      noMatch: !isLoading && t('grid.no.records'),
      toolTip: 'Sort',
      columnHeaderTooltip: (column: any) => column.label,
    },
    pagination: {
      next: t('grid.next.page'),
      previous: t('grid.previous.page'),
      rowsPerPage: t('grid.row.per.page'),
      displayRows: t('grid.of'),
    },
    toolbar: {
      search: t('grid.search'),
      downloadCsv: t('grid.download.csv'),
      print: t('grid.print'),
      viewColumns: t('grid.view.column'),
      filterTable: t('grid.filters'),
    },
    filter: {
      all: t('grid.filter.all'),
      title: t('grid.filters'),
      reset: t('grid.filter.reset'),
    },
    viewColumns: {
      title: t('grid.view.column'),
      titleAria: t('grid.view.column.show.hide'),
    },
    selectedRows: {
      text: t('grid.selected.row'),
      delete: t('grid.delete'),
      deleteAria: t('grid.delete.selected.row'),
    },
  }

  const options: any = {
    filter: filterable,
    rowsSelected: rowsSelected,
    search: false,
    elevation: elevation,
    filterType: 'textField',
    serverSide: true,
    onRowClick: onRowClick,
    rowsPerPage: rowsPerPages,
    count: totalCount,
    selectableRows: selectable,
    page: page,
    print: print,
    download: downloadCsv,
    viewColumns: viewColumns,
    enableNestedDataAccess: enableNestedDataAccess,
    downloadOptions: {
      separator: ';',
    },
    textLabels: textLabels,
    setRowProps: (row: any, index: number) => {
      if (setRowProps) {
        const rowData = data[index]
        return setRowProps(rowData)
      }
    },
    customToolbar: () => {
      if (creatable) {
        return (
          <>
            <IconButton
              onClick={() => {
                if (customCreateAction) customCreateAction()
                else {
                  setCreation(true)
                  setEdit(defaultValues)
                  toggleDialog()
                }
              }}
            >
              <AddBoxIcon />
            </IconButton>
            {customToolbarAction ? customToolbarAction : <></>}
          </>
        )
      } else if (customToolbarAction) {
        return customToolbarAction
      }
    },
    customFooter: (count: any, page: any, rowsPerPage: any, changeRowsPerPage: any, changePage: any) => (
      <TableFooter>
        <TableRow>
          <TableCell className={classes.footer} colSpan={1000}>
            <TablePagination
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, page) => changePage(page)}
              onRowsPerPageChange={(event) => {
                changeRowsPerPage(event.target.value)
              }}
              rowsPerPageOptions={rowsPerPageOptions}
            />
            <IconButton title={t('grid.reload')} onClick={() => getData(filter, readAdditionalData)}>
              <Replay />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableFooter>
    ),
    onTableChange: (action: any, tableState: any) => {
      let tempFilter = filter
      let toreload = false
      if (onChangeRef) onChangeRef(filter)
      switch (action) {
        case 'changeRowsPerPage':
          tempFilter.pageSize = tableState.rowsPerPage
          setRowsPerPages(tableState.rowsPerPage)
          setFilter(tempFilter)
          toreload = true
          break
        case 'changePage':
          tempFilter.page = tableState.page
          setRowsSelected(Array)
          setFilter(tempFilter)
          toreload = true
          break
        case 'sort':
          tempFilter.sortOrder = tableState.sortOrder
          setFilter(tempFilter)
          toreload = true
          break
        case 'filterChange':
          tempFilter.filters = [] as { column: string; value: string }[]
          tempFilter.page = 0
          tableState.filterList.forEach((filterChange: any, index: number) => {
            if (filterChange.length > 0) {
              tempFilter.filters.push({
                column: columns[index].name,
                value: filterChange.join(),
              })
            }
          })
          useFilter = true
          setTimeout(() => {
            if (useFilter) {
              setFilter(tempFilter)
              getData(filter, readAdditionalData)
              useFilter = false
            }
          }, 500)
          break
        case 'resetFilters':
          tempFilter.filters = []
          setFilter(tempFilter)
          toreload = true
          break
        case 'search':
          if (tableState.searchText) {
            useFilter = true
            tempFilter.search = tableState.searchText
            tempFilter.page = 0
            setTimeout(() => {
              if (useFilter) {
                setFilter(tempFilter)
                getData(filter, readAdditionalData)
                useFilter = false
              }
            }, 500)
          } else {
            //Si clear aucun timeout
            tempFilter.search = tableState.searchText
            setFilter(tempFilter)
            getData(filter, readAdditionalData)
          }
          break
        case 'rowSelectionChange':
          var indexes = tableState.selectedRows.data.map((s: any) => s.index)
          setRowsSelected(indexes)
          if (afterRowSelectionChange) {
            var selectedDatas = indexes.map((index: number) => data[index])
            afterRowSelectionChange(selectedDatas)
          }
          break
      }
      if (toreload) {
        getData(filter, readAdditionalData)
      }
    },
    selectToolbarPlacement: 'none',
    selectableRowsOnClick: selectable === 'single' ? true : false,
    selectableRowsHideCheckboxes: selectable === 'single' ? true : false,
    expandableRows: renderExpandableRow && renderExpandableRow !== undefined,
    renderExpandableRow: renderExpandableRow && renderExpandableRow !== undefined ? renderExpandableRow : null,
  }

  let allColumns = [
    ...columns,
    {
      name: 'action',
      label: t('grid.actions'),
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        setCellHeaderProps: () => {
          return {
            className: classes.actionCell,
          }
        },
        setCellProps: () => {
          return {
            className: classes.actionCell,
          }
        },
        customBodyRender: (value: any, table: any) => {
          if (!table) return null
          const rowData: any = data[table.rowIndex]
          return (
            <>
              {customAction ? customAction(rowData) : <></>}
              {editable && (
                <Box display="inline-block" mr={deletable ? 0.5 : 0}>
                  <IconButton size="small" aria-label="edit" onClick={() => onEditClick(rowData)}>
                    <CreateIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
              {deletable && (
                <IconButton
                  size="small"
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteValid(rowData[uniqueidentifier])
                  }}
                >
                  <Icon icon={iconDelete ?? 'supprimer'} fontSize="small" />
                </IconButton>
              )}
            </>
          )
        },
      },
    },
  ]

  const onFormSubmit = (values: any) => {
    return new Promise<void>((resolve) => {
      let cb = (e: any) => {
        if (e.isError) /* handleError(methods, e.dicoError) */ console.log('error')
        else {
          methods.clearErrors()
          if (closeAfterCreateOrUpdate) {
            setDialog(false)
            setEdit(null)
          }
          getData(filter, readAdditionalData)
        }
        resolve()
      }

      if (creation) {
        if (beforeCreate) beforeCreate(values)
        create(values)
          .then((e: any) => {
            if (afterCreate) afterCreate(values)
            cb(e)
          })
          .catch((e: any) => {
            resolve()
          })
      } else {
        if (beforeUpdate) beforeUpdate(values)
        update(values)
          .then((e: any) => {
            if (afterUpdate) afterUpdate(values)
            cb(e)
          })
          .catch((e: any) => {
            resolve()
          })
      }
    })
  }

  const deleteValid = (id: any) => {
    const doDelete = () => {
      if (beforeDelete) beforeDelete()

      remove(id).then(() => {
        getData(filter, readAdditionalData)
      })

      if (afterDelete) afterDelete()
    }

    if (id) {
      if (hasCustomDelete) {
        doDelete()
      } else {
        confirm({ description: t('grid.delete.confirm.text') }).then(() => {
          doDelete()
        })
      }
    }
  }

  //Affichage des filtres dans des chips avec le nom de la colonne
  const CustomChip = (props: any) => {
    let index = props.index
    let label = props.label
    let onDelete = props.onDelete
    let name = columns[index].label
    return (
      <Chip
        className={classes.chip}
        variant="outlined"
        color="primary"
        label={name ? name + ':' + label : label}
        onDelete={onDelete}
      />
    )
  }

  const CustomFilterList = (props: any) => {
    return <TableFilterList {...props} ItemComponent={CustomChip} />
  }

  const CustomTableBody = (props: any) => {
    return (
      <>
        {isLoading && (
          <tbody>
            <tr className={classes.loader}>
              <td>
                <CircularProgress />
              </td>
            </tr>
          </tbody>
        )}
        <TableBody {...props} />
      </>
    )
  }

  return (
    <div className={classes.table}>
      <MUIDataTable
        title={title}
        columns={customAction || editable || deletable ? allColumns : columns}
        data={data}
        options={options}
        components={{
          TableFilterList: CustomFilterList,
          TableBody: CustomTableBody,
        }}
      />
      <Dialog
        open={dialog}
        maxWidth={maxWidth ? maxWidth : 'sm'}
        fullWidth={fullWidth ? fullWidth : false}
        fullScreen={fullScreen ? fullScreen : false}
        keepMounted
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Typography className={classes.title} variant="h5">
              {creation ? t('grid.create') : t('grid.edit')}
            </Typography>
            <IconButton onClick={toggleDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        {edit && (
          <DialogContent>
            <form onSubmit={methods.handleSubmit(onFormSubmit)}>
              <FormCreateUpdate cancel={toggleDialog} methods={methods} />
            </form>
          </DialogContent>
        )}
      </Dialog>
    </div>
  )
}

const useCustomTable = (props: CustomTableProps) => {
  const [refresh, doRefresh] = React.useState(0)
  const [filters, setFilters] = React.useState<CustomTableFilterProps[]>()
  const [search, setSearch] = React.useState<string>()
  const [data, setData] = React.useState<any>(Array)

  const onChangeRef = (values: CustomTableFiltersProps, tableData: any) => {
    setFilters(values.filters)
    setSearch(values.search)
    setData(tableData)
  }

  const component = <CustomTable {...props} refresh={refresh} onChangeRef={onChangeRef} />

  return {
    render: component,
    filters: filters,
    search: search,
    data: data,
    refresh: () => doRefresh((prev) => prev + 1),
  }
}

export { CustomTable, useCustomTable }
