import { Box, Grid, makeStyles, Typography, Link as MuiLink } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import ShowPrice, { formatCost } from 'components/ShowPrice'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getRoutePath } from 'routes'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  img: {
    height: 150,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
  },
  iconCredits: {
    fontSize: 15,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
  infos: {
    display: 'flex',
    position: 'absolute',
    bottom: theme.spacing(1),
  },
}))

function EventCard({ event }) {
  const classes = useStyles()

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box alignItems="center" display="flex">
          <Icon icon="agenda-jour" className={classes.icon} />
          <Typography variant="body1" color="textSecondary">
            <Box component="span" fontWeight="fontWeightBold">
              {moment(event.Date).format('dddd DD MMMM, HH[h]mm')}
            </Box>
          </Typography>
        </Box>
        <Box alignItems="center" display="flex" p={0.5}>
          <Typography variant="body1" color="textSecondary">
            <Box component="span" fontWeight="fontWeightBold">
              <ShowPrice
                ht={formatCost(event.Price.EuroHT)}
                ttc={formatCost(event.Price.EuroTTC)}
                credits={formatCost(event.Price.Credits)}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box mb={2}>
        <Typography variant="h3">
          <Box component="span" color="primary.darker" fontWeight="fontWeightBold">
            {event.EventName}
          </Box>
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="subtitle2" color="textSecondary">
          <Box component="span" dangerouslySetInnerHTML={{ __html: event.EventDescription }} />
        </Typography>
      </Box>
      <Box className={classes.infos}>
        <Box mr={1} display="flex" alignItems="center">
          <Icon icon="maps" color="secondary" />
        </Box>
        <Typography variant="subtitle1">
          <Box component="span" color="primary.darker">
            {event.SpaceName}
          </Box>
        </Typography>
      </Box>
    </>
  )
}

export default EventCard
