import { Box, Divider, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import Icon from 'components/IcoMoon/Icon'
import { formatCost } from 'components/ShowPrice'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  zero: {
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  subdivider: {
    backgroundColor: theme.palette.secondary.light,
  },
  lessOpacity: {
    opacity: 0.5,
  },
  rotation: {
    transform: 'rotate(90deg)',
  },
  catName: {
    [theme.breakpoints.down('xs')]: {
      wordBreak: 'break-word',
    },
  },
}))

const RenderLine = ({ category, oldPrice, actualPrice, percent }) => {
  const classes = useStyles()

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <Grid item xs md={5} className={classes.catName}>
        {category}
      </Grid>
      <Grid item xs md={2} className={classes.lessOpacity}>
        <Box display="flex" justifyContent={isXsDown ? 'flex-start' : 'flex-end'}>
          {oldPrice}
        </Box>
      </Grid>
      <Grid item xs md={3}>
        <Box display="flex" justifyContent={isXsDown ? 'flex-start' : 'flex-end'}>
          {actualPrice}
        </Box>
      </Grid>
      <Grid item xs md={2}>
        <Box ml={2}>
          <Typography variant="subtitle2" color="textSecondary">
            {percent}
          </Typography>
        </Box>
      </Grid>
    </>
  )
}

function ArrayRecap({ datas }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [start, setStart] = React.useState()
  const [end, setEnd] = React.useState()

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  React.useEffect(() => {
    if (datas && datas[0]) {
      setStart(datas[0].startDate)
      setEnd(datas[0].endDate)
    }
  }, [datas])

  return (
    <>
      <RenderLine
        category={
          <Typography variant="subtitle1">
            <Box component="span" color="primary.darker" wordBreak="break-word">
              {t('owner-by-categories.graph.categories')}
            </Box>
          </Typography>
        }
        oldPrice={
          <Typography variant="subtitle1" align="right">
            <Box component="span" color="primary.dark">
              {start}
            </Box>
            <div>
              <Typography
                component="span"
                variant="subtitle2"
                color="textSecondary"
                align={isXsDown ? 'left' : 'right'}
              >
                {t('prices.label.euro.ht')}
              </Typography>
            </div>
          </Typography>
        }
        actualPrice={
          <Typography variant="subtitle1" align={isXsDown ? 'left' : 'right'}>
            <Box component="span" color="primary.dark">
              {end}
            </Box>
            <div>
              <Typography
                component="span"
                variant="subtitle2"
                color="textSecondary"
                align={isXsDown ? 'left' : 'right'}
              >
                {t('prices.label.euro.ht')}
              </Typography>
            </div>
          </Typography>
        }
        percent={'Tendances'}
      />
      <Box width="100%">
        <Divider className={classes.divider} />
      </Box>
      <Box width="100%" p={1}>
        <Grid container spacing={2} alignItems={isXsDown ? 'center' : 'flex-end'}>
          {datas.map((c, index) => {
            let old = c.startValue
            let actual = c.endValue
            let percent = (actual / old - 1) * 100
            if (percent === Infinity) percent = 100

            if (!old && !actual) return
            return (
              <>
                <RenderLine
                  category={
                    <Typography variant="subtitle1">
                      <Box color="primary.darker" component="span">
                        {c.category}
                      </Box>
                    </Typography>
                  }
                  oldPrice={
                    <Typography variant={old ? 'h3' : 'subtitle2'} align={isXsDown ? 'left' : 'right'}>
                      {old ? formatCost(old) : t('owner-by-categories.graph.no.data')}
                    </Typography>
                  }
                  actualPrice={
                    <Typography variant={actual ? 'h3' : 'subtitle2'} align={isXsDown ? 'left' : 'right'}>
                      {actual ? formatCost(actual) : t('owner-by-categories.graph.no.data')}
                    </Typography>
                  }
                  percent={
                    percent !== null ? (
                      <Typography>
                        <Box
                          className={percent > 0 ? classes.success : percent === 0 ? classes.zero : classes.error}
                          display="flex"
                          alignItems="center"
                          component="span"
                        >
                          <Icon
                            className={percent < 0 ? classes.rotation : ''}
                            icon={percent === 0 ? 'idicateur2' : 'idicateur1'}
                          />
                          <Box component="span" ml={1}>
                            {formatCost(percent)}%
                          </Box>
                        </Box>
                      </Typography>
                    ) : (
                      <Typography variant="subtitle2">{t('owner-by-categories.graph.data.missing')}</Typography>
                    )
                  }
                />
                {index + 1 < datas.length && (
                  <Box width="100%">
                    <Divider className={classes.subdivider} />
                  </Box>
                )}
              </>
            )
          })}
        </Grid>
      </Box>
    </>
  )
}

export default ArrayRecap
