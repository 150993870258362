import { alpha, Box, Button, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { getRoutePath } from 'routes'
import CreateChannel from '../views/PublicationsPage/CreateChannel'

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttonGroup: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
    '& *': {
      color: theme.palette.getContrastText(theme.palette.secondary.light),
    },
    borderBottomRightRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  boxChannel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1),
  },
  channel: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha('#000000', 0.04),
    },
  },
}))

const GroupPublication = ({ group, onGroupClick, onChannelClick }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const match = useRouteMatch()

  const [openCreateChannel, setOpenCreateChannel] = React.useState(false)

  const handleClick = (channel) => {
    onChannelClick(group.GroupId, channel.ChannelId)
  }

  const handleGroupClick = () => {
    onGroupClick(group.GroupId)
  }

  return (
    <CustomPaper selectable>
      <Box p={2}>
        <Button
          fullWidth
          className={
            classes.buttonGroup +
            ' ' +
            (match?.params?.group && match?.params?.group === group.GroupId && !match?.params?.channel
              ? classes.selected
              : '')
          }
          onClick={handleGroupClick}
        >
          <Typography variant="h6">
            <Box component="span" color="primary.darker" fontWeight="fontWeightBold">
              {group.GroupName}
            </Box>
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t('publications.channels.count', { count: group.Channels?.length })}
          </Typography>
        </Button>
        <Divider className={classes.divider} />
        <Box mb={1}>
          {group.Channels?.map((c, index) => {
            const hasDivider = index !== group.Channels.length - 1
            return (
              <div
                key={index}
                className={
                  classes.channel +
                  ' ' +
                  (match?.params?.channel && match?.params?.channel === c.ChannelId ? classes.selected : '')
                }
                onClick={() => handleClick(c)}
              >
                <Box className={classes.boxChannel}>
                  <Typography variant="body2">
                    <Box component="span" color="primary.darker">
                      {c.ChannelName}
                    </Box>
                  </Typography>
                  <Icon icon="fleche-droite" />
                </Box>
                {hasDivider && <Divider />}
              </div>
            )
          })}
        </Box>
        <CustomButton fullWidth variant="outlined" color="secondary" onClick={() => setOpenCreateChannel(true)}>
          <Box color="primary.darker">{t('publications.channel.create')}</Box>
        </CustomButton>
      </Box>
      {openCreateChannel && <CreateChannel groupId={group.GroupId} handleClose={() => setOpenCreateChannel(false)} />}
    </CustomPaper>
  )
}

function PublicationsMenu({ data, onMenuClick }) {
  const history = useHistory()

  const handleChannelClick = (groupId, channelId) => {
    history.push(getRoutePath('publications-channel', { ':group': groupId, ':channel': channelId }))
    onMenuClick()
  }

  const handleGroupClick = (groupId) => {
    history.push(getRoutePath('publications-group', { ':group': groupId }))
    onMenuClick()
  }

  if (!data) return <LoadingElem />
  return (
    <Grid container spacing={1}>
      {data &&
        data.map((group, index) => {
          return (
            <Grid item xs={12} key={index}>
              <GroupPublication
                key={index}
                group={group}
                onGroupClick={handleGroupClick}
                onChannelClick={handleChannelClick}
              />
            </Grid>
          )
        })}
    </Grid>
  )
}

export default PublicationsMenu
