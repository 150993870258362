import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Icon from 'components/IcoMoon/Icon'
import { MoneyContext } from 'contexts/MoneyContext'
import 'helper/number'
import React from 'react'
import { useTranslation } from 'react-i18next'
import reactStringReplace from 'react-string-replace'

const useStyles = makeStyles((theme) => ({
  euro: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    verticalAlign: 'middle',
    '& > .MuiSvgIcon-root': {
      fontSize: '.8em',
      display: 'inline',
      verticalAlign: 'middle',
    },
    '& > .MuiBox-root': {
      fontWeight: 'bold',
      fontSize: '0.7em',
      display: 'inline',
      verticalAlign: 'top',
      lineHeight: 1,
    },
  },
  icon: {
    fontSize: '1em',
    marginLeft: '0.2em',
  },
  iconcontainer: {
    display: 'inline-flex',
    alignItems: 'center',
    '& > span': {
      fontSize: 'inherit',
      lineHeight: 1,
    },
  },
  texticon: {
    display: 'inline',
  },
  iconFlex: {
    color: theme.palette.secondary.dark,
  },
}))

const separateThousands = (cost) => {
  return cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/\,00$/, '')
}

export const formatCost = (cost) => {
  if (typeof cost === 'undefined') return ''
  return separateThousands(cost)
}

export const ToShowIcon = ({ toshow, icon, noIcon, classesNames }) => {
  const { t } = useTranslation()

  if (toshow === 0 || toshow === '0') {
    return (
      <span className={classesNames}>
        <Typography variant="h5">{t('common.free')}</Typography>
      </span>
    )
  } else {
    return (
      <>
        <span className={classesNames}>{toshow && formatCost(toshow)}</span>
        {noIcon ?? icon}
      </>
    )
  }
}

export const useCurrentMoneyIcon = () => {
  const money = React.useContext(MoneyContext)
  const allCurrencies = money?.allCurrencies

  switch (money.type) {
    case allCurrencies[0].value:
      return <Icon icon={allCurrencies[0].iconName} />
    case allCurrencies[1].value:
      return <Icon icon={allCurrencies[1].iconName} />
    case allCurrencies[2].value:
    default:
      return <Icon icon={allCurrencies[2].iconName} />
  }
}

function ShowPrice({ flexRender, title, credits, ht, ttc, i18nKey, i18nReplaceKey, className, force, noIcon }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const money = React.useContext(MoneyContext)
  const allCurrencies = money?.allCurrencies

  let type = money.type

  if (force && force === 'ttc') type = allCurrencies[0].value
  if (force && force === 'credits') type = allCurrencies[2].value

  let toshow, icon, iconFlex, label
  switch (type) {
    case allCurrencies[0].value:
      toshow = ttc
      icon = (
        <Box className={classes.euro} component="span">
          <Icon icon={allCurrencies[0].iconName} />
          <Box component="span">TTC</Box>
        </Box>
      )
      iconFlex = (
        <Box display="flex" component="span">
          <Box component="span">
            <Icon icon={allCurrencies[0].iconName} className={classes.iconFlex} fontSize="small" />
          </Box>
          <Box component="span" color="secondary.dark">
            TTC
          </Box>
        </Box>
      )
      label = t('prices.label.euro.ttc')
      break
    case allCurrencies[1].value:
      toshow = ht
      icon = (
        <Box className={classes.euro} component="span">
          <Icon icon={allCurrencies[1].iconName} />
          <Box component="span">HT</Box>
        </Box>
      )
      iconFlex = (
        <Box display="flex" component="span">
          <Box component="span">
            <Icon icon={allCurrencies[1].iconName} className={classes.iconFlex} fontSize="small" />
          </Box>
          <Box component="span" color="secondary.dark">
            HT
          </Box>
        </Box>
      )
      label = t('prices.label.euro.ht')
      break
    case allCurrencies[2].value:
    default:
      toshow = credits
      icon = <Icon icon={allCurrencies[2].iconName} className={classes.icon} />
      iconFlex = <Icon icon={allCurrencies[2].iconName} className={classes.iconFlex} />
      label = t('prices.label.credit')
      break
  }

  if (flexRender) {
    return (
      <>
        <Box component="span" display="flex" justifyContent="space-between" alignItems="center">
          <Typography component="span" variant="subtitle1">
            {title}
          </Typography>
          <Typography variant="h4" component="span">
            <ToShowIcon icon={icon} toshow={toshow} noIcon />
          </Typography>
        </Box>
        <Box component="span" display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" component="span">
            <Box component="span" color="text.secondary">
              {label}
            </Box>
          </Typography>
          <Box component="span" display="flex" color="secondary.main">
            <span>{iconFlex}</span>
          </Box>
        </Box>
      </>
    )
  } else {
    if (i18nKey && i18nReplaceKey) {
      return (
        <Box className={classes.texticon}>
          {reactStringReplace(t(i18nKey, { skipInterpolation: true }), i18nReplaceKey, (match, i) => (
            <Box key={'key_' + i} className={classes.iconcontainer} component="span">
              <ToShowIcon icon={icon} toshow={toshow} classesNames={className} noIcon={flexRender} />
            </Box>
          ))}
        </Box>
      )
    }

    return (
      <Box className={classes.iconcontainer} component="span">
        <ToShowIcon icon={icon} toshow={toshow} classesNames={className} noIcon={flexRender || noIcon} />
      </Box>
    )
  }
}

export default ShowPrice
