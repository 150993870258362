import { Box, CircularProgress, Dialog, Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import Layout from 'components/views/Layout'
import ItemsFilters from 'components/views/NewReservationPage/ItemsFilters'
import ItemsGrid from 'components/views/NewReservationPage/ItemsGrid'
import ItemsScheduler from 'components/views/NewReservationPage/ItemsScheduler'
import SearchBarItems from 'components/views/NewReservationPage/SearchBarItems'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  filters: {
    backgroundColor: 'white',
    borderRadius: 20,
  },
  btnFiltersMobile: {
    minWidth: 42,
    height: 42,
  },
  mobileDialog: {
    '& .MuiCollapse-container, & .MuiCollapse-wrapper': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  fullHeight: {
    height: '100%',
  },
}))

function ItemsPage({ onHomeClick, onItemClick, categoryId, coworkingSpaceId }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const { apiCall } = React.useContext(UserContext)

  const [filters, setFilters] = useState({})
  const [showMobileFilters, setShowMobileFilters] = useState(false)
  const [showType, setShowType] = useState('grid')
  const [allItems, setAllItems] = useState(null)
  const [searchBarValue, setSearchBarValue] = useState(null)
  const [resetFilters, setResetFilters] = useState(false)
  const [loading, setLoading] = useState(false)

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const { data: categoryInfos, error: categoryError } = useSWR(`getCategory/${coworkingSpaceId}/${categoryId}`, () =>
    apiCall(apiActions.getCategory, { coworkingSpaceId, categoryId }).catch((e) => {
      throw Error(e)
    })
  )

  const getFilters = () =>
    apiCall(apiActions.getCategoryFilters, {
      coworkingSpaceId,
      categoryId,
    }).then((r) => {
      if (r.Error) {
        //
      } else {
        return r.data
      }
    })

  const { data: promFilters } = useSWR(`getFilters/${coworkingSpaceId}/${categoryId}`, getFilters)

  React.useEffect(() => {
    localStorage.removeItem('startdateitem')
    localStorage.removeItem('enddateitem')
    localStorage.removeItem('startduration')
    localStorage.removeItem('endduration')
  }, [])

  React.useEffect(() => {
    setLoading(true)
    if (filters && Object.keys(filters).length !== 0) {
      apiCall(apiActions.getAllItems, { coworkingSpaceId, categoryId }, 'post', filters).then((r) => {
        if (r.Error) {
          //
        } else {
          setLoading(false)
          let items = {}
          items.UnvisitedItems = r.UnvisitedItems
          items.VisitedItems = r.VisitedItems
          setAllItems(items)
        }
      })
    }
  }, [filters, coworkingSpaceId, categoryId, setAllItems])

  if (categoryError) onHomeClick()
  if (!categoryInfos)
    return (
      <Layout
        title={t('menu.reservations.new')}
        pageTitle={t('reservations.items.page.title')}
        pageDescription={t('reservations.items.page.subtitle')}
        breadcrumbsInfos={{ categoryName: categoryInfos ? categoryInfos.Name : '...' }}
      >
        <LoadingElem />
      </Layout>
    )

  const handleFiltersChange = (values) => {
    let newFilters = { ...values }
    if (catType === 'rent_all_duration') {
      if (values.startdate && values.enddate) {
        let duration = values.duration
        if (
          !duration &&
          moment(values.startdate).format('DDD MMMM YYYY') === moment(values.enddate).format('DDD MMMM YYYY')
        ) {
          newFilters.startdate = moment(values.startdate).set({ hour: '00', minute: '00', second: '00' }).toDate()
          newFilters.enddate = moment(values.enddate).set({ hour: '23', minute: '59', second: '59' }).toDate()
        }
        if (duration && duration.start && duration.end) {
          newFilters.startdate = moment(values.startdate)
            .set({
              hour: moment(duration.start, 'hh:mm').format('HH'),
              minute: moment(duration.start, 'hh:mm').format('mm'),
            })
            .toDate()
          newFilters.enddate = moment(values.enddate)
            .set({
              hour: moment(duration.end, 'hh:mm').format('HH'),
              minute: moment(duration.end, 'hh:mm').format('mm'),
            })
            .toDate()
        }
      }
    }
    if (catType === 'instant_buy' || catType === 'order') {
      newFilters.queryname = searchBarValue
    }
    setFilters(newFilters)
  }

  const handleRequestSearch = (value) => {
    setSearchBarValue(value === '' ? null : value)

    let newFilters = { ...filters }
    newFilters.queryname = value === '' ? null : value
    setFilters(newFilters)
  }

  const handleItemClickList = (e, id) => {
    if (filters) {
      if (filters.startdate) localStorage.setItem('startdateitem', filters.startdate)

      if (filters.enddate) localStorage.setItem('enddateitem', filters.enddate)

      if (filters.date)
        if (filters.duration && filters.duration.start && filters.duration.end) {
          let startdateitem = moment(filters.date)
            .set({
              hour: moment(filters.duration.start, 'hh:mm:ss').format('HH'),
              minute: moment(filters.duration.start, 'hh:mm:ss').format('mm'),
            })
            .toDate()
          let enddateitem = moment(filters.date)
            .set({
              hour: moment(filters.duration.end, 'hh:mm:ss').format('HH'),
              minute: moment(filters.duration.end, 'hh:mm:ss').format('mm'),
            })
            .toDate()
          localStorage.setItem('startdateitem', startdateitem)
          localStorage.setItem('enddateitem', enddateitem)
          localStorage.setItem('startduration', filters.duration.start)
          localStorage.setItem('endduration', filters.duration.end)
        } else {
          localStorage.setItem('startdateitem', filters.date)
        }
      if (filters.datewithhours) {
        let start = filters.datewithhours.start
        let end = filters.datewithhours.end
        if (filters.datewithhours.start && filters.datewithhours.end) {
          localStorage.setItem('startdateitem', start)
          localStorage.setItem('enddateitem', end)
          localStorage.setItem('startduration', moment(start).format('HH:mm'))
          localStorage.setItem('endduration', moment(end).format('HH:mm'))
        }
      }
    }

    onItemClick(e, id)
  }

  const handleResetFilters = () => {
    setSearchBarValue(null)
    setResetFilters(!resetFilters)
  }

  let catType = ''
  switch (categoryInfos.Type) {
    case 10:
      catType = 'rent'
      break
    case 15:
      catType = 'rent_all_duration'
      break
    case 30:
      catType = 'pack'
      break
    case 40:
      catType = 'instant_buy'
      break
    case 50:
      catType = 'order'
      break
    default:
      break
  }

  let render
  switch (showType) {
    case 'scheduler':
      render = (
        <ItemsScheduler
          filters={filters}
          items={allItems}
          onItemClick={onItemClick}
          categoryId={categoryId}
          coworkingSpaceId={coworkingSpaceId}
        />
      )
      break
    case 'grid':
    default:
      render = (
        <ItemsGrid
          resetFilters={handleResetFilters}
          catType={catType}
          visited={allItems && allItems.VisitedItems}
          unvisited={allItems && allItems.UnvisitedItems}
          onItemClick={handleItemClickList}
          categoryId={categoryId}
          coworkingSpaceId={coworkingSpaceId}
        />
      )
      break
  }

  const itemsFilter = promFilters && (
    <ItemsFilters
      csId={coworkingSpaceId}
      categoryId={categoryId}
      resetFilters={resetFilters}
      dialogIsOpen={!isLgUp && showMobileFilters}
      onFiltersChange={handleFiltersChange}
      promFilters={promFilters}
      onExitDialog={() => setShowMobileFilters(false)}
    />
  )

  return (
    <Layout
      title={t('menu.reservations.new')}
      pageTitle={t('reservations.items.page.title')}
      pageDescription={t('reservations.items.page.subtitle')}
      breadcrumbsInfos={{ categoryName: categoryInfos.Name }}
    >
      <Box>
        <Grid container spacing={4}>
          {promFilters && (
            <Hidden mdDown>
              <Grid item xs={12} lg={3}>
                {(catType === 'instant_buy' || catType === 'order') && (
                  <Box mb={2}>
                    <SearchBarItems reset={resetFilters} handleRequestSearch={handleRequestSearch} />
                  </Box>
                )}
                <CustomPaper>{itemsFilter}</CustomPaper>
              </Grid>
            </Hidden>
          )}
          <Grid item xs={12} lg>
            <Box display="flex" flexDirection="column">
              <div>
                <Grid container spacing={1} alignItems="stretch">
                  {promFilters && (
                    <Hidden lgUp>
                      <Grid item xs="auto">
                        <Box mb={1} display="flex" alignItems="center">
                          <CustomButton
                            className={classes.btnFiltersMobile}
                            variant="outlined"
                            size="small"
                            color="secondary"
                            onClick={() => setShowMobileFilters(true)}
                          >
                            <Icon icon="filtres" />
                          </CustomButton>
                          <Box ml={1}>
                            <Typography variant="subtitle1">
                              <Box component="span" color="primary.darker">
                                {t('grid.filters.text')}
                              </Box>
                            </Typography>
                          </Box>
                          <Dialog
                            keepMounted
                            fullScreen={isXsDown}
                            open={showMobileFilters}
                            className={classes.mobileDialog}
                            onClose={() => setShowMobileFilters(false)}
                          >
                            {itemsFilter}
                          </Dialog>
                        </Box>
                      </Grid>
                    </Hidden>
                  )}
                  {catType === 'rent' && (
                    <>
                      <Grid item xs sm="auto">
                        <Box mb={1}>
                          <CustomButton
                            onClick={() => setShowType('grid')}
                            className={showType === 'grid' ? ' selected' : ''}
                            startIcon={<Icon icon="encart" />}
                            tag
                            iconSize="small"
                            fullWidth
                          >
                            {t('reservations.items.see.items')}
                          </CustomButton>
                        </Box>
                      </Grid>
                      <Grid item xs sm="auto">
                        <CustomButton
                          onClick={() => setShowType('scheduler')}
                          className={showType === 'scheduler' ? ' selected' : ''}
                          startIcon={<Icon icon="agenda" />}
                          tag
                          fullWidth
                        >
                          {t('reservations.items.see.diary')}
                        </CustomButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
              <Box flex="1">
                {loading ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                  </Box>
                ) : (
                  render
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default ItemsPage
