import { Avatar, Backdrop, Box, Button, CircularProgress, Hidden } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import CreditPackage from 'area/home/components/CreditPackage'
import DocumentsToValidate from 'area/user/components/DocumentsToValidate'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import { useTransformUrlForMedia } from 'components/CustomComponents/MediaImage'
import Icon from 'components/IcoMoon/Icon'
import { formatCost } from 'components/ShowPrice'
// import BackCustomer from 'components/views/BackCustomer'
import BackToV1 from 'components/views/BackToV1'
import { DEFAULT_IMG } from 'config'
import { CoworkingSpaceContext } from 'contexts/CoworkingSpaceContext'
import { MoneyContext } from 'contexts/MoneyContext'
import { SocietyContext } from 'contexts/SocietyContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'
import { logoutUser } from '_actions/auth_actions'

const avatarWidth = 80
const userDrawerStyle = makeStyles((theme) => {
  const bgColor = theme.palette.primary.darker
  const textColor = theme.palette.getContrastText(bgColor)
  return {
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff',
    },
    userdrawerPaper: {
      backgroundColor: bgColor,
      color: textColor,
      padding: theme.spacing(2),
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      paddingTop: theme.spacing(4),
      maxWidth: 250,
      [theme.breakpoints.up('sm')]: {
        maxWidth: 380,
      },
    },
    grey: {
      color: theme.palette.grey[500],
    },
    drawerAvatar: {
      width: avatarWidth,
      height: avatarWidth,
      borderRadius: theme.shape.borderRadius * 2,
    },
    menu: {
      '& .MuiListItem-root': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        '& .MuiListItemIcon-root': {
          minWidth: theme.spacing(4),
          color: textColor,
          '& > .MuiSvgIcon-root': {
            fontSize: theme.spacing(2.5),
            padding: 1,
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: theme.spacing(0.5),
            borderColor: alpha(textColor, 0.25),
          },
        },
        '&.Mui-selected': {
          '& .MuiListItemIcon-root': {
            color: bgColor,
            '& > .MuiSvgIcon-root': {
              borderColor: textColor,
              backgroundColor: textColor,
            },
          },
        },
      },
    },
    foci: {
      display: 'inline-block',
      margin: theme.spacing(0.25),
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      borderWidth: 2,
      borderStyle: 'solid',
      borderRadius: theme.shape.borderRadius,
      borderColor: alpha(textColor, 0.25),

      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    // backCustomer: {
    //   borderRadius: theme.shape.borderRadius + 'px !important',
    //   '& .MuiPaper-root': {
    //     border: '1px solid ' + theme.palette.primary.main,
    //     color: theme.palette.primary.darker,
    //     '& .MuiIconButton-root': {
    //       backgroundColor: 'white',
    //       color: theme.palette.primary.darker,
    //     },
    //   },
    // },
  }
})

const UserDrawer = ({ open, onClose, user }) => {
  const [showLogoutBackdrop, setShowLogoutBackdrop] = React.useState(false)
  const [openBuyCredits, setOpenBuyCredits] = React.useState(false)
  const history = useHistory()
  const classes = userDrawerStyle()
  const { t } = useTranslation()
  const userName = user.FirstName + ' ' + user.LastName
  const basePathName = history.location.pathname.split('/')[1]
  const { society } = React.useContext(SocietyContext)
  const { coworkingSpace } = React.useContext(CoworkingSpaceContext)
  const money = useContext(MoneyContext)
  const allCurrencies = money?.allCurrencies

  const showBackToV1 = coworkingSpace && coworkingSpace.BackToV1

  const logoutHandler = () => {
    setShowLogoutBackdrop(true)
    setTimeout(function () {
      logoutUser().then((response) => {
        if (response.success) {
          window.localStorage.setItem('showpricetype', 'ht')
          history.go(0)
        } else {
          setShowLogoutBackdrop(false)
          console.error('Log Out Failed')
        }
      })
    }, 250)
  }

  const menu = [
    {
      category: t('user.drawer.parameters.title'),
      submenus: [
        {
          icon: <Icon icon="mon-profil" />,
          url: getRoutePath('profile'),
          text: t('user.drawer.parameters.profil'),
          v2: true,
        },
        // {
        //   icon: <Icon icon="notif" />,
        //   url: '/Notification', //getRoutePath("notifications"),
        //   text: t('user.drawer.parameters.notifications'),
        //   v2: false,
        // },
        // {
        //   icon: <Icon icon="historique" />,
        //   url: '/Profile', //getRoutePath("history"),
        //   text: t('user.drawer.parameters.history'),
        //   v2: false,
        // },
        // {
        //   icon: <Icon icon={money.iconMoney} />,
        //   url: getRoutePath('my-invoices'),
        //   text: t('user.drawer.parameters.bills'),
        //   v2: true,
        // },
        // {
        //     icon : <Icon icon="aide" />,
        //     url: getRoutePath("help"),
        //     text : t("user.drawer.parameters.help")
        // }
      ],
    },
  ]

  const userImageURL = useTransformUrlForMedia(user.Image?.Url ?? DEFAULT_IMG, {
    xs: {
      width: avatarWidth,
      height: avatarWidth,
      cx: user.Image?.CropX,
      cy: user.Image?.CropY,
      cWidth: user.Image?.CropWidth,
      cHeight: user.Image?.CropHeight,
      retina: true,
      mode: 'crop',
    },
  })

  const handleLinkClick = (e, url, isV2) => {
    e.preventDefault()
    if (isV2) history.push(url)
    else window.location = url
  }

  const handleBuyCredits = () => {
    onClose()
    setOpenBuyCredits(true)
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        elevation={3}
        disablePortal
        classes={{
          paper: classes.userdrawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
          // BackdropProps: {
          //   invisible: true
          // }
        }}
      >
        <div>
          <Box display="flex" justifyContent="center" mb={1}>
            <Avatar className={classes.drawerAvatar} alt={userName} src={userImageURL}>
              {userName[0]}
            </Avatar>
          </Box>
          <Box textAlign="center">
            <Typography variant="h4">{userName}</Typography>
            <Box mb={2}>
              <Button
                size="small"
                startIcon={<Icon icon="deconnexion" />}
                onClick={logoutHandler}
                color="inherit"
                className={classes.grey}
              >
                {t('avatar.menu.logout')}
              </Button>
            </Box>
            {society.Credits && society.Payments.Credits && (
              <>
                <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
                  <Typography variant="h3">
                    <Box component="span" display="flex" alignItems="center">
                      <span>{formatCost(user.Credits)}</span>
                      &nbsp;
                      <Icon
                        fontSize="inherit"
                        icon={allCurrencies && allCurrencies.filter((c) => c.value === 'credits')[0].iconName}
                      />
                      &nbsp;
                    </Box>
                  </Typography>
                  <Box ml={1}>
                    <Typography variant="subtitle1" className={classes.grey}>
                      {t('user.drawer.money.available')}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="stretch" mb={4} textAlign="center">
                  <Button onClick={handleBuyCredits} fullWidth variant="contained" color="secondary" size="large">
                    {t('user.drawer.credit.account')}
                  </Button>
                </Box>
                <CustomDialog
                  title={t('buy.credits.title')}
                  maxWidth="lg"
                  fullWidth
                  open={openBuyCredits}
                  handleClose={() => setOpenBuyCredits(false)}
                >
                  <CreditPackage />
                </CustomDialog>
              </>
            )}
          </Box>

          {(user.Job ||
            user.Society ||
            user.JobDescription ||
            user.Description ||
            (user.Foci && user.Foci.length > 0)) && (
            <Box mb={4}>
              <Box mb={1}>
                <Typography variant="overline" className={classes.grey}>
                  {t('user.drawer.infos.title')}
                </Typography>
              </Box>
              <Typography variant="subtitle2" component="div">
                <div>
                  {user.Job && <Box component="span">{user.Job}</Box>}
                  {user.Job && user.Society && (
                    <Box component="span" mx={0.5}>
                      {t('user.drawer.job.society.at')}
                    </Box>
                  )}
                  {user.Society && <Box component="span">{user.Society}</Box>}
                </div>
                {user.JobDescription && <Box dangerouslySetInnerHTML={{ __html: user.JobDescription }}></Box>}
                {user.Description && <Box mt={1} dangerouslySetInnerHTML={{ __html: user.Description }}></Box>}
                {user.Foci && user.Foci.length > 0 && (
                  <Box mt={1}>
                    {user.Foci.map((f, i) => (
                      <Box key={'foci_' + i} className={classes.foci}>
                        {f}
                      </Box>
                    ))}
                  </Box>
                )}
              </Typography>
            </Box>
          )}
          <Box>
            <Box className={classes.menu}>
              {menu.map((item) => (
                <Box key={'menukey_' + item.category}>
                  <Typography variant="overline" className={classes.grey}>
                    {item.category}
                  </Typography>
                  <List>
                    {user &&
                      item.submenus.map((menu) => {
                        const selected = menu.url && basePathName === menu.url.split('/')[1]
                        return (
                          <ListItem
                            component="a"
                            href={menu.url}
                            dense
                            selected={selected}
                            button
                            onClick={(e) => handleLinkClick(e, menu.url, menu.v2)}
                            key={'menutext_' + menu.text}
                          >
                            <ListItemIcon>{menu.icon}</ListItemIcon>
                            <ListItemText>{menu.text}</ListItemText>
                          </ListItem>
                        )
                      })}
                    {user.IsAdmin && (
                      <ListItem
                        component="a"
                        href={'/Admin'}
                        dense
                        button
                        onClick={(e) => {
                          e.preventDefault()
                          window.location.href = '/Admin'
                        }}
                        key={'menutext_' + t('user.drawer.admin')}
                      >
                        <ListItemIcon>
                          <Icon icon="admin" />
                        </ListItemIcon>
                        <ListItemText>{t('user.drawer.admin')}</ListItemText>
                      </ListItem>
                    )}
                    {user.HasSociety && (
                      <ListItem
                        component="a"
                        href={'/Society'}
                        dense
                        button
                        onClick={(e) => {
                          e.preventDefault()
                          window.location.href = '/Society'
                        }}
                        key={'menutext_' + t('user.drawer.society')}
                      >
                        <ListItemIcon>
                          <Icon icon="admin" />
                        </ListItemIcon>
                        <ListItemText>{t('user.drawer.society')}</ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Box>
              ))}
            </Box>
          </Box>
          {/* {showBackToV1 && (
            <Hidden mdUp>
              {/* <Box mb={2}>
                <BackCustomer propClasses={classes.backCustomer} />
              </Box> */}
          {/* <Box>
                <BackToV1 propClasses={classes.backCustomer} />
              </Box>
            </Hidden> */}
          {/* )}} */}
        </div>
      </Drawer>

      <Backdrop className={classes.backdrop} open={showLogoutBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default UserDrawer
