import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Grid,
  lighten,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import SeparatorBox from 'components/CustomComponents/SeparatorBox'
import CustomChoiceButton from 'components/CustomComponents/CustomChoiceButton'
import Icon from 'components/IcoMoon/Icon'
import SquareButton from 'components/SquareButton'
import Layout from 'components/views/Layout'
import { DEFAULT_IMG } from 'config'
import React, { useCallback, useContext } from 'react'
import CustomStepper from 'components/CustomComponents/CustomStepper'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import RenderField from 'components/views/PaymentPage/RenderField'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'
import { InfoItem } from 'components/views/NewReservationPage/ItemPage'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'
import { UserContext } from 'contexts/UserContext'
import LoadingElem from 'components/LoadingElem'
import DynamicForm from 'components/DynamicForm/DynamicForm'
import { Alert } from '@material-ui/lab'
import CoworkingSpaceInfos from 'components/views/NewReservationPage/CoworkingSpaceInfos'
import ShowPrice, { formatCost } from 'components/ShowPrice'
import CardCart from 'components/views/UserCartPage/CardCart'
import { SubLine as Sl } from 'components/views/UserCartPage/RenderCards'
import { MoneyContext } from 'contexts/MoneyContext'
import TransformConfirmation from 'area/crm/views/TransformProspectPage/TransformConfirmation'
import moment from 'moment'
import CustomLabel from 'components/CustomLabel'
import CustomErrorMessage from 'components/CustomComponents/CustomErrorMessage'
import Uploady, { UploadyContext, useItemFinishListener } from '@rpldy/uploady'
import UploadDropZone from '@rpldy/upload-drop-zone'
import ImageIcon from '@material-ui/icons/Image'
import CustomAutocomplete from 'components/CustomComponents/CustomAutocomplete'
import { inspect } from 'util'

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
  },
  captiontext: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    fontFamily: theme.typography.subtitle2.fontFamily,
    color: theme.palette.text.secondary,
  },
  container: {
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  blurred: {
    opacity: 0.5,
  },
  boxSuccess: {
    width: '100%',
    backgroundColor: lighten(theme.palette.success.light, 0.5),
    padding: theme.spacing(2),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  boxFailure: {
    width: '100%',
    backgroundColor: lighten(theme.palette.error.light, 0.5),
    padding: theme.spacing(2),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  imageIcon: {
    width: '100%',
    height: '100%',
    border: '1px dashed ' + theme.palette.grey['400'],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey['400'],
  },
  imgError: {
    borderColor: theme.palette.error.main,
  },
  errorField: {
    '& > label': {
      color: theme.palette.error.main,
    },
    '& .MuiInputBase-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
      },
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      margin: 0,
    },
    '& > p': {
      color: theme.palette.error.main,
      marginLeft: 'inherit',
    },
  },
  errorTitle: {
    color: theme.palette.error.main,
  },
  birthdate: {
    '& input': {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.subtitle2.fontWeight,
    },
  },
}))

const AccountForm = ({ methods }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { apiCall, user } = useContext(UserContext)

  const { data: cwSpaces } = useSWR('coworkingSpaces/', () =>
    apiCall(apiActions.getCoworkingSpaces).then((r) => r.data)
  )

  React.useEffect(() => {
    if (cwSpaces) {
      methods.setValue('CoworkingSpaceId', user?.CoworkingSpaceId)
    }
  }, [cwSpaces])

  if (!cwSpaces) return <LoadingElem />

  return (
    <>
      <Box mb={2}>
        <SeparatorBox text={t('prospect.transform.account.global')} />
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              control={methods.control}
              name="Civility"
              render={({ field }) => {
                return (
                  <CustomChoiceButton
                    value={field.value}
                    label={t('prospect.transform.account.form.civility.title') + '*'}
                    color="primary"
                    variant="outlined"
                    buttonNames={['F', 'M']}
                    buttonLabels={[
                      t('prospect.transform.account.form.civility.miss'),
                      t('prospect.transform.account.form.civility.mister'),
                    ]}
                    onClickButton={(name) => methods.setValue(field.name, name[0])}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={methods.control}
              name="Sexe"
              render={({ field }) => {
                return (
                  <CustomChoiceButton
                    value={field.value}
                    label={t('prospect.transform.account.form.sex.title') + '*'}
                    color="primary"
                    variant="outlined"
                    buttonNames={['F', 'M']}
                    buttonLabels={[
                      t('prospect.transform.account.form.sex.female'),
                      t('prospect.transform.account.form.sex.male'),
                    ]}
                    onClickButton={(name) => methods.setValue(field.name, name[0])}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <RenderField
              methods={methods}
              name="FirstName"
              fullWidth
              label={t('prospect.transform.account.form.firstname.title') + '*'}
            />
          </Grid>
          <Grid item xs={6}>
            <RenderField
              methods={methods}
              name="LastName"
              fullWidth
              label={t('prospect.transform.account.form.lastname.title') + '*'}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomLabel>{t('prospect.transform.account.form.birthdate.title') + '*'}</CustomLabel>
            <Controller
              control={methods.control}
              name="Birthdate"
              render={({ field }) => {
                return (
                  <KeyboardDatePicker
                    classes={{ root: classes.birthdate }}
                    className={methods.formState?.errors?.Birthdate ? classes.errorField : ''}
                    value={field.value ?? null}
                    variant="inline"
                    autoOk
                    inputVariant="outlined"
                    views={['year', 'month', 'date']}
                    openTo="year"
                    fullWidth
                    color="secondary"
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      methods.setValue(field.name, value)
                    }}
                    helperText={methods.formState?.errors?.Birthdate?.message ?? ''}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <SeparatorBox text={t('prospect.transform.account.coworkingspace')} />
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomLabel>{t('prospect.transform.account.form.coworkingspace.title') + '*'}</CustomLabel>
            <Controller
              name="CoworkingSpaceId"
              control={methods.control}
              render={({ field }) => (
                <Select
                  fullWidth
                  variant="outlined"
                  inputRef={field.ref}
                  onChange={(e) => field.onChange(e)}
                  defaultValue={user?.CoworkingSpaceId ?? cwSpaces[0]}
                >
                  {cwSpaces &&
                    Object.entries(cwSpaces).map(([index, value]) => {
                      return (
                        <MenuItem key={index} value={value.Id}>
                          {value.Name}
                        </MenuItem>
                      )
                    })}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <RenderField
              methods={methods}
              name="SwipeCardId"
              placeholder={t('prospect.transform.account.form.swipecard.placeholder')}
              label={t('prospect.transform.account.form.swipecard.title') + '*'}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <SeparatorBox text={t('prospect.transform.account.personal')} />
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RenderField
              methods={methods}
              name="Email"
              fullWidth
              label={t('prospect.transform.account.form.mail.title') + '*'}
            />
          </Grid>
          <Grid item xs={6}>
            <RenderField
              methods={methods}
              name="MobilePhone"
              label={t('prospect.transform.account.form.phone.title') + '*'}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RenderField
                  methods={methods}
                  name="Address"
                  label={t('prospect.transform.account.form.address.title') + '*'}
                  placeholder={t('prospect.transform.account.form.address.placeholder')}
                />
              </Grid>
              <Grid item xs={6}>
                <RenderField
                  methods={methods}
                  name="Zipcode"
                  placeholder={t('prospect.transform.account.form.zipcode.placeholder')}
                />
              </Grid>
              <Grid item xs={6}>
                <RenderField
                  methods={methods}
                  name="City"
                  placeholder={t('prospect.transform.account.form.city.placeholder')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const InvoiceForm = ({ methods }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { apiCall } = useContext(UserContext)

  const { data: societies } = useSWR(`societies`, () =>
    apiCall(apiActions.getAllSocietiesByFTELSocietyId).then((r) => r)
  )

  const [isSociety, setIsSociety] = React.useState(methods.getValues().IsSociety)
  const [nameValue, setNameValue] = React.useState(methods.getValues().SocietyName)
  const [siret, setSiret] = React.useState(methods.getValues().SocietySiret)
  const [status, setStatus] = React.useState(methods.getValues().SocietyStatus)
  const [tva, setTva] = React.useState(methods.getValues().SocietyTVA)

  const nationalIdTypes = ['idcard', 'passport', 'license']

  React.useEffect(() => {
    methods.setValue('SocietyName', nameValue ?? null)
    if (nameValue) {
      const societyExists = societies?.find((s) => s.Name === nameValue)
      if (societyExists) {
        methods.setValue('SocietyId', societyExists.Id)
        methods.setValue('SocietyStatus', societyExists.Statut)
        methods.setValue('SocietySiret', societyExists.Siret)
        methods.setValue('SocietyTVA', societyExists.TVA)
        setStatus(societyExists.Statut)
        setSiret(societyExists.Siret)
        setTva(societyExists.TVA)
      } else {
        methods.setValue('SocietyId', null)
      }
    } else {
      methods.setValue('SocietyId', null)
      methods.setValue('SocietyStatus', null)
      methods.setValue('SocietySiret', null)
      methods.setValue('SocietyTVA', null)
      setStatus(null)
      setSiret(null)
      setTva(null)
    }
  }, [nameValue])

  const handleSelectSociety = (v, r) => {
    if (r === 'select-option') return
    setNameValue(v)
  }

  const handleChangeValue = (name, v) => {
    methods.setValue(name, v)
  }

  return (
    <div>
      <Box mb={2}>
        <Controller
          control={methods.control}
          name="AccountType"
          render={({ field }) => {
            return (
              <CustomChoiceButton
                value={field.value ?? isSociety}
                label={t('prospect.transform.invoice.form.type.title')}
                color="primary"
                variant="outlined"
                buttonNames={['Private', 'Society']}
                buttonLabels={[
                  t('prospect.transform.invoice.form.type.private'),
                  t('prospect.transform.invoice.form.type.society'),
                ]}
                onClickButton={(name) => {
                  setIsSociety(name[0] === 'Society')
                  methods.setValue('IsSociety', name[0] === 'Society')
                  methods.setValue('AccountType', name[0])
                }}
              />
            )
          }}
        />
      </Box>
      {isSociety && (
        <>
          <Box mb={2}>
            <SeparatorBox text={t('prospect.transform.invoice.type.title')} />
          </Box>
          <Box mb={2}>
            <Grid container spacing={2}>
              {societies && (
                <Grid item xs={6}>
                  <Box mt={0.5}>
                    <CustomLabel className={methods.formState?.errors?.SocietyName ? classes.errorTitle : ''}>
                      {t('prospect.transform.invoice.form.societyname.title') + '*'}
                    </CustomLabel>
                    <CustomAutocomplete
                      className={methods.formState?.errors?.SocietyName ? classes.errorField : ''}
                      TextFieldProps={{
                        helperText: methods.formState?.errors?.SocietyName
                          ? methods.formState.errors['SocietyName']?.message
                          : '',
                      }}
                      options={societies.filter((s) => s.Name)}
                      loading={!societies}
                      optionLabel="Name"
                      freeSolo
                      autoSelect
                      selectOnFocus
                      onChange={(e, v, r) => handleSelectSociety(v, r)}
                      value={nameValue}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={6}>
                <RenderField
                  methods={methods}
                  name="SocietyStatus"
                  value={status ?? ''}
                  onChange={(e) => {
                    setStatus(e.target.value)
                    handleChangeValue('SocietyStatus', e.target.value === '' ? null : e.target.value)
                  }}
                  placeholder={t('prospect.transform.invoice.form.societystatus.placeholder')}
                  label={t('prospect.transform.invoice.form.societystatus.title') + '*'}
                />
              </Grid>
              <Grid item xs={6}>
                <RenderField
                  methods={methods}
                  name="SocietySiret"
                  value={siret ?? ''}
                  onChange={(e) => {
                    setSiret(e.target.value)
                    handleChangeValue('SocietySiret', e.target.value === '' ? null : e.target.value)
                  }}
                  placeholder={t('prospect.transform.invoice.form.societysiret.placeholder')}
                  label={t('prospect.transform.invoice.form.societysiret.title') + '*'}
                />
              </Grid>
              <Grid item xs={6}>
                <RenderField
                  methods={methods}
                  name="SocietyTVA"
                  value={tva ?? ''}
                  onChange={(e) => {
                    setTva(e.target.value)
                    handleChangeValue('SocietyTVA', e.target.value === '' ? null : e.target.value)
                  }}
                  placeholder={t('prospect.transform.invoice.form.societytva.placeholder')}
                  label={t('prospect.transform.invoice.form.societytva.title')}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {!isSociety && (
        <Box mb={2}>
          <Box mb={2}>
            <SeparatorBox text={t('prospect.transform.invoice.id.title')} />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box mt={0.5}>
                <CustomLabel>{t('prospect.transform.account.form.nationalid.title')}</CustomLabel>
                <Controller
                  name="IdDocumentType"
                  control={methods.control}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      variant="outlined"
                      inputRef={field.ref}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                      }}
                      value={field.value}
                    >
                      {nationalIdTypes.map((value, index) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {t('prospect.transform.account.form.nationalidtypes.' + value)}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderField
                label={t('prospect.transform.invoice.form.nationalid.label') + '*'}
                methods={methods}
                name="IdDocument"
                placeholder={t('prospect.transform.invoice.form.nationalid.label')}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box mb={2}>
        <SeparatorBox text={t('prospect.transform.invoice.infos.title')} />
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RenderField
              methods={methods}
              name="SocietyBillingEmail"
              label={t('prospect.transform.invoice.form.societyemail.title') + '*'}
            />
          </Grid>
          <Grid item xs={6}>
            <RenderField
              methods={methods}
              name="SocietyPhoneNumber"
              label={t('prospect.transform.invoice.form.societyphone.title') + '*'}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RenderField
                  methods={methods}
                  name="SocietyAddress"
                  label={t('prospect.transform.invoice.form.societyaddress.title') + '*'}
                  placeholder={t('prospect.transform.invoice.form.societyaddress.placeholder')}
                />
              </Grid>
              <Grid item xs={6}>
                <RenderField
                  methods={methods}
                  name="SocietyZipcode"
                  placeholder={t('prospect.transform.invoice.form.societyzipcode.placeholder')}
                />
              </Grid>
              <Grid item xs={6}>
                <RenderField
                  methods={methods}
                  name="SocietyCity"
                  placeholder={t('prospect.transform.invoice.form.societycity.placeholder')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

const UploadyComponent = ({ coworker, methods }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const uploady = useContext(UploadyContext)
  const [imgUrl, setImgUrl] = React.useState(methods.getValues().PictureProfileUrl ?? null)

  const onClick = useCallback(() => {
    uploady.showFileUpload()
  }, [uploady])

  useItemFinishListener((item) => {
    let url = item.uploadResponse.data
    setImgUrl(url)
    methods.setValue('PictureProfileUrl', url)
  })

  const deleteImage = () => {
    setImgUrl(null)
    methods.setValue('PictureProfileUrl', null)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Box mb={1} height="100%">
          {imgUrl ? (
            <img src={imgUrl} className={classes.img} alt="" width="100%" height="100%" />
          ) : (
            <ImageIcon
              className={
                classes.imageIcon + ' ' + (methods.formState?.errors?.PictureProfileUrl ? classes.imgError : '')
              }
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box mb={1}>
          <SquareButton size="small" color="secondary" onClick={onClick}>
            <Icon icon="message" />
          </SquareButton>
        </Box>
        <SquareButton size="small" onClick={() => deleteImage()} color="secondary">
          <Icon icon="supprimer" />
        </SquareButton>
      </Grid>
      {methods.formState?.errors?.PictureProfileUrl && (
        <Grid item xs={12}>
          <CustomErrorMessage message={t('common.field.required')} />
        </Grid>
      )}
      <Grid item xs>
        <Typography className={classes.captiontext}>{t('prospect.transform.profilecard.text.title')}</Typography>
        <br />
        <Typography className={classes.captiontext}>{t('prospect.transform.profilecard.formats')}</Typography>
        <br />
        <Typography className={classes.captiontext}>{t('prospect.transform.profilecard.maximum')}</Typography>
      </Grid>
    </Grid>
  )
}

function ProfileCard({ coworker, methods }) {
  const { t } = useTranslation()
  const isSociety = methods.getValues().IsSociety
  let uploadUrl = 'https://localhost/media/media/save'

  if (process.env.NODE_ENV && process.env.NODE_ENV === 'production')
    uploadUrl = 'https://media.fteledition.fr/media/save'

  return (
    <div>
      <Box mb={2}>
        <Typography align="center" variant="h3">
          <Box color="primary.darker" component="span">
            {t('prospect.transform.profilecard.title')}
          </Box>
        </Typography>
      </Box>
      <Box mb={2}>
        <Uploady destination={{ url: uploadUrl }}>
          <UploadDropZone>
            <UploadyComponent coworker={coworker} methods={methods} />
          </UploadDropZone>
        </Uploady>
      </Box>
      <RenderField
        methods={methods}
        name="Job"
        label={t('prospect.transform.profilecard.form.job.title') + '*'}
        placeholder={t('prospect.transform.profilecard.form.job.placeholder')}
      />
      {(isSociety || !coworker) && (
        <Box mt={2}>
          <RenderField
            methods={methods}
            name="Brand"
            label={t('prospect.transform.profilecard.form.brand.title')}
            placeholder={t('prospect.transform.profilecard.form.brand.placeholder')}
          />
        </Box>
      )}
    </div>
  )
}

const Total = ({ methods, coworkingSpaceId, categoryId, itemId, fields, changeFieldsErrors, onTotalChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { apiCall } = React.useContext(UserContext)

  const [total, setTotal] = React.useState(null)
  const [showError, setShowError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [isClosed, setIsClosed] = React.useState(false)
  const [fieldsErrors, setFieldsErrors] = React.useState(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  React.useEffect(() => {
    setTotal(null)
    setLoading(true)
    const timeout = setTimeout(() => {
      apiCall(
        apiActions.getTotalPrice,
        {
          CoworkingSpaceId: coworkingSpaceId,
          CategoryId: categoryId,
          ItemId: itemId,
          SkipVisibleFilter: true,
        },
        'post',
        { ...fields }
      ).then((r) => {
        setTotal(r.Total)
        if (r.Error || r.Message) {
          setShowError(r.Message)
        } else if (JSON.stringify(r.Fields) !== JSON.stringify(fieldsErrors)) {
          // setFieldsErrors(r.Fields)
          if (r.Fields) {
            setFieldsErrors(r.Fields)
            setShowError('')
          } else {
            setShowError(null)
            setFieldsErrors(null)
          }
        } else {
          setShowError(null)
        }
        setIsClosed(r.isClosed)
        onTotalChange(r.Total !== null && !r.Error && !r.Message && !r.Fields)
        setLoading(false)
      })
    }, 250)

    return () => {
      clearTimeout(timeout)
    }
  }, [fields])

  React.useEffect(() => {
    if (showError !== null) {
      fieldsErrors &&
        fieldsErrors.map((f) => {
          methods.setError(f.Name, { type: 'string', message: t(f.Message) })
        })
    } else {
      methods.clearErrors()
    }
    changeFieldsErrors(showError)
  }, [showError])

  return (
    <Box position="relative" className={loading ? classes.blurred : ''}>
      {showError && (
        <>
          <Box mt={1}>
            <Alert severity="error">{showError.includes(':') ? showError : t(showError)}</Alert>
          </Box>
          {isClosed && (
            <>
              <Button variant="text" onClick={() => setDialogOpen(true)}>
                <Typography variant="caption" color="primary">
                  {t('reservations.item.see.more')}
                </Typography>
              </Button>
              <Dialog open={dialogOpen} fullWidth maxWidth="lg">
                <CoworkingSpaceInfos id={coworkingSpaceId} onCloseDialog={() => setDialogOpen(false)} />
              </Dialog>
            </>
          )}
        </>
      )}
      <Box mt={2} mb={2} bgcolor="secondary.main">
        <Divider />
      </Box>
      <Typography variant="h5">
        <Box color="primary.darker" fontWeight="fontWeightBold" component="span">
          <ShowPrice
            flexRender
            force="credits"
            title={t('reservations.details.total')}
            credits={total ? total.Credits : ''}
            ttc={total ? total.EuroTTC : ''}
            ht={total ? total.EuroHT : ''}
          />
        </Box>
      </Typography>
      {loading && (
        <Box className={classes.loading}>
          <Box display="flex" height="100%" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        </Box>
      )}
    </Box>
  )
}

const ReservationsForm = ({ coworkingSpaceId, categoryId, itemId, fields, currentStep, totalStep, nextStep }) => {
  const { t } = useTranslation()
  const [fieldsErrors, setFieldsErrors] = React.useState(null)
  const [canNext, setCanNext] = React.useState(false)

  const methods = useForm({ mode: 'onChange' })
  const watchFields = methods.watch()

  const onFormSubmit = (values, error) => {
    if (!error) {
      let canSend = true
      const allFieldsNames = fields.map((f) => f.Name)
      allFieldsNames.push('startenddate')
      if (!values) canSend = false
      else {
        Object.entries(values).map(([key, value]) => {
          if (allFieldsNames.indexOf(key) > -1) {
            if (!value || (value && Array.isArray(value) && value.length === 0) || value === '') {
              canSend = false
              methods.setError(key, { type: 'string', message: t('errors.message.required') })
            } else {
              methods.clearErrors(key)
            }
          }
        })
      }
      if (canSend) {
        nextStep(values)
      }
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    if (!fieldsErrors) {
      methods.clearErrors()
    }
    methods.handleSubmit(onFormSubmit)()
  }

  const onFieldsErrorsChange = (errors) => {
    setFieldsErrors(errors)
  }

  return (
    <>
      <CustomPaper>
        <Box p={3}>
          <Box display="flex" justifyContent="space-between">
            <div>
              <Typography variant="h2">
                <Box color="primary.darker" component="span">
                  {t('prospect.transform.book.title')}
                </Box>
              </Typography>
            </div>
          </Box>
          <Typography variant="h2" color="secondary">
            <Box component="span" color="secondary.dark">
              {`${currentStep}/${totalStep}`}
            </Box>
          </Typography>
          <form>
            <Box mt={3}>
              {fields && (
                <DynamicForm
                  csId={coworkingSpaceId}
                  categoryId={categoryId}
                  itemId={itemId}
                  methods={methods}
                  inputs={fields}
                  component="div"
                />
              )}
            </Box>
            <Total
              methods={methods}
              coworkingSpaceId={coworkingSpaceId}
              categoryId={categoryId}
              itemId={itemId}
              fields={watchFields}
              changeFieldsErrors={onFieldsErrorsChange}
              onTotalChange={(can) => setCanNext(can)}
            />
          </form>
          <Box mt={4}>
            <CustomButton onClick={nextStep} variant="outlined" color="secondary" fullWidth>
              <Box color="primary.darker">{t('prospect.transform.book.delete')}</Box>
            </CustomButton>
          </Box>
        </Box>
      </CustomPaper>
      <Box mt={2}>
        <CustomButton
          onClick={(e) => handleClick(e)}
          disabled={!canNext}
          fullWidth
          variant="contained"
          color="secondary"
        >
          {currentStep === totalStep ? t('prospect.transform.book.next.step') : t('prospect.transform.book.next.book')}
        </CustomButton>
      </Box>
    </>
  )
}

function Reservations({ prospect, nextGlobalStep }) {
  const [itemInfos, setItemInfos] = React.useState(null)
  const [cwspaceId, setCwspaceId] = React.useState(null)
  const [categoryId, setCategoryId] = React.useState(null)
  const [itemId, setItemId] = React.useState(null)
  const [currentStep, setCurrentStep] = React.useState(0)
  const [fields, setFields] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [reservationsToSend, setReservationsToSend] = React.useState([])
  const [interests, setInterests] = React.useState(null)

  const { apiCall } = useContext(UserContext)

  React.useEffect(() => {
    if (prospect?.InterestDate) localStorage.setItem('startdateitem', prospect.InterestDate)
    apiCall(apiActions.getInterestsByProspectId, { ProspectId: prospect?.Id }).then((r) => {
      setInterests(r.data)
    })
  }, [])

  React.useEffect(() => {
    setLoading(true)
    if (interests) {
      if (interests.length > 0) {
        const thisInterest = interests[currentStep]
        if (thisInterest) {
          const csId = thisInterest.CoworkingSpaceId
          const catId = thisInterest.CategoryId
          const id = thisInterest.Id
          apiCall(apiActions.getItem, {
            CoworkingSpaceId: csId,
            CategoryId: catId,
            ItemId: id,
            SkipVisibleFilter: true,
          }).then((r) => {
            setItemInfos(r)
            setCwspaceId(csId)
            setCategoryId(catId)
            setItemId(id)
          })
        }
      } else {
        setLoading(false)
        nextGlobalStep(null)
      }
    }
  }, [interests, currentStep])

  React.useEffect(() => {
    if (cwspaceId && categoryId && itemId) {
      apiCall(apiActions.getForm, {
        CoworkingSpaceId: cwspaceId,
        CategoryId: categoryId,
        ItemId: itemId,
        SkipVisibleFilter: true,
      }).then((r) => {
        if (r.Error) {
          //
        } else {
          setFields(r.data)
          setLoading(false)
        }
      })
    }
  }, [itemId])

  const nextStep = (values) => {
    let newResToSend = [...reservationsToSend]
    if (values && values.itemId) {
      values.cartId = currentStep
      newResToSend.push(values)
      setReservationsToSend(newResToSend)
    }
    if (currentStep + 1 === interests.length) {
      nextGlobalStep(newResToSend)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  if (loading || !itemInfos || !fields) return <LoadingElem />
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <InfoItem withScheduler={false} itemInfos={itemInfos} />
      </Grid>
      <Grid item xs={12} md>
        <ReservationsForm
          currentStep={currentStep + 1}
          totalStep={interests.length}
          coworkingSpaceId={cwspaceId}
          categoryId={categoryId}
          itemId={itemId}
          itemInfos={itemInfos}
          fields={fields}
          nextStep={nextStep}
        />
      </Grid>
    </Grid>
  )
}

function Recap({ coworkerId, reservations, methods, nextStep }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const fields = methods.watch()
  const { apiCall } = useContext(UserContext)
  const [recapOrders, setRecapOrders] = React.useState(null)
  const [finalReservations, setFinalReservations] = React.useState(reservations)
  const [balance, setBalance] = React.useState(null)
  const [totalPrice, setTotalPrice] = React.useState(null)

  const money = useContext(MoneyContext)

  React.useEffect(() => {
    if (coworkerId) {
      apiCall(apiActions.getWalletBalance, { coworkerId: coworkerId }, 'get').then((r) => setBalance(r.Balance))
    }
  }, [])

  React.useEffect(() => {
    apiCall(
      apiActions.getCart,
      {
        SkipVisibleFilter: true,
      },
      'post',
      {
        orders: finalReservations,
      }
    ).then((r) => {
      setRecapOrders(r)
      setTotalPrice(r.Total)
    })
  }, [finalReservations])

  const SubLine = ({ children }) => {
    return (
      <Typography variant="subtitle2" color="textSecondary">
        {children}
      </Typography>
    )
  }

  const UserInfos = () => {
    return (
      <CustomPaper>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Box display="flex" alignItems="center">
                <Box mr={1} display="flex" alignItems="center">
                  <img
                    src={methods.getValues().PictureProfileUrl ?? DEFAULT_IMG}
                    height={40}
                    width={40}
                    className={classes.img}
                  />
                </Box>
                <Box display="block">
                  <div>
                    <Typography variant="h3">{fields.FirstName + ' ' + fields.LastName}</Typography>
                  </div>
                  <div>
                    <SubLine>{fields.Description ?? t('prospect.transform.recap.no.description')}</SubLine>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">{t('prospect.transform.recap.mail')}</Typography>
              <SubLine>{fields.Email}</SubLine>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">{t('prospect.transform.recap.phone')}</Typography>
              <SubLine>{fields.MobilePhone}</SubLine>
            </Grid>
          </Grid>
        </Box>
      </CustomPaper>
    )
  }

  const InvoiceInfos = () => {
    return (
      <CustomPaper>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1">
                <Box component="span" color="primary.darker">
                  {fields.SocietyName}
                </Box>
              </Typography>
              <SubLine>{fields.SocietySiret}</SubLine>
              <SubLine>{fields.SocietyStatus}</SubLine>
              <SubLine>{fields.SocietyTVA}</SubLine>
            </Grid>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1">
                <Box component="span" color="primary.darker">
                  {t('prospect.transform.recap.socialseat')}
                </Box>
              </Typography>
              <SubLine>{fields.SocietyAddress}</SubLine>
              <SubLine>{fields.SocietyZipcode + ' ' + fields.SocietyCity}</SubLine>
            </Grid>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1">
                <Box component="span" color="primary.darker">
                  {t('prospect.transform.recap.others')}
                </Box>
              </Typography>
              <SubLine>{fields.SocietyPhoneNumber}</SubLine>
              <SubLine>{fields.SocietyBillingEmail}</SubLine>
            </Grid>
          </Grid>
        </Box>
      </CustomPaper>
    )
  }

  const ProvisionalCart = () => {
    return (
      <Box mb={2}>
        {recapOrders &&
          recapOrders.ItemsCategory?.map((cat, catIndex) => {
            return (
              <Box width="100%" key={catIndex} mb={2}>
                <Box mb={2}>
                  <SeparatorBox text={cat.CategoryName} />
                </Box>
                <Grid container spacing={2}>
                  {cat.Items?.map((i, itemIndex) => {
                    return (
                      <Grid item xs={12}>
                        <CardCart key={itemIndex} handleDelete={(id) => deleteReservation(id)} item={i} />
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            )
          })}
      </Box>
    )
  }

  const TotalDetails = () => {
    return (
      <div>
        {coworkerId && (
          <Paper
            className={
              !balance || (balance && totalPrice.Credits > balance && totalPrice.Credits > 0)
                ? classes.boxFailure
                : classes.boxSuccess
            }
          >
            {!balance || (balance && totalPrice.Credits > balance && totalPrice.Credits > 0)
              ? t('prospect.transform.recap.user.not.enough')
              : t('prospect.transform.recap.user.enough')}
          </Paper>
        )}
        <Box mb={2}>
          <Divider className={classes.divider} />
        </Box>
        <Grid container justify="flex-end">
          <Grid item xs sm="auto">
            <Sl
              left={<Typography variant="h5">{t('cart.total')}</Typography>}
              right={
                <Typography variant="h4">
                  <Box component="span" alignItems="center">
                    <span>{formatCost(totalPrice.Credits)}</span>
                    &nbsp;
                    <Icon fontSize="inherit" icon="credits" />
                    &nbsp;
                  </Box>
                </Typography>
              }
            />
            <Sl
              left={<Typography variant="h5">{t('prospect.transform.recap.ht.correspondance')}</Typography>}
              right={
                <Typography variant="body2">
                  <Box component="span" display="flex" alignItems="center">
                    <span>{formatCost(totalPrice.EuroHT)}</span>
                    &nbsp;
                    <Icon fontSize="inherit" icon={money.iconMoney} />
                    &nbsp;
                  </Box>
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  const deleteReservation = (id) => {
    let newFinalRes = [...finalReservations]
    newFinalRes.forEach((item, index) => {
      if (parseInt(item.cartId) === parseInt(id)) {
        newFinalRes.splice(index, 1)
      }
    })
    setFinalReservations(newFinalRes)
  }

  return (
    <Container maxWidth="lg">
      <Box mb={2}>
        <SeparatorBox text={t('prospect.transform.recap.user.title')} />
      </Box>
      <Box mb={2}>
        <UserInfos />
      </Box>
      <Box mb={2}>
        <SeparatorBox text={t('prospect.transform.recap.invoices.title')} />
      </Box>
      <Box mb={2}>
        <InvoiceInfos />
      </Box>
      <ProvisionalCart />
      {totalPrice && <TotalDetails />}
      <Box display="flex" justifyContent="center" width="100%">
        <CustomButton onClick={() => nextStep(finalReservations)} variant="outlined" color="secondary">
          <Box component="span" color="primary.darker">
            {coworkerId // Coworker existant
              ? finalReservations && finalReservations.length > 0 // Avec des réservations
                ? t('prospect.transform.recap.create.reservation')
                : t('prospect.transform.recap.archive')
              : // Nouveau coworker
              finalReservations && finalReservations.length > 0 // Avec des réservations
              ? t('prospect.transform.recap.create.coworker.reservation')
              : t('prospect.transform.recap.create.coworker')}
          </Box>
        </CustomButton>
      </Box>
    </Container>
  )
}

function TransformProspectForm({ defaultValues, prospect }) {
  const { t } = useTranslation()
  const { apiCall, user } = useContext(UserContext)

  const [currentStep, setCurrentStep] = React.useState(1)
  const [reservations, setReservations] = React.useState(null)
  const [over, setOver] = React.useState(false)
  const [isSociety, setIsSociety] = React.useState(false)
  const [finalReservations, setFinalReservations] = React.useState(null)
  const [alert, setAlert] = React.useState(null)

  const requiredForFalse = { is: false, then: Yup.string().nullable() }

  const methods = useForm({
    defaultValues: {
      Civility: defaultValues?.Civility ?? 'F',
      Sexe: defaultValues?.Sexe ?? 'F',
      FirstName: defaultValues?.FirstName ?? prospect?.FirstName,
      LastName: defaultValues?.LastName ?? prospect?.LastName,
      Birthdate: defaultValues?.Birthdate,
      CoworkingSpaceId: defaultValues?.CoworkingSpace?.Id,
      SwipeCardId: defaultValues?.SwipeCardId,
      Email: defaultValues?.Email ?? prospect?.Email,
      MobilePhone: defaultValues?.MobilePhone ?? prospect?.MobilePhone,
      Address: defaultValues?.Address ?? prospect?.Address,
      City: defaultValues?.City ?? prospect?.City,
      Zipcode: defaultValues?.Zipcode ?? prospect?.Zipcode,
      Job: defaultValues?.Job ?? prospect?.Job,
      Brand: defaultValues?.Brand,
      PictureProfileUrl: defaultValues?.PictureProfileUrl,
      IsPhotoRequired: isPhotoRequired,

      AccountType: defaultValues?.AccountType ?? 'Private',
      SocietyId: defaultValues?.SocietyId ?? null,
      IsSociety: isSociety,
      SocietyName: defaultValues?.SocietyName ?? prospect?.SocietyName,
      SocietyStatus: defaultValues?.SocietyStatus ?? null,
      SocietySiret: defaultValues?.SocietySiret ?? prospect?.Siret,
      SocietyTVA: defaultValues?.SocietyTVA ?? null,
      SocietyBillingEmail: isSociety ? defaultValues?.SocietyBillingEmail : defaultValues?.Email ?? prospect?.Email,
      SocietyPhoneNumber: defaultValues?.SocietyPhoneNumber ?? prospect?.SocietyPhone ?? prospect?.Phone,
      SocietyAddress: defaultValues?.SocietyAddress ?? prospect?.SocietyAddress,
      SocietyZipcode: defaultValues?.SocietyZipcode ?? prospect?.SocietyZipcode,
      SocietyCity: defaultValues?.SocietyCity ?? prospect?.SocietyCity,
      IdDocumentType: defaultValues?.IdDocumentType ?? 'idcard',
      IdDocument: defaultValues?.IdDocumentType ?? null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        //Infos personnelles
        Civility: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        Sexe: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        FirstName: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        LastName: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        Birthdate: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        CoworkingSpaceId: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        SwipeCardId: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        Email: Yup.string()
          .email(t('email.not.valid'))
          .required(t('common.field.required'))
          .typeError(t('common.field.required')),
        MobilePhone: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        Address: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        City: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        Zipcode: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        Job: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        Brand: Yup.string().nullable(),
        PictureProfileUrl: Yup.string().when('IsPhotoRequired', {
          is: true,
          then: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
          otherwise: Yup.string().nullable(),
        }),
        //Infos de facturation
        AccountType: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        SocietyId: Yup.string().nullable(),
        SocietyName: Yup.string().when('IsSociety', requiredForFalse).typeError(t('common.field.required')),
        SocietyStatus: Yup.string().when('IsSociety', requiredForFalse).typeError(t('common.field.required')),
        SocietySiret: Yup.string().when('IsSociety', requiredForFalse).typeError(t('common.field.required')),
        SocietyTVA: Yup.string().nullable(),
        SocietyBillingEmail: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        SocietyPhoneNumber: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        SocietyAddress: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        SocietyZipcode: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        SocietyCity: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        IdDocumentType: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        IdDocument: Yup.string().when('IsSociety', {
          is: false,
          then: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
          otherwise: Yup.string().nullable(),
        }),
      })
    ),
  })

  const { data: isPhotoRequired } = useSWR(`transform/isPhotoRequired`, () =>
    apiCall(apiActions.getMandatoryPhotoOption).then((r) => methods.setValue('IsPhotoRequired', r))
  )

  React.useEffect(() => {
    setIsSociety(methods.getValues().IsSociety)
  }, [methods.getValues().IsSociety])

  async function checkFirstStep() {
    const firstFormNames = [
      'Civility',
      'Sexe',
      'FirstName',
      'LastName',
      'Birthdate',
      'CoworkingSpaceId',
      'CoworkingSpaceBadgeId',
      'SwipeCardId',
      'Email',
      'MobilePhone',
      'Address',
      'City',
      'Zipcode',
      'Job',
      'Brand',
      'PictureProfileUrl',
    ]

    await methods.trigger(firstFormNames)

    const prepareNextStep = () => {
      const values = methods.getValues()
      // Pré-rempli les champs d'après selon certaines conditions
      if (!defaultValues?.SocietyZipcode && !prospect?.SocietyZipcode)
        methods.setValue('SocietyZipcode', values.Zipcode)
      if (!defaultValues?.SocietyCity && !prospect?.SocietyCity) methods.setValue('SocietyCity', values.City)
      if (!defaultValues?.SocietyPhoneNumber && !prospect?.SocietyPhone && !prospect?.Phone)
        methods.setValue('SocietyPhoneNumber', values.MobilePhone)
      if (!defaultValues?.SocietyAddress && !prospect?.SocietyAddress)
        methods.setValue('SocietyAddress', values.Address)
      if (!values.SocietyBillingEmail) methods.setValue('SocietyBillingEmail', values.Email)
    }

    if (!moment(methods.getValues().Birthdate).isValid())
      methods.setError('Birthdate', { message: t('common.field.required'), type: 'string' })

    if (Object.keys(methods.formState.errors).length > 0) {
      setAlert(null)
      return
    } else {
      if (!defaultValues) {
        await apiCall(apiActions.coworkerExists, {}, 'post', { Email: methods.getValues().Email }).then((r) => {
          if (r.data && r.data.length > 0) {
            setAlert(t('email.already.exists'))
          } else {
            prepareNextStep()
            methods.clearErrors()
            setCurrentStep(2)
          }
        })
      } else {
        prepareNextStep()
        methods.clearErrors()
        setCurrentStep(2)
      }
    }
  }

  async function checkSecondStep() {
    let secondFormNames = [
      'AccountType',
      'SocietyId',
      'SocietyName',
      'SocietyStatus',
      'SocietySiret',
      'SocietyTVA',
      'SocietyBillingEmail',
      'SocietyPhoneNumber',
      'SocietyAddress',
      'SocietyZipcode',
      'SocietyCity',
      'IdDocumentType',
      'IdDocument',
    ]

    await methods.trigger(secondFormNames)

    if (Object.keys(methods.formState.errors).length > 0) {
      return
    } else {
      methods.clearErrors()
      setCurrentStep(3)
    }
  }

  const nextToReview = (reservations) => {
    setReservations(reservations)
    setCurrentStep(4)
  }

  const finalize = (finalReservations) => {
    setFinalReservations(finalReservations)
    setOver(true)
  }

  if (over)
    return (
      <TransformConfirmation
        coworker={defaultValues}
        methods={methods}
        reservations={finalReservations}
        prospect={prospect}
      />
    )

  return (
    <Layout pageTitle={t('prospect.transform.page.title')} pageDescription={t('prospect.transform.page.subtitle')}>
      <form>
        <Box mb={2}>
          <CustomStepper
            totalStep={5}
            labels={[
              t('prospect.transform.step.first.label'),
              t('prospect.transform.step.second.label'),
              t('prospect.transform.step.third.label'),
              t('prospect.transform.step.fourth.label'),
              t('prospect.transform.step.fivth.label'),
            ]}
            activeStep={currentStep}
            onChangeActive={(step) => {
              if (step !== 0) setCurrentStep(step)
            }}
          />
        </Box>

        {/* Bouton retour pour faciliter le développement, à voir si on le laisse */}
        {/* <Button
          onClick={() => {
            if (currentStep > 1) setCurrentStep(currentStep - 1)
            return
          }}
        >
          Retour */}
        {/* </Button> */}

        {currentStep === 1 && (
          <>
            <Box mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <CustomPaper>
                    <Box p={2}>
                      <Box mb={4}>
                        <Typography align="center" variant="h3">
                          <Box component="span" color="primary.darker">
                            {t('prospect.transform.account.title')}
                          </Box>
                        </Typography>
                      </Box>
                      <AccountForm methods={methods} />
                      {alert && <CustomErrorMessage message={alert} />}
                    </Box>
                  </CustomPaper>
                </Grid>
                <Grid item xs>
                  <CustomPaper>
                    <Box p={2}>
                      <ProfileCard coworker={defaultValues} methods={methods} />
                    </Box>
                  </CustomPaper>
                </Grid>
              </Grid>
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
              <CustomButton onClick={() => checkFirstStep()} variant="contained" color="secondary">
                {t('prospect.transform.next.step')}
              </CustomButton>
            </Box>
          </>
        )}
        {currentStep === 2 && (
          <div>
            <Box mb={2}>
              <Container maxWidth="md">
                <CustomPaper>
                  <Box p={2}>
                    <Box mb={4}>
                      <Typography align="center" variant="h3">
                        <Box component="span" color="primary.darker">
                          {t('prospect.transform.invoice.title')}
                        </Box>
                      </Typography>
                    </Box>
                    <InvoiceForm methods={methods} />
                  </Box>
                </CustomPaper>
              </Container>
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
              <CustomButton onClick={() => checkSecondStep()} variant="contained" color="secondary">
                {t('prospect.transform.next.step')}
              </CustomButton>
            </Box>
          </div>
        )}
        {currentStep === 3 && <Reservations nextGlobalStep={nextToReview} prospect={prospect} />}
        {currentStep === 4 && (
          <Recap
            nextStep={finalize}
            coworkerId={defaultValues?.CoworkerId}
            reservations={reservations}
            methods={methods}
          />
        )}
      </form>
    </Layout>
  )
}

export default TransformProspectForm
