import { Box, Button, Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import CustomLabel from 'components/CustomLabel'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    '& .MuiAutocomplete-input': {
      width: '100%',
    },
  },
  deleteTime: {
    padding: 0,
  },
  textAutocomplete: {
    '& > div': {
      '& > fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.light,
      },
      '&:hover > fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.dark,
      },
      '&.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.darker,
      },
    },
    '& .MuiInputBase-root': {
      fontWeight: 'bold',
    },
  },
}))

function RenderHours({ onClose, index, disabled, times, onChange, timesSelected, slot, canDelete }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [start, setStart] = React.useState(null)
  const [end, setEnd] = React.useState(null)

  const [valueStart, setValueStart] = React.useState(null)
  const [valueEnd, setValueEnd] = React.useState(null)

  const [selectedHours, setSelectedHours] = React.useState([])

  React.useEffect(() => {
    onChange({
      start: start,
      end: end,
    })
  }, [start, end])

  React.useEffect(() => {
    if (slot) {
      setStart(slot.start ? moment(slot.start, 'HH:mm:ss').format('HH:mm') : null)
      setEnd(slot.end ? moment(slot.end, 'HH:mm:ss').format('HH:mm') : null)
      setValueStart(
        slot.start
          ? {
              value: moment(slot.start, 'HH:mm:ss').format('HH:mm'),
              label: moment(slot.start, 'HH:mm:ss').format('HH[h]mm'),
            }
          : null
      )
      setValueEnd(
        slot.end
          ? {
              value: moment(slot.end, 'HH:mm:ss').format('HH:mm'),
              label: moment(slot.end, 'HH:mm:ss').format('HH[h]mm'),
            }
          : null
      )
    }
  }, [slot])

  React.useEffect(() => {
    if (localStorage.getItem('startduration') && index === 0) {
      setStart(moment(localStorage.getItem('startduration'), 'HH:mm:ss').format('HH:mm'))
      setValueStart({
        value: moment(localStorage.getItem('startduration'), 'HH:mm:ss').format('HH:mm'),
        label: moment(localStorage.getItem('startduration'), 'HH:mm:ss').format('HH[h]mm'),
      })
    }
    if (localStorage.getItem('endduration') && index === 0) {
      setEnd(moment(localStorage.getItem('endduration'), 'HH:mm:ss').format('HH:mm'))
      setValueEnd({
        value: moment(localStorage.getItem('endduration'), 'HH:mm:ss').format('HH:mm'),
        label: moment(localStorage.getItem('endduration'), 'HH:mm:ss').format('HH[h]mm'),
      })
    }
  }, [localStorage.getItem('startduration'), localStorage.getItem('endduration')])

  React.useEffect(() => {
    let newSelectedHours = []

    timesSelected &&
      timesSelected.forEach((t) => {
        if (t.start && t.end) newSelectedHours.push([t.start, t.end])
      })

    setSelectedHours(newSelectedHours)
  }, [timesSelected])

  let timesOptions = []
  let timeValues = []

  times &&
    times.map((time) => {
      if (!timeValues.includes(time.split(';')[0])) {
        timeValues.push(time.split(';')[0])
      }
      if (!timeValues.includes(time.split(';')[1])) {
        timeValues.push(time.split(';')[1])
      }
    })

  timeValues.sort()

  timeValues.forEach((timeValue) => {
    if (timeValue) {
      timesOptions.push({
        label: moment(timeValue, 'HH:mm').format('HH[h]mm'),
        value: timeValue,
      })
    }
  })

  const handleClose = () => {
    onClose(index)
  }

  const getMinHourSelectable = (end) => {
    let maxBeforeEnd = timesOptions[0].value
    selectedHours.forEach((hours) => {
      if (hours[1] < end && hours[1] >= maxBeforeEnd) maxBeforeEnd = hours[1]
    })
    return maxBeforeEnd
  }

  const getDisabledOptionStart = (options) => {
    const optionValue = options.value
    var isDisabled = false

    if (index === 0) isDisabled = end && optionValue >= end
    else {
      if (selectedHours && selectedHours.length > 0) {
        if (end) {
          let minHourSelectable = getMinHourSelectable(end)
          isDisabled = optionValue >= end || optionValue < minHourSelectable
        } else {
          selectedHours.forEach((hours) => {
            if (optionValue >= hours[0] && optionValue < hours[1]) isDisabled = true
          })
        }
      }
    }
    return isDisabled
  }

  const getMaxHourSelectable = (start) => {
    let minAfterStart = timesOptions[timesOptions.length - 1]
    selectedHours.forEach((hours) => {
      if (hours[0] > start && hours[0] <= minAfterStart) minAfterStart = hours[0]
    })
    return minAfterStart
  }

  const getDisabledOptionEnd = (options) => {
    const optionValue = options.value
    var isDisabled = false

    if (index === 0) {
      isDisabled = start && moment(optionValue, 'HH:mm:ss') <= moment(start, 'HH:mm:00')
    } else {
      if (selectedHours && selectedHours.length > 0) {
        if (start) {
          let maxHourSelectable = getMaxHourSelectable(start)
          isDisabled = optionValue <= start || optionValue > maxHourSelectable
        } else {
          selectedHours.forEach((hours) => {
            if (optionValue > hours[0] && optionValue <= hours[1]) isDisabled = true
          })
        }
      }
    }
    return isDisabled
  }

  return (
    <Box mb={1}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs="auto">
          <Typography variant="body2">
            <Box color="primary.darker" component="span">
              {t('reservations.details.field.times.from')}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs>
          <Autocomplete
            disabled={disabled}
            className={classes.autocomplete}
            forcePopupIcon={false}
            value={valueStart}
            disableClearable
            fullWidth
            options={timesOptions}
            getOptionDisabled={(options) => getDisabledOptionStart(options)}
            getOptionLabel={(options) => options.label}
            onChange={(e, v) => {
              setStart(v && v.value)
              setValueStart(v)
            }}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                className={classes.textAutocomplete}
                fullWidth
                {...params}
                placeholder="--"
              />
            )}
          />
        </Grid>
        <Grid item xs="auto">
          <Typography variant="body2">
            <Box color="primary.darker" component="span">
              {t('reservations.details.field.times.to')}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs>
          <Autocomplete
            disabled={disabled}
            className={classes.autocomplete}
            fullWidth
            disableClearable
            value={valueEnd}
            forcePopupIcon={false}
            options={timesOptions}
            getOptionLabel={(options) => options.label}
            getOptionDisabled={(options) => getDisabledOptionEnd(options)}
            onChange={(e, v) => {
              setEnd(v && v.value)
              setValueEnd(v)
            }}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                className={classes.textAutocomplete}
                fullWidth
                {...params}
                placeholder="--"
              />
            )}
          />
        </Grid>
        {canDelete && (
          <Grid item xs="auto">
            <IconButton onClick={handleClose} className={classes.deleteTime}>
              <Icon icon="close" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

function RenderTime({ methods, onClose, index, times, onChange, canDelete, slot }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [value, setValue] = React.useState(null)

  let availableTimes = [...times]
  let unavailableTimes = methods.getValues().dateandduration_ ?? methods.getValues().startenddate_

  React.useEffect(() => {
    if (slot.start && slot.end) {
      handleChange(slot.start + ';' + slot.end)
    }
  }, [slot])

  if (unavailableTimes && unavailableTimes.times) {
    unavailableTimes.times.forEach((time) => {
      if (time.start && time.end) {
        const t = time.start + ';' + time.end
        if (t !== value) availableTimes.splice(availableTimes.indexOf(t), 1)
      }
    })
  }

  const RenderLabelHours = ({ hours }) => {
    let start = hours.split(';')[0]
    let end = hours.split(';')[1]

    return t('reservations.details.field.times.fromto', {
      from: moment(start, 'HH:mm').format('HH[h]mm'),
      to: moment(end, 'HH:mm').format('HH[h]mm'),
    })
  }

  const handleChange = (val) => {
    setValue(val)
    const split = val.split(';')
    onChange({
      start: split[0],
      end: split[1],
    })
  }

  const handleClose = () => {
    onClose(index)
  }

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs>
        <CustomTextField onChange={(e) => handleChange(e.target.value)} select value={value} fullWidth>
          {availableTimes.map((time, index) => (
            <MenuItem key={index} value={time}>
              <RenderLabelHours hours={time} />
            </MenuItem>
          ))}
        </CustomTextField>
      </Grid>
      {canDelete && (
        <Grid item>
          <IconButton onClick={handleClose} className={classes.deleteTime}>
            <Icon icon="close" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

function Times({ disabled, times, methods, filter, type, noAdd, label, timesSelected, reset }) {
  const { t } = useTranslation()
  const [timeslots, setTimeslots] = React.useState([])

  const handleClickAdd = () => {
    setTimeslots([...timeslots, { start: null, end: null }])
  }

  React.useEffect(() => {
    setTimeslots([
      {
        start: null,
        end: null,
      },
    ])
  }, [type, reset])

  const handleChange = (val, i) => {
    let newTimeslots = [...timeslots]
    newTimeslots[i].start = val.start
    newTimeslots[i].end = val.end
    setTimeslots(newTimeslots)
  }

  const handleClose = (index) => {
    if (index === 0 && timeslots.length === 1) {
      setTimeslots([
        {
          start: null,
          end: null,
        },
      ])
    } else {
      let newTimeslots = [...timeslots]
      newTimeslots.splice(index, 1)
      setTimeslots(newTimeslots)
    }
  }

  React.useEffect(() => {
    methods.setValue(filter.Name, timeslots)
  }, [timeslots])

  return (
    <div>
      <div>
        <CustomLabel>{label ?? t('reservations.details.field.times')}</CustomLabel>
        {timeslots.map((t, i) => {
          switch (type) {
            case 'hour':
              return (
                <RenderHours
                  timesSelected={timesSelected}
                  slot={t}
                  canDelete={timeslots.length > 1 || noAdd}
                  key={i}
                  index={i}
                  disabled={disabled}
                  times={times}
                  onClose={handleClose}
                  onChange={(val) => handleChange(val, i)}
                />
              )
            default:
              return (
                <RenderTime
                  methods={methods}
                  slot={t}
                  canDelete={timeslots.length > 1 || noAdd}
                  key={i}
                  index={i}
                  times={times}
                  onClose={handleClose}
                  onChange={(val) => handleChange(val, i)}
                />
              )
          }
        })}
      </div>
      {!noAdd && times && timeslots.length < times.length && (
        <div>
          <Button color="primary" onClick={handleClickAdd}>
            <Typography variant="body2">{t('reservations.details.field.times.add')}</Typography>
          </Button>
        </div>
      )}
    </div>
  )
}

export default Times
