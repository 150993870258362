//SERVER ROUTES
export const basename = '/v2'

const { PUBLIC_URL: clientPath, REACT_APP_PUBLIC_API_PATH: apiPath } = process.env
export const SERVER_URL = (route) => {
  const {
    location: { hostname },
  } = window
  const absoluteRoute = hostname + clientPath + apiPath + route
  return (
    'https://' +
    absoluteRoute
      .replace(/^http\:\/\//, '') // remove the leading http://
      .replace(/\/+/g, '/') // replace consecutive slashes with a single slash
      .replace(/\/+$/, '')
  ) // remove trailing slashes
}

export const USERS_SERVER = SERVER_URL('/api/users')
export const SOCIETY_SERVER = SERVER_URL('/api/society')

export const DEFAULT_IMG =
  'https://media.fteledition.fr/Uploads/237e1ae8-7d56-444b-90f0-89933b8cab00/image_vide_min.png'
