import { Box, Grid, OutlinedInput, Slider, Typography } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import CustomLabel from 'components/CustomLabel'
import { MoneyContext } from 'contexts/MoneyContext'
import { SocietyContext } from 'contexts/SocietyContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  price: {
    maxWidth: 100,
    '&.Mui-disabled': {
      color: alpha(theme.palette.primary.dark, 0.75),
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.light,
      },
    },
    '& input': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& label': {
      color: theme.palette.text.secondary,
    },
  },
  slider: {
    color: theme.palette.secondary.main,
    '& > .MuiSlider-thumb > span > span > span': {
      color: theme.palette.secondary.contrastText,
    },
  },
}))

function DoubleSlider({ methods, filter, reset }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const money = useContext(MoneyContext)
  const { society } = useContext(SocietyContext)

  const fields = filter.Fields
  const min = fields[0].Prices
  const max = fields[1].Prices
  const tva = 1 + society.VAT

  const [valuesHT, setValuesHT] = React.useState([Math.floor(min.EuroHT), Math.ceil(max.EuroHT)])
  const [valuesTTC, setValuesTTC] = React.useState([Math.floor(min.EuroTTC), Math.ceil(max.EuroTTC)])
  const [valuesCredits, setValuesCredits] = React.useState([Math.floor(min.Credits), Math.ceil(max.Credits)])

  const disabled = min.EuroHT === max.EuroHT

  React.useEffect(() => {
    methods.register(filter.Name)
  }, [])

  React.useEffect(() => {
    setValuesHT([Math.floor(min.EuroHT), Math.ceil(max.EuroHT)])
    setValuesTTC([Math.floor(min.EuroTTC), Math.ceil(max.EuroTTC)])
    setValuesCredits([Math.floor(min.Credits), Math.ceil(max.Credits)])
  }, [reset])

  const handleChange = (event, newValue) => {
    switch (money.type) {
      case 'ht':
      case 'credits':
      default:
        setValuesHT(newValue)
        setValuesCredits(newValue)
        setValuesTTC([Math.floor(newValue[0] * tva), Math.ceil(newValue[1] * tva)])
        break
      case 'ttc':
        setValuesTTC(newValue)
        let newMin = Math.floor(newValue[0] / tva)
        let newMax = Math.ceil(newValue[1] / tva)
        if (Math.floor(newValue[0] / tva) < min.EuroHT) newMin = min.EuroHT
        if (Math.ceil(newValue[1] / tva) > max.EuroHT) newMax = max.EuroHT
        setValuesHT([newMin, newMax])
        setValuesCredits([newMin, newMax])
    }
  }

  const handleChangeCommitted = (event, newValue) => {
    let sendValues = newValue[0] !== min.EuroHT || newValue[1] !== max.EuroHT ? valuesHT.join(';') : null

    if (money.type === 'ttc') {
      sendValues =
        newValue[0] > min.EuroTTC || newValue[1] < max.EuroTTC ? valuesTTC[0] / tva + ';' + valuesTTC[1] / tva : null
    }

    if (sendValues) {
      methods.setValue(filter.Name, sendValues, { shouldDirty: true })
    } else {
      methods.setValue(filter.Name, null, { shouldDirty: true })
    }
  }

  let sliderHT = (
    <Slider
      disabled={disabled}
      name={filter.Name}
      step={1}
      min={Math.floor(min.EuroHT)}
      max={Math.ceil(max.EuroHT)}
      value={valuesHT}
      className={classes.slider}
      onChange={handleChange}
      onChangeCommitted={handleChangeCommitted}
      valueLabelDisplay="auto"
    />
  )

  let sliderTTC = (
    <Slider
      disabled={disabled}
      name={filter.Name}
      step={1}
      min={Math.floor(min.EuroTTC)}
      max={Math.ceil(max.EuroTTC)}
      value={valuesTTC}
      className={classes.slider}
      onChange={handleChange}
      onChangeCommitted={handleChangeCommitted}
      valueLabelDisplay="auto"
    />
  )

  let sliderCredits = (
    <Slider
      disabled={disabled}
      name={filter.Name}
      step={1}
      min={Math.floor(min.Credits)}
      max={Math.ceil(max.Credits)}
      value={valuesCredits}
      className={classes.slider}
      onChange={handleChange}
      onChangeCommitted={handleChangeCommitted}
      valueLabelDisplay="auto"
    />
  )

  let slider = null
  let printValues = []

  switch (money.type) {
    case 'ttc':
      slider = sliderTTC
      printValues = valuesTTC
      break
    case 'credits':
      slider = sliderCredits
      printValues = valuesCredits
      break
    case 'ht':
    default:
      slider = sliderHT
      printValues = valuesHT
      break
  }

  return (
    <Box>
      <CustomLabel>{t(filter.Key)}</CustomLabel>
      <Box>{slider}</Box>
      <Grid container>
        <Grid item xs>
          <OutlinedInput
            fullWidth
            readOnly
            disabled
            className={classes.price}
            value={t(fields[0].Key)}
            endAdornment={<Typography color="secondary">{printValues[0]}</Typography>}
          />
        </Grid>
        <Grid item xs>
          <Box textAlign="right">
            <OutlinedInput
              fullWidth
              readOnly
              disabled
              className={classes.price}
              value={t(fields[1].Key)}
              endAdornment={<Typography color="secondary">{printValues[1]}</Typography>}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DoubleSlider
