import { Box, Button, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import LoadingElem from 'components/LoadingElem'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    paddingLeft: 0,
    height: 'auto',
    '& *': {
      height: '100%',
    },
    '& .MuiButton-label': {
      color: theme.palette.primary.darker,
      fontWeight: theme.typography.subtitle2.fontWeight,
    },
    '& .MuiButton-outlined:not(.MuiButton-outlinedSecondary):not(.MuiButton-outlinedPrimary)': {
      border: '1px solid ' + theme.palette.primary.darker,
    },
  },
  selected: {
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.subtitle1.fontWeight,
    },
    '& .MuiButton-outlined:not(.MuiButton-outlinedSecondary):not(.MuiButton-outlinedPrimary)': {
      border: '1px solid ' + theme.palette.primary.main,
    },
  },
}))

function CoworkingSpacesButtons({ onChange, nameRoutePathOnClick, unique }) {
  const classes = useStyles()
  const [cSpacesSelected, setCSpacesSelected] = React.useState([])
  const { apiCall, user } = useContext(UserContext)

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const isOwnerPage = nameRoutePathOnClick === 'owner-plan'

  const { data: cSpaces } = useSWR(`${user?.Id}/coworkingspaces`, () =>
    apiCall(
      isOwnerPage ? apiActions.ownerGetCoworkingSpacesByOwnerId : apiActions.getCoworkingSpaceByAdminId,
      { OwnerId: isOwnerPage ? user?.Id : null, AdminId: !isOwnerPage ? user?.Id : null },
      'get'
    ).then((r) => {
      if (r.Error) {
        //
      } else {
        return r
      }
    })
  )

  React.useEffect(() => {
    let cSpacesLocalStorage = JSON.parse(sessionStorage.getItem('ownerfilters'))
    if (
      cSpacesLocalStorage &&
      cSpacesLocalStorage.coworkingSpaces &&
      cSpacesLocalStorage.coworkingSpaces.length > 0 &&
      !unique
    ) {
      setCSpacesSelected(cSpacesLocalStorage.coworkingSpaces)
    } else {
      if (cSpaces) {
        if (unique) setCSpacesSelected([cSpaces[0]?.CoworkingSpaceId])
        else setCSpacesSelected(cSpaces.map((c) => c.CoworkingSpaceId))
      }
    }
  }, [cSpaces])

  React.useEffect(() => {
    onChange(cSpacesSelected)
  }, [cSpacesSelected])

  const changeCSpace = (id) => {
    if (unique) {
      setCSpacesSelected([id])
    } else {
      let newCwSpaces = [...cSpacesSelected]
      if (newCwSpaces.indexOf(id) >= 0) newCwSpaces.splice(newCwSpaces.indexOf(id), 1)
      else newCwSpaces.push(id)

      setCSpacesSelected(newCwSpaces)
    }
  }

  if (!cSpaces) return <LoadingElem />

  return (
    <Box display={isXsDown ? 'block' : 'flex'} width={isXsDown ? '100%' : 'auto'}>
      {cSpaces &&
        cSpaces.map((cs, index) => {
          return (
            <Box
              key={index}
              className={
                classes.button + ' ' + (cSpacesSelected.indexOf(cs.CoworkingSpaceId) >= 0 ? classes.selected : '')
              }
            >
              <Button
                size="small"
                fullWidth={isXsDown}
                onClick={() => changeCSpace(cs.CoworkingSpaceId)}
                variant="outlined"
              >
                {cs.Name}
              </Button>
            </Box>
          )
        })}
    </Box>
  )
}

export default CoworkingSpacesButtons
