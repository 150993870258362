import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import MuiLink from '@material-ui/core/Link'
import image from 'assets/images/cart_error.svg'
import Layout from 'components/views/Layout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getRoutePath } from 'routes'

const useStyles = makeStyles((theme) => ({
  image: {
    maxHeight: '60vh',
    maxWidth: '100%',
  },
}))

function ErrorPage() {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Layout title={t('payment.error.title')}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h1">
          <Box component="span" color="primary.darker">
            {t('payment.error.title')}
          </Box>
        </Typography>
        <Box my={2}>
          <img className={classes.image} src={image} alt="" />
        </Box>
        <Typography color="textSecondary">{t('payment.error.description')}</Typography>
      </Box>
      <Grid className={classes.btnContainer} container alignItems="center" spacing={1} justifyContent="center">
        <Grid item xs sm="auto">
          <MuiLink underline="none" component={Link} to={getRoutePath('cart')}>
            <Button variant="outlined" color="secondary">
              {t('payment.error.to.cart')}
            </Button>
          </MuiLink>
        </Grid>
        <Grid item xs sm="auto">
          <MuiLink underline="none" component={Link} to={getRoutePath('index')}>
            <Button variant="contained" color="secondary">
              {t('cart.back.home')}
            </Button>
          </MuiLink>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ErrorPage
