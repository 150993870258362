import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'
import Tpe from 'area/home/components/Tpe'
import { useContext } from 'react'
import { UserContext } from 'contexts/UserContext'
import LoadingElem from 'components/LoadingElem'
import { Box, Divider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

function CreditPackage() {
  const classes = useStyles()
  const { apiCall } = useContext(UserContext)

  const { data } = useSWR(`tpes`, () => apiCall(apiActions.getTpes).then((r) => r.tpeList))

  if (!data) return <LoadingElem />
  return (
    <Box display="flex">
      {data &&
        data.map((d, index) => {
          return (
            <>
              <Tpe key={index} tpe={d} />
              {index !== data.length - 1 && <Divider className={classes.divider} orientation="vertical" flexItem />}
            </>
          )
        })}
    </Box>
  )
}

export default CreditPackage
