import { Box, Button, ButtonProps, Grid, Typography } from '@material-ui/core'
import MuiLink from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

type HeroTextProps = {
  imageProps?: React.HTMLProps<HTMLImageElement>
  title?: string
  description?: string
  btnUrl?: string
  btnText?: string
  btnProps?: ButtonProps
}

const useStyle = makeStyles((theme) => ({
  image: {
    maxHeight: '60vh',
    maxWidth: '100%',
  },
  btnContainer: {
    '& .MuiButtonBase-root': {
      width: '100%',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
  },
}))

const HeroText: React.FC<HeroTextProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const { imageProps, title, description, btnProps, btnText = t('common.back'), btnUrl } = props

  let btnRender = null
  if (btnProps) {
    const { variant = 'contained', color = 'secondary', ...rest } = btnProps
    btnRender = (
      <Button variant={variant} color={color} {...rest}>
        {btnText}
      </Button>
    )
  } else {
    btnRender = (
      <Button variant="contained" color="secondary">
        {btnText}
      </Button>
    )
  }

  let imgRender = null
  if (imageProps) {
    const imageClassNames = [classes.image]
    if (imageProps.className) {
      imageClassNames.push(imageProps.className)
    }

    imgRender = <img className={imageClassNames.join(' ')} src={imageProps.src} alt={imageProps.alt ?? ''} />
  }

  return (
    <div>
      {(title || imgRender || description) && (
        <Box textAlign="center" mb={2}>
          {title && (
            <Typography variant="h1">
              <Box component="span" color="primary.darker">
                {title}
              </Box>
            </Typography>
          )}
          {imgRender && <Box mb={2}>{imgRender}</Box>}
          {description && (
            <Typography variant="h4">
              <Box component="span" color="primary.darker">
                {description}
              </Box>
            </Typography>
          )}
        </Box>
      )}
      <Grid className={classes.btnContainer} container alignItems="center" spacing={1} justifyContent="center">
        <Grid item xs="auto">
          {btnUrl ? (
            <MuiLink underline="none" component={Link} to={btnUrl}>
              {btnRender}
            </MuiLink>
          ) : (
            btnRender
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default HeroText
