import { yupResolver } from '@hookform/resolvers/yup'
import {
  alpha,
  Box,
  Button,
  CardActionArea,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  Link,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import SeparatorBox from 'components/CustomComponents/SeparatorBox'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import { formatCost } from 'components/ShowPrice'
import Layout from 'components/views/Layout'
import RenderField from 'components/views/PaymentPage/RenderField'
import { SubLine } from 'components/views/UserCartPage/RenderCards'
import { EmptyCart } from 'components/views/UserCartPage/UserCartPage'
import { MoneyContext } from 'contexts/MoneyContext'
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { getRoutePath } from 'routes'
import * as Yup from 'yup'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  card: {
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    '& .MuiCardContent-root': {
      height: '100%',
      paddingBottom: 0,
    },
  },
  container: {
    '& .MuiGrid-item': {
      maxWidth: 200,
    },
  },
  fullHeight: {
    height: '100%',
    '& .disabled': {
      opacity: 0.4,
    },
  },
  alert: {
    '& .MuiAlert-message': {
      width: '100%',
    },
    '& .MuiAlert-icon': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  btnContainer: {
    '& .MuiButtonBase-root': {
      width: '100%',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
  },
  supTtc: {
    textTransform: 'uppercase',
    fontSize: '.6em',
  },
  smallEuroIcon: {
    fontSize: '.8em',
  },
  userType: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  checkboxCGV: {
    marginRight: 0,
  },
  infoDisabled: {
    backgroundColor: alpha(theme.palette.error.main, 0.3),
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  disabledBox: {
    height: '100%' - theme.spacing(2),
  },
  creditButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.darker,
    border: '1px solid' + theme.palette.warning.main,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  paperRegister: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    border: '1px solid ' + theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  registerButton: {
    width: 250,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      marginTop: theme.spacing(1),
    },
  },
  paying: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 99,
    backdropFilter: 'blur(1px)',
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      top: '50%',
      bottom: '50%',
      left: '50%',
      right: '50%',
    },
  },
}))

const CardPayment = ({ disabled, handleCheck, checked, value, icon, title, description }) => {
  const classes = useStyles()
  return (
    <div className={classes.fullHeight}>
      <CustomPaper selectable={checked} className={classes.fullHeight}>
        <CardActionArea className={classes.card + (disabled ? ' disabled' : '')} onClick={handleCheck(value)}>
          <CardContent>
            <Box className={classes.fullHeight} display="flex" flexDirection="column">
              <Box flexGrow="1">
                <Box color={disabled ? 'text.secondary' : 'primary.darker'} mb={1}>
                  <Icon icon={icon} />
                </Box>
                <Typography variant="h6">
                  <Box
                    fontWeight="fontWeightBold"
                    color={disabled ? 'text.secondary' : 'primary.darker'}
                    component="span"
                  >
                    {title}
                  </Box>
                </Typography>
                <Hidden only="xs">
                  <Typography variant="subtitle2">
                    <Box color="text.secondary" component="span">
                      {description}
                    </Box>
                  </Typography>
                </Hidden>
              </Box>
              <div>
                <Radio
                  className={classes.radio}
                  disabled={disabled}
                  size="small"
                  checked={checked}
                  value={value}
                  onChange={handleCheck}
                />
              </div>
            </Box>
          </CardContent>
        </CardActionArea>
      </CustomPaper>
    </div>
  )
}

const RenderForm = ({ methods, isPerson }) => {
  const { t } = useTranslation()
  return (
    <div>
      <Grid container spacing={2}>
        {!isPerson && (
          <Grid item xs={12} md>
            <RenderField methods={methods} name="societyname" label={t('payment.form.society.name')} />
          </Grid>
        )}
        <Grid item xs={12} sm>
          <RenderField methods={methods} name="firstname" label={t('payment.form.user.firstname')} />
        </Grid>
        <Grid item xs={12} sm>
          <RenderField methods={methods} name="lastname" label={t('payment.form.user.lastname')} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm>
          <RenderField methods={methods} name="phone" label={t('payment.form.user.phone')} />
        </Grid>
        <Grid item xs={12} sm>
          <RenderField methods={methods} name="email" label={t('payment.form.user.email')} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm>
          <RenderField methods={methods} name="address" label={t('payment.form.user.address')} />
        </Grid>
        <Grid item xs={12} sm>
          <RenderField methods={methods} name="zipcode" label={t('payment.form.user.zipcode')} />
        </Grid>
        <Grid item xs={12} sm>
          <RenderField methods={methods} name="city" label={t('payment.form.user.city')} />
        </Grid>
      </Grid>
    </div>
  )
}

const ContainerAlert = ({ alert, message }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <Box mt={1}>
          <Alert severity={alert ?? 'error'} className={classes.alert}>
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <div>{message}</div>
              <div>
                <Button href="/CreditPackage" variant="contained" color="primary" className={classes.creditButton}>
                  {t('payment.buy.credits')}
                </Button>
              </div>
            </Box>
          </Alert>
        </Box>
      </Grid>
    </Grid>
  )
}

const ForcedPaymentCard = ({ icon, title, description, text, disabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div>
      <Box mb={2}>
        <SeparatorBox text={t(title)} />
      </Box>
      <Box display="flex" justifyContent="center" mb={4}>
        <Grid container spacing={2} className={classes.container} justifyContent="center">
          <Grid item xs>
            <CustomPaper selectable>
              <CardContent>
                <Box textAlign="center" display="flex" flexDirection="column">
                  <Box flexGrow="1">
                    <Box color="primary.darker" mb={1}>
                      <Icon icon={icon} />
                    </Box>
                    <Typography variant="h6">
                      <Box fontWeight="fontWeightBold" color="primary.darker" component="span">
                        {description}
                      </Box>
                    </Typography>
                    <Typography variant="subtitle2">
                      <Box color="text.secondary" component="span">
                        {text}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </CustomPaper>
          </Grid>
        </Grid>
      </Box>
      {disabled && (
        <Box mb={1}>
          <ContainerAlert message={t('payment.credits.not.enough')} />
        </Box>
      )}
    </div>
  )
}

function PaymentPage() {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { user, cart, apiCall } = useContext(UserContext)
  const { society } = React.useContext(SocietyContext)
  const { Payments: payments } = society
  const [items, setItems] = React.useState(null)
  const [isPerson, setIsPerson] = React.useState(true)
  const [alert, setAlert] = React.useState(null)
  const [valueChecked, setValueChecked] = React.useState('onsite')

  const money = useContext(MoneyContext)
  const allCurrencies = money?.allCurrencies
  const location = useLocation()

  const total = items ? items.Total : null
  const totalIsZero = items?.Total?.EuroHT === 0

  const requiredTranslation = t('common.field.required')
  const requiredForFalse = { is: false, then: Yup.string().required(requiredTranslation).nullable() }
  const hasMoney = (user && payments.CreditCard) || (!user && payments.CreditCardExternal)

  const availablePayments = Object.entries(payments)
    .map(([key, value]) => {
      if ((user && value && !key.includes('External')) || (!user && value && key.includes('External'))) return key
    })
    ?.filter((p) => p)

  const methods = useForm({
    defaultValues: {
      isUser: user != null,
      isPerson: isPerson,
      cgv: false,
      societyname: '',
      firstname: '',
      lastname: '',
      address: '',
      city: '',
      zipCode: '',
      phone: '',
      email: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        cgv: Yup.boolean().required(requiredTranslation),
        societyname: Yup.string().when('isPerson', requiredForFalse),
        firstname: Yup.string().when('isUser', requiredForFalse),
        lastname: Yup.string().when('isUser', requiredForFalse),
        address: Yup.string().when('isUser', requiredForFalse),
        city: Yup.string().when('isUser', requiredForFalse),
        zipcode: Yup.string().when('isUser', requiredForFalse),
        phone: Yup.string().when('isUser', requiredForFalse),
        email: Yup.string().email(t('email.not.valid')).when('isUser', requiredForFalse),
      })
    ),
  })

  const cgvChecked = methods.watch('cgv')
  const [submitDisabled, setSubmitDisabled] = React.useState(
    user && items?.TotalPayInCredits > 0 && items?.TotalPayInCredits > user?.Credits
  )

  const [paying, setPaying] = React.useState(false)

  React.useEffect(() => {
    setSubmitDisabled(!cgvChecked || (user && items.TotalPayInCredits > 0 && items.TotalPayInCredits > user.Credits))
  }, [cgvChecked])

  React.useEffect(() => {
    apiCall(apiActions.getCart, {}, 'post', {
      orders: cart.get(),
    }).then((response) => {
      setItems(response)
    })
  }, [cart])

  React.useEffect(() => {
    if (user) {
      methods.setValue('isUser', true)
      if (payments.Credits) setValueChecked('credit')
      else setValueChecked(hasMoney ? 'money' : 'onsite')
    } else {
      methods.setValue('isUser', false)
    }
  }, [user])

  React.useEffect(() => {
    if (user && total && user.Credits < total.Credits) {
      setValueChecked(hasMoney ? 'money' : 'onsite')
    }

    if (!user) setValueChecked(hasMoney ? 'money' : 'onsite')

    if (totalIsZero && user) setValueChecked('credit')
  }, [total, totalIsZero])

  React.useEffect(() => {
    methods.setValue('isPerson', isPerson)
  }, [isPerson])

  const handleCheck = (value) => (e) => {
    if (value === 'credit' && user.Credits < total.Credits) {
      setAlert(t('payment.credits.not.enough'))
    } else {
      if (totalIsZero) {
        setAlert(t('cart.all.must.pay.credits'))
      } else {
        setAlert(null)
        if (value) {
          setValueChecked(value)
        } else {
          setValueChecked(e.target.value)
        }
      }
    }
  }

  const goToErrorPage = () => {
    history.push(getRoutePath('payment-error'))
  }

  const onFormSubmit = (values) => {
    setSubmitDisabled(true)
    return new Promise((resolve, reject) => {
      setPaying(true)
      const data = { ...values, cart: cart.get(), paymentType: totalIsZero && !user ? 'onsite' : valueChecked }
      apiCall(apiActions.paymentPay, {}, 'post', data)
        .then((r) => {
          const ret = r.RedirectUrl
          if (!ret) {
            goToErrorPage()
          } else {
            if (!r.UrlOK && !r.UrlKO) {
              history.push(ret)
            } else {
              let urlOKToSend = r.UrlOK
              let urlKOToSend = r.UrlKO
              if (process.env.NODE_ENV === 'development') {
                urlOKToSend = 'http://localhost:3000' + r.UrlOK
                urlKOToSend = 'http://localhost:3000' + r.UrlKO
              }
              const urltosend =
                ret +
                '?' +
                new URLSearchParams({ TpeId: r.TpeId }).toString() +
                '&' +
                (user ? new URLSearchParams({ UserId: user ? user.Id : '' }).toString() + '&' : '') +
                new URLSearchParams({ Credit: r.Credit }).toString() +
                '&' +
                new URLSearchParams({ UrlOK: urlOKToSend }).toString() +
                '&' +
                new URLSearchParams({ UrlKO: urlKOToSend }).toString() +
                '&' +
                new URLSearchParams({ NotifiedURL: r.NotifiedURL }).toString() +
                '&' +
                new URLSearchParams({ ReturnURL: r.ReturnURL }).toString() +
                // "&" + new URLSearchParams({ReturnURL: `http://localhost:3000/v2/payment/confirm`}).toString() +
                '&' +
                new URLSearchParams({ InvoiceId: r.InvoiceId }).toString()

              window.location = urltosend
            }
          }
          setPaying(false)
          resolve()
        })
        .catch((r) => {
          setPaying(false)
          goToErrorPage()
          reject(r)
        })
    })
  }

  if (!items)
    return (
      <Layout pageTitle={t('cart.page.title')} pageDescription={t('cart.page.description')}>
        <LoadingElem />
      </Layout>
    )
  if (items.ItemsCategory.length === 0)
    return (
      <Layout pageTitle={t('cart.page.title')} pageDescription={t('cart.page.description')}>
        <EmptyCart />
      </Layout>
    )

  const paymentChoices = []
  if (user && society.Credits && payments.Credits) {
    paymentChoices.push(
      <CardPayment
        disabled={user.Credits < total.Credits || (totalIsZero && items.TotalPayInCredits === 0)}
        handleCheck={handleCheck}
        checked={valueChecked === 'credit'}
        value="credit"
        icon="portefeuille"
        title={t('payment.credits')}
        description={t('payment.credits.description')}
      />
    )
  }

  if ((user && payments.CreditCard) || (!user && payments.CreditCardExternal)) {
    paymentChoices.push(
      <CardPayment
        disabled={totalIsZero}
        handleCheck={handleCheck}
        checked={valueChecked === 'money'}
        value="money"
        icon="cb"
        title={t('payment.card')}
        description={t('payment.card.description')}
      />
    )
  }

  if ((user && payments.OnSite) || (!user && payments.OnSiteExternal)) {
    paymentChoices.push(
      <CardPayment
        disabled={totalIsZero}
        handleCheck={handleCheck}
        checked={valueChecked === 'onsite'}
        value="onsite"
        icon="maps"
        title={t('payment.on.place')}
        description={t('payment.on.place.description')}
      />
    )
  }

  const CGVUrl = user == null ? society.ExternalCGVUrl : society.CGVUrl

  if (paymentChoices.length === 0) {
    return (
      <Layout pageTitle={t('payment.page.title')} pageDescription={t('payment.page.description')}>
        <Alert severity="warning">{t('no.payment.method.available')}</Alert>
      </Layout>
    )
  }

  return (
    <Layout pageTitle={t('payment.page.title')} pageDescription={t('payment.page.description')}>
      {paying && (
        <div className={classes.paying}>
          <CircularProgress />
        </div>
      )}
      {!user && (
        <Box mb={4}>
          <Paper className={classes.paperRegister}>
            <div>
              <Typography variant="subtitle1">
                <Box component="span" color="primary.darker">
                  {t('cart.register.text')}
                </Box>
              </Typography>
            </div>
            <div>
              <CustomButton
                onClick={() => history.push(getRoutePath('register') + '?r=' + location.pathname)}
                className={classes.registerButton}
                color="primary"
                variant="contained"
              >
                {t('cart.register.button')}
              </CustomButton>
            </div>
          </Paper>
        </Box>
      )}
      {items.Total.Tickets > 0 && (
        <ForcedPaymentCard
          icon="tickets"
          title={'payment.tickets.usage'}
          description={t('payment.tickets.usage.title', { count: items.Total.Tickets })}
          text={t('payment.tickets.usage.description')}
        />
      )}
      {items.TotalPayInCredits > 0 && user && society.Credits && (
        <ForcedPaymentCard
          disabled={user.Credits < items.TotalPayInCredits}
          icon="credits"
          title={'payment.credits.usage'}
          description={t('payment.credits.usage.title', { count: items.TotalPayInCredits })}
          text={t('payment.credits.usage.description')}
        />
      )}
      <form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <div>
          {!user && (
            <Box mb={2}>
              <Box mb={2}>
                <SeparatorBox text={t('payment.user.infos')} />
              </Box>
              <Box mb={2}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} md="auto">
                    <Typography>{t('payment.user.infos.type.select.text')}</Typography>
                  </Grid>
                  <Grid item xs={6} sm="auto">
                    <CustomButton fullWidth tag isSelected={isPerson} onClick={() => setIsPerson(true)}>
                      {t('payment.user.infos.person')}
                    </CustomButton>
                  </Grid>
                  <Grid item xs={6} sm="auto">
                    <CustomButton fullWidth tag isSelected={!isPerson} onClick={() => setIsPerson(false)}>
                      {t('payment.user.infos.society')}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Box>

              <RenderForm methods={methods} isPerson={isPerson} />
            </Box>
          )}
          {!totalIsZero && (
            <div>
              <Box mb={2}>
                <SeparatorBox text={t('payment.mode')} />
              </Box>
              <Box display="flex" justifyContent="center" mb={4}>
                <RadioGroup defaultValue="money">
                  <Grid container spacing={2} className={classes.container}>
                    {paymentChoices.map((pc, index) => (
                      <Grid key={index} item xs={6} sm>
                        {pc}
                      </Grid>
                    ))}
                  </Grid>
                  {/* {alert &&
                      <ContainerAlert message={alert}/>
                    } */}
                  {/* <Grid container spacing={2} className={classes.container}>
                    {user && society.Credits && 
                      <>
                        <Grid item xs={6} sm>
                          <CardPayment disabled={user.Credits < total.Credits} handleCheck={handleCheck} checked={valueChecked === "credit"} value="credit" icon="credits" title={t("payment.credits")} description={t("payment.credits.description")}/>
                        </Grid>
                      </>
                    }
                    <Grid item xs={6} sm><CardPayment handleCheck={handleCheck} checked={valueChecked === "money"} value="money" icon="cb" title={t("payment.card")} description={t("payment.card.description")}/></Grid>
                    <Hidden smUp>
                      <ContainerAlertNotEnoughCredit/>
                    </Hidden>
                    <Grid item xs={6} sm><CardPayment handleCheck={handleCheck} checked={valueChecked === "onsite"} value="onsite" icon="maps" title={t("payment.on.place")} description={t("payment.on.place.description")}/></Grid>
                  </Grid>
                  <Hidden xsDown>
                    <ContainerAlertNotEnoughCredit/>
                  </Hidden>  */}
                </RadioGroup>
              </Box>
            </div>
          )}
        </div>
        {alert && (
          <Box mb={1}>
            <ContainerAlert message={alert} />
          </Box>
        )}

        <SeparatorBox text={t('payment.info')} />

        <Box mb={2}>
          <Divider className={classes.divider} />
        </Box>

        <Box mt={2} mb={2}>
          <Grid container justifyContent="flex-end">
            <Grid item xs sm="auto">
              <div>
                {valueChecked === 'credit' ? (
                  <SubLine
                    left={<Typography variant="h5">{t('cart.total')}</Typography>}
                    right={
                      <Typography variant="h4" className={classes.priceContainer}>
                        <Box component="span" display="flex" alignItems="center">
                          <span>{formatCost(total.Credits)}</span>
                          &nbsp;
                          <Icon
                            fontSize="inherit"
                            icon={allCurrencies.filter((c) => c.value === 'credits')[0].iconName}
                          />
                          &nbsp;
                        </Box>
                      </Typography>
                    }
                  />
                ) : (
                  <>
                    <SubLine
                      left={t('ht')}
                      right={
                        <>
                          <span>{formatCost(total.EuroHT)}</span>
                          &nbsp;
                          <Icon className={classes.smallEuroIcon} fontSize="inherit" icon={money.iconMoney} />
                        </>
                      }
                    />
                    <SubLine
                      left={t('vat')}
                      right={
                        <>
                          <span>{society.VAT * 100}</span>&nbsp;%
                        </>
                      }
                    />
                    <SubLine
                      left={<Typography variant="h5">{t('cart.total')}</Typography>}
                      right={
                        <Typography variant="h4" className={classes.priceContainer}>
                          <span>{formatCost(total.EuroTTC)}</span>
                          &nbsp;
                          <Icon className={classes.smallEuroIcon} fontSize="inherit" icon={money.iconMoney} />
                          &nbsp;
                          <sup className={classes.supTtc}>{t('ttc')}</sup>
                        </Typography>
                      }
                    />
                  </>
                )}
              </div>
            </Grid>
          </Grid>

          <Box textAlign="right">
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <FormControlLabel
                className={classes.checkboxCGV}
                onChange={(e) => methods.setValue('cgv', e.target.checked)}
                control={<Checkbox name="cgv" />}
                label={t('accept.cgv.line')}
              />
              <Box ml={1}>
                <Link target={CGVUrl ? '_blank' : '_self'} href={CGVUrl ?? ''}>
                  ({t('see.cgv.link')})
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>

        <Grid className={classes.btnContainer} container alignItems="center" spacing={1} justifyContent="flex-end">
          <Grid item xs sm="auto">
            <CustomButton
              variant="contained"
              color="secondary"
              type="submit"
              disabled={
                submitDisabled ||
                (availablePayments.length === 1 &&
                  availablePayments[0].toLocaleLowerCase() === 'credits' &&
                  user?.Credits < total.Credits)
              }
            >
              {t('payment.pay')}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </Layout>
  )
}

export default PaymentPage
