import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import CustomButton from 'components/CustomButton'
import CustomLabel from 'components/CustomLabel'
import LoadingElem from 'components/LoadingElem'
import { formatCost } from 'components/ShowPrice'
import RenderField from 'components/views/PaymentPage/RenderField'
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'
import * as Yup from 'yup'
import { apiActions } from '_actions/api_actions'

function Tpe({ tpe }) {
  const { t } = useTranslation()
  const { user, apiCall } = useContext(UserContext)
  const { society } = useContext(SocietyContext)
  const history = useHistory()

  const [cgvAccepted, setCgvAccepted] = React.useState(false)

  const [credits, setCredits] = React.useState()
  const [euroHT, setEuroHT] = React.useState()
  const [euroTTC, setEuroTTC] = React.useState()
  const [promoCode, setPromoCode] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const [codeApply, setCodeApply] = React.useState(null)

  const CGVUrl = society.CGVUrl
  const hasExtraCredits = tpe.ExtraCredits

  const calculateNewValue = (fromName, toName, baseValue) => {
    let toret = ''

    let floatValue = parseFloat(baseValue.replace(',', '.'))

    if (fromName === toName) toret = floatValue

    if (fromName === 'euroTTC') {
      if (toName === 'euroHT') {
        toret = floatValue / (1 + society.VAT)
      } else if (toName === 'credits') {
        if (hasExtraCredits) {
          let v = floatValue / (1 + society.VAT)
          toret = v + v * (tpe.ExtraCredits / 100)
        } else {
          toret = floatValue / (1 + society.VAT)
        }
      } else {
        toret = floatValue
      }
    } else {
      if (toName === 'euroHT' || toName === 'credits') {
        toret = floatValue
      } else {
        toret = floatValue + floatValue * society.VAT
      }
    }
    toret = formatCost(toret)
    return toret.toString()
  }

  const changeValues = (changed, value) => {
    if (value === '' || Number.isNaN(parseFloat(value))) {
      setCredits('')
      setEuroHT('')
      setEuroTTC('')
      return
    }

    setCredits(calculateNewValue(changed, 'credits', value))
    setEuroHT(calculateNewValue(changed, 'euroHT', value))
    setEuroTTC(calculateNewValue(changed, 'euroTTC', value))

    setCodeApply(null)
    setPromoCode('')
    setCgvAccepted(false)
  }

  const methods = useForm({
    defaultValues: {
      credits: credits,
      euroHT: euroHT,
      euroTTC: euroTTC,
      promoCode: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        credits: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
        euroHT: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
        euroTTC: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
        promoCode: Yup.string().nullable(),
      })
    ),
  })

  React.useEffect(() => {
    if (hasExtraCredits) {
      changeValues('euroTTC', (tpe.MinCredits + tpe.MinCredits * society.VAT).toString())
    } else changeValues('credits', tpe.MinCredits.toString())
  }, [])

  React.useEffect(() => {
    methods.setValue('credits', credits)
  }, [credits])

  React.useEffect(() => {
    methods.setValue('euroHT', euroHT)
  }, [euroHT])

  React.useEffect(() => {
    methods.setValue('euroTTC', euroTTC)
  }, [euroTTC])

  React.useEffect(() => {
    methods.setValue('promoCode', promoCode)
  }, [promoCode])

  const onFormSubmit = (values) => {
    let doubleValues = {}
    Object.entries(values).map(([key, value]) => (doubleValues[key] = value))

    if (doubleValues.credits < tpe.MinCredits) {
      methods.setError('credits', { message: t('buy.credits.minimum.error') })
    }
    if (doubleValues.euroHT < tpe.MinHT) {
      methods.setError('euroHT', { message: t('buy.credits.minimum.error') })
    }
    if (doubleValues.euroTTC < tpe.MinTTC) {
      methods.setError('euroTTC', { message: t('buy.credits.minimum.error') })
    }

    if (Object.entries(methods.formState.errors).length === 0) {
      setLoading(true)
      apiCall(apiActions.payCredits, {}, 'post', {
        TpeId: tpe.TpeId,
        Credit: parseFloat(values.credits.toString().replace(',', '.').replace(/\s/g, '')),
        PromoCode: values.promoCode,
      }).then((r) => {
        const ret = r.RedirectUrl
        if (!ret) {
          history.push(getRoutePath('payment-error'))
        } else {
          if (!r.UrlOK && !r.UrlKO) {
            history.push(ret)
          } else {
            const urltosend =
              ret +
              '?' +
              new URLSearchParams({ TpeId: r.TpeId }).toString() +
              '&' +
              (user ? new URLSearchParams({ UserId: user ? user.Id : '' }).toString() + '&' : '') +
              new URLSearchParams({ Credit: r.Credit }).toString() +
              '&' +
              new URLSearchParams({ UrlOK: r.UrlOK }).toString() +
              '&' +
              new URLSearchParams({ UrlKO: r.UrlKO }).toString() +
              '&' +
              new URLSearchParams({ NotifiedURL: r.NotifiedURL }).toString() +
              '&' +
              new URLSearchParams({ ReturnURL: r.ReturnURL }).toString() +
              // "&" + new URLSearchParams({ReturnURL: `http://localhost:3000/v2/payment/confirm`}).toString() +
              (r.InvoiceId ? '&' + new URLSearchParams({ InvoiceId: r.InvoiceId }).toString() : '') +
              (codeApply ? '&' + new URLSearchParams({ PromotionalCode: codeApply.code }).toString() : '')

            window.location = urltosend
          }
        }
      })
    } else {
      setLoading(false)
    }
  }

  const handleApplyPromo = () => {
    let code = methods.getValues().promoCode
    if (!code || code === '') {
      methods.clearErrors('promoCode')
      return
    }

    if (codeApply && code.toLowerCase() === codeApply.code.toLowerCase()) {
      return
    }

    apiCall(apiActions.checkPromotionalCode, {}, 'post', {
      Code: code,
    }).then((r) => {
      if (!r.IsValid) {
        methods.setError('promoCode', { message: t('Code non valide') })
      } else {
        if (r.PromotionalCodeType === 'PERCENTAGE') {
          let hasComa = credits ? credits.charAt(credits.length - 1) === '.' : null

          let ht = formatCost(parseFloat(credits) * (1 - r.ReductionToApply / 100).toString()) + (hasComa ? '.' : '')
          setEuroHT(ht)
          let newHT = parseFloat(ht.replace(',', '.'))
          setEuroTTC(formatCost((newHT + newHT * society.VAT).toString()) + (hasComa ? '.' : ''))
        } else {
          let hasComa = credits ? credits.charAt(credits.length - 1) === '.' : null

          let ht = parseFloat(credits)
          let newHT = ht - r.ReductionToApply < 0 ? 0 : ht - r.ReductionToApply
          setEuroHT(ht - r.ReductionToApply < 0 ? 0 : formatCost(ht - r.ReductionToApply))

          newHT = parseFloat(newHT)
          setEuroTTC(formatCost((newHT + newHT * society.VAT).toString()) + (hasComa ? '.' : ''))
        }
        let message =
          code +
          ' : ' +
          t('buy.credits.promo.applied', { amount: r.ReductionToApply }) +
          (r.PromotionalCodeType === 'PERCENTAGE' ? '%' : '€')
        setCodeApply({ code: code, message: message })
        methods.clearErrors('promoCode')
      }
    })
  }

  const handleChange = (name, value) => {
    if (name === 'credits') setCredits(value)
    if (name === 'euroHT') setEuroHT(value)
    if (name === 'euroTTC') setEuroTTC(value)

    setCodeApply(null)
    setPromoCode('')
    setCgvAccepted(false)
  }

  if (loading) return <LoadingElem />
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          {tpe.TpeName}
        </Typography>
      </Grid>
      <form onSubmit={methods.handleSubmit(onFormSubmit)} style={{ width: '100%' }}>
        {tpe.ExtraCredits && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center" color="textSecondary">
              {t('buy.credits.extra', { count: tpe.ExtraCredits })}
            </Typography>
          </Grid>
        )}
        <Box p={2}>
          <Box mb={2}>
            <Grid item xs={12}>
              <RenderField
                disabled={hasExtraCredits}
                value={credits}
                onChange={(e) => handleChange('credits', e.target.value)}
                onBlur={(e) =>
                  changeValues(
                    'credits',
                    parseFloat(e.target.value) < tpe.MinCredits ? tpe.MinCredits.toString() : e.target.value
                  )
                }
                onKeyPress={(e) => {
                  if (e.key === 'Enter')
                    changeValues(
                      'credits',
                      parseFloat(e.target.value) < tpe.MinCredits ? tpe.MinCredits.toString() : e.target.value
                    )
                }}
                name="credits"
                fullWidth
                methods={methods}
                label={
                  t('buy.credits.credits.label') + (hasExtraCredits ? ' ' + t('buy.credits.extra.label') : '') + '*'
                }
              />
              <Typography variant="caption" color="textSecondary">
                {t('buy.credits.minimum', { count: tpe.MinCredits })}
              </Typography>
            </Grid>
          </Box>
          {tpe.HavePromoCode && !hasExtraCredits && !tpe.IsRecurring && (
            <Box mb={2}>
              <Grid item xs={12}>
                <CustomLabel>{t('buy.credits.promo.label')}</CustomLabel>
                <Box display="flex" alignItems="center">
                  <Box flex="5" mr={1}>
                    <RenderField
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      name="promoCode"
                      fullWidth
                      methods={methods}
                    />
                  </Box>
                  <CustomButton onClick={handleApplyPromo} variant="contained" color="secondary">
                    {t('buy.credits.apply.promo')}
                  </CustomButton>
                </Box>
                {codeApply && codeApply.message && (
                  <Typography variant="caption" color="">
                    {codeApply.message}
                  </Typography>
                )}
              </Grid>
            </Box>
          )}
          <Box mb={2}>
            <Grid item xs={12}>
              <RenderField
                disabled
                value={euroHT}
                onChange={(e) => handleChange('euroHT', e.target.value)}
                onBlur={(e) => changeValues('euroHT', e.target.value)}
                onKeyPress={(e) => {
                  e.stopPropagation()
                  if (e.key === 'Enter') changeValues('euroHT', e.target.value)
                }}
                name="euroHT"
                fullWidth
                methods={methods}
                label={t('buy.credits.ht.label') + '*'}
              />
            </Grid>
          </Box>
          <Grid item xs={12}>
            <RenderField
              disabled={!hasExtraCredits}
              value={euroTTC}
              onChange={(e) => handleChange('euroTTC', e.target.value)}
              onBlur={(e) => changeValues('euroTTC', e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') changeValues('euroTTC', e.target.value)
              }}
              name="euroTTC"
              fullWidth
              methods={methods}
              label={t('buy.credits.ttc.label') + '*'}
            />
            {/* {hasExtraCredits &&
              <Typography variant="caption" color="textSecondary">
                {t('buy.credits.minimum.euros', { count: tpe.MinTTC })}€
              </Typography>
            } */}
          </Grid>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <FormControlLabel
            onChange={(e) => setCgvAccepted(e.target.checked)}
            checked={cgvAccepted}
            control={<Checkbox name="cgv" />}
            label={t('accept.cgv.line')}
          />
          <Box ml={1}>
            <Button color="primary" onClick={() => window.open(CGVUrl, '_blank')}>
              ({t('see.cgv.link')})
            </Button>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <CustomButton variant="contained" color="secondary" type="submit" disabled={!cgvAccepted}>
            {t('buy.credits.buy.button')}
          </CustomButton>
        </Box>
      </form>
    </Grid>
  )
}

export default Tpe
