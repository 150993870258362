import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import ListView from 'components/CustomComponents/ListView'
import CustomLabel from 'components/CustomLabel'
import CustomTextField from 'components/CustomTextField'
import { downloadFile } from 'components/downloadFile'
import Icon from 'components/IcoMoon/Icon'
import { formatCost } from 'components/ShowPrice'
import SquareButton from 'components/SquareButton'
import RenderField from 'components/views/PaymentPage/RenderField'
import { MoneyContext } from 'contexts/MoneyContext'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  border: {
    borderRight: '1px solid ' + theme.palette.primary.main,
  },
  line: {
    borderBottom: '1px solid ' + theme.palette.secondary.dark,
    padding: theme.spacing(0.5),
    paddingBottom: 0,
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5),
    },
  },
  header: {
    borderBottom: '1px solid ' + theme.palette.secondary.dark,
    padding: theme.spacing(0.5),
  },
  button: {
    padding: theme.spacing(1),
  },
  accordion: {
    position: 'initial',
    width: '100%',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'inherit',
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
  },
  accordionDetails: {
    padding: 0,
    paddingBottom: theme.spacing(1),
    '& .MuiGrid-item': {
      backgroundColor: alpha(theme.palette.primary.light, 0.3),
    },
  },
  smallIcon: {
    fontSize: 15,
    marginLeft: theme.spacing(0.5),
  },
}))

const InvoicesFilters = ({ onFilterChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [filters, setFilters] = React.useState({
    StartDate: null,
    EndDate: null,
  })

  const changeFilter = (name, value) => {
    if (filters[name] !== value) {
      let newFilters = { ...filters, [name]: value }
      setFilters(newFilters)
      reloadInvoices(newFilters)
    }
  }

  const reloadInvoices = (values) => {
    onFilterChange(values)
  }

  return (
    <CustomPaper>
      <Box p={4}>
        <Box mb={2}>
          <Typography gutterBottom variant="h3">
            <Box component="span" color="primary.darker" fontWeight="fontWeightBold">
              {t('my-invoices.filter.title')}
            </Box>
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {t('my-invoices.filter.subtitle')}
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {/* <Grid item xs className={classes.border}>
            <CustomTextField
              defaultValue={filters.QueryName}
              onBlur={(e) => {
                if (e.target.value !== filters.QueryName) changeFilter('QueryName', e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && e.target.value !== filters.QueryName) changeFilter('QueryName', e.target.value)
              }}
              fullWidth
              label={t('my-invoices.filter.name.label')}
              placeholder={t('my-invoices.filter.name.placeholder')}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <CustomLabel>{t('my-invoices.filter.start.label')}</CustomLabel>
            <Box display="flex" alignItems="center">
              <DatePicker
                value={filters.StartDate}
                onChange={(date) => setFilters({ ...filters, StartDate: date })}
                onClose={() => reloadInvoices(filters)}
                fullWidth
                autoOk={false}
                format="DD/MM/YYYY"
                views={['year', 'month', 'date']}
                openTo="year"
                variant="inline"
                TextFieldComponent={CustomTextField}
              />
              {filters.StartDate && (
                <IconButton>
                  <Icon
                    icon="close"
                    onClick={() => {
                      setFilters({ ...filters, StartDate: null })
                      reloadInvoices({ ...filters, StartDate: null })
                    }}
                  />
                </IconButton>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomLabel>{t('my-invoices.filter.end.label')}</CustomLabel>
            <Box display="flex" alignItems="center">
              <DatePicker
                value={filters.EndDate}
                onChange={(date) => setFilters({ ...filters, EndDate: date })}
                onClose={() => reloadInvoices(filters)}
                fullWidth
                autoOk={false}
                format="DD/MM/YYYY"
                views={['year', 'month', 'date']}
                openTo="year"
                variant="inline"
                TextFieldComponent={CustomTextField}
              />
              {filters.EndDate && (
                <IconButton>
                  <Icon
                    icon="close"
                    onClick={() => {
                      setFilters({ ...filters, EndDate: null })
                      reloadInvoices({ ...filters, StartDate: null })
                    }}
                  />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </CustomPaper>
  )
}

const ListViewComp = ({ item }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const money = useContext(MoneyContext)
  const { apiCall } = useContext(UserContext)

  const [expanded, setExpanded] = React.useState(false)

  const handleGetInvoice = (e) => {
    e.stopPropagation()
    apiCall(apiActions.getReservationInvoice, {}, 'post', { Id: item.InvoiceId }, { responseType: 'blob' }).then(
      (response) => {
        try {
          const suggestedFileName = response.headers['content-disposition']
            .split(';')[1]
            .trim()
            .split('=')[1]
            .replace(/"/g, '')
          const effectiveFileName = suggestedFileName === undefined ? 'invoice.pdf' : suggestedFileName
          downloadFile(response.data, effectiveFileName, response.headers['content-type'])
        } catch (e) {
          console.error(e)
        }
      }
    )
  }

  return (
    <Grid container spacing={1} className={classes.line} justifyContent="space-between">
      <Accordion defaultExpanded={expanded} onChange={() => setExpanded(!expanded)} className={classes.accordion}>
        <AccordionSummary>
          <Grid item xs={4}>
            <Icon icon={expanded ? 'fleche-haut' : 'fleche-bas'} />
            <Typography variant="subtitle1">{item.InvoiceNumber}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex">
              <Box mr={1} display="flex" alignItems="center">
                <Icon icon="agenda-jour" />
              </Box>
              {moment(item.InvoiceDate).format('DD/MM/YYYY')}
            </Box>
          </Grid>
          <Grid item xs>
            <Box display="flex" alignItems="center">
              <Typography variant="h3">
                <Box component="span" display="flex" alignItems="center" fontWeight="fontWeightBold">
                  {formatCost(item.Price)}
                  <Icon icon={money.iconMoney} />
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <SquareButton color="secondary" className={classes.button} onClick={handleGetInvoice}>
              <Icon icon="telechargement" />
            </SquareButton>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid container>
            {item.Orders &&
              item.Orders.map((o) => {
                const start = o.StartDate
                const end = o.EndDate
                let toPrint = ''

                if (!start && !end) toPrint = ''
                else {
                  if (!end) toPrint = t('cart.date.from', { date: moment(start).format('DD/MM/YYYY') })
                  else if (moment(start).startOf('day').isSame(moment(end).startOf('day')))
                    toPrint = t('my-invoices.date.same.day', {
                      date: moment(start).format('DD/MM/YYYY'),
                      start: moment(start).format('HH[h]mm'),
                      end: moment(end).format('HH[h]mm'),
                    })
                  else
                    toPrint = t('my.reservations.from.to', {
                      start: moment(start).format('DD/MM/YYYY'),
                      end: moment(end).format('DD/MM/YYYY'),
                    })
                }
                return (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">
                        {o.ResourceName + (o.CategoryName ? ' • ' + o.CategoryName : '')}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {toPrint}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h6" color="textSecondary">
                        <Box component="span" display="flex" alignItems="center" fontWeight="fontWeightBold">
                          {formatCost(o.Price)}
                          <Icon icon={money.iconMoney} className={classes.smallIcon} />
                        </Box>
                      </Typography>
                    </Grid>
                  </>
                )
              })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

const TitleComp = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const Title = ({ title, subtitle }) => {
    return (
      <div>
        <Typography variant="subtitle1">
          <Box component="span" color="primary.darker">
            {title}
          </Box>
        </Typography>
        {subtitle && (
          <Typography variant="subtitle2">
            <Box component="span" color="text.secondary">
              {subtitle}
            </Box>
          </Typography>
        )}
      </div>
    )
  }
  return (
    <Grid container spacing={1} justifyContent="space-between" className={classes.header}>
      <Grid item xs={4}>
        <Title title={t('my-invoices.tab.invoice-number')} subtitle={t('my-invoices.tab.invoice-number.subtitle')} />
      </Grid>
      <Grid item xs={4}>
        <Title title={t('my-invoices.tab.date')} subtitle={t('my-invoices.tab.date.subtitle')} />
      </Grid>
      <Grid item xs>
        <Title title={t('my-invoices.tab.price')} />
      </Grid>
      <Grid item xs={1}>
        <Title title={t('my-invoices.tab.action')} />
      </Grid>
    </Grid>
  )
}

function Invoices() {
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const [filters, setFilters] = React.useState({
    StartDate: null,
    EndDate: null,
  })

  return (
    <div>
      <Box mb={2}>
        <InvoicesFilters onFilterChange={setFilters} />
      </Box>
      <CustomPaper>
        <Box p={4}>
          <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <div>
              <Typography gutterBottom variant="h3">
                <Box component="span" color="primary.darker" fontWeight="fontWeightBold">
                  {t('my-invoices.invoices')}
                </Box>
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {t('my-invoices.invoices.subtitle')}
              </Typography>
            </div>
            {/* <div>
              <CustomButton variant="contained" color="primary">
                <Icon icon="telechargement" />
                &nbsp;Exporter
              </CustomButton>
            </div> */}
          </Box>

          <ListView
            reload={filters}
            showPerPageSelect
            titleComponent={TitleComp}
            perPageList={[50, 100, 200]}
            component={(item) => <ListViewComp item={item} />}
            read={(opt) => {
              return new Promise((resolve) => {
                apiCall(apiActions.getInvoices, {}, 'post', {
                  PerPage: opt.perPage,
                  Page: opt.page,
                  ...filters,
                }).then((r) => {
                  if (!r.data) {
                    resolve({
                      page: 0,
                      total: 0,
                      data: [],
                    })
                  } else {
                    resolve(r)
                  }
                })
              })
            }}
          />
        </Box>
      </CustomPaper>
    </div>
  )
}

export default Invoices
