import { Box, Typography } from '@material-ui/core'
import MuiLink from '@material-ui/core/Link'
import ActivityCard from 'components/views/CRMPages/ActivitiesPage/ActivityCard'
import Layout from 'components/views/Layout'
import { UserContext } from 'contexts/UserContext'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getRoutePath } from 'routes'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

function ActivitiesPage() {
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const { data: activities } = useSWR('activities/', () =>
    apiCall(apiActions.getAllActivities, 'get').then((r) => {
      return r.data
    })
  )

  return (
    <Layout pageTitle={t('crm.activities.title')} pageDescription={t('crm.activities.description')}>
      {activities && activities.length === 0 && (
        <Typography variant="h3" align="center" color="textSecondary">
          {t('crm.activities.none')}
        </Typography>
      )}
      {activities &&
        activities.length > 0 &&
        activities.map((a, index) => {
          return (
            <MuiLink
              underline="none"
              key={index}
              component={Link}
              to={getRoutePath('prospect-detail', { ':prospect': a.ProspectId })}
            >
              <Box mb={1}>
                <ActivityCard activity={a} />
              </Box>
            </MuiLink>
          )
        })}
    </Layout>
  )
}

export default ActivitiesPage
