import { societyActions } from '_actions/api_actions'
import { postWithAuth } from '_actions/auth_actions'

export function getTheme() {
  return postWithAuth(societyActions.getTheme, {}).then((response) => response.data)
}

export function getSociety() {
  return postWithAuth(societyActions.getSociety, {}).then((response) => response.data)
}
