import {
  alpha,
  Box,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import { DEFAULT_IMG } from 'config'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  img: {
    height: 40,
    width: 40,
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
  },
  paper: {
    backgroundColor: alpha(theme.palette.primary.light, 0.5),
    width: '100%',
    padding: theme.spacing(1),
  },
  iconPoints: {
    '& svg': {
      fontSize: 15,
      color: theme.palette.text.secondary,
    },
    padding: theme.spacing(0.5),
  },
}))

function PostCommentaries({ commentaries, onDelete }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const handleClick = (url) => {
    window.open(url, '_blank')
  }

  const handleDelete = (id) => {
    onDelete(id)
  }

  return (
    <div>
      {commentaries &&
        commentaries.map((c, index) => {
          return (
            <Box display="flex" ml={2} mb={1} key={index}>
              <Box mt={2} mr={1}>
                <img src={c.AuthorImageURL} className={classes.img} />
              </Box>
              <CustomPaper className={classes.paper}>
                <Box display="flex" justifyContent="space-between">
                  <div>
                    <Typography variant="subtitle1">
                      <Box component="span" color="primary.darker">
                        {c.AuthorFirstName + ' ' + c.AuthorLastName}
                      </Box>
                    </Typography>
                  </div>
                  <Box display="flex" alignItems="flex-start">
                    <Typography variant="subtitle1" color="textSecondary">
                      {moment(c.Date).format(isXsDown ? 'DD/MM/YY, HH:mm' : 'DD MMMM YYYY, HH:mm')}
                    </Typography>
                    {c.CanDelete && (
                      <IconButton className={classes.iconPoints} onClick={() => handleDelete(c.Id)}>
                        <Icon icon="supprimer" />
                      </IconButton>
                    )}
                  </Box>
                </Box>
                <Typography variant="body2">
                  <Box color="primary.darker" component="span" dangerouslySetInnerHTML={{ __html: c.Content }} />
                </Typography>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {c.Attachments &&
                    c.Attachments.map((a, index) => {
                      const extension = a.Extension
                      return (
                        <Box mr={1} key={index}>
                          <img
                            onClick={() => handleClick(a.Url)}
                            src={
                              extension &&
                              (extension.toLowerCase() === 'jpg' ||
                                extension.toLowerCase() === 'png' ||
                                extension.toLowerCase() === 'gif')
                                ? a.Url
                                : DEFAULT_IMG
                            }
                            className={classes.img + ' ' + classes.imgAttachment}
                          />
                        </Box>
                      )
                    })}
                </Box>
              </CustomPaper>
            </Box>
          )
        })}
    </div>
  )
}

export default PostCommentaries
