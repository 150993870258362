import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import { formatCost } from 'components/ShowPrice'
import { UserContext } from 'contexts/UserContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      flex: 1,
    },
    padding: theme.spacing(2),
  },
  indicatorContainer: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.darker,
    fontSize: 70,
  },
}))

const CARepartition = ({ filters }) => {
  const { t } = useTranslation()
  const { apiCall } = React.useContext(UserContext)
  const classes = useStyles()

  const { data } = useSWR('getCARepartition/' + JSON.stringify(filters), () =>
    apiCall(apiActions.ownerGetCAInfos, {}, 'post', filters).then((r) => {
      if (r.Error) {
        //
      } else {
        return {
          billed: r.Billed,
          consumed: r.Consumed,
          creditsLeft: r.CreditsLeft,
        }
      }
    })
  )

  const infos = data ?? {}
  const { consumed } = infos

  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom align="center">
        <Box color="primary.darker" component="span">
          {t('owner-dashboard.ca.title')}
        </Box>
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" align="center">
        {t('owner-dashboard.ca.text')}
      </Typography>
      {data ? (
        <div>
          <Box mt={8} mb={2} display="flex" justifyContent="center">
            <Icon className={classes.icon} icon="graph-proprio" />
          </Box>
          <Box px={2}>
            <Typography align="center" variant="h1">
              {formatCost(consumed).split(',')[0]} € HT
            </Typography>
            <Typography align="center" variant="subtitle1" color="textSecondary">
              {t('owner-dashboard.ca.consumed.text')}
            </Typography>
          </Box>
        </div>
      ) : (
        <Box p={2}>
          <LoadingElem minHeight="50px" />
        </Box>
      )}
    </div>
  )
}

export default CARepartition
