import { Box, Button, Dialog, Divider, Grid, Hidden, makeStyles, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { DatePicker } from '@material-ui/pickers'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import MyReservationsDetails from 'components/views/MyReservationsPage/MyReservationsDetails'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const xsWidth = '40px'
const xsHeight = '40px'
const smWidth = '60px'
const smHeight = '60px'
const mdWidth = '55px'
const mdHeight = '60px'

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttons: {
    '& .disabled': {
      color: 'inherit',
    },
    '& .today': {
      color: theme.palette.primary.main,
    },
    minWidth: 'inherit',
    [theme.breakpoints.down('md')]: {
      width: mdWidth,
      height: mdHeight,
    },
    [theme.breakpoints.down('sm')]: {
      width: smWidth,
      height: smHeight,
    },
    [theme.breakpoints.down('xs')]: {
      width: xsWidth,
      height: xsHeight,
    },
    '& .MuiButton-label': {
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
      },
    },
  },
  scheduler: {
    // color: theme.palette.primary.darker,
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.subtitle1.fontWeight,
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.caption.fontSize,
    },
  },
  schedulerDay: {
    padding: theme.spacing(1),
  },
  selectedDay: {
    '& > button': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.getContrastText(theme.palette.secondary.dark),
      },
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.getContrastText(theme.palette.secondary.main),
    },
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.getContrastText(theme.palette.secondary.main),
    },
  },
  gridScheduler: {
    '& .MuiPickersBasePicker-pickerViewLandscape': {
      overflow: 'hidden',
    },
    '& .MuiPickersBasePicker-container': {
      flexDirection: 'inherit',
      '& > div:first-child': {
        maxWidth: 'inherit !important',
      },
      '& .MuiPickersBasePicker-pickerView': {
        [theme.breakpoints.down('xs')]: {
          padding: 0,
        },
        '& .MuiPickersCalendarHeader-switchHeader': {
          display: 'flex',
          justifyContent: 'center',
          color: theme.palette.primary.darker,
          '& .MuiPickersSlideTransition-transitionContainer': {
            width: 'auto',
            // overflow: "visible",
            paddingRight: theme.spacing(8),
            paddingLeft: theme.spacing(8),
          },
          '& .MuiTypography-root': {
            fontSize: theme.typography.subtitle1.fontSize,
            fontWeight: theme.typography.subtitle1.fontWeight,
          },
        },
        '& .MuiPickersCalendarHeader-daysHeader': {
          '& .MuiPickersCalendarHeader-dayLabel': {
            width: '100%',
          },
          '& > span': {
            fontSize: theme.typography.h5.fontSize,
            color: theme.palette.text.secondary,
            [theme.breakpoints.down('xs')]: {
              fontSize: theme.typography.body2.fontSize,
            },
          },
        },
      },
      '& .MuiPickersCalendar-transitionContainer': {
        '& div:first-child': {
          position: 'relative',
          '& .MuiPickersCalendar-week > div': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            '& .MuiBadge-badge': {
              bottom: theme.spacing(1),
              top: 'inherit',
              right: '45%',
              transform: 'inherit',
              [theme.breakpoints.down('xs')]: {
                bottom: theme.spacing(0.25),
              },
            },
          },
        },
        '& .MuiPickersCalendar-week > div': {
          display: 'block !important',
          '& > *': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            height: 'auto',
          },
        },
        '& .MuiPickersCalendar-week': {
          minHeight: 70,
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
  },
  globalBox: {
    position: 'relative',
    '& .dialogOpened': {
      filter: 'blur(4px)',
      [theme.breakpoints.up('lg')]: {
        filter: 'inherit',
      },
    },
  },
  dialog: {
    position: 'absolute',
    bottom: '0',
    zIndex: '1',
  },
  boxIcon: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
  },
  icon: {
    width: '6px',
    height: 'calc(5px)',
  },
  iconBounds: {
    width: '15px',
    height: 'calc(10px)',
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function MyReservationsScheduler({ withoutTitle }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { apiCall } = React.useContext(UserContext)

  const [date, setDate] = React.useState(new Date())
  const [items, setItems] = React.useState([])
  const [itemsSelected, setItemsSelected] = React.useState(null)
  const [dialogOpened, setDialogOpened] = React.useState(false)
  const [actualMonth, setActualMonth] = React.useState(moment())
  const [loading, setLoading] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)

  const [startOfLoc, setStartOfLoc] = React.useState([])
  const [ponctual, setPonctual] = React.useState([])
  const [endOfLoc, setEndOfLoc] = React.useState([])
  const [others, setOthers] = React.useState([])

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    handleMonthChange(moment().set({ h: 0, m: 0, s: 0, ms: 0 }).toDate(), true)
  }, [refresh])

  React.useEffect(() => {
    let ponctual = []
    let startofloc = []
    let endofloc = []
    let others = []

    if (items) {
      items.map((i) => {
        let start = moment(i.Start)
        let end = i.End ? moment(i.End) : null

        let isStartInThisMonth = start.format('MMMM YYYY') == moment(actualMonth).format('MMMM YYYY')
        let isEndInThisMonth = end && end.format('MMMM YYYY') == moment(actualMonth).endOf('month').format('MMMM YYYY')
        let isPonctual =
          (end &&
            start.format('DD MMMM YYYY') === end.format('DD MMMM YYYY') &&
            start.format('MMMM') === moment(actualMonth).format('MMMM')) ||
          i.IsOrder
        if (isPonctual) {
          if (ponctual.indexOf(start.date()) < 0) ponctual.push(start.date())
        }
        if (isStartInThisMonth && !isPonctual) {
          if (startofloc.indexOf(start.date()) < 0) startofloc.push(start.date())
        }
        if (isEndInThisMonth && !isPonctual) {
          if (endofloc.indexOf(end.date()) < 0) endofloc.push(end.date())
        }
        if (!isPonctual) {
          start = isStartInThisMonth
            ? moment(i.Start).add(1, 'day').set({ h: 0, m: 0, s: 0, ms: 0 }).date()
            : moment(actualMonth).set({ h: 0, m: 0, s: 0, ms: 0 }).date()
          end = !end
            ? moment(actualMonth).endOf('month').date()
            : isEndInThisMonth
            ? moment(i.End).date()
            : moment(actualMonth).endOf('month').date()

          while (start < end) {
            if (others.indexOf(start) < 0) {
              others.push(start)
            }
            start += 1
          }
        }
      })
    }

    setPonctual(ponctual)
    setStartOfLoc(startofloc)
    setEndOfLoc(endofloc)
    setOthers(others)
  }, [items, date])

  const handleMonthChange = (newdate, refreshDate) => {
    let d = moment(newdate).startOf('month').format()
    let end = moment(d).endOf('month').format()
    setActualMonth(newdate)
    setLoading(true)
    return new Promise((resolve) => {
      apiCall(apiActions.getBetweenDates, {}, 'post', {
        start: d,
        end: end,
      }).then((r) => {
        if (r.Error) {
          //
        } else {
          setItems(r.data)
          if (refreshDate) {
            handleChangeDate(moment().toDate(), r.data)
          }
        }
        setLoading(false)
        resolve(r)
      })
    })
  }
  const handleChangeDate = (newdate, it) => {
    setDate(newdate)
    let itemsToShow = []
    let allitems = items
    if (it) {
      allitems = it
    }

    if (allitems) {
      allitems.map((i) => {
        if (i.IsOrder) {
          if (moment(i.Start).format('DD MMMM YYYY') == moment(newdate).format('DD MMMM YYYY')) {
            itemsToShow.push(i)
          }
        } else {
          if (!i.End) {
            if (moment(i.Start).toDate() <= moment(newdate).toDate()) itemsToShow.push(i)
          } else {
            if (
              moment(i.Start).format('YYYY-MM-DD') <= moment(newdate).format('YYYY-MM-DD') &&
              moment(i.End).format('YYYY-MM-DD') >= moment(newdate).format('YYYY-MM-DD')
            )
              itemsToShow.push(i)
          }
        }
      })
    }
    setItemsSelected(itemsToShow)

    if (isSmDown) setDialogOpened(true)
  }

  const DayComponent = ({ day, disabled, today, ponctual, startOfLoc, endOfLoc, others, date }) => {
    let selected = day.format('DD MMMM YYYY') === date.format('DD MMMM YYYY')

    return (
      <Box className={selected ? classes.selectedDay : ''}>
        <Button disabled={disabled} className={classes.buttons}>
          <Box className={classes.schedulerDay + (disabled ? ' disabled' : '') + (today && !selected ? ' today' : '')}>
            {day.format('DD')}
            <Box className={classes.boxIcon} color="primary">
              {ponctual && <Icon className={classes.icon} icon="dot" />}
              {(others || startOfLoc || endOfLoc) && (
                <Box display="block" pl={0.5} width="20px" height="5px">
                  {others && (
                    <div>
                      <Divider className={classes.divider} />
                    </div>
                  )}
                  {startOfLoc && (
                    <Box display="flex" pt={0.5}>
                      <Icon className={classes.iconBounds} icon="agenda-debut" />
                    </Box>
                  )}
                  {endOfLoc && (
                    <Box display="flex" pt={0.5}>
                      <Icon className={classes.iconBounds} icon="agenda-fin" />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Button>
      </Box>
    )
  }

  return (
    <Box className={classes.globalBox}>
      <CustomPaper>
        {!withoutTitle && (
          <Box p={3} className={dialogOpened ? ' dialogOpened' : ''}>
            <Typography variant="h4">
              <Box component="span" color="primary.darker">
                {t('myreservations.scheduler.title')}
              </Box>
            </Typography>
          </Box>
        )}
        <Box pb={4} pt={withoutTitle ? 4 : 0}>
          <Grid container>
            <Grid item xs={12} md={7} className={classes.gridScheduler + (dialogOpened ? ' dialogOpened' : '')}>
              <DatePicker
                className={classes.scheduler}
                disableToolbar
                autoOk
                leftArrowButtonProps={{
                  classes: { root: loading ? 'Mui-disabled' : '' },
                }}
                rightArrowButtonProps={{
                  classes: { root: loading ? 'Mui-disabled' : '' },
                }}
                fullWidth
                orientation="landscape"
                variant="static"
                openTo="date"
                value={date}
                onChange={(date) => handleChangeDate(date)}
                onMonthChange={(date) => handleMonthChange(date)}
                renderDay={(day, date, dayInCurrentMonth, dayComponent) => {
                  let p = ponctual.indexOf(day.date()) > -1 && dayInCurrentMonth
                  let start = startOfLoc.indexOf(day.date()) > -1 && dayInCurrentMonth
                  let end = endOfLoc.indexOf(day.date()) > -1 && dayInCurrentMonth
                  let o = others.indexOf(day.date()) > -1 && dayInCurrentMonth
                  let disabled =
                    day.set({ h: 0, m: 0, s: 0, ms: 0 }) < moment().set({ h: 0, m: 0, s: 0, ms: 0 }) ||
                    !dayInCurrentMonth
                  let today = day.format('DD MMMM YYYY') === moment().format('DD MMMM YYYY')

                  return (
                    <DayComponent
                      day={day}
                      disabled={disabled}
                      today={today}
                      ponctual={p}
                      startOfLoc={start}
                      endOfLoc={end}
                      others={o}
                      date={date}
                    />
                  )
                }}
              />
            </Grid>
            <Hidden smDown>
              <Divider className={classes.divider} flexItem orientation="vertical" />
              <Grid item sm className={classes.details}>
                <Box width="100%" p={4} display="flex" justifyContent="center">
                  <MyReservationsDetails date={date} items={itemsSelected} refresh={() => setRefresh(!refresh)} />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Box>
        <Hidden mdUp>
          <Dialog
            open={dialogOpened}
            fullWidth
            // className={classes.dialog}
          >
            <MyReservationsDetails
              date={date}
              items={itemsSelected}
              dialog
              closeDialog={() => setDialogOpened(false)}
              refresh={() => setRefresh(!refresh)}
            />
          </Dialog>
        </Hidden>
      </CustomPaper>
    </Box>
  )
}

export default MyReservationsScheduler
