import {
  Box,
  Container,
  Dialog,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Link as MuiLink,
} from '@material-ui/core'
import Layout from 'components/views/Layout'
import ItemsFilters from 'components/views/NewReservationPage/ItemsFilters'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'
import EventCard from 'area/social/views/EventsPage/EventCard'
import CustomButton from 'components/CustomComponents/CustomButton'
import LoadingElem from 'components/LoadingElem'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import CustomTextField from 'components/CustomTextField'
import image from 'assets/images/contact_admin.svg'
import Icon from 'components/IcoMoon/Icon'
import { CSSGrid } from 'components/CustomComponents/CSSGrid'
import { SocietyContext } from 'contexts/SocietyContext'
import HeroText from 'components/CustomComponents/HeroText'
import SquaredImg from 'area/social/components/SquaredImg'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import { Link } from 'react-router-dom'
import { getRoutePath } from 'routes'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  paper: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  icon: {
    fontSize: 40,
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > div': {
      position: 'relative',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      '& + div:before': {
        content: "''",
        height: '50%',
        position: 'absolute',
        margin: 'auto',
        top: 0,
        left: 0,
        bottom: 0,
        right: 'auto',
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  btnFiltersMobile: {
    minWidth: 42,
    height: 42,
  },
  image: {
    maxHeight: '40vh',
    maxWidth: '100%',
  },
  link: {
    display: 'block',
    height: '100%',
    '&, &:hover': {
      textDecoration: 'none',
    },
    flex: 1,
    '& > .MuiCardActionArea-root': {
      '&, & > .MuiCardContent-root': {
        //height:"100%"
      },
    },
  },
}))

const FilterCategories = ({ categories, onChangeCategory }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [categorySelected, setCategorySelected] = React.useState(null)

  React.useEffect(() => {
    setCategorySelected(null)
  }, [categories])

  React.useEffect(() => {
    onChangeCategory(categorySelected)
  }, [categorySelected])

  return (
    <div className={classes.tabs}>
      <div>
        <CustomButton color={!categorySelected ? 'secondary' : 'primary'} onClick={() => setCategorySelected(null)}>
          {t('events.categories.all')}
        </CustomButton>
      </div>
      {categories &&
        categories.map((c) => (
          <div key={'menu_tabs_' + c.Id}>
            <CustomButton
              color={categorySelected === c.Id ? 'secondary' : 'primary'}
              onClick={() => setCategorySelected(c.Id)}
            >
              {c.Name}
            </CustomButton>
          </div>
        ))}
    </div>
  )
}

const DialogSuggestion = ({ open, closeDialog }) => {
  const { t } = useTranslation()

  const [step, setStep] = React.useState(0)
  const [suggestion, setSuggestion] = React.useState('')

  let title = ''
  let description = ''
  let cancelButton = ''
  let nextButton = ''

  React.useEffect(() => {
    setStep(0)
  }, [open])

  switch (step) {
    case 0:
    default: {
      title = 'event.suggestion.dialog.title'
      description = 'event.suggestion.dialog.description'
      cancelButton = 'event.suggestion.dialog.cancel'
      nextButton = 'event.suggestion.dialog.next'
      break
    }
    case 1: {
      title = 'event.suggestion.done.dialog.title'
      description = 'event.suggestion.done.dialog.description'
      cancelButton = 'event.suggestion.done.dialog.cancel'
      nextButton = 'event.suggestion.done.dialog.next'
      break
    }
  }

  const handleNextButton = () => {
    if (step === 0) {
      setStep(1)
      setSuggestion('')
    } else {
      closeDialog()
    }
  }

  const handleCancelButton = () => {
    if (step === 0) {
      closeDialog()
    } else {
      setStep(0)
    }
  }

  return (
    <CustomDialog open={open} handleClose={() => closeDialog()} title={t(title)} maxWidth="lg" fullWidth>
      <Container maxWidth="md">
        {step === 1 && (
          <Box mb={1}>
            <img src={image} width="100%" height={300} />
          </Box>
        )}
        <Box mb={4}>
          <Typography variant="subtitle1" color="textSecondary" align={step === 1 ? 'center' : 'left'}>
            {t(description)}
          </Typography>
        </Box>
        {step === 0 && (
          <Box mb={2}>
            <CustomTextField
              defaultValue={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
              label={t('event.suggestion.dialog.label')}
              fullWidth
              multiline
              placeholder={t('event.suggestion.dialog.placeholder')}
            />
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <Box mr={2}>
            <CustomButton variant="outlined" color="secondary" onClick={() => handleCancelButton()}>
              <Box component="span" color="primary.darker">
                {t(cancelButton)}
              </Box>
            </CustomButton>
          </Box>
          <CustomButton variant="contained" color="secondary" onClick={() => handleNextButton()}>
            {t(nextButton)}
          </CustomButton>
        </Box>
      </Container>
    </CustomDialog>
  )
}

function EventsPage() {
  const { t } = useTranslation()
  const { apiCall, user } = useContext(UserContext)
  const { society } = useContext(SocietyContext)
  const classes = useStyles()

  const [filters, setFilters] = React.useState(false)
  const [filtersValues, setFiltersValues] = React.useState()
  const [events, setEvents] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [categories, setCategories] = React.useState([])
  const [changingCategory, setChangingCategory] = React.useState(false)
  const [openDialogSuggestion, setOpenDialogSuggestion] = React.useState(false)
  const [showMobileFilters, setShowMobileFilters] = React.useState(false)
  const [resetFilters, setResetFilters] = React.useState(false)

  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  React.useEffect(() => {
    apiCall(apiActions.getEventsFilters, {}, 'post', {
      SocietyName: society?.Name,
    }).then((r) => setFilters(r))
  }, [])

  localStorage.removeItem('startdateitem')
  localStorage.removeItem('enddateitem')

  React.useEffect(() => {
    if (filters) {
      setLoading(true)
      apiCall(apiActions.getEvents, {}, 'post', {
        ...filtersValues,
        SocietyName: society?.Name,
      }).then((r) => {
        setEvents(r.data)
        let categories = []
        let ids = []
        if (!changingCategory) {
          r.data?.map((e) => {
            if (ids.indexOf(e.CategoryId) === -1) {
              categories.push({
                Id: e.CategoryId,
                Name: e.CategoryName,
              })
              ids.push(e.CategoryId)
            }
          })
          setCategories(categories)
        }
        setChangingCategory(false)
        setLoading(false)
      })
    }
  }, [filtersValues])

  React.useEffect(() => {
    setChangingCategory(false)
  }, [filters])

  const itemsFilter = filters && (
    <ItemsFilters
      dialogIsOpen={!isLgUp && showMobileFilters}
      promFilters={filters}
      onFiltersChange={(values) => setFiltersValues(values)}
      onExitDialog={() => setShowMobileFilters(false)}
      resetFilters={resetFilters}
    />
  )

  return (
    <Layout pageTitle={t('events.page.title')} pageDescription={t('events.page.description')}>
      <Grid container spacing={2}>
        <Hidden mdDown>
          <Grid item xs={3} md={4} lg={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {itemsFilter}
              </Grid>
              {/* <Grid item xs={12}>
                <Paper className={classes.paper} onClick={() => setOpenDialogSuggestion(true)}>
                <Box align="center" p={2}>
                <Icon icon="publications" className={classes.icon} />
                </Box>
                <Typography gutterBottom variant="h3">
                <Box component="span" fontWeight="fontWeightBold">
                {t('events.suggestion.title')}
                </Box>
                </Typography>
                <Typography variant="body2">{t('events.suggestion.description')}</Typography>
                </Paper>
                <DialogSuggestion open={openDialogSuggestion} closeDialog={() => setOpenDialogSuggestion(false)} />
              </Grid> */}
            </Grid>
          </Grid>
        </Hidden>
        <Grid item xs>
          <Box display="flex" mb={1}>
            <Hidden lgUp>
              <CustomButton
                className={classes.btnFiltersMobile}
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => setShowMobileFilters(true)}
              >
                <Icon icon="filtres" />
              </CustomButton>
              <Dialog
                keepMounted
                open={showMobileFilters}
                onClose={() => setShowMobileFilters(false)}
                fullWidth={isXsDown}
              >
                {itemsFilter}
              </Dialog>
            </Hidden>
            <FilterCategories
              categories={categories}
              onChangeCategory={(categorySelected) => {
                setChangingCategory(true)
                setFiltersValues({ ...filtersValues, categoryId: categorySelected })
              }}
            />
          </Box>
          {loading ? (
            <LoadingElem />
          ) : events ? (
            events.length > 0 ? (
              <CSSGrid colWidth={230} spacing={2}>
                {events.map((e) => {
                  // return <EventCard event={e} />
                  return (
                    <CustomPaper selectable className={classes.card}>
                      <MuiLink
                        className={classes.link}
                        component={Link}
                        to={getRoutePath('event', {
                          ':coworkingspace': e.CoworkingSpaceId,
                          ':category': e.CategoryId,
                          ':event': e.Id,
                        })}
                      >
                        <Box mb={1}>
                          <SquaredImg image={e.Image} container />
                        </Box>
                        <EventCard event={e} />
                      </MuiLink>
                    </CustomPaper>
                  )
                })}
              </CSSGrid>
            ) : (
              <HeroText
                imageProps={{
                  src: image,
                  className: classes.image,
                }}
                description={t('items.empty')}
                btnProps={{
                  variant: 'outlined',
                  onClick: () => setResetFilters(!resetFilters),
                }}
                btnText={t('items.delete.filters')}
              />
            )
          ) : (
            <LoadingElem />
          )}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default EventsPage
