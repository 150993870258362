import {
  Box,
  CardActionArea,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  makeStyles,
  MenuList,
  Popover,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SquaredImg from 'area/social/components/SquaredImg'
import image from 'assets/images/no_offers.svg'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import PopoverElem from 'components/CustomComponents/PopoverElem'
import SeparatorBox from 'components/CustomComponents/SeparatorBox'
import { downloadFile } from 'components/downloadFile'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import SquareButton from 'components/SquareButton'
import CancelOrder from 'components/views/MyReservationsPage/CancelOrder'
import { DEFAULT_IMG } from 'config'
import { CoworkingSpaceContext } from 'contexts/CoworkingSpaceContext'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'
import { apiActions } from '_actions/api_actions'
import ReturnReservation from './ReturnReservation'

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    '& > *': {
      width: '100%',
    },
  },
  imgContainer: {
    position: 'relative',
    width: '100%',
    height: '90px',
    overflow: 'hidden',
  },
  oneImgContainer: {
    position: 'relative',
    width: '100%',
    height: '200px',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
  },
  imageEmpty: {
    maxHeight: '20vh',
    width: '100%',
  },
  button: {
    height: '100%',
    color: theme.palette.primary.darker,
  },
  textfield: {
    '&::before': {
      borderColor: 'red !important',
    },
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  iconButton: {
    borderRadius: theme.shape.borderRadius,
  },
  gridContainer: {
    height: '90%',
  },
}))

function RenderDates({ start, end, isOrder, isEvent }) {
  const { t } = useTranslation()

  let date = t('my.reservations.date', { date: moment(start).format('DD MMMM YYYY') })
  let hours = null
  const isSameDay = (end && moment(start).format('DD MMMM YYYY') === moment(end).format('DD MMMM YYYY')) || isOrder

  if (isOrder || isEvent) {
    hours = moment(start).format('HH[h]mm')
  } else {
    if (!isSameDay && end) {
      date = t('my.reservations.fromto', {
        start: moment(start).format('DD MMMM YYYY'),
        end: moment(end).format('DD MMMM YYYY'),
      })
    }
    if (!isSameDay && !end) {
      date = t('cart.date.from', { date: moment(start).format('DD MMMM YYYY') })
    }
    if (isSameDay) {
      hours = moment(start).format('HH[h]mm') + ' - ' + moment(end).format('HH[h]mm')
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <Box display="flex" alignItems="center">
          <Icon icon="agenda-jour" />
          <Box ml={1}>
            <Typography variant="subtitle1" color="textSecondary">
              {t('myreservations.scheduler.date.title')}
            </Typography>
          </Box>
        </Box>
        <Typography color="textSecondary" variant="subtitle1">
          <Box color="primary.darker" component="span">
            {date}
          </Box>
        </Typography>
      </Grid>
      {isSameDay && (
        <Grid item xs>
          <Box display="flex" alignItems="center">
            <Icon icon="horloge" />
            <Box ml={1}>
              <Typography variant="subtitle1" color="textSecondary">
                {t('myreservations.scheduler.date.hours')}
              </Typography>
            </Box>
          </Box>
          <Typography color="textSecondary" variant="subtitle1">
            <Box color="primary.darker" component="span">
              {hours}
            </Box>
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

function DetailOneItem({ item, goBack, date, dialog, refresh }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dialogContent, setDialogContent] = React.useState(null)
  const [titleDialog, setTitleDialog] = React.useState(null)
  const { apiCall } = React.useContext(UserContext)

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const handleClickMore = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleOpenDialog = (content, title) => {
    setAnchorEl(null)
    setDialogContent(content)
    setTitleDialog(title)
  }

  const handleCloseDialog = () => {
    setDialogContent(null)
  }

  const handleGetInvoice = () => {
    apiCall(apiActions.getReservationInvoice, {}, 'post', { Id: item.InvoiceId }, { responseType: 'blob' }).then(
      (response) => {
        try {
          const suggestedFileName = response.headers['content-disposition']
            .split(';')[1]
            .trim()
            .split('=')[1]
            .replace(/"/g, '')
          const effectiveFileName = suggestedFileName === undefined ? 'invoice.pdf' : suggestedFileName
          downloadFile(response.data, effectiveFileName, response.headers['content-type'])
          setAnchorEl(null)
        } catch (e) {
          console.error(e)
        }
      }
    )
  }

  if (!item) return <LoadingElem />
  return (
    <>
      {goBack && (
        <Box display="flex" mb={1}>
          <Box>
            <IconButton onClick={goBack} className={classes.iconButton}>
              <Box color="primary.darker" display="flex" alignItems="center">
                <Icon icon="fleche-gauche" />
              </Box>
              <Typography variant="subtitle1">
                <Box component="span" color="primary.darker">
                  {t('myreservations.scheduler.back.list')}
                </Box>
              </Typography>
            </IconButton>
          </Box>
          {/* <Box flex="2" display="flex" alignItems="center" justifyContent="center">
            <Typography align="center" variant="subtitle1"><Box color="primary.darker" component="span">{moment(date).format("dddd DD MMMM YYYY")}</Box></Typography>
          </Box> */}
        </Box>
      )}
      <Box mb={1} display="flex" className={classes.oneImgContainer} justifyContent="center">
        <SquaredImg className={classes.image} image={item.Image} />
      </Box>
      <Box>
        <Typography variant="h3">
          <Box color="primary.darker" component="span">
            {item.ItemName}
          </Box>
        </Typography>
      </Box>
      {!dialog && (
        <Box mb={1}>
          <Typography variant="caption" color="textSecondary">
            <Box component="span" dangerouslySetInnerHTML={{ __html: item.Description ?? '' }}></Box>
          </Typography>
        </Box>
      )}
      <Box mb={2} display="flex">
        <RenderDates
          start={item.Start}
          end={item.End}
          isOrder={item.IsOrder}
          isEvent={item.OrderResourceEventId !== null}
        />
        <div>
          <SquareButton onClick={handleClickMore}>
            <Icon icon="trois-petits-points" />
          </SquareButton>
        </div>
        <Popover
          open={anchorEl ? true : false}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          elevation={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuList>
            {item.InvoiceId && (
              <PopoverElem icon="facture" text={t('reservation.see.bill')} onClick={handleGetInvoice} />
            )}
            {item.InvoiceId || !item.Prices || item.NbTickets > 0 ? (
              <PopoverElem disabled text={t('cancel.order.impossible')} />
            ) : (
              <PopoverElem
                icon="supprimer"
                text={t('reservation.cancel')}
                onClick={() =>
                  handleOpenDialog(
                    <CancelOrder item={item} handleClose={handleCloseDialog} />,
                    t('reservation.cancel.order.title')
                  )
                }
              />
            )}
            {!item.End && item.OrderResourceRentId && item.CanReturnLoc && (
              <PopoverElem
                icon="agenda-mois"
                text={t('reservation.take.back')}
                onClick={() =>
                  handleOpenDialog(
                    <ReturnReservation item={item} refresh={refresh} handleClose={handleCloseDialog} />,
                    item.ItemName
                  )
                }
              />
            )}
          </MenuList>
        </Popover>
        <CustomDialog
          title={titleDialog}
          fullScreen={isXsDown}
          open={dialogContent}
          maxWidth="md"
          fullWidth
          handleClose={handleCloseDialog}
        >
          {dialogContent}
        </CustomDialog>
      </Box>
      {/* Boutons pour invitation, à décommenter et commenter la ligne au dessus du SquareButton */}
      {/* <Box display="flex">
        <CustomButton fullWidth onClick={() => setInviteOpen(true)} color="secondary" variant="contained">Inviter</CustomButton>
        <Box ml={2}><SquareButton onClick={handleClickMore}><Icon icon="trois-petits-points" /></SquareButton></Box>
      </Box>
      <Dialog
          fullScreen={isXsDown} 
          open={inviteOpen}
          maxWidth="lg"
          fullWidth
        >
          <Invite item={item} handleClose={() => setInviteOpen(false)}/>
      </Dialog> */}
    </>
  )
}

function DetailMultipleItems({ item, dialog, date, goBack }) {
  const classes = useStyles()

  return !dialog ? (
    <Box display="flex" justifyContent="center" p={0.5} className={classes.fullWidth}>
      <Box display="block">
        <Box mb={1}>
          <SeparatorBox text={item.CategoryName} />
        </Box>
        <Box mb={1} display="flex" className={classes.imgContainer} justifyContent="center">
          <SquaredImg className={classes.image} image={item.Image} />
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="subtitle1" align="center">
            <Box color="primary.darker" component="span">
              {item.ItemName}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  ) : (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <div>
          <Typography variant="subtitle1">
            <Box color="primary.darker" component="span">
              {item.ItemName}
            </Box>
          </Typography>
        </div>
        <div>
          <SeparatorBox text={item.CategoryName} />
        </div>
      </Box>
      <Divider className={classes.divider} />
    </div>
  )
}

function MyReservationsDetails({ date, items, dialog, closeDialog, refresh }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { user } = useContext(UserContext)
  const { coworkingSpace } = useContext(CoworkingSpaceContext)
  const [render, setRender] = React.useState(null)

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    setRender(null)
  }, [items])

  const handleItemClick = (item) => {
    setRender(item)
  }

  return (
    <Box width="100%" p={dialog ? 2 : 0}>
      {!render && !dialog && (
        <Box display="flex" justifyContent="center" mb={2}>
          <Typography aling="center" variant="subtitle1">
            <Box color="primary.darker" component="span">
              {moment(date).format('dddd DD MMMM YYYY')}
            </Box>
          </Typography>
        </Box>
      )}
      <Grid container spacing={2} className={classes.gridContainer}>
        {render && (
          <Grid item xs={12}>
            <DetailOneItem item={render} goBack={() => setRender(null)} date={date} dialog={dialog} refresh={refresh} />
          </Grid>
        )}
        {!render && items && items.length === 1 && (
          <Grid item xs={12}>
            <DetailOneItem item={items[0]} goBack={closeDialog} refresh={refresh} />
          </Grid>
        )}
        {!render && items && items.length > 1 && (
          <>
            {dialog && (
              <Box display="flex" mb={2} width="100%">
                <Link onClick={closeDialog}>
                  <Box display="flex" alignItems="center" color="text.secondary">
                    <Icon icon="fleche-gauche" />
                    <Typography variant="subtitle1">{t('myreservations.scheduler.back')}</Typography>
                  </Box>
                </Link>
                <Box display="flex" justifyContent="center" width="100%">
                  <Typography align="center" variant="subtitle1">
                    <Box color="primary.darker" component="span">
                      {moment(date).format('dddd DD MMMM YYYY')}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            )}
            {items.map((i, index) => (
              <Grid key={index} item xs={12} md={6} lg={4}>
                <CardActionArea onClick={() => handleItemClick(i)}>
                  <DetailMultipleItems item={i} dialog={dialog} date={date} goBack={() => setRender(null)} />
                </CardActionArea>
              </Grid>
            ))}
          </>
        )}
        {!render && (!items || items.length === 0) && (
          <>
            {isSmDown && (
              <IconButton onClick={closeDialog} className={classes.iconButton}>
                <Box color="primary.darker" display="flex" alignItems="center">
                  <Icon icon="fleche-gauche" />
                </Box>
                <Typography variant="subtitle1">
                  <Box component="span" color="primary.darker">
                    {t('myreservations.scheduler.back')}
                  </Box>
                </Typography>
              </IconButton>
            )}
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
              <Box display="block">
                <img src={image} className={classes.imageEmpty} alt="" />
                <Box mb={2}>
                  <Typography variant="h5" align="center">
                    {t('myreservations.scheduler.none')}
                  </Typography>
                </Box>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() =>
                    history.push(
                      getRoutePath('newreservation', {
                        ':coworkingspace': user ? user.CoworkingSpaceId : coworkingSpace?.Id,
                      })
                    )
                  }
                >
                  {t('myreservations.scheduler.new')}
                </CustomButton>
              </Box>
            </Box>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default MyReservationsDetails
