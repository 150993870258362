import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'
import React from 'react'
import { MapContainer, MapContainerProps, Marker, MarkerProps, TileLayer } from 'react-leaflet'

const iconSize = [25, 41]
const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [iconSize[0], iconSize[1]],
  iconAnchor: [iconSize[0] / 2, iconSize[1]],
})

L.Marker.prototype.options.icon = DefaultIcon

interface CustomOSMapProps extends MapContainerProps {
  markers?: MarkerProps[]
  containerHeight?: number | string
  containerWidth?: number | string
}

const CustomOSMap: React.FC<CustomOSMapProps> = (props) => {
  let { markers, zoom = 4, center = [0, 0], scrollWheelZoom = true, ...rest } = props

  markers = markers ?? []
  zoom = zoom ?? 10
  center = center ?? [0, 0]
  scrollWheelZoom = scrollWheelZoom ?? true

  return (
    <MapContainer {...rest} center={center} zoom={zoom} scrollWheelZoom={scrollWheelZoom}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markers.map((m, i) => (
        <Marker key={'marker_' + i} {...m}></Marker>
      ))}
    </MapContainer>
  )
}

export { CustomOSMap }
