import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Grid, makeStyles, MenuItem, Select } from '@material-ui/core'
import CustomLabel from 'components/CustomLabel'
import RenderField from 'components/views/PaymentPage/RenderField'
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { getRoutePath } from 'routes'
import * as Yup from 'yup'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: 0,
    marginRight: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
  },
}))

function CreateProspect({ handleClose }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { user, apiCall } = useContext(UserContext)
  const { society } = useContext(SocietyContext)

  const methods = useForm({
    defaultValues: {
      CoworkingSpaceId: user?.CoworkingSpaceId,
    },
    resolver: yupResolver(
      Yup.object().shape({
        CoworkingSpaceId: Yup.string(t('common.field.must.string')).required(t('common.field.required')),
        FirstName: Yup.string(t('common.field.must.string')).required(t('common.field.required')),
        LastName: Yup.string(t('common.field.must.string')).required(t('common.field.required')),
      })
    ),
  })

  const onFormSubmit = (values) => {
    new Promise((resolve, reject) => {
      apiCall(apiActions.addProspect, {}, 'post', values).then((r) => {
        history.push(getRoutePath('prospect-detail', { ':prospect': r.ProspectId }))
        resolve()
      })
      handleClose()
      resolve()
    })
  }

  return (
    <form onSubmit={methods.handleSubmit(onFormSubmit)}>
      <Controller
        name="CoworkingSpaceId"
        control={methods.control}
        render={({ field }) => {
          return (
            <Box mb={1}>
              <CustomLabel>Espace de coworking</CustomLabel>
              <Select
                onChange={(v) => field.onChange(v)}
                defaultValue={user?.CoworkingSpaceId}
                fullWidth
                variant="outlined"
                displayEmpty
              >
                {society.CoworkingSpaces?.map((c) => {
                  return (
                    <MenuItem key={c.Id} value={c.Id}>
                      {c.Name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          )
        }}
      />
      <Box display="flex" justifyContent="center" mb={2}>
        <Box mr={2}>
          <RenderField methods={methods} name="FirstName" label={t('crm.prospect.create.firstname')} />
        </Box>
        <div>
          <RenderField methods={methods} name="LastName" label={t('crm.prospect.create.lastname')} />
        </div>
      </Box>

      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs sm={3}>
          <Button fullWidth onClick={handleClose} variant="outlined" color="secondary">
            <Box component="span" color="primary.darker">
              {t('crm.prospect.create.button.cancel')}
            </Box>
          </Button>
        </Grid>
        <Grid item xs sm={3}>
          <Button fullWidth type="submit" variant="contained" color="secondary">
            {t('crm.prospect.create.button.create')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CreateProspect
