import { alpha, Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import SeparatorBox from 'components/CustomComponents/SeparatorBox'
import ActivityDialog from 'components/views/CRMPages/ProspectPage/ActivityDialog'
import { getActivityText } from 'components/views/CRMPages/Utils/Enums'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  lost: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '& .MuiButton-label': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.light, 0.2),
      borderColor: theme.palette.error.main,
    },
  },
  won: {
    borderColor: theme.palette.success.main,
    '& .MuiButton-label': {
      color: theme.palette.success.main,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.light, 0.2),
      borderColor: theme.palette.success.main,
    },
  },
}))

const InfoActivity = ({ activity }) => {
  const { t } = useTranslation()

  return (
    <CustomPaper>
      <Box p={4}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography gutterBottom variant="subtitle1">
              <Box component="span" color="primary.darker">
                {t('crm.activity.close.infos')}
              </Box>
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {activity.ActivityOwner?.Name}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {t('crm.activity.close.infos.date', { date: moment(activity.Date).format('DD MMM YYYY') })}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {t(getActivityText(activity.Type))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Typography gutterBottom variant="subtitle1">
              <Box component="span" color="primary.darker">
                {t('crm.activity.close.comment')}
              </Box>
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {activity.Title}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </CustomPaper>
  )
}

function CloseActivity({ prospect, activity, handleClose, refreshData, onEnd }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div>
      <Box mb={2}>
        <Alert severity="success">
          <AlertTitle>{t('activities.close.recap')}</AlertTitle>
          {t('activities.close.recap.subtitle')}
        </Alert>
      </Box>
      <Box mb={2}>
        <InfoActivity activity={activity} />
      </Box>
      <Box mb={2}>
        <SeparatorBox text={t('crm.activity.close.create.title')} />
      </Box>
      <Box mb={3}>
        <ActivityDialog
          prospect={prospect}
          noCancelButton
          closeAndCreate
          activityId={activity.Id}
          handleClose={handleClose}
          textValidateButton={t('activities.create.the.activity')}
          refreshData={refreshData}
        />
      </Box>
      {prospect?.Status === 200 && (
        <div>
          <Box mb={2}>
            <SeparatorBox text={t('activities.close.transform.title')} />
          </Box>
          <Box mb={2}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={4} md={2}>
                <Button
                  onClick={() => {
                    onEnd(false)
                  }}
                  fullWidth
                  color="primary"
                  className={classes.lost}
                  variant="outlined"
                >
                  {t('crm.prospect.lost')}
                </Button>
              </Grid>
              <Grid item xs={4} md={2}>
                <Button
                  onClick={() => {
                    onEnd(true)
                  }}
                  fullWidth
                  color="primary"
                  className={classes.won}
                  variant="outlined"
                >
                  {t('crm.prospect.won')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </div>
  )
}

export default CloseActivity
