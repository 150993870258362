import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  spot: {
    display: 'inline-block',
    background:
      theme.palette.type === 'dark'
        ? theme.palette.getContrastText(theme.palette.background.paper)
        : theme.palette.primary.main,
    opacity: 0.5,
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
  },
}))

function Spot(props) {
  const classes = useStyles()

  return <Box {...props} className={classes.spot}></Box>
}

export default Spot
