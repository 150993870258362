/* eslint-disable react-hooks/exhaustive-deps */
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'

/**
 * Sécurise l'accès au composant
 * @param {React.Component} SpecificComponent Le composant à sécuriser
 * @param {boolean?} option Le type de sécurité à utiliser
 * - null  : Tout le monde peut accéder au composant
 * - true  : Seul les utilisateurs connectés peuvent accéder au composant
 * - false : Seul les utilisateurs déconnectés peuvent accéder au composant
 * @param {boolean?} adminRoute Indique si le composant ne doit être accessible que par les administrateurs
 */
function Auth(SpecificComponent, option, adminRoute = null) {
  const redirectUser = (history, user, society) => {
    if (user) {
      let coworkingSpace = user.CoworkingSpaceId
      if (coworkingSpace) {
        return pushTo(history, getRoutePath(`publications`))
      }
    } else if (society && society.CoworkingSpaces.length === 1) {
      if (society.CoworkingSpaces[0].CategoriesIds.length === 1) {
        return pushTo(history, getRoutePath(`publications`))
      }
      return pushTo(history, getRoutePath(`publications`))
    }
    return pushTo(history, getRoutePath(`publications`))
  }

  const pushTo = (history, to) => {
    history.push(to)
    return null
  }

  function AuthenticationCheck(props) {
    const userContext = React.useContext(UserContext)
    const { society } = React.useContext(SocietyContext)
    const history = useHistory()
    const pathname = history.location.pathname
    const query = new URLSearchParams(history.location.search)
    const from = query.get('from')

    let infos = userContext && userContext.infos ? userContext.infos : null
    if (!infos) return null

    if (!infos.isAuth) {
      if (option) {
        if (from === getRoutePath('login')) {
          return pushTo(history, getRoutePath(userContext.User ? 'publications' : 'publications'))
        } else {
          // return pushTo(history, getRoutePath('login') + '?r=' + pathname)
          return (window.location = 'https://' + window.location.hostname)
        }
      }
    } else {
      if (adminRoute && !infos.User.IsAdmin) {
        // return pushTo(history, getRoutePath(`login`) + '?r=' + pathname)
        return (window.location = 'https://' + window.location.hostname)
      } else if (option === false) {
        return pushTo(history, getRoutePath(`publications`))
      }
    }

    if (SpecificComponent) {
      return <SpecificComponent {...props} />
    } else {
      return redirectUser(history, infos.User, society)
    }
  }

  return AuthenticationCheck
}

export default Auth
