import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    padding: theme.spacing(1.75),
    '&.MuiIconButton-sizeSmall': {
      padding: theme.spacing(1.25),
    },
  },
  border: {
    color: 'inherit',
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0.5,
  },
  defaultOpacity: {
    opacity: 0.25,
  },
}))

function SquareButton(props) {
  const classes = useStyles()
  const { className, children, noBorder, color, selected = false, ...rest } = props

  const cNames = []
  const cNamesBtn = className ? (Array.isArray(className) ? className : [className]) : []

  if (!noBorder) {
    cNames.push(classes.border)
  }

  if (!color || color === 'default') {
    cNames.push(classes.defaultOpacity)
  }

  if (selected) {
    cNames.push(classes.selected)
  }

  cNamesBtn.push(classes.btn)

  return (
    <IconButton {...rest} color={color} className={cNamesBtn.join(' ')} centerRipple={false}>
      {children}
      <Box className={cNames.join(' ')}></Box>
    </IconButton>
  )
}

export default SquareButton
