import ListView from 'components/CustomComponents/ListView'
import LoadingElem from 'components/LoadingElem'
import Layout from 'components/views/Layout'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'
import CardPhotoGroup from 'area/social/views/PhotoGroupPage/CardPhotoGroup'
import {
  Box,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  setRef,
  useMediaQuery,
  useTheme,
  Link as MuiLink,
  Typography,
} from '@material-ui/core'
import LoadingPage from 'components/LoadingPage'
import SquareButton from 'components/SquareButton'
import Icon from 'components/IcoMoon/Icon'
import CustomTextField from 'components/CustomTextField'
import { useTranslation } from 'react-i18next'
import { getRoutePath } from 'routes'
import { useHistory } from 'react-router-dom'
import { SocietyContext } from 'contexts/SocietyContext'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import CustomLabel from 'components/CustomLabel'
import { CSSGrid } from 'components/CustomComponents/CSSGrid'
import SquaredImg from 'area/social/components/SquaredImg'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import { Link } from 'react-router-dom'
import SelectCoworkingSpaces from 'area/home/components/SelectCoworkingSpaces'

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiGrid-item': {
      width: '100%',
    },
  },
  buttonFilters: {
    width: 30,
    height: 30,
    '& svg': {
      fontSize: 20,
    },
  },
  searchBar: {
    '& input': {
      padding: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
        width: 300,
      },
    },
  },
  buttonCards: {
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  buttonList: {
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  progress: {
    // position: "absolute",
    // bottom: 0,
    // left: 0,
    // right: 0,
    // top: 0,
    // backdropFilter: "blur(2px)",
    // "& .MuiCircularProgress-root": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // }
  },
  link: {
    '&, &:hover': {
      textDecoration: 'none',
    },
    flex: 1,
    '& > .MuiCardActionArea-root': {
      '&, & > .MuiCardContent-root': {
        height: '100%',
      },
    },
  },
  fullHeight: {
    height: '100%',
    position: 'relative',
  },
  padding: {
    padding: theme.spacing(2),
  },
}))

const elementsStep = 50

function PhotoGroupPage() {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const { society } = useContext(SocietyContext)
  const { apiCall, user } = useContext(UserContext)

  const [nbElements, setNbElements] = React.useState(elementsStep)
  const [data, setData] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [scrollLoading, setScrollLoading] = React.useState(false)
  const [viewSelected, setViewSelected] = React.useState('card')
  const [queryName, setQueryName] = React.useState('')
  const [disabledScrollEvent, setDisabledScrollEvent] = React.useState(false)
  const [nbTotal, setNbTotal] = React.useState(null)
  const [refresh, setRefresh] = React.useState(false)
  const [csId, setCsId] = React.useState(null)
  const [showMobileFilters, setShowMobileFilters] = React.useState(false)

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  //Charge des nouveaux elements lors du scroll en bas de page
  window.onscroll = () => {
    if (
      data &&
      data.length >= nbElements &&
      window.innerHeight + window.scrollY + 50 >= document.documentElement.scrollHeight &&
      !disabledScrollEvent &&
      !loading
    ) {
      setScrollLoading(true)
      setNbElements(nbElements + elementsStep)
      setRefresh(!refresh)
    }
  }

  React.useEffect(() => {
    if (!scrollLoading) setLoading(true)
    setDisabledScrollEvent(true)
    const hasQueryName = queryName && queryName !== ''
    if (csId !== null) {
      apiCall(apiActions.getPhotogroup, {}, 'post', {
        Skip: nbElements - elementsStep,
        Take: hasQueryName ? null : elementsStep,
        QueryName: queryName,
        CoworkingSpaceId: csId,
      }).then((r) => {
        setNbTotal(r.Total)
        if (nbElements - elementsStep === 0) {
          setData(r.data)
        } else {
          let newData = data ? [...data] : []
          r.data.map((a) => newData.push(a))
          setData(newData)
        }
        setLoading(false)
        setScrollLoading(false)
        setDisabledScrollEvent(false)
      })
    }
  }, [refresh])

  React.useEffect(() => {
    if (isSmUp) setViewSelected('card')
  }, [isSmUp])

  const handleChangeQueryName = (e) => {
    const query = e.target.value
    const hasQueryName = query && query !== ''
    setDisabledScrollEvent(hasQueryName)
    if (hasQueryName) {
      setNbElements(elementsStep)
    }

    if (query !== queryName) {
      setQueryName(query)
      setRefresh(!refresh)
    }
  }

  const SearchBar = ({ total }) => {
    return (
      <CustomTextField
        defaultValue={queryName}
        fullWidth={!isSmUp}
        className={classes.searchBar}
        placeholder={total && `Rechercher parmis les ${total} utilisateurs`}
        onBlur={(e) => handleChangeQueryName(e)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleChangeQueryName(e)
        }}
      />
    )
  }

  const handleCSChange = (v) => {
    setCsId(v)
    setNbElements(elementsStep)
    setRefresh(!refresh)
  }

  const handleClick = (userId) => {
    history.push(getRoutePath('photogroup-user', { ':user': userId }))
  }

  return (
    <>
      <Layout pageTitle={t('photogroup.page.title')} pageDescription={t('photogroup.page.description')}>
        <Hidden smUp>
          <Box display="flex" alignItems="center" mb={2}>
            <Box flex="1">
              <SquareButton
                color="secondary"
                className={classes.buttonFilters}
                onClick={() => setShowMobileFilters(true)}
              >
                <Icon icon="filtres" />
              </SquareButton>
            </Box>
            <Box flex="2">
              <IconButton
                className={
                  classes.buttonFilters +
                  ' ' +
                  classes.buttonCards +
                  ' ' +
                  (viewSelected === 'card' ? classes.selected : '')
                }
                onClick={() => setViewSelected('card')}
                color="primary"
              >
                <Icon icon="encart" />
              </IconButton>
              <IconButton
                className={
                  classes.buttonFilters +
                  ' ' +
                  classes.buttonList +
                  ' ' +
                  (viewSelected === 'list' ? classes.selected : '')
                }
                onClick={() => setViewSelected('list')}
                color="primary"
              >
                <Icon icon="annuaire" />
              </IconButton>
            </Box>
            <Box flex="5">
              <SearchBar total={nbTotal} />
            </Box>
          </Box>
        </Hidden>
        <Hidden xsDown>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Box mr={1}>
              <SelectCoworkingSpaces onChange={(v) => handleCSChange(v)} emptyOption />
            </Box>
            <SearchBar total={nbTotal} />
          </Box>
        </Hidden>
        {loading ? (
          <LoadingElem />
        ) : (
          <CSSGrid colWidth={isSmUp ? 230 : 160} spacing={2}>
            {data &&
              data.map((item, index) => {
                return (
                  <MuiLink
                    className={classes.link}
                    component={Link}
                    to={getRoutePath('photogroup-user', { ':user': item.Id })}
                  >
                    <CustomPaper
                      selectable
                      className={classes.fullHeight + ' ' + classes.padding}
                      onClick={() => handleClick(item.Id)}
                    >
                      <Box mb={1}>
                        <SquaredImg image={item.Image} container />
                      </Box>
                      <CardPhotoGroup item={item} />
                    </CustomPaper>
                  </MuiLink>
                )
              })}
          </CSSGrid>
        )}
        {scrollLoading && (
          <Box className={classes.progress}>
            <CircularProgress />
          </Box>
        )}
        <CustomDialog
          open={showMobileFilters}
          handleClose={() => setShowMobileFilters(false)}
          title={t('filters.title')}
          maxWidth="md"
          keepMounted
          fullWidth
        >
          <CustomLabel>{t('filters.coworkingspace')}</CustomLabel>
          <SelectCoworkingSpaces onChange={(v) => handleCSChange(v)} emptyOption fullWidth />
          {nbTotal && (
            <Typography variant="subtitle1" color="primary">
              {t('photogroup.count.users', { count: nbTotal })}
            </Typography>
          )}
        </CustomDialog>
      </Layout>
    </>
  )
}

export default PhotoGroupPage
