import {
  Box,
  Button,
  Checkbox,
  Grid,
  makeStyles,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import CustomTextField from 'components/CustomTextField'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'
import randomColor from 'randomcolor'
import LoadingElem from 'components/LoadingElem'
import { Line } from 'react-chartjs-2'
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { UserContext } from 'contexts/UserContext'
import { onlyUnique } from 'components/views/NewReservationPage/ItemsScheduler'
import moment from 'moment'
import Icon from 'components/IcoMoon/Icon'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  graphContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: 350,
    width: '100%',
    '& > canvas': {
      height: '100% !important',
      width: '100% !important',
    },
  },
  legendContainer: {
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  boxLegend: {
    border: '1px solid ' + theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1) + 'px ' + theme.spacing(2) + 'px',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      display: 'inherit',
    },
  },
  legendhover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hidden: {
    textDecoration: 'line-through',
  },
}))

let options = {
  animation: {
    duration: 100,
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return ' ' + context.dataset.label + ' : ' + context.parsed.y + ' %'
        },
      },
    },
  },
  scales: {
    y: {
      min: 0,
      max: 100,
    },
  },
}

function OccupancyRateChart() {
  const classes = useStyles()
  const { t } = useTranslation()

  const datesLocalStorage = JSON.parse(sessionStorage.getItem('ownerfilters'))

  const { apiCall, user } = React.useContext(UserContext)
  const [labels, setLabels] = React.useState(null)

  const [series, setSeries] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const [start, setStart] = React.useState(datesLocalStorage?.startDate ?? moment().subtract(3, 'months'))
  const [end, setEnd] = React.useState(datesLocalStorage?.endDate ?? moment())

  const [datasets, setDatasets] = React.useState(null)
  const [dataChart, setDataChart] = React.useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const [legend, setLegend] = React.useState(null)
  const ref = React.useRef(null)

  const theme = useTheme()

  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    setLoading(true)
    apiCall(apiActions.ownerGetCoworkingSpacesByOwnerId, { OwnerId: user?.Id }).then((r) => {
      let coworkingSpaces = r.map((c) => c.CoworkingSpaceId)
      apiCall(apiActions.ownerGetOccupancyRateGraph, {}, 'post', {
        startDate: start,
        endDate: end,
        type: 'month',
        coworkingSpaces: coworkingSpaces,
      }).then((r) => {
        if (r.Error) {
          //
        } else {
          setSeries(r.series)
          setLoading(false)
        }
      })
    })
  }, [start, end])

  React.useEffect(() => {
    if (series) {
      setLabels(series.map((s) => s.data.map((d) => d.period))[0]?.filter(onlyUnique))

      if (!legend) {
        let legends = series.map((cs, index) => {
          return {
            text: cs.name,
            fillStyle: cs.GraphColor ?? randomColor({ seed: cs.name }),
            lineWidth: 0,
            fontColor: theme.palette.text.primary,
            datasetIndex: index,
            hidden: false,
          }
        })
        setLegend(legends)
      }
    }
  }, [series])

  React.useEffect(() => {
    if (series && labels) {
      let newDatasets = []
      series.map((s) => {
        newDatasets.push({
          label: s.name,
          data: s.data.map((d) => d.value),
          borderColor: s.GraphColor ?? randomColor({ seed: s.name }),
          backgroundColor: s.GraphColor ?? randomColor({ seed: s.name }),
          borderWidth: 3,
        })
      })
      setDatasets(newDatasets)
    }
  }, [labels])

  React.useEffect(() => {
    if (labels && datasets) {
      setDataChart({
        labels: labels,
        datasets: datasets,
      })
    }
  }, [datasets])

  const handleCheckCs = (legendItem) => {
    let newlegend = [...legend]

    const index = legendItem.datasetIndex
    const ci = ref.current
    if (ci.isDatasetVisible(index)) {
      ci.hide(index)
      newlegend[index].hidden = true
    } else {
      ci.show(index)
      newlegend[index].hidden = false
    }
    setLegend(newlegend)
  }

  const handleClick = (legendItem) => {
    const label = legendItem.text
    let newlegend = [...legend]
    const ci = ref.current

    ci.data?.datasets?.map((ds, index) => {
      if (ds.label === label) {
        if (ci.isDatasetVisible(index)) {
          ci.hide(index)
          newlegend.filter((l) => l.text === label)[0].hidden = true
        } else {
          ci.show(index)
          newlegend.filter((l) => l.text === label)[0].hidden = false
        }
      }
    })

    setLegend(newlegend)
  }

  return (
    <CustomPaper className={classes.container}>
      <Box p={4}>
        <Typography gutterBottom variant="h4">
          <Box color="primary.darker" component="span">
            {t('owner.plans.chart.title')}
          </Box>
        </Typography>
        <Box mb={2}>
          <Typography gutterBottom variant="subtitle2" color="textSecondary">
            {t('owner.plans.chart.subtitle')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <DatePicker
                label={t('common.start')}
                autoOk
                fullWidth
                color="primary"
                clearable="true"
                placeholder={t('filters.date.placeholder')}
                variant="inline"
                value={start}
                format={'MMMM yyyy'}
                openTo={'month'}
                views={['month']}
                onChange={(date) => setStart(date)}
                TextFieldComponent={CustomTextField}
                PopoverProps={{
                  elevation: 3,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                label={t('common.end')}
                autoOk
                fullWidth
                color="primary"
                clearable="true"
                placeholder={t('filters.date.placeholder')}
                variant="inline"
                value={end}
                disableFuture
                format={'MMMM yyyy'}
                openTo={'month'}
                views={['month']}
                onChange={(date) => setEnd(date)}
                TextFieldComponent={CustomTextField}
                PopoverProps={{
                  elevation: 3,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {series && !loading ? (
          dataChart ? (
            <>
              <div className={classes.graphContainer}>
                <Line options={options} data={dataChart} ref={ref} />
              </div>
              <Box className={classes.boxLegend}>
                <Box display="flex" alignItems="center" mb={isSmDown ? 1 : 0}>
                  <Box mr={1} display="flex" alignItems="center">
                    <Icon icon="legende" className={classes.icon} />
                  </Box>
                  <Box mr={4} display="flex" alignItems="center">
                    <Typography variant="subtitle1">
                      <Box component="span" color="primary.darker">
                        {t('owner-by-categories.chart.legend')}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
                {legend && (
                  <Grid container spacing={isXsDown ? 1 : 4} justify="flex-start" className={classes.legendContainer}>
                    {legend.map((l, index) => {
                      return (
                        <Grid item>
                          <Box display="flex" alignItems="center" whiteSpace="nowrap">
                            <Box mr={1}>
                              <Icon
                                className={classes.legendhover}
                                icon="dot"
                                onClick={() => handleClick(l, index)}
                                style={{ color: l.fillStyle, fontSize: 12 }}
                              />
                            </Box>
                            <Typography
                              className={classes.legendhover + ' ' + (l.hidden ? classes.hidden : '')}
                              onClick={() => handleClick(l, index)}
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {l.text}
                            </Typography>
                          </Box>
                        </Grid>
                      )
                    })}
                  </Grid>
                )}
              </Box>
            </>
          ) : (
            <Box p={2}>{t('owner-dashboard.evolutions.noserie')}</Box>
          )
        ) : (
          <Box p={2}>
            <LoadingElem minHeight="50px" />
          </Box>
        )}
      </Box>
    </CustomPaper>
  )
}

export default OccupancyRateChart
