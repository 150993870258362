import { Box, Button, Divider, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import { DEFAULT_IMG } from 'config'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  img: {
    height: 50,
    width: 40,
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('md')]: {
      width: 100,
      height: 100,
    },
  },
  iconSize: {
    fontSize: 18,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  boxActions: {
    width: 'max-content',
    cursor: 'pointer',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  breakword: {
    wordBreak: 'break-all',
    '& *': {
      fontFamily: theme.typography.subtitle1.fontFamily,
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.primary.darker,
    },
  },
  buttonAction: {
    width: 'max-content',
    cursor: 'pointer',
    display: 'flex',
    marginTop: theme.spacing(2),
    padding: 0,
  },
  buttonMail: {
    padding: 0,
  },
  dot: {
    color: theme.palette.success.main,
    fontSize: 10,
    marginRight: theme.spacing(2),
  },
  frame: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
}))

const InfoUser = ({ icon, content }) => {
  const classes = useStyles()

  return (
    <Box display="flex" mb={2}>
      <Box mr={2} alignItems="center" display="flex">
        <Icon icon={icon} color="primary" className={classes.iconSize} />
      </Box>
      <div className={classes.breakword}>
        <Typography variant="subtitle1">
          <Box component="span" color="primary.darker">
            {content}
          </Box>
        </Typography>
      </div>
    </Box>
  )
}

function ProfileInfos({ user }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  if (!user) return <LoadingElem />
  return (
    <div>
      <Box display={isSmDown ? 'flex' : 'block'} alignItems="center" mb={2}>
        <Box mr={1} display="flex">
          <img src={user.ImageURL ?? DEFAULT_IMG} className={classes.img} />
        </Box>
        <Box display="block">
          <Typography variant="h3">
            <Box component="span" color="primary.darker">
              {user.FirstName + ' ' + user.LastName}
            </Box>
          </Typography>
          <Typography gutterBottom variant="subtitle1" color="textSecondary">
            {user.Job + (user.Brand ? ' • ' + user.Brand : '')}
          </Typography>
          {user.CoworkingSpaceName && (
            <Typography variant="subtitle1" color="textSecondary">
              {t('userprofile.csname', { name: user.CoworkingSpaceName })}
            </Typography>
          )}
          {user.City && (
            <Typography variant="subtitle1" color="textSecondary">
              {t('userprofile.city', { name: user.City })}
            </Typography>
          )}
        </Box>
        {user.IsInCoworkingSpace && (
          <Box mt={1} display="flex" color="primary.darker" alignItems="center" component="span">
            <Icon icon="dot" className={classes.dot} />
            &nbsp;
            <Typography variant="subtitle2">{t('photogroup.is.entrance')}</Typography>
          </Box>
        )}
      </Box>
      <Box mb={2} display="flex" alignItems="center" flexWrap="wrap">
        {/* <Box mb={1} width="100%">
          <Button fullWidth={!isSmDown} variant="outlined" color="secondary">
            <Icon className={classes.iconSize} icon="message" color="secondary" />
            &nbsp;
            {t('userprofile.new.message')}
          </Button>
        </Box> */}
        {user.MobilePhone && (
          <Box mb={1} width="100%">
            <Button href={`tel:${user.MobilePhone}`} fullWidth={!isSmDown} variant="outlined" color="secondary">
              <Icon className={classes.iconSize} icon="appeler" color="secondary" />
              &nbsp;
              {t('userprofile.call')}
            </Button>
          </Box>
        )}
        <Button href={`mailto:${user.Email}`} fullWidth={!isSmDown} variant="outlined" color="secondary">
          <Icon className={classes.iconSize} icon="email" color="secondary" />
          &nbsp;
          {t('userprofile.mail')}
        </Button>
      </Box>

      <Divider className={classes.divider} />

      {user.Email && (
        <InfoUser
          icon="email"
          content={
            <Button className={classes.buttonMail} href={'mailto:' + user.Email}>
              {user.Email}
            </Button>
          }
        />
      )}
      {user.MobilePhone && (
        <InfoUser
          icon="appeler"
          content={
            <Button className={classes.buttonMail} href={'tel:' + user.Email}>
              {user.MobilePhone}
            </Button>
          }
        />
      )}
      {user.Website && (
        <InfoUser
          icon="internet"
          content={user.Website.startsWith('http') ? <a href={user.Website}>{user.Website}</a> : user.Website}
        />
      )}
      {user.Facebook && (
        <InfoUser
          icon="facebook"
          content={user.Facebook.startsWith('http') ? <a href={user.Facebook}>{user.Facebook}</a> : user.Facebook}
        />
      )}
      {user.Instagram && (
        <InfoUser
          icon="instagramme"
          content={user.Instagram.startsWith('http') ? <a href={user.Instagram}>{user.Instagram}</a> : user.Instagram}
        />
      )}
      {user.Youtube && (
        <InfoUser
          icon="youtube"
          content={user.Youtube.startsWith('http') ? <a href={user.Youtube}>{user.Youtube}</a> : user.Youtube}
        />
      )}
      {user.LinkedIn && (
        <InfoUser
          icon="LinkedIn"
          content={user.LinkedIn.startsWith('http') ? <a href={user.LinkedIn}>{user.LinkedIn}</a> : user.LinkedIn}
        />
      )}
      {user.Twitter && (
        <InfoUser
          icon="twitter"
          content={user.Twitter.startsWith('http') ? <a href={user.Twitter}>{user.Twitter}</a> : user.Twitter}
        />
      )}

      <Divider className={classes.divider} />

      <Box mb={2}>
        <Typography variant="subtitle2">
          <Box component="span" color="primary.darker">
            {user.Description ?? t('userprofile.no.description')}
          </Box>
        </Typography>
      </Box>

      {user.Foci && user.Foci.length > 0 && (
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Icon icon="interets" />
          </Box>
          <Typography variant="body1" color="textSecondary">
            {user.Foci.join(', ')}
          </Typography>
        </Box>
      )}
      {user.VideoDescription && (
        <Box mt={2}>
          <Divider className={classes.divider} />
          <iframe
            className={classes.frame}
            src={user.VideoDescription}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          />
        </Box>
      )}
    </div>
  )
}

export default ProfileInfos
