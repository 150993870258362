import Icon from 'components/IcoMoon/Icon'
import { CoworkingSpaceContext } from 'contexts/CoworkingSpaceContext'
import { UserContext } from 'contexts/UserContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getRoutePath } from 'routes'
import { apiActions } from '_actions/api_actions'
import WifiIcon from '@material-ui/icons/Wifi'
import FavoriteIcon from '@material-ui/icons/Favorite'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'

let m = []
const useCreateMenu = (hide) => {
  const { t } = useTranslation()
  const { user, apiUrl } = React.useContext(UserContext)
  const { coworkingSpace } = React.useContext(CoworkingSpaceContext)
  const [menu, setMenu] = React.useState(m)

  const coworkingSpaceId = coworkingSpace ? coworkingSpace.Id : null
  const hidemenu = hide

  const categories = {
    home: '',
    reservation: t('menu.reservations.title'),
    social: t('menu.social.title'),
    other: t('menu.other'),
  }

  const addToSubmenus = (submenus, submenu) => {
    let indexOf = submenus.findIndex((x) => x.name === submenu.name)
    if (indexOf === -1) {
      submenus.push(submenu)
    } else {
      submenus[indexOf] = submenu
    }
  }

  const addToCategory = (cat, submenu, iconName, isSubCategory) => {
    let indexOf = menu.findIndex((x) => x.category === cat)

    if (indexOf === -1) {
      menu.push({
        category: cat,
        submenus: [],
        icon: <Icon icon={iconName} fontSize="small" />,
        subcategories: [],
      })
      indexOf = menu.length - 1
    }

    let indexOfSubCategory = null
    if (isSubCategory) {
      indexOfSubCategory = menu[indexOf].subcategories?.findIndex((x) => x.category === submenu.category)
      if (indexOfSubCategory === -1) {
        menu[indexOf].subcategories.push({
          category: submenu.category,
          submenus: [],
          icon: submenu.iconCategory,
        })
        indexOfSubCategory = menu[indexOf].subcategories.length - 1
      }
    }

    if (isSubCategory) {
      if (Array.isArray(submenu)) {
        submenu.map((m) => addToSubmenus(menu[indexOf].subcategories[indexOfSubCategory].submenus, m))
      } else {
        addToSubmenus(menu[indexOf].subcategories[indexOfSubCategory].submenus, submenu)
      }
    } else {
      if (Array.isArray(submenu)) {
        submenu.map((m) => addToSubmenus(menu[indexOf].submenus, m))
      } else {
        addToSubmenus(menu[indexOf].submenus, submenu)
      }
    }
  }

  React.useEffect(() => {
    if (hidemenu) {
      setMenu([])
    }

    // //#region Home
    // if (user) {
    //   addToCategory(
    //     categories.home,
    //     {
    //       name: 'index',
    //       icon: <Icon icon="home" />,
    //       url: getRoutePath('index'),
    //       text: t('menu.home'),
    //     },
    //     'home'
    //   )
    // }

    // if (apiUrl(apiActions.getResourcesGivingCredits)) {
    //   addToCategory(
    //     categories.home,
    //     {
    //       name: 'print',
    //       icon: <Icon icon="facture" />,
    //       url: getRoutePath('print'),
    //       text: user?.PrintCodeApp ? t(user.PrintCodeApp) : '',
    //     },
    //     'facture'
    //   )
    // }

    // if (apiUrl(apiActions.addGuest)) {
    //   addToCategory(
    //     categories.home,
    //     {
    //       name: 'wifi',
    //       icon: <WifiIcon />,
    //       url: getRoutePath('wifi'),
    //       text: t('menu.social.wifi'),
    //     },
    //     'wifi'
    //   )
    // }

    // if (apiUrl(apiActions.getAllPartners))
    //   addToCategory(
    //     categories.home,
    //     {
    //       name: 'partners',
    //       icon: <FavoriteIcon />,
    //       url: getRoutePath('partners'),
    //       text: t('menu.partners'),
    //     },
    //     'partners'
    //   )

    // if (apiUrl(apiActions.getPlatformLink))
    //   addToCategory(categories.home, {
    //     name: 'platformlink',
    //     icon: <SwapHorizIcon />,
    //     url: '/PlatformLink',
    //     text: t('menu.platformlink'),
    //     mock: true,
    //   })

    // //#region Réservation

    // if (coworkingSpaceId) {
    //   addToCategory(
    //     categories.reservation,
    //     {
    //       name: 'newreservation',
    //       icon: <Icon icon="nouvelle-reservation" />,
    //       url: getRoutePath('newreservation', { ':coworkingspace': coworkingSpaceId }),
    //       text: t('menu.reservations.new'),
    //     },
    //     'nouvelle-reservation'
    //   )
    // }

    // if (user) {
    //   addToCategory(
    //     categories.reservation,
    //     {
    //       name: 'myreservations',
    //       icon: <Icon icon="mes-reservations" />,
    //       url: getRoutePath('myreservations'),
    //       text: t('menu.reservations.mines'),
    //     },
    //     'nouvelle-reservation'
    //   )
    // }

    //#endregion

    //#region Réseau social

    if (apiUrl(apiActions.getPhotogroup)) {
      addToCategory(
        categories.social,
        {
          icon: <Icon icon="annuaire" />,
          url: getRoutePath('photogroup'),
          text: t('menu.social.photogroup'),
        },
        'message'
      )
    }

    if (apiUrl(apiActions.getPublications)) {
      addToCategory(
        categories.social,
        {
          name: 'publications',
          icon: <Icon icon="publications" />,
          url: getRoutePath('publications'),
          text: t('menu.social.daily.news'),
        },
        'message'
      )
    }

    // if (apiUrl(apiActions.getEvents)) {
    //   addToCategory(
    //     categories.social,
    //     {
    //       name: 'events',
    //       icon: <Icon icon="evenement" />,
    //       url: getRoutePath('events'),
    //       text: t('menu.social.events'),
    //     },
    //     'message'
    //   )
    // }
    //#endregion

    //#region CRM

    // if (apiUrl(apiActions.getAllActivities)) {
    //   addToCategory(
    //     categories.other,
    //     {
    //       name: 'activities',
    //       category: subCategories.crm,
    //       iconCategory: <Icon icon="crm" />,
    //       icon: <Icon icon="activites" />,
    //       url: getRoutePath('activities'),
    //       text: t('menu.crm.activities'),
    //     },
    //     'plus',
    //     true
    //   )
    // }

    // if (apiUrl(apiActions.getAllProspect)) {
    //   addToCategory(
    //     categories.other,
    //     {
    //       name: 'prospects',
    //       category: subCategories.crm,
    //       icon: <Icon icon="inviter" />,
    //       url: getRoutePath('prospects'),
    //       text: t('menu.crm.prospects'),
    //     },
    //     'plus',
    //     true
    //   )
    // }

    // //#endregion

    // //#region Propriétaire
    // if (apiUrl(apiActions.ownerGetCAInfos)) {
    //   addToCategory(
    //     categories.other,
    //     {
    //       name: 'owner-dashboard',
    //       category: subCategories.owner,
    //       iconCategory: <Icon icon="mot-de-passe" />,
    //       icon: <Icon icon="dashboard" />,
    //       url: getRoutePath('owner-dashboard'),
    //       text: t('menu.owner.dashboard'),
    //     },
    //     'plus',
    //     true
    //   )
    // }

    // if (apiUrl(apiActions.ownerGetOrdersByCoworkingSpaceId)) {
    //   addToCategory(
    //     categories.other,
    //     {
    //       name: 'owner-by-rr',
    //       category: subCategories.owner,
    //       icon: <Icon icon="bureau" />,
    //       url: getRoutePath('owner-by-rr'),
    //       text: t('menu.owner-by-rr.dashboard'),
    //     },
    //     'plus',
    //     true
    //   )
    // }

    // if (apiUrl(apiActions.ownerGetCAByCategories)) {
    //   addToCategory(
    //     categories.other,
    //     {
    //       name: 'owner-by-categories',
    //       category: subCategories.owner,
    //       icon: <Icon icon="mes-reservations" />,
    //       url: getRoutePath('owner-by-categories'),
    //       text: t('menu.owner-by-categories.dashboard'),
    //     },
    //     'plus',
    //     true
    //   )
    //   //#endregion
    // }

    // if (apiUrl(apiActions.ownerGetPlansByCoworkingSpaceId)) {
    //   addToCategory(
    //     categories.other,
    //     {
    //       name: 'owner-plans',
    //       category: subCategories.owner,
    //       icon: <Icon icon="plan" />,
    //       url: getRoutePath('owner-plans'),
    //       text: t('menu.owner-plans.dashboard'),
    //     },
    //     'plus',
    //     true
    //   )
    // }
    // //#endregion

    // //#region Admin

    // if (apiUrl(apiActions.getAdminPlans)) {
    //   addToCategory(
    //     categories.other,
    //     {
    //       name: 'admin-plans',
    //       category: subCategories.admin,
    //       iconCategory: <Icon icon="admin" />,
    //       icon: <Icon icon="plan" />,
    //       url: getRoutePath('admin-plans'),
    //       text: t('menu.admin-plans.dashboard'),
    //     },
    //     'plus',
    //     true
    //   )
    // }

    // if (user && user.IsAdmin) {
    //   addToCategory(
    //     categories.other,
    //     {
    //       name: 'admin',
    //       category: subCategories.admin,
    //       icon: <Icon icon="admin" />,
    //       iconCategory: <Icon icon="admin" />,
    //       url: '/Admin' /* getRoutePath('admin') */,
    //       text: t('menu.admin.dashboard'),
    //       mock: true,
    //     },
    //     'plus',
    //     true
    //   )
    // }

    menu.map((i, index) => {
      if (i.category === '' && index > 0) {
        const element = menu.splice(index, 1)[0]
        menu.splice(0, 0, element)
      }
    })

    //#endregion
  }, [hidemenu, coworkingSpaceId, user])

  React.useEffect(() => {
    m = menu
  }, [menu])

  if (!coworkingSpaceId || hidemenu) return []
  return menu
}

export default useCreateMenu
