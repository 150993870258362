import { alpha, Box, Grid, Hidden, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import { MediaImage } from 'components/CustomComponents/MediaImage'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import ShowPrice, { useCurrentMoneyIcon } from 'components/ShowPrice'
import { DEFAULT_IMG } from 'config'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'

const imgSize = {
  xs: 48,
  sm: 60,
  xl: 80,
}

const useStyles = makeStyles((theme) => {
  const iconTextStyle = {
    display: 'flex',
    alignItems: 'center',
    lineHeight: theme.typography.subtitle1.lineHeight,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(13),
    '& > *:first-child': {
      flexGrow: 0,
    },
    '& > *:last-child': {
      marginLeft: theme.spacing(1),
      flewGrow: 1,
    },
    '& svg': {
      fontSize: '1rem',
    },
  }

  return {
    imageContainer: {
      [theme.breakpoints.down('xl')]: {
        width: imgSize.xl,
      },
      [theme.breakpoints.down('sm')]: {
        width: imgSize.sm,
      },
      [theme.breakpoints.down('xs')]: {
        width: imgSize.xs,
      },
    },
    image: {
      borderRadius: theme.shape.borderRadius,
    },
    borderLeft: {
      position: 'relative',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderColor: theme.palette.secondary.main,
      borderLeft: '2px dashed',
      [theme.breakpoints.only('xs')]: {
        borderLeft: 'none',
        borderTop: '2px dashed',
        paddingTop: theme.spacing(2),
        paddingBottom: 0,
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    priceContainer: {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(24),
      '& svg, div': {
        color: theme.palette.secondary.dark,
      },
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
    },
    priceTickets: {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(24),
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
    },
    prices: {
      fontWeight: theme.typography.subtitle1.fontWeight,
    },
    html: {
      fontSize: theme.typography.pxToRem(13),
      '& p': {
        marginTop: 0,
        marginBottom: 0,
      },
      '& > p': {
        '&:first-child': {
          marginTop: 0,
        },
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    zeroFontSize: {
      fontSize: 0,
    },
    relative: {
      position: 'relative',
    },
    deleteBtn: {
      position: 'absolute',
      bottom: -theme.spacing(1.5),
      right: -theme.spacing(2),
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
        top: 0,
        right: 0,
        padding: theme.spacing(1),
      },
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    capacity: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        width: 35,
        height: 35,
        '& > svg': {
          marginLeft: theme.spacing(0.25),
        },
      },
    },
    description: {
      // [theme.breakpoints.up("md")]: {
      //   maxWidth:200
      // },
      // [theme.breakpoints.up("lg")]: {
      //   maxWidth:350
      // }
    },
    iconText: { ...iconTextStyle },
    iconTextNoWrap: {
      ...iconTextStyle,
      whiteSpace: 'nowrap',
    },
    cardDisabled: {
      opacity: 0.4,
    },
    infoDisabled: {
      backgroundColor: alpha('#EB5E55', 0.3),
      padding: theme.spacing(1),
    },
    infoAlert: {
      backgroundColor: alpha('#D3D9FB', 0.2),
      color: theme.palette.primary.darker,
      '& .MuiAlert-icon': {
        color: theme.palette.primary.darker,
      },
    },
    wrap: {
      whiteSpace: 'initial',
    },
  }
})

export const RenderInfo = ({ title, description, color = 'textPrimary' /* onClick */ }) => {
  return (
    <div>
      <Box mb={1}>
        <Typography variant="subtitle1" color={color}>
          {title}
        </Typography>
      </Box>
      {description &&
        description.map((line, index) => (
          <Typography key={index} variant="subtitle1">
            <Box color="text.secondary" component="span" /* onClick={() => onClick() ?? ""} */>
              {line}
            </Box>
          </Typography>
        ))}
    </div>
  )
}

export const IconAndText = ({ icon, text, color = 'textPrimary' }) => {
  const classes = useStyles()
  const curIcon = useCurrentMoneyIcon()

  return (
    <Typography variant="body2" color={color} className={classes.iconTextNoWrap}>
      <span component="span" className={classes.zeroFontSize}>
        {icon === 'currentMoney' ? curIcon : <Icon icon={icon} />}
      </span>
      <span>{text}</span>
    </Typography>
  )
}

function RentCard({ item, dateFormat = 'DD MMMM YYYY', hourFormat = 'HH:mm', leftSize, showCancelCost = true }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const startDate = item.Dates[0] && item.Dates[0].StartDate
  const endDate = item.Dates[0] && item.Dates[0].EndDate

  let datesCompArray = []
  if (!endDate) {
    datesCompArray = [
      <IconAndText
        icon="agenda-jour"
        text={
          <>
            <Box component="span" whiteSpace="initial">
              {t('cart.cancel.date.from.without.date')}
            </Box>
            <Box component="span" whiteSpace="nowrap">
              {moment(startDate).format(dateFormat)}
            </Box>
          </>
        }
      />,
    ]
  } else if (endDate && !moment(startDate).startOf('day').isSame(moment(endDate).startOf('day'))) {
    datesCompArray = [
      <IconAndText
        icon="agenda-jour"
        text={t('cart.date.fromto.from', { from: moment(startDate).format(dateFormat) })}
      />,
      <IconAndText icon="fin" text={t('cart.date.fromto.to', { to: moment(endDate).format(dateFormat) })} />,
    ]
  } else {
    datesCompArray = [
      <IconAndText icon="agenda-jour" text={t('cart.date', { date: moment(startDate).format(dateFormat) })} />,
    ]
  }

  const Hour = ({ start, end }) => {
    let content = ''

    if (!end) {
      content = <>{t('cart.date.end.none')}</>
    } else {
      content = <>{t('cart.fromto', { from: moment(start).format(hourFormat), to: moment(end).format(hourFormat) })}</>
    }

    return content
  }

  const haveLeftUndefined = typeof leftSize === 'undefined'
  return (
    <>
      <Grid item xs={12} lg={haveLeftUndefined ? true : leftSize}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs="auto">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <CardImage imageURL={item.Image.Url} imageProps={item.Image} />
              </Grid>
              {item.Capacity && haveLeftUndefined && (
                <Hidden smUp>
                  <Grid item xs="auto">
                    <CustomPaper>
                      <Box
                        className={classes.capacity}
                        color="primary.darker"
                        borderRadius="borderRadius"
                        textAlign="center"
                      >
                        <Typography variant="body2" component="div">
                          {item.Capacity}
                        </Typography>
                        <Icon icon="mon-profil" />
                      </Box>
                    </CustomPaper>
                  </Grid>
                </Hidden>
              )}
            </Grid>
          </Grid>
          <Grid item xs>
            <Box mb={1}>
              <Typography variant="h6">
                <Box component="span" fontWeight="600">
                  {item.ItemName}
                </Box>
              </Typography>
            </Box>
            <Hidden xsDown>
              {item.Capacity && haveLeftUndefined && (
                <Hidden xsDown>
                  <Box mb={1} mt={1}>
                    <IconAndText
                      icon="mon-profil"
                      text={
                        <>
                          {item.Capacity} {t('reservations.item.infos.persons')}
                        </>
                      }
                    />
                  </Box>
                </Hidden>
              )}
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      {item.Dates && (
        <Grid item xs lg={haveLeftUndefined ? 2 : 'auto'}>
          <RenderInfo
            title={t('common.date')}
            description={[
              ...datesCompArray,
              item.Dates.filter(
                (i) =>
                  !(i.EndDate && !moment(i.StartDate).startOf('day').isSame(moment(i.EndDate).startOf('day'))) &&
                  !(i.EndDate && moment(i.StartDate).isSame(moment(i.EndDate)))
              ).map((value, index) => (
                <span key={index}>
                  <IconAndText
                    icon={value.EndDate ? 'horloge' : 'fin'}
                    text={<Hour key={index} start={value.StartDate} end={value.EndDate} />}
                  />
                </span>
              )),
            ]}
          />
        </Grid>
      )}
      {item.CancelCost && showCancelCost && !item.UseTicket && (
        <Grid item xs lg={haveLeftUndefined ? 2 : 'auto'}>
          <RenderInfo
            color="textSecondary"
            title={t('cart.cancel.cost')}
            description={[
              // <IconAndText color="textSecondary" icon="agenda-jour" text={<>{t("cart.cancel.date.from", {date: moment(item.CancelCostDate).format(dateFormat)})}</>}/>,
              <IconAndText
                color="textSecondary"
                icon="agenda-jour"
                text={
                  <>
                    <Box component="span" whiteSpace="initial">
                      {t('cart.cancel.date.from.without.date')}
                    </Box>
                    <Box component="span" whiteSpace="nowrap">
                      {moment(item.CancelCostDate).format(dateFormat)}
                    </Box>
                  </>
                }
              />,
              <IconAndText
                color="textSecondary"
                icon="currentMoney"
                text={
                  <>
                    {item.CancelCost.EuroHT === 0 ? (
                      <Box color="text.secondary" component="span">
                        {t('cart.cancel.cost.none')}
                      </Box>
                    ) : (
                      <ShowPrice
                        noIcon
                        className={classes.prices}
                        ht={item.CancelCost.EuroHT}
                        ttc={item.CancelCost.EuroTTC}
                        credits={item.CancelCost.Credits}
                      />
                    )}
                  </>
                }
              />,
            ]}
          />
        </Grid>
      )}
      {item.UseTicket && (
        <Grid item xs lg={haveLeftUndefined ? 2 : 'auto'}>
          <RenderInfo
            color="textSecondary"
            title={t('cart.cancel.cost')}
            description={[
              <Typography variant="body2" color="textSecondary" className={classes.iconText}>
                {t('cart.cancel.cost.tickets')}
              </Typography>,
            ]}
          />
        </Grid>
      )}
    </>
  )
}

function InstantBuyCard({ item /* handleDelete */ }) {
  const { t } = useTranslation()
  // const { cart, apiCall } = useContext(UserContext)

  const classes = useStyles()
  // const [openChange, setOpenChange] = React.useState(false)
  // const [quantity, setQuantity] = React.useState(item.Quantity)

  // const handleQuantityClick = () => {
  //   setOpenChange(true)
  // }

  // const handleBlur = () => {
  //   const c = cart.get()
  //   const thisItem = c.filter((i) => i.cartId === item.Id)[0]

  //   apiCall(apiActions.getTotalPrice, {
  //     coworkingSpaceId: thisItem.coworkingSpaceId,
  //     categoryId: thisItem.categoryId,
  //     itemId: thisItem.itemId
  //   }, 'post', { ...thisItem, quantity: quantity }).then((r) => {
  //     if (!r.Error && !r.Message) {
  //       handleDelete(item.Id)
  //       cart.add({...thisItem, quantity: quantity})
  //       setOpenChange(false)
  //     }
  //   })
  // }

  return (
    <>
      <Grid item xs={12} lg>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs="auto">
            <CardImage imageURL={item.Image.Url} imageProps={item.Image} />
          </Grid>
          <Grid item xs>
            <Box mb={1}>
              <Typography variant="h6">
                <Box component="span" fontWeight="600">
                  {item.ItemName}
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs lg={2}>
        {/* {openChange ? 
          <CustomTextField 
            placeholder="quantité" 
            label="Quantité" 
            defaultValue={item.Quantity} 
            onBlur={handleBlur}
            onChange={(e) => setQuantity(e.target.value)}
          />
        : */}
        <RenderInfo
          title={t('cart.quantity')}
          description={[<IconAndText icon="quantite" text={item.Quantity} />]}
          // onClick={handleQuantityClick}
        />
        {/* } */}
      </Grid>
    </>
  )
}
function EventCard({ item, dateFormat = 'DD MMMM YYYY' }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Grid item xs="auto">
        <CardImage imageURL={item.Image.Url} imageProps={item.Image} />
      </Grid>
      <Grid item xs={12} lg>
        <Box mb={1}>
          <Typography variant="h6">
            <Box component="span" fontWeight="600">
              {item.ItemName}
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs lg={2}>
        <RenderInfo title={t('cart.quantity')} description={[<IconAndText icon="quantite" text={item.Quantity} />]} />
      </Grid>
      <Grid item xs lg={2}>
        <RenderInfo
          title={t('cart.title.date.event')}
          description={[
            <IconAndText icon="agenda-jour" text={moment(item.Date).format('dddd DD MMMM YYYY')} />,
            <IconAndText icon="horloge" text={moment(item.Date).format('HH[h]mm')} />,
          ]}
        />
      </Grid>
      <Grid item xs lg="auto">
        <RenderInfo
          color="textSecondary"
          title={t('cart.cancel.cost')}
          description={[
            // <IconAndText color="textSecondary" icon="agenda-jour" text={<>{t("cart.cancel.date.from", {date: moment(item.CancelCostDate).format(dateFormat)})}</>}/>,
            <IconAndText
              color="textSecondary"
              icon="agenda-jour"
              text={
                <>
                  <Box component="span" whiteSpace="initial">
                    {t('cart.cancel.date.from.without.date')}
                  </Box>
                  <Box component="span" whiteSpace="nowrap">
                    {moment(item.CancelCostDate).format(dateFormat)}
                  </Box>
                </>
              }
            />,
            <IconAndText
              color="textSecondary"
              icon="currentMoney"
              text={
                <>
                  {item.CancelCost.EuroHT === 0 ? (
                    <Box color="text.secondary" component="span">
                      {t('cart.cancel.cost.none')}
                    </Box>
                  ) : (
                    <ShowPrice
                      noIcon
                      className={classes.prices}
                      ht={item.CancelCost.EuroHT}
                      ttc={item.CancelCost.EuroTTC}
                      credits={item.CancelCost.Credits}
                    />
                  )}
                </>
              }
            />,
          ]}
        />
      </Grid>
    </>
  )
}

function PackCard({ item }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Grid item xs="auto">
        <CardImage imageURL={item.Image.Url} imageProps={item.Image} />
      </Grid>
      <Grid item xs={12} lg>
        <RenderInfo title={item.ItemName} />
      </Grid>
      <Grid item xs={12} sm lg={4}>
        <RenderInfo
          title={t('cart.title.tickets')}
          description={[<IconAndText icon="tickets" text={item.NbTickets} />]}
        />
      </Grid>
    </>
  )
}

function OrderCard({ item }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Grid item xs="auto">
        <CardImage imageURL={item.Image.Url} imageProps={item.Image} />
      </Grid>
      <Grid item xs={12} lg>
        <Box mb={1}>
          <Typography variant="h6">
            <Box component="span" fontWeight="600">
              {item.ItemName}
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs lg={2}>
        <RenderInfo title={t('cart.quantity')} description={[<IconAndText icon="quantite" text={item.Quantity} />]} />
      </Grid>
      <Grid item xs lg={2}>
        <RenderInfo
          title={t('cart.title.date.reception')}
          description={[
            <IconAndText icon="agenda-jour" text={moment(item.Date).format('dddd DD MMMM YYYY')} />,
            <IconAndText icon="horloge" text={moment(item.Date).format('HH[h]mm')} />,
          ]}
        />
      </Grid>
    </>
  )
}

export const CardImage = ({ imageURL = DEFAULT_IMG, imageProps }) => {
  const classes = useStyles()

  return (
    <div className={classes.imageContainer}>
      <MediaImage
        container
        className={classes.image}
        src={imageURL}
        mediaParams={{
          xs: {
            mode: 'crop',
            retina: true,
            cx: imageProps?.CropX,
            cy: imageProps?.CropY,
            cWidth: imageProps?.CropWidth,
            cHeight: imageProps?.CropHeight,
          },
          sm: {
            mode: 'crop',
            retina: true,
            cx: imageProps?.CropX,
            cy: imageProps?.CropY,
            cWidth: imageProps?.CropWidth,
            cHeight: imageProps?.CropHeight,
          },
          xl: {
            mode: 'crop',
            retina: true,
            cx: imageProps?.CropX,
            cy: imageProps?.CropY,
            cWidth: imageProps?.CropWidth,
            cHeight: imageProps?.CropHeight,
          },
        }}
      />
    </div>
  )
}

function CardCart(props) {
  const { type, handleDelete, showPrice = true, item } = props
  const classes = useStyles()
  const { user } = useContext(UserContext)
  const { t } = useTranslation()

  const isDisabled = item.DisabledItem ?? false
  const infoMessage = item.InfoMessage
  const forcePaymentInCredits = item.ForcePaymentInCredits

  let compItem
  switch (type) {
    case 50:
      compItem = <OrderCard {...props} />
      break
    case 40:
      compItem = <InstantBuyCard {...props} />
      break
    case 30:
      compItem = <PackCard {...props} />
      break
    case 20:
      compItem = <EventCard {...props} />
      break
    case 10:
    case 15:
    default:
      compItem = <RentCard {...props} />
      break
  }

  const priceComp = showPrice && (
    <Grid item xs={12} sm lg={2}>
      <Box className={classes.borderLeft}>
        <div>
          {item.UseTicket ? (
            <Typography variant="h4" className={classes.priceTickets}>
              <Box mr={1} component="span">
                {item.Prices.Tickets}
              </Box>
              <Icon icon="tickets" color="secondary" />
            </Typography>
          ) : (
            <Typography variant="h4" className={classes.priceContainer}>
              <ShowPrice
                ht={item.Prices.EuroHT}
                ttc={item.Prices.EuroTTC}
                credits={item.Prices.Credits}
                force={forcePaymentInCredits ? 'credits' : null}
              />
            </Typography>
          )}
          {handleDelete && (
            <Box textAlign="center">
              <IconButton size="small" className={classes.deleteBtn} onClick={() => handleDelete(item.Id)}>
                <Icon icon="supprimer" />
              </IconButton>
            </Box>
          )}
        </div>
      </Box>
    </Grid>
  )

  return (
    <>
      <CustomPaper className={isDisabled ? classes.cardDisabled : null}>
        <Box p={2}>
          <Grid container spacing={2} alignItems="center">
            {compItem}
            {priceComp}
          </Grid>
        </Box>
      </CustomPaper>
      {infoMessage && (
        <Box mt={0.5}>
          <Alert className={!isDisabled ? classes.infoAlert : ''} severity={isDisabled ? 'error' : 'info'}>
            {t(infoMessage)}
          </Alert>
        </Box>
      )}
    </>
  )
}

export { RentCard, PackCard, InstantBuyCard, OrderCard }
export default CardCart
