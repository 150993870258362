import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import MuiLink from '@material-ui/core/Link'
import image from 'assets/images/cart_confirm.svg'
import Layout from 'components/views/Layout'
import { UserContext } from 'contexts/UserContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getRoutePath } from 'routes'

const useStyles = makeStyles((theme) => ({
  image: {
    maxHeight: '60vh',
    maxWidth: '100%',
  },
}))

function ConfirmationPage() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user, cart } = React.useContext(UserContext)

  React.useEffect(() => {
    cart.reset()
  }, [])

  const description = user ? t('payment.confirmation.description.coworker') : t('payment.confirmation.description')

  return (
    <Layout title={t('payment.confirmation.title')}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h1">
          <Box component="span" color="primary.darker">
            {t('payment.confirmation.title')}
          </Box>
        </Typography>
        <Box my={2}>
          <img className={classes.image} src={image} alt="" />
        </Box>
        <Typography color="textSecondary">{description}</Typography>
      </Box>
      <Grid className={classes.btnContainer} container alignItems="center" spacing={1} justifyContent="center">
        {user && (
          <Grid item xs sm="auto">
            <MuiLink underline="none" component={Link} to={getRoutePath('myreservations')}>
              <Button variant="outlined" color="secondary">
                {t('payment.confirmation.my.reservations')}
              </Button>
            </MuiLink>
          </Grid>
        )}
        <Grid item xs sm="auto">
          <MuiLink underline="none" component={Link} to={getRoutePath('index')}>
            <Button variant="contained" color="secondary">
              {t('cart.back.home')}
            </Button>
          </MuiLink>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ConfirmationPage
