import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import CustomLabel from 'components/CustomLabel'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'
import CoworkingSpacesButtons from './CoworkingSpacesButtons'

const useStyles = makeStyles((theme) => ({
  csContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '& div': {
      flexWrap: 'wrap',
    },
    marginTop: theme.spacing(1),
  },
}))

function Filters({ state, onChange }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { apiCall, user } = React.useContext(UserContext)

  const [filters, setFilters] = React.useState(state)
  const { startDate, endDate, coworkingSpaces } = filters

  React.useEffect(() => {
    onChange(filters)
  }, [filters])

  const changeFilterVal = (attr, val) => {
    let old = { ...filters }
    old[attr] = val
    setFilters(old)
    sessionStorage.setItem('ownerfilters', JSON.stringify(old))
  }

  const handleClr = (e, attr) => {
    e.stopPropagation()
    changeFilterVal(attr, null)
  }

  const updateCoworkingSpacesFilters = (csIds) => {
    changeFilterVal('coworkingSpaces', csIds)
  }

  return (
    <Box>
      <Typography variant="h4">
        <Box color="primary.darker" component="span">
          {t('filters.title')}
        </Box>
      </Typography>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              {t('owner-dashboard.filters.duration')}
            </Typography>
            <Box mb={2}>
              {/* <Grid container alignItems="center"> */}
              {/* <Grid item xs={12} md="auto">
                  <Box mr={2}>
                    <CustomLabel style={{ marginBottom: 0 }}>{t('owner-dashboard.filters.duration.text')}</CustomLabel>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={isMonth}
                        onChange={(e, val) =>
                          val ? changeFilterVal('type', 'month') : changeFilterVal('type', 'year')
                        }
                        name="checkedA"
                      />
                    }
                    label={t('common.month')}
                  />

                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={!isMonth}
                        onChange={(e, val) =>
                          val ? changeFilterVal('type', 'year') : changeFilterVal('type', 'month')
                        }
                        name="checkedA"
                      />
                    }
                    label={t('common.year')}
                  />
                </Grid> */}
              {/* </Grid> */}
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label={t('common.start')}
                  autoOk
                  fullWidth
                  color="primary"
                  clearable="true"
                  placeholder={t('filters.date.placeholder')}
                  variant="inline"
                  disableFuture
                  value={startDate}
                  format={'MMMM yyyy'}
                  openTo={'year'}
                  views={['year', 'month']}
                  onChange={(date) => changeFilterVal('startDate', moment(date).startOf('month').format())}
                  TextFieldComponent={CustomTextField}
                  PopoverProps={{
                    elevation: 3,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label={t('common.end')}
                  autoOk
                  fullWidth
                  color="primary"
                  clearable="true"
                  placeholder={t('filters.date.placeholder')}
                  variant="inline"
                  value={endDate}
                  format={'MMMM yyyy'}
                  openTo={'year'}
                  views={['year', 'month']}
                  disableFuture
                  onChange={(date) => changeFilterVal('endDate', moment(date).startOf('month').format())}
                  TextFieldComponent={CustomTextField}
                  PopoverProps={{
                    elevation: 3,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                {t('owner-dashboard.filters.spaces')}
              </Typography>
            </Box>
            <CustomLabel>{t('owner-dashboard.filters.spaces.text')}</CustomLabel>
            <Box className={classes.csContainer}>
              <CoworkingSpacesButtons onChange={updateCoworkingSpacesFilters} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Filters
