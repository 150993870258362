import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import image from 'assets/images/cart_confirm_onsite.svg'
import Layout from 'components/views/Layout'
import { UserContext } from 'contexts/UserContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'

const useStyles = makeStyles((theme) => ({
  image: {
    maxHeight: '60vh',
    maxWidth: '100%',
  },
  button: {
    color: theme.palette.primary.darker,
  },
}))

function OnSiteConfirmationPage() {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { user, cart } = React.useContext(UserContext)

  const isFree = history?.location?.state?.free

  React.useEffect(() => {
    cart.reset()
  }, [])

  const handleClick = () => {
    if (user) {
      history.push(getRoutePath('newreservation', { ':coworkingspace': user.CoworkingSpaceId }))
    } else {
      history.push(getRoutePath('index'))
    }
  }

  return (
    <Layout title={t('payment.confirmation.title')}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h1">
          <Box component="span" color="primary.darker">
            {t('payment.onsite.title')}
          </Box>
        </Typography>
        <Box my={2}>
          <img className={classes.image} src={image} alt="" />
        </Box>
      </Box>
      <Box m={'0 auto'} mb={2} maxWidth="500px" textAlign="center">
        <Typography variant="subtitle1" color="textPrimary">
          {t(
            user
              ? isFree
                ? 'payment.onsite.free.description'
                : 'payment.onsite.description'
              : 'payment.onsite.description.external'
          )}
        </Typography>
      </Box>
      <Grid className={classes.btnContainer} container alignItems="center" spacing={1} justifyContent="center">
        <Grid item xs sm="auto">
          <Button variant="outlined" className={classes.button} onClick={handleClick} color="secondary">
            {t('payment.onsite.back')}
          </Button>
        </Grid>
        {user && (
          <Grid item xs sm="auto">
            <Button variant="contained" onClick={() => history.push(getRoutePath('my-invoices'))} color="secondary">
              {t('payment.onsite.see.bills')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Layout>
  )
}

export default OnSiteConfirmationPage
