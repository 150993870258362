import HeroText from 'components/CustomComponents/HeroText'
import FullHeightCenter from 'components/FullHeightCenter'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'

const ErrorContext = React.createContext()

function ErrorContextProvider({ children }) {
  const { t } = useTranslation()
  const [error, setError] = React.useState()
  const history = useHistory()

  const closeError = () => {
    setError(null)
    history.push(getRoutePath('index'))
  }

  if (error) {
    return (
      <FullHeightCenter>
        <HeroText
          title={t('an.error.occured.title')}
          description={t('an.error.occured.description')}
          btnText={t('cart.back.home')}
          btnProps={{
            onClick: closeError,
          }}
        />
      </FullHeightCenter>
    )
  }

  return <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>
}

export { ErrorContextProvider, ErrorContext }
