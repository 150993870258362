import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

type CSSGridProps = {
  children: React.ReactNode
  spacing?: number
  colWidth?: number
}

const CSSGrid: React.FC<CSSGridProps> = (props) => {
  const { children, spacing = 0, colWidth = 300 } = props
  const classes = makeStyles((theme) => {
    const gap = theme.spacing(spacing)
    return {
      grid: {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${colWidth}px, 1fr))`,
        columnGap: gap,
        rowGap: gap,
      },
    }
  })()

  return <div className={classes.grid}>{children}</div>
}

export { CSSGrid }
