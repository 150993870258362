import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import LoadingElem from 'components/LoadingElem'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getRoutePath } from 'routes'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const svgHeight = 200

const useStyles = makeStyles((theme) => ({
  container: {
    // height: "100%"
  },
  image: {
    borderRadius: theme.shape.borderRadius,
    border: '1px solid ' + theme.palette.primary.light,
  },
  containersvg: {
    border: '1px solid ' + theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    '& > svg': {
      width: '100%',
      height: svgHeight - theme.spacing(2),
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}))

function CardPlan({ csIds, nameRoutePathOnClick }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const [loading, setLoading] = React.useState(false)

  const { data } = useSWR(`plans/${csIds.join('')}`, () => {
    setLoading(true)
    return apiCall(
      nameRoutePathOnClick === 'owner-plan' ? apiActions.ownerGetPlansByCoworkingSpaceId : apiActions.getAdminPlans,
      {},
      'post',
      { CoworkingSpaceId: csIds[0] }
    ).then((r) => {
      setLoading(false)
      return r
    })
  })

  if (!data || loading) return <LoadingElem />
  return data && data.length > 0 ? (
    <Grid container spacing={4}>
      {data.map((p, index) => {
        return (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <Link
              className={classes.link}
              to={getRoutePath(nameRoutePathOnClick, { ':coworkingspace': csIds[0], ':planpart': p.Id })}
            >
              <div>
                {p.File.Extension === 'svg' ? (
                  <Box
                    mb={2}
                    width="100%"
                    height={svgHeight}
                    className={classes.containersvg}
                    dangerouslySetInnerHTML={{ __html: p.SVG }}
                  ></Box>
                ) : (
                  <Box mb={1}>
                    <img
                      className={classes.containersvg}
                      src={`data:image/${p.File.Extension};base64,${p.File.BinaryFile}`}
                      height={svgHeight}
                      width="100%"
                    />
                  </Box>
                )}
                <Box mb={1}>
                  <Typography variant="h3">
                    <Box component="span" color="primary.darker">
                      {p.Name}
                    </Box>
                  </Typography>
                </Box>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('owner-plans.percent.located', { percent: p.PercentLocated })}
                </Typography>
              </div>
            </Link>
          </Grid>
        )
      })}
    </Grid>
  ) : (
    t('owner-dashboard.evolutions.noserie')
  )
}

export default CardPlan
