import { Box, Grid, Hidden, makeStyles, Typography } from '@material-ui/core'
import { CustomOSMap } from 'components/CustomComponents/CustomOSMap'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import SquareButton from 'components/SquareButton'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 0,
    },
  },
  map: {
    borderRadius: theme.shape.borderRadius,
    height: '30vh',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
}))

function RenderOpeningTime({ day, start, end }) {
  const { t } = useTranslation()
  const formatDay = moment(day, 'e').format('dddd')

  const toShow = (start, end) =>
    !start || !end
      ? t('coworkingspaces.closed')
      : start === '00:00:00' && end === '00:00:00'
      ? t('coworkingspaces.open.allday')
      : moment(start, 'HH:mm:ss').format('HH[h]mm') + ' - ' + moment(end, 'HH:mm:ss').format('HH[h]mm')

  return (
    <Box mb={1} display="flex" justifyContent="space-between">
      <Box pr={2}>
        <Typography variant="h6">
          <Box component="span" color="primary.darker">
            {formatDay.charAt(0).toUpperCase() + formatDay.slice(1)}
          </Box>
        </Typography>
      </Box>
      <div>
        <Typography variant="h6">
          <Box component="span" color="primary.darker">
            {start.map((s, index) => toShow(start[index], end[index])).join(' | ')}
          </Box>
        </Typography>
      </div>
    </Box>
  )
}

export const getItemFullAddress = (item) => {
  let add = ''
  const addInfo = (info) => {
    add += info ? info + ' ' : ''
  }

  addInfo(item.Name)
  addInfo(item.WayNumber)
  addInfo(item.Address)
  addInfo(item.ZipCode)
  addInfo(item.CityName)

  return add
}

export const getGoogleMapUrlFromAddress = (searchAddress) => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(searchAddress)}`
}

function CoworkingSpaceInfos({ id, onCloseDialog }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user, apiCall } = useContext(UserContext)

  const getCoworkingSpaceInfos = () =>
    apiCall(apiActions.getCoworkingSpace, {
      CoworkingSpaceId: id,
    })

  const { data } = useSWR(`${id}/getallinfos/`, getCoworkingSpaceInfos)

  if (!data)
    return (
      <Box py={4} px={16}>
        <LoadingElem />
      </Box>
    )
  const markerPosition = data.Latitude && data.Longitude ? [data.Latitude, data.Longitude] : null
  const googleMapSearch = getItemFullAddress(data)

  const addressComp = (
    <>
      {(data.CityName || data.Address || data.PhoneNumber) && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs="auto">
            <SquareButton
              component="a"
              target="_blank"
              href={getGoogleMapUrlFromAddress(googleMapSearch)}
              className={classes.buttonMobile}
              color="secondary"
            >
              <Icon icon="map" />
            </SquareButton>
          </Grid>
          <Grid item xs>
            {data.CityName && <Typography variant="subtitle1">{data.CityName}</Typography>}

            {data.Address && (
              <Typography variant="subtitle2" color="textSecondary">
                {data.WayNumber ? data.WayNumber + ' ' : ''}
                {data.Address}
              </Typography>
            )}

            {data.PhoneNumber && (
              <Typography variant="subtitle2" color="textSecondary">
                {t('coworkingspaces.phone', { number: data.PhoneNumber })}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </>
  )

  let openingTimes = [...data.OpeningTimes]
  let sunday = openingTimes.splice(0, 1)[0]
  openingTimes.push(sunday)

  return (
    <div>
      <Box p={3}>
        <Box display="flex" mb={2}>
          <Box margin="auto">
            <Typography variant="h4">
              <Box component="span" color="primary.darker">
                {data.Name}
              </Box>
            </Typography>
          </Box>
          <div>
            <SquareButton onClick={onCloseDialog} size="small">
              <Icon icon="close" />
            </SquareButton>
          </div>
        </Box>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={12} sm className={classes.mapContainer}>
            <Hidden smUp>
              <Box mb={1}>{addressComp}</Box>
            </Hidden>
            <CustomOSMap
              className={classes.map}
              center={markerPosition ?? [47, 2]}
              zoom={markerPosition ? 20 : 5}
              markers={
                markerPosition && [
                  {
                    position: markerPosition,
                  },
                ]
              }
            />
          </Grid>
          <Grid item xs={12} sm>
            <div>
              <Hidden xsDown>
                <Box mb={2}>{addressComp}</Box>
              </Hidden>
              {user && user.CoworkingSpaceId === id && (
                <Box mb={2} color="success.main" display="flex" alignItems="center">
                  <Icon icon="favori" />
                  <Box pl={1}>
                    <Typography variant="body2">{t('coworkingspaces.page.default.space')}</Typography>
                  </Box>
                </Box>
              )}
            </div>
            <div>
              <Box mb={2}>
                <Typography variant="h5">
                  <Box component="span" color="primary.darker" fontWeight="fontWeightBold">
                    {t('coworkingspaces.openingtimes')}
                  </Box>
                </Typography>
              </Box>
              {openingTimes
                .sort((a, b) => a.Day - b.Day)
                .map((ot, index) => (
                  <RenderOpeningTime key={index} day={ot.Day} start={ot.Start} end={ot.End} />
                ))}
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default CoworkingSpaceInfos
