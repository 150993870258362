import { Box, Paper } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export default function LoadingElem(props) {
  const { paper, elevation } = props
  const useStyles = makeStyles((theme) => ({
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: props.height ?? '100%',
      minHeight: props.minHeight ?? 200,
    },
  }))

  const classes = useStyles()
  const load = <CircularProgress />
  if (paper)
    return (
      <Paper {...props} className={classes.loading} elevation={elevation ?? 0}>
        {load}
      </Paper>
    )
  return (
    <Box {...props} className={classes.loading}>
      {load}
    </Box>
  )
}
