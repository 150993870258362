import { MenuItem, Select } from '@material-ui/core'
import LoadingElem from 'components/LoadingElem'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'

function SelectCoworkingSpaces(props) {
  const {
    onChange = () => '',
    emptyOption = false,
    valueEmpty = '',
    labelEmpty = 'common.all',
    variant = 'outlined',
    fullWidth = false,
  } = props

  const { user, apiCall } = useContext(UserContext)
  const { t } = useTranslation()

  const [csId, setCsId] = React.useState(user?.CoworkingSpaceId ?? '')
  const [coworkingSpaces, setCoworkingSpaces] = React.useState(null)

  React.useEffect(() => {
    apiCall(apiActions.getCoworkingSpaces).then((r) => {
      setCoworkingSpaces(r.data)
      setCsId(user?.CoworkingSpaceId ?? r.data[0].Id)
    })
  }, [])

  React.useEffect(() => {
    onChange(csId)
  }, [csId])

  const handleChange = (e) => {
    setCsId(e.target.value)
  }

  if (!coworkingSpaces) return null
  return (
    <Select onChange={handleChange} fullWidth={fullWidth} value={csId} variant={variant} displayEmpty={emptyOption}>
      {emptyOption && <MenuItem value={valueEmpty}>{t(labelEmpty)}</MenuItem>}
      {coworkingSpaces?.map((c) => {
        return (
          <MenuItem key={c.Id} value={c.Id}>
            {c.Name}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default SelectCoworkingSpaces
