import { Box, makeStyles, OutlinedInput, Paper, Typography } from '@material-ui/core'
import CustomButton from 'components/CustomButton'
import { CSSGrid } from 'components/CustomComponents/CSSGrid'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import CustomLabel from 'components/CustomLabel'
import LoadingElem from 'components/LoadingElem'
import Layout from 'components/views/Layout'
import { Item } from 'components/views/NewReservationPage/ItemsGrid'
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    color: theme.palette.getContrastText(theme.palette.primary.light),
    marginBottom: theme.spacing(2),
  },
}))

function PrintPage() {
  const classes = useStyles()
  const { t } = useTranslation()

  const { apiCall, user } = useContext(UserContext)
  const { society } = useContext(SocietyContext)

  const [items, setItems] = React.useState(null)
  const [printCode, setPrintCode] = React.useState(null)
  const [urlLink, setUrlLink] = React.useState(null)

  React.useEffect(() => {
    apiCall(apiActions.getResourcesGivingCredits, {
      CoworkingSpaceId: user ? user.CoworkingSpaceId : society.CoworkingSpaces[0].Id,
    }).then((r) => {
      if (!r.Error) {
        setItems(r.data)
        setPrintCode(r.PrintCode)
        setUrlLink(r.LinkPapercut)
      }
    })
  }, [])

  const handleClick = () => {
    window.location = urlLink
  }

  const text = t('papercut.message.body', { name: t(user.PrintCodeApp) })

  if (!items) return <LoadingElem />
  return (
    <Layout
      pageTitle={t('papercut.page.title', { name: t(user.PrintCodeApp) })}
      pageDescription={t('papercut.page.description')}
      titleComponent={
        <Box display="block">
          <CustomLabel>{t('papercut.printcode.label')}</CustomLabel>
          <OutlinedInput color="primary" value={printCode} disabled />
        </Box>
      }
    >
      <Paper className={classes.paper}>
        <Typography gutterBottom variant="h4">
          {t('papercut.message.title')}
        </Typography>
        <Typography variant="body2">
          <Box component="span" dangerouslySetInnerHTML={{ __html: text }} />
        </Typography>
      </Paper>
      <CustomPaper>
        <Box p={3}>
          <Box mb={2}>
            <Typography variant="h4" gutterBottom>
              <Box component="span" color="primary.darker" fontWeight="fontWeightBold">
                {t('papercut.resources.title')}
              </Box>
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {t('papercut.resources.description')}
            </Typography>
          </Box>
          {items && items.length > 0 ? (
            <CSSGrid colWidth={280} spacing={1}>
              {items.map((i) => {
                return (
                  <div key={i.Id}>
                    <Item
                      key={i.Id}
                      coworkingSpaceId={i.CoworkingSpaceId}
                      categoryId={i.CategoryId}
                      item={i}
                      catType={i.Type}
                      visited={false}
                      onClick={() => ''}
                    />
                  </div>
                )
              })}
            </CSSGrid>
          ) : (
            <Typography variant="h4" color="textSecondary">
              {t('papercut.no.resources')}
            </Typography>
          )}
        </Box>
      </CustomPaper>
      {urlLink && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CustomButton variant="contained" color="secondary" onClick={handleClick}>
            {t('papercut.goto', { name: t(user.PrintCodeApp) })} &nbsp;{<ExitToAppIcon />}
          </CustomButton>
        </Box>
      )}
    </Layout>
  )
}

export default PrintPage
