import { Box, Button, Hidden, makeStyles, MenuItem, Typography } from '@material-ui/core'
import Publication from 'area/social/components/Publication'
import CustomButton from 'components/CustomButton'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  tabs: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
}))

function ProfileActivities({ userProfile, handleBackClick }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const { apiCall, user } = useContext(UserContext)
  const [publications, setPublications] = React.useState(null)

  const elementsStep = 20
  const [nbElements, setNbElements] = React.useState(elementsStep)
  const [loading, setLoading] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [disabledScrollEvent, setDisabledScrollEvent] = React.useState(false)

  const [menuSelected, setMenuSelected] = React.useState('all')
  const allKeys = ['all', 'publications', 'reactions']

  const { data: menu } = useSWR(`publications/menu`, () => apiCall(apiActions.getPublicationsMenu).then((r) => r))

  window.onscroll = () => {
    if (
      !disabledScrollEvent &&
      publications &&
      publications.length >= nbElements &&
      window.innerHeight + window.scrollY + 50 >= document.documentElement.scrollHeight &&
      !loading
    ) {
      setNbElements(nbElements + elementsStep)
      setRefresh(!refresh)
    }
  }

  React.useEffect(() => {
    if (userProfile) {
      setLoading(true)
      setDisabledScrollEvent(true)
      apiCall(apiActions.getPublications, {}, 'post', {
        AuthorId: userProfile.Id,
        Skip: nbElements - elementsStep,
        Take: elementsStep,
      }).then((r) => {
        if (nbElements - elementsStep === 0) {
          setPublications(r)
        } else {
          let newData = publications ? [...publications] : []
          r.map((a) => newData.push(a))
          setPublications(newData)
        }
        setLoading(false)
        setDisabledScrollEvent(false)
      })
    }
  }, [refresh])

  if (!publications) return <LoadingElem />
  return (
    <div>
      <Hidden mdUp>
        <Button onClick={handleBackClick}>
          <Box display="flex" alignItems="center" color="primary.darker">
            <Icon icon="fleche-gauche" />
            {t('common.back')}
          </Box>
        </Button>
      </Hidden>
      <Box mb={2}>
        <Typography variant="h3">
          <Box color="primary.darker" component="span">
            {userProfile.Id === user.Id
              ? t('userprofile.publications.title')
              : t('userprofile.publications.other.title', { name: userProfile.FirstName + ' ' + userProfile.LastName })}
          </Box>
        </Typography>
      </Box>
      {/* <Box mb={2}>
        <Hidden mdUp>
          <CustomTextField
            select
            margin="dense"
            value={menuSelected}
            onChange={(e) => setMenuSelected(e.target.value)}
            className={classes.select}
          >
            {allKeys.map((c) => (
              <MenuItem key={'menu_' + c} value={c}>
                {t('userprofile.menu.' + c)}
              </MenuItem>
            ))}
          </CustomTextField>
        </Hidden>
        <Hidden smDown>
          <div className={classes.tabs}>
            {allKeys.map((c) => (
              <Box key={'menu_tabs_' + c} mr={1}>
                <CustomButton
                  variant="outlined"
                  color={menuSelected === c ? 'secondary' : 'primary'}
                  onClick={() => setMenuSelected(c)}
                >
                  {t('userprofile.menu.' + c)}
                </CustomButton>
              </Box>
            ))}
          </div>
        </Hidden>
      </Box> */}
      {publications && publications.length > 0 ? (
        publications.map((p, index) => {
          return (
            <Box mb={4} key={index}>
              <Publication post={p} menu={menu} refresh={() => setRefresh(!refresh)} />
            </Box>
          )
        })
      ) : (
        <Typography variant="h6" color="textSecondary">
          {t('userprofile.no.activity')}
        </Typography>
      )}
    </div>
  )
}

export default ProfileActivities
