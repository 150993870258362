import { Snackbar, SnackbarProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { darken } from '@material-ui/core/styles/colorManipulator'

const customPaperStyle = makeStyles((theme) => {
  const darker = darken(theme.palette.primary.main, 0.7)
  return {
    snackbar: {
      '& .MuiSnackbarContent-root': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    error: {
      '& .MuiSnackbarContent-root': {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.getContrastText(theme.palette.error.dark),
      },
    },
  }
})

interface CustomSnackBarProps extends SnackbarProps {
  className?: string
  error?: boolean
}

const CustomSnackBar: React.FC<CustomSnackBarProps> = function (props) {
  const classes = customPaperStyle()
  const { className, error = false, ...rest } = props

  let classNames = [classes.snackbar]
  if (className) classNames.push(className)
  if (error) classNames.push(classes.error)

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} {...rest} className={classNames.join(' ')} />
  )
}

export default CustomSnackBar
