import { Box, Dialog, DialogProps, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React from 'react'
import SquareButton from '../SquareButton'
import Icon from '../IcoMoon/Icon'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: 0,
    },
  },
  children: {
    padding: '0px ' + theme.spacing(8) + 'px ' + theme.spacing(4) + 'px ' + theme.spacing(8) + 'px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  withFooter: {
    paddingBottom: 'inherit',
  },
}))

export { useStyles }

interface CustomDialogProps extends DialogProps {
  handleClose: void
  open: boolean
  title: string
  withFooter?: boolean
}

const CustomDialog: React.FC<CustomDialogProps> = function (props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const { handleClose, open, children, title, withFooter, ...rest } = props

  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <Dialog {...rest} open={open} fullScreen={isXsDown}>
        <Box className={classes.container}>
          <Box className={classes.closeButton}>
            <SquareButton onClick={handleClose}>
              <Icon icon="close" />
            </SquareButton>
          </Box>
          <Typography align="center" variant="h2">
            <Box component="span" fontWeight="fontWeightBold" color="primary.darker">
              {title}
            </Box>
          </Typography>
        </Box>
        <Box className={classes.children + ' ' + (withFooter ? classes.withFooter : '')}>{children}</Box>
      </Dialog>
    </>
  )
}

export default CustomDialog
