import { Box, Button, CircularProgress, Dialog, Divider, Grid, Hidden, Switch, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Alert } from '@material-ui/lab'
import image from 'assets/images/contact_admin.svg'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import DynamicForm from 'components/DynamicForm/DynamicForm'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import ShowPrice from 'components/ShowPrice'
import SquareButton from 'components/SquareButton'
import Layout from 'components/views/Layout'
import CoworkingSpaceInfos from 'components/views/NewReservationPage/CoworkingSpaceInfos'
import ItemImagesGrid from 'components/views/NewReservationPage/ItemImagesGrid'
import ItemScheduler from 'components/views/NewReservationPage/ItemScheduler'
import { onlyUnique } from 'components/views/NewReservationPage/ItemsScheduler'
import RecapOrder from 'components/views/NewReservationPage/RecapOrder'
import { DEFAULT_IMG } from 'config'
import { CoworkingSpaceContext } from 'contexts/CoworkingSpaceContext'
import { MoneyContext } from 'contexts/MoneyContext'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  prices: {
    '& > div': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderRightStyle: 'solid',
      borderRightWidth: 1,
      borderRightColor: theme.palette.secondary.dark,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.only('xs')]: {
        '&:nth-child(2n+1)': {
          paddingLeft: 0,
        },
        '&:nth-child(2n+2)': {
          border: 'none',
        },
      },
      [theme.breakpoints.between('sm', 'md')]: {
        '&:nth-child(3n+1)': {
          paddingLeft: 0,
        },
        '&:nth-child(2n+3)': {
          border: 'none',
        },
      },
      [theme.breakpoints.up('md')]: {
        '&:first-child': {
          paddingLeft: 0,
        },
      },
      '&:last-child': {
        border: 'none',
        paddingRight: 0,
      },
    },
  },
  fixedButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    left: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      left: 100,
    },
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(10),
      left: theme.spacing(2),
    },
    '&.ghost': {
      position: 'relative',
      bottom: 0,
      right: 0,
      visibility: 'hidden',
    },
    '&.noBar': {
      [theme.breakpoints.down('sm')]: {
        bottom: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        bottom: theme.spacing(10),
      },
    },
  },
  card: {
    height: '100%',
  },
  image: {
    maxHeight: '30vh',
    width: '100%',
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  blurred: {
    opacity: 0.5,
  },
  disabled: {
    // backgroundColor: theme.palette.grey[500],
    // "&:hover": {
    //   backgroundColor: theme.palette.grey[500],
    // },
    // opacity: 0.4
  },
}))

function CardTicket({ ticket }) {
  const { t } = useTranslation()
  const classes = useStyles()

  function InfoTicket({ icon, value }) {
    return (
      <Box color="primary.darker" display="flex">
        <Icon icon={icon} />
        <Typography component="span" variant="caption">
          <Box pl={1} component="span" color="primary.darker" fontWeight="fontWeightBold">
            {value}
          </Box>
        </Typography>
      </Box>
    )
  }

  function ItemTicket({ title, infos }) {
    return (
      <Grid item xs={4}>
        <Box mb={1}>
          <Typography variant="subtitle2">
            <Box color="text.secondary" component="span">
              {title}
            </Box>
          </Typography>
        </Box>
        {infos}
      </Grid>
    )
  }

  let validity =
    ticket.ValidityInMonths > 0 ? t('reservations.item.pack.validity.months', { months: ticket.ValidityInMonths }) : ''
  if (ticket.ValidityInDays > 0)
    validity += ' ' + t('reservations.item.pack.validity.days', { days: ticket.ValidityInDays })

  return (
    <CustomPaper className={classes.card}>
      <Box p={2}>
        <Grid container spacing={2}>
          <ItemTicket
            title={t('reservations.item.pack.tickets')}
            infos={
              <>
                <InfoTicket icon="tickets" value={ticket.NbTickets} />
                {ticket.KeyType !== '' && <InfoTicket icon="agenda-jour" value={t(ticket.KeyType)} />}
              </>
            }
          />
          <ItemTicket
            title={t('reservations.item.pack.usable')}
            infos={ticket.CategoriesAllowed.map((name, index) => (
              <InfoTicket
                icon="check"
                key={index}
                value={ticket.Type === 1 ? name : t('reservations.item.pack.space', { coworkingSpace: name })}
              />
            ))}
          />
          <ItemTicket
            title={t('reservations.item.pack.validity')}
            infos={<InfoTicket icon="horloge" value={validity} />}
          />
        </Grid>
      </Box>
    </CustomPaper>
  )
}

function CantBook({ description, categoryInfos, closeDialog }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between">
        <div>
          <Typography variant="h2" gutterBottom>
            {categoryInfos.Type === 10 || categoryInfos.Type === 15 || categoryInfos.Type === 50 ? (
              <Box color="primary.darker" component="span">
                {t('reservations.details.title')}
              </Box>
            ) : (
              <Box color="primary.darker" component="span">
                {t('reservations.details.purchase.title')}
              </Box>
            )}
          </Typography>
        </div>
        <div>
          {isXsDown && (
            <SquareButton onClick={closeDialog} size="small">
              <Icon icon="close" />
            </SquareButton>
          )}
        </div>
      </Box>
      <Typography variant="subtitle2" color="textSecondary">
        {description}
      </Typography>
      <img src={image} className={classes.image} alt="" />
    </Box>
  )
}

export function ItemDetails({ icon, title, description }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs="auto">
        <CustomPaper>
          <Box
            color="primary.darker"
            width={50}
            height={50}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="borderRadius"
          >
            {icon}
          </Box>
        </CustomPaper>
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle1">
          <Box color="text.secondary" component="span">
            {Array.isArray(description) ? description.join(', ') : description}
          </Box>
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {title}
        </Typography>
      </Grid>
    </Grid>
  )
}

export function InfoItem({ itemInfos, coworkingSpaceId, categoryId, onRangeSelect, openDialog, withScheduler = true }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const hasTickets = itemInfos.Prices?.filter((p) => p.Ticket)?.length > 0
  const money = useContext(MoneyContext)

  const onRangeChange = () => {
    onRangeSelect()
  }

  return (
    <div>
      <Box mb={3}>
        <ItemImagesGrid images={itemInfos?.Image?.Url ? [itemInfos?.Image?.Url] : [DEFAULT_IMG]} />
      </Box>
      <Box mb={2}>
        <Typography variant="h3">
          <Box color="primary.darker" component="span">
            {itemInfos.Name}
          </Box>
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography gutterBottom variant="h5">
          <Box color="primary.darker" component="span">
            {itemInfos.Prices && itemInfos.Prices.length > 1
              ? t('reservations.items.prices')
              : t('reservations.item.info.price')}
          </Box>
        </Typography>
        <Grid container className={classes.prices}>
          {itemInfos.Prices &&
            itemInfos.Prices.map((price, index) => (
              <Grid item xs={6} sm={4} lg="auto" key={index}>
                <Typography variant="subtitle1">
                  <Box color="secondary.dark" component="span">
                    <ShowPrice credits={price.Credits} ttc={price.EuroTTC} ht={price.EuroHT} />
                  </Box>
                </Typography>
                {price.Description && (
                  <Typography variant="subtitle2">
                    <Box color="text.secondary" component="span">
                      {t('reservations.item.price.per')}
                      {price.Description}
                    </Box>
                  </Typography>
                )}
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box mb={3}>
        <Typography gutterBottom variant="h5">
          <Box color="primary.darker" component="span">
            {t('reservations.item.infos')}
          </Box>
        </Typography>
        <Typography variant="subtitle1">
          <Box
            color="text.secondary"
            component="span"
            dangerouslySetInnerHTML={{ __html: itemInfos.ShortDescription }}
          ></Box>
        </Typography>
      </Box>
      <Box mb={1}>
        <Grid container spacing={2}>
          {itemInfos.NbUsers > 0 && (
            <Grid item lg={4} md={6} xs={12}>
              <ItemDetails
                icon={<Icon icon="mon-profil" />}
                // title={t('reservations.item.infos.capacity')}
                // description={itemInfos.NbUsers + ' ' + t('reservations.item.infos.persons')}
                title={itemInfos.NbUsers + ' ' + t('reservations.item.infos.persons')}
                description={t('reservations.item.infos.capacity')}
              />
            </Grid>
          )}
          {/* <Grid item lg={4} md={6} xs={12}>
            <ItemDetails
              icon={<Icon icon={money.iconMoney} />}
              title={t('reservations.item.infos.payment')}
              description="A la réservation"
            />
          </Grid> */}
          {((Array.isArray(itemInfos.Equipments) && itemInfos.Equipments.length > 0) ||
            itemInfos.Equipments === '') && (
            <Grid item lg={4} md={6} xs={12}>
              <ItemDetails
                icon={<Icon icon="equipements" />}
                title={t('reservations.item.infos.equipments')}
                description={itemInfos.Equipments}
              />
            </Grid>
          )}
          {hasTickets && (
            <Grid item lg={4} md={6} xs={12}>
              <ItemDetails
                icon={<Icon icon="tickets" />}
                title={t('reservations.item.infos.tickets')}
                description={t('reservations.item.infos.tickets.description')}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      {itemInfos.Type === 30 && (
        <div>
          <Box mt={2} mb={1}>
            <Typography variant="h5">
              <Box color="primary.darker" component="span">
                {t('reservations.item.pack.detail')}
              </Box>
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {itemInfos.Tickets.map(
              (ticket, index) =>
                ticket.NbTickets > 0 && (
                  <Grid key={index} item xs={12} lg={6}>
                    <CardTicket key={index} ticket={ticket} />
                  </Grid>
                )
            )}
          </Grid>
        </div>
      )}
      {itemInfos.Type === 10 && withScheduler && (
        <Box mt={2} mb={1}>
          <ItemScheduler
            openDialog={openDialog}
            onRangeChange={onRangeChange}
            categoryId={categoryId}
            coworkingSpaceId={coworkingSpaceId}
            itemId={itemInfos.Id}
          />
        </Box>
      )}
    </div>
  )
}

const TotalAndOrder = ({ methods, handleOrderClick, coworkingSpaceId, categoryId, itemId, fields, hasTickets }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { apiCall } = React.useContext(UserContext)

  const [total, setTotal] = React.useState(null)
  const [canUse, setCanUse] = React.useState(false)
  const [priceTicket, setPriceTicket] = React.useState(0)
  const [showError, setShowError] = React.useState(null)
  const [switchValue, setSwitchValue] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isClosed, setIsClosed] = React.useState(false)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [fieldsErrors, setFieldsErrors] = React.useState(null)

  React.useEffect(() => {
    setTotal(null)
    setLoading(true)
    const timeout = setTimeout(() => {
      apiCall(apiActions.getTotalPrice, { coworkingSpaceId, categoryId, itemId }, 'post', { ...fields }).then((r) => {
        setTotal(r.Total)
        setCanUse(r.CanUseTickets)
        setPriceTicket(r.Total ? r.Total.Tickets : null)
        if (r.Error || r.Message) {
          setShowError(r.Message)
        } else if (JSON.stringify(r.Fields) !== JSON.stringify(fieldsErrors)) {
          // setFieldsErrors(r.Fields)
          if (r.Fields) {
            setFieldsErrors(r.Fields)
            setShowError('')
          } else {
            setShowError(null)
            setFieldsErrors(null)
          }
        } else {
          setShowError(null)
        }
        setIsClosed(r.isClosed)
        setLoading(false)
      })
    }, 250)

    return () => {
      clearTimeout(timeout)
    }
  }, [fields])

  React.useEffect(() => {
    if (fieldsErrors) {
      fieldsErrors.map((f) => {
        methods.setError(f.Name, { type: 'string', message: t(f.Message) })
      })
    } else {
      methods.clearErrors()
    }
  }, [fieldsErrors])

  React.useEffect(() => {
    if (hasTickets) {
      if (!canUse || priceTicket <= 0) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  }, [canUse, priceTicket])

  React.useEffect(() => {
    if (hasTickets && disabled) {
      methods.setValue('ticket', false)
      setSwitchValue(false)
    }
  }, [disabled])

  const handleSwitchChange = (e, v) => {
    setSwitchValue(!switchValue)
    methods.setValue('ticket', v)
  }

  const handleClick = (e) => {
    e.preventDefault()
    if (!showError) {
      methods.clearErrors()
    }
    handleOrderClick(showError)
  }

  return (
    <Box position="relative" className={loading ? classes.blurred : ''}>
      {showError && (
        <>
          <Box mt={1}>
            <Alert severity="error">{showError.includes(':') ? showError : t(showError)}</Alert>
          </Box>
          {isClosed && (
            <>
              <Button variant="text" onClick={() => setDialogOpen(true)}>
                <Typography variant="caption" color="primary">
                  {t('reservations.item.see.more')}
                </Typography>
              </Button>
              <Dialog open={dialogOpen} fullWidth maxWidth="lg">
                <CoworkingSpaceInfos id={coworkingSpaceId} onCloseDialog={() => setDialogOpen(false)} />
              </Dialog>
            </>
          )}
        </>
      )}
      {hasTickets && (
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Box display="block">
            <div>
              <Typography variant="subtitle1">{t('reservations.details.field.tickets.total')}</Typography>
            </div>
            <div>
              <Typography variant="subtitle2">
                <Box color="text.secondary" component="span">
                  {t('reservations.details.field.tickets.use')}
                </Box>
              </Typography>
            </div>
          </Box>
          <Box display="block">
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box mr={1}>
                <Typography variant="h4">
                  <Box color="primary.darker" component="span">
                    {priceTicket && priceTicket > 0 ? priceTicket : ''}
                  </Box>
                </Typography>
              </Box>
              <Icon color="secondary" icon="tickets" />
            </Box>
            <div>
              <Switch
                size="small"
                color="primary"
                disabled={disabled}
                checked={switchValue}
                onChange={(e, v) => handleSwitchChange(e, v)}
              />
            </div>
          </Box>
        </Box>
      )}
      <Box mt={2} mb={2} bgcolor="secondary.main">
        <Divider />
      </Box>
      <Typography variant="h5">
        {methods && (!methods.getValues().ticket || !canUse) ? (
          <Box color="primary.darker" fontWeight="fontWeightBold" component="span">
            <ShowPrice
              flexRender
              title={t('reservations.details.total')}
              credits={total ? total.Credits : ''}
              ttc={total ? total.EuroTTC : ''}
              ht={total ? total.EuroHT : ''}
            />
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between" mt={2} component="span">
            <Box display="block" component="span">
              <Box component="span">
                <Typography variant="subtitle1">{t('reservations.details.total')}</Typography>
              </Box>
              <Box component="span">
                <Typography variant="subtitle2">
                  <Box color="text.secondary" component="span">
                    {t('reservations.details.tickets')}
                  </Box>
                </Typography>
              </Box>
            </Box>
            <Box display="block" component="span">
              <Box display="flex" justifyContent="flex-end" component="span">
                <Typography variant="h4">
                  <Box color="primary.darker" component="span">
                    {total && total.Tickets}
                  </Box>
                </Typography>
              </Box>
              <Icon color="secondary" icon="tickets" />
            </Box>
          </Box>
        )}
      </Typography>
      <Box display="flex" justifyContent="center" mt={3}>
        <CustomButton
          color="secondary"
          onClick={(e) => handleClick(e)}
          className={!total ? classes.disabled : ''}
          disabled={showError !== null || fieldsErrors !== null}
          type="submit"
          fullWidth
          variant="contained"
        >
          {t('reservations.details.order')}
        </CustomButton>
      </Box>
      {loading && (
        <Box className={classes.loading}>
          <Box display="flex" height="100%" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export function ReservationDetails({
  categoryInfos,
  onCloseDialog,
  dialogIsOpen,
  fields,
  itemInfos,
  coworkingSpaceId,
  categoryId,
  itemId,
  nothingBookable,
  allClosed,
  isEvent = false,
}) {
  const { t } = useTranslation()
  const { cart } = React.useContext(UserContext)
  const [ordered, setOrdered] = React.useState(false)
  const [valuesOrder, setValuesOrder] = React.useState(null)
  const history = useHistory()

  const hasTickets = itemInfos.Prices.filter((p) => p.Ticket).length > 0

  const methods = useForm({ mode: 'onChange' })
  const watchFields = methods.watch()

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const generateId = () => {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var result = ''
    for (var i = 0; i < 10; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
    }
    return result
  }

  const onFormSubmit = (values, error) => {
    if (!error) {
      let canSend = true
      let allFieldsNames = fields.map((f) => f.Name)
      allFieldsNames.push('startenddate')
      if (!values) canSend = false
      else {
        Object.entries(values).map(([key, value]) => {
          if (allFieldsNames.indexOf(key) > -1) {
            if (!value || (value && Array.isArray(value) && value.length === 0) || value === '') {
              canSend = false
              methods.setError(key, { type: 'string', message: t('errors.message.required') })
            } else {
              methods.clearErrors(key)
            }
          }
        })
      }

      if (canSend) {
        values.cartId = generateId()
        cart.add(values)
        setValuesOrder(values)
        setOrdered(true)
        if (isEvent) history.push(getRoutePath('cart'))
      }
    }
  }

  const handleClose = () => {
    onCloseDialog()
  }

  const closeDialog = () => {
    setOrdered(false)
  }

  const recapOrder = valuesOrder ? (
    <RecapOrder
      coworkingSpaceId={coworkingSpaceId}
      categoryId={categoryId}
      categoryType={categoryInfos.Type}
      itemId={itemId}
      closeDialog={closeDialog}
      values={valuesOrder}
    />
  ) : (
    <LoadingElem />
  )

  return (
    <>
      {allClosed ? (
        <CantBook description={t('coworkingspace.closed.everyday')} categoryInfos={categoryInfos} />
      ) : !nothingBookable ? (
        <Box p={3}>
          <Box display="flex" justifyContent="space-between">
            <div>
              <Typography variant="h2" gutterBottom>
                {categoryInfos.Type === 10 || categoryInfos.Type === 15 || categoryInfos.Type === 50 ? (
                  <Box color="primary.darker" component="span">
                    {t('reservations.details.title')}
                  </Box>
                ) : isEvent ? (
                  <Box color="primary.darker" component="span">
                    {t('reservations.details.events.title')}
                  </Box>
                ) : (
                  <Box color="primary.darker" component="span">
                    {t('reservations.details.purchase.title')}
                  </Box>
                )}
              </Typography>
            </div>
            <div>
              {dialogIsOpen && (
                <SquareButton onClick={handleClose} size="small">
                  <Icon icon="close" />
                </SquareButton>
              )}
            </div>
          </Box>
          {!isEvent && localStorage.getItem('startdateitem') && (
            <Typography variant="subtitle2" color="textSecondary">
              {t('reservations.details.subtitle')}
            </Typography>
          )}
          {!isEvent &&
            !localStorage.getItem('startdateitem') &&
            (categoryInfos.Type === 10 || categoryInfos.Type === 15 || categoryInfos.Type === 50) && (
              <Typography variant="subtitle2" color="textSecondary">
                {t('reservations.details.subtitle.complete')}
              </Typography>
            )}
          <form>
            <Box mt={3}>
              {fields && (
                <DynamicForm
                  csId={coworkingSpaceId}
                  categoryId={categoryId}
                  itemId={itemId}
                  methods={methods}
                  inputs={fields}
                  component="div"
                />
              )}
            </Box>
            <TotalAndOrder
              hasTickets={hasTickets}
              methods={methods}
              handleOrderClick={methods.handleSubmit(onFormSubmit)}
              coworkingSpaceId={coworkingSpaceId}
              categoryId={categoryId}
              itemId={itemId}
              fields={watchFields}
            />
            <Dialog
              maxWidth="lg"
              keepMounted
              fullWidth
              fullScreen={isXsDown}
              open={ordered}
              onClose={() => setOrdered(false)}
            >
              {recapOrder}
            </Dialog>
          </form>
        </Box>
      ) : (
        <CantBook
          categoryInfos={categoryInfos}
          description={t('reservations.details.nothing.bookable.subtitle')}
          closeDialog={handleClose}
        />
      )}
    </>
  )
}

function ItemPage({ onHomeClick, onCategoryClick, categoryId, itemId, coworkingSpaceId }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user, apiCall } = React.useContext(UserContext)
  const [showMobileForm, setShowMobileForm] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [nothingBookable, setNothingBookable] = React.useState(false)
  const [allClosed, setAllClosed] = React.useState(false)
  const { coworkingSpace } = React.useContext(CoworkingSpaceContext)

  const { data: categoryInfos, error: categoryError } = useSWR(`getCategory/${coworkingSpaceId}/${categoryId}`, () =>
    apiCall(apiActions.getCategory, { coworkingSpaceId, categoryId }).catch((e) => {
      throw Error(e)
    })
  )
  const { data: itemInfos, error: itemError } = useSWR(`getItem/${coworkingSpaceId}/${categoryId}/${itemId}`, () =>
    apiCall(apiActions.getItem, { coworkingSpaceId, categoryId, itemId }).catch((e) => {
      throw Error(e)
    })
  )
  const { data: fields } = useSWR(`getForm/${coworkingSpaceId}/${categoryId}/${itemId}`, () =>
    apiCall(apiActions.getForm, { coworkingSpaceId, categoryId, itemId }).then((r) => {
      if (r.Error) {
        //
      } else {
        setNothingBookable(r.NothingBookable)
        return r.data
      }
    })
  )

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  React.useEffect(() => {
    if (coworkingSpace) {
      const start = coworkingSpace.OpeningTimes.map((m) => m.Start).filter(onlyUnique)
      const end = coworkingSpace.OpeningTimes.map((m) => m.End).filter(onlyUnique)
      setAllClosed(start.length === 0 && end.length === 0)
    }
  }, [coworkingSpace])

  //On regarde si l'espace est ouvert au moins un jour
  if (categoryError) onHomeClick()
  if (itemError) onCategoryClick(null, categoryId)
  if (!categoryInfos || !itemInfos)
    return (
      <Layout
        breadcrumbsInfos={{
          categoryName: categoryInfos ? categoryInfos.Name : '...',
          itemName: itemInfos ? itemInfos.Name : '...',
        }}
      >
        <LoadingElem />
      </Layout>
    )

  const reservationForm = fields ? (
    <ReservationDetails
      categoryInfos={categoryInfos}
      nothingBookable={nothingBookable}
      allClosed={allClosed}
      dialogIsOpen={showMobileForm}
      onCloseDialog={() => setShowMobileForm(false)}
      fields={fields}
      itemInfos={itemInfos}
      coworkingSpaceId={coworkingSpaceId}
      categoryId={categoryId}
      itemId={itemId}
      refresh={refresh}
    />
  ) : (
    <LoadingElem />
  )

  const OrderButton = ({ noBar, ghost }) => {
    const theme = useTheme()
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

    const compClasses = [classes.fixedButton]
    if (ghost) {
      compClasses.push('ghost')
    }
    if (noBar) {
      compClasses.push('noBar')
    }

    return (
      <Box className={compClasses.join(' ')}>
        <Button color="secondary" onClick={() => setShowMobileForm(true)} type="submit" fullWidth variant="contained">
          {isSmDown ? t('reservations.details.order.mobile') : t('reservations.details.order')}
        </Button>
      </Box>
    )
  }

  return (
    <Layout
      title={t('menu.reservations.new')}
      pageTitle={t('reservations.item.page.title')}
      pageDescription={t('reservations.item.page.subtitle')}
      breadcrumbsInfos={{ categoryName: categoryInfos.Name, itemName: itemInfos.Name }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={7} lg={8}>
          <InfoItem
            openDialog={() => setShowMobileForm(true)}
            onRangeSelect={() => setRefresh(!refresh)}
            itemInfos={itemInfos}
            coworkingSpaceId={coworkingSpaceId}
            categoryId={categoryId}
          />
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={5} lg={4}>
            <CustomPaper>
              <div>
                {allClosed ? (
                  <CantBook description={t('coworkingspace.closed.everyday')} categoryInfos={categoryInfos} />
                ) : !nothingBookable ? (
                  reservationForm
                ) : (
                  <CantBook
                    description={t('reservations.details.nothing.bookable.subtitle')}
                    categoryInfos={categoryInfos}
                  />
                )}
              </div>
            </CustomPaper>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <div>
            <OrderButton noBar={user === null} />
            <OrderButton ghost />
            <Dialog keepMounted fullScreen={isXsDown} open={showMobileForm} onClose={() => setShowMobileForm(false)}>
              {reservationForm}
            </Dialog>
          </div>
        </Hidden>
      </Grid>
    </Layout>
  )
}

export default ItemPage
