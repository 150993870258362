import { alpha, Box, Button, Grid, makeStyles, MenuList, Paper, Popover, Typography } from '@material-ui/core'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import PopoverElem from 'components/CustomComponents/PopoverElem'
import SeparatorBox from 'components/CustomComponents/SeparatorBox'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import SquareButton from 'components/SquareButton'
import ActivityDialog from 'components/views/CRMPages/ProspectPage/ActivityDialog'
import CloseActivity from 'components/views/CRMPages/ProspectPage/CloseActivity'
import { getActivityText } from 'components/views/CRMPages/Utils/Enums'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { apiActions } from '_actions/api_actions'
import EndActivity from './EndActivity'

const iconSize = 35

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  infoIcon: {
    color: theme.palette.getContrastText(theme.palette.primary.light),
    width: iconSize,
    height: iconSize,
  },
  changeIcon: {
    color: theme.palette.success.main,
    width: iconSize,
    height: iconSize,
  },
  cancelIcon: {
    color: theme.palette.error.main,
    width: iconSize,
    height: iconSize,
  },
  refreshIcon: {
    color: theme.palette.getContrastText(theme.palette.text.secondary),
    width: iconSize,
    height: iconSize,
    padding: theme.spacing(0.5),
    backgroundColor: alpha(theme.palette.text.secondary, 0.3),
    borderRadius: 30,
  },
  success: {
    backgroundColor: alpha(theme.palette.success.main, 0.1),
    padding: theme.spacing(1.5),
  },
  error: {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
    padding: theme.spacing(1.5),
  },
  buttonWithSpecialBorder: {
    border: '2px dotted ' + theme.palette.primary.main,
    backgroundColor: 'white',
  },
  fullHeight: {
    height: '100%',
  },
  squareButton: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.darker,
    },
    borderWidth: '2px',
  },
}))

const OngoingActivity = ({ prospect, activity, refreshData }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const { Id, Type, ActivityOwner, Date, Title } = activity
  const prospectId = prospect?.Id

  const [endActivity, setEndActivity] = React.useState(false)
  const [isWon, setIsWon] = React.useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dialogOpen, setDialopOpen] = React.useState(false)
  const [modifyActivity, setModifyActivity] = React.useState(false)

  const handleCheckClick = () => {
    apiCall(apiActions.closeActivity, { ProspectId: prospectId, ActivityId: activity.Id }, 'put').then((r) => {
      refreshData()
    })
  }

  const handleDeleteClick = () => {
    apiCall(apiActions.deleteProspectActivity, { ProspectId: prospectId, ActivityId: Id }, 'delete').then((r) => {
      refreshData()
    })
  }

  const handleCloseActivity = () => {
    apiCall(apiActions.closeActivity, { ProspectId: prospectId, ActivityId: Id }, 'put').then((r) => {
      if (!r.Error) {
        setDialopOpen(true)
      }
    })
  }

  const onCloseDialogCloseActivity = () => {
    refreshData()
  }

  const onEnd = (isWon) => {
    setIsWon(isWon)
    setDialopOpen(false)
    setEndActivity(true)
  }

  return (
    <div>
      <CustomPaper className={classes.paper}>
        <Box display="flex" mb={1}>
          <Box mr={2}>
            <RefreshRoundedIcon className={classes.refreshIcon} />
          </Box>
          <div>
            <Typography variant="subtitle1">{ActivityOwner?.Name}</Typography>
            <Typography gutterBottom variant="subtitle2" color="textSecondary">
              {t('crm.prospect.ongoing.planned.for', { date: moment(Date).format('DD MMMM YYYY') }) +
                ' · ' +
                t(getActivityText(Type))}
            </Typography>
            {Title}
          </div>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="secondary" onClick={handleCloseActivity}>
            {t('crm.prospect.ongoing.close')}
          </Button>
          <Box ml={3}>
            <SquareButton
              className={classes.squareButton}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              variant="outlined"
              color="secondary"
            >
              <Icon icon="trois-petits-points" />
            </SquareButton>
          </Box>
        </Box>
      </CustomPaper>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        elevation={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>
          <PopoverElem onClick={handleDeleteClick} icon="supprimer" text={t('crm.prospect.ongoing.delete')} />
          <PopoverElem
            onClick={() => setModifyActivity(true)}
            icon="modifier"
            text={t('crm.prospect.ongoing.update')}
          />
          <PopoverElem onClick={handleCheckClick} icon="check" text={t('crm.prospect.ongoing.check')} />
        </MenuList>
      </Popover>
      <CustomDialog
        title={t('crm.activity.close.title')}
        handleClose={onCloseDialogCloseActivity}
        open={dialogOpen}
        maxWidth="lg"
        fullWidth
      >
        <CloseActivity
          onEnd={onEnd}
          prospect={prospect}
          refreshData={refreshData}
          activity={activity}
          handleClose={onCloseDialogCloseActivity}
        />
      </CustomDialog>
      <CustomDialog
        handleClose={() => setModifyActivity(false)}
        title={t('crm.activity.edit.title')}
        open={modifyActivity}
        maxWidth="lg"
        fullWidth
      >
        <ActivityDialog
          edit
          prospect={prospect}
          textValidateButton={t('crm.activity.edit.button')}
          defaultValues={{ Date: Date, Type: Type, Status: 100, Title: Title, ActivityOwner: ActivityOwner }}
          handleClose={() => setModifyActivity(false)}
          activityId={Id}
          refreshData={refreshData}
        />
      </CustomDialog>
      <CustomDialog
        title={isWon ? t('crm.prospect.won.title') : t('crm.prospect.lost.title')}
        maxWidth="lg"
        fullWidth
        open={endActivity}
        handleClose={() => {
          setEndActivity(false)
          refreshData()
        }}
      >
        <EndActivity
          status={isWon ? '210' : '220'}
          refreshData={refreshData}
          isWon={isWon}
          prospect={prospect}
          handleClose={() => {
            setEndActivity(false)
            refreshData()
          }}
        />
      </CustomDialog>
    </div>
  )
}

const Activity = ({ activity, icon, bgcolor }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { Title, Data, RawData, Type, Date } = activity

  let datas = []
  if (Data) Data.map((data) => datas.push(t(data)))

  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Box mr={2} display="flex">
        {icon}
      </Box>
      <Paper className={classes.paper + ' ' + (bgcolor ? classes[bgcolor] : '')} elevation={0}>
        <Typography variant="subtitle1">{activity?.ActivityOwner?.Name}</Typography>
        <Typography gutterBottom variant="subtitle2" color="textSecondary">
          {t('crm.activity.done.date', { date: moment(Date).format('DD MMMM YYYY - HH[h]mm') }) +
            ' ● ' +
            t(getActivityText(Type))}
        </Typography>
        {t(Title)} {datas.length > 0 && ' ● ' + datas.join(', ')} {RawData && RawData.length > 0 && RawData.join(', ')}
      </Paper>
    </Box>
  )
}

function ProspectActivities({ prospect, refreshData }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [createActivityDialog, setCreateActivityDialog] = React.useState(false)

  const activities = prospect.PassedActivities
  const ongoingActivities = prospect.OnGoingActivities
  const prospectStatus = prospect.Status

  if (!activities || (prospectStatus === null && !ongoingActivities)) return <LoadingElem />

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              size="large"
              onClick={() => setCreateActivityDialog(true)}
              fullWidth
              className={classes.buttonWithSpecialBorder}
            >
              <Box textAlign="center" p={1}>
                <Typography color="primary">{t('crm.prospect.ongoing.add')}</Typography>
              </Box>
            </Button>
          </Grid>
        </Grid>
      </Box>
      {ongoingActivities && (
        <div>
          <Box mb={1}>
            <SeparatorBox text={t('crm.prospect.ongoing.title')} />
          </Box>
          {ongoingActivities.length > 0 ? (
            <Box mb={3}>
              {ongoingActivities.map((a) => {
                return (
                  <Box mb={1}>
                    <OngoingActivity prospect={prospect} refreshData={refreshData} activity={a} key={a.Id} />
                  </Box>
                )
              })}
            </Box>
          ) : (
            <Typography variant="h3">
              <Box mb={2} display="flex" justifyContent="center" component="span" color="primary.darker">
                {t('crm.activities.ongoing.none')}
              </Box>
            </Typography>
          )}
        </div>
      )}
      <Box mb={1}>
        <SeparatorBox text={t('crm.activity.all.title')} />
      </Box>
      {activities && activities.length > 0 ? (
        activities.map((a) => {
          let icon = null
          let bgcolor = null

          switch (a.Type) {
            case 55:
              bgcolor = 'error'
              icon = <CancelOutlinedIcon className={classes.cancelIcon} />
              break
            case 65:
              bgcolor = 'success'
              icon = <CheckCircleIcon className={classes.changeIcon} />
              break
            case 75:
              bgcolor = 'success'
              icon = <SwapVerticalCircleOutlinedIcon className={classes.changeIcon} />
              break
            default:
              icon = <CheckCircleIcon className={classes.infoIcon} />
              break
          }
          return (
            <div key={a.Id}>
              <Activity activity={a} icon={icon} bgcolor={bgcolor} />
            </div>
          )
        })
      ) : (
        <Typography align="center" variant="h3" color="secondary">
          {t('prospect.activities.archived')}
        </Typography>
      )}
      <CustomDialog
        handleClose={() => setCreateActivityDialog(false)}
        open={createActivityDialog}
        title={t('crm.activity.add.title')}
        maxWidth="lg"
        fullWidth
      >
        <ActivityDialog
          prospect={prospect}
          textValidateButton={t('crm.activity.add.create')}
          handleClose={() => setCreateActivityDialog(false)}
          refreshData={refreshData}
        />
      </CustomDialog>
    </>
  )
}

export default ProspectActivities
