import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { DEFAULT_IMG } from 'config'
import React from 'react'

interface MediaImageProps {
  src: string
  alt?: string
  width?: number
  height?: number
  container?: boolean
  ratio?: number
  mediaParams?: MediaImageParamsProps
  style?: React.CSSProperties
  className?: string
}

type MediaImageURLParamsProps = {
  mode?: string
  width?: number
  height?: number
  retina?: boolean
  upscale?: boolean
  animationprocessmode?: string
  autorotate?: boolean
  cx: number
  cy: number
  cWidth: number
  cHeight: number
}

type MediaImageParamsProps = {
  xs?: MediaImageURLParamsProps
  sm?: MediaImageURLParamsProps
  md?: MediaImageURLParamsProps
  lg?: MediaImageURLParamsProps
  xl?: MediaImageURLParamsProps
}

const useStyles = (params: { imgWidth?: number; imgHeight?: number; containerRatio: number }) =>
  makeStyles((theme) => {
    let image: {
      height?: number
      width?: number
    } = {}

    const { imgHeight, imgWidth, containerRatio } = params

    if (imgHeight) image.height = imgHeight
    if (imgWidth) image.width = imgWidth

    return {
      container: {
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        paddingBottom: 100 * containerRatio + '%',
        '& > img': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          // objectFit: "contain",
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        },
      },
      image: { ...image },
    }
  })

const useTransformUrlForMedia = (src: string, mediaParams?: MediaImageParamsProps) => {
  const theme = useTheme()

  const matches: {
    xs: boolean
    sm: boolean
    md: boolean
    lg: boolean
    xl: boolean
  } = {
    xs: useMediaQuery(theme.breakpoints.up('xs')),
    sm: useMediaQuery(theme.breakpoints.up('sm')),
    md: useMediaQuery(theme.breakpoints.up('md')),
    lg: useMediaQuery(theme.breakpoints.up('lg')),
    xl: useMediaQuery(theme.breakpoints.up('xl')),
  }

  if (!src) return null

  let newSrc = src
  const addParams = (params: MediaImageURLParamsProps) => {
    const {
      retina = false,
      width,
      height,
      upscale = false,
      animationprocessmode = 'first',
      autorotate = true,
      mode,
      cx,
      cy,
      cWidth,
      cHeight,
    } = params

    let containsParam = newSrc.indexOf('?') > -1

    const addParamToUrl = (name: string, val: any) => {
      newSrc += `${containsParam ? '&' : '?'}${name}=${val}`
      containsParam = true
    }

    addParamToUrl('upscale', upscale)
    addParamToUrl('animationprocessmode', animationprocessmode)
    addParamToUrl('autorotate', autorotate)

    if (src !== DEFAULT_IMG) {
      if (
        cx !== null &&
        cx !== undefined &&
        cy !== null &&
        cy !== undefined &&
        cWidth !== null &&
        cWidth !== undefined &&
        cHeight !== null &&
        cHeight !== undefined &&
        (cHeight !== 0 || cWidth !== 0)
      )
        addParamToUrl('crop', `${cx},${cy},${cWidth},${cHeight}`)
    }
    if (width) addParamToUrl('width', retina ? width * 2 : width)
    if (height) addParamToUrl('height', retina ? height * 2 : height)
    if (mode) addParamToUrl('mode', mode)
  }

  if (mediaParams) {
    const { xs: pXs, sm: pSm, md: pMd, lg: pLg, xl: pXl } = mediaParams

    if (matches.xl && pXl) addParams(pXl)
    else if (matches.lg && pLg) addParams(pLg)
    else if (matches.md && pMd) addParams(pMd)
    else if (matches.sm && pSm) addParams(pSm)
    else if (matches.xs && pXs) addParams(pXs)
  }

  return newSrc
}

const MediaImage: React.FC<MediaImageProps> = ({
  className,
  container = false,
  ratio = 1,
  alt = '',
  width,
  height,
  src,
  mediaParams,
  style,
}) => {
  const classes = useStyles({
    imgHeight: height,
    imgWidth: width,
    containerRatio: ratio,
  })()

  const newSrc = useTransformUrlForMedia(src, mediaParams)
  const img = <img src={newSrc ?? ''} style={style} className={[classes.image, className].join(' ')} alt={alt} />
  if (container) {
    return (
      <div className={classes.container} style={{ height: height, width: width }}>
        {img}
      </div>
    )
  }

  return img
}

export { MediaImage, useTransformUrlForMedia }
