import { Box, makeStyles, useTheme } from '@material-ui/core'
import { MediaImage } from 'components/CustomComponents/MediaImage'
import { DEFAULT_IMG } from 'config'

const useStyles = makeStyles((theme) => ({
  img: {
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    aspectRatio: 1,
  },
}))

function SquaredImg({ width, image = { Url: DEFAULT_IMG }, ...rest }) {
  const classes = useStyles()

  const defaultMediaParams = {
    xs: {
      width: width ?? image?.Width ?? null,
      height: width ?? image?.Height ?? null,
      cx: image?.CropX ?? 0,
      cy: image?.CropY ?? 0,
      cWidth: image?.CropWidth && image.CropWidth,
      cHeight: image?.CropHeight && image.CropHeight,
    },
  }

  return <MediaImage className={classes.img} src={image.Url} mediaParams={defaultMediaParams} {...rest} />
}

export default SquaredImg
