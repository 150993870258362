import { Box, BoxProps, makeStyles, Paper, PaperProps, Typography, TypographyProps } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
}))

interface SeparatorBoxProps extends PaperProps {
  text?: string
  TypographyParams?: TypographyProps
  BoxParams?: BoxProps
}

const defaultVariant = 'subtitle1'
const defaultAlign = 'center'
const SeparatorBox: React.FC<SeparatorBoxProps> = (props) => {
  const classes = useStyles()
  const { text, TypographyParams, BoxParams, elevation = 0, ...rest } = props

  let typoComp
  if (TypographyParams) {
    const { variant = defaultVariant, align = defaultAlign, ...typoRest } = TypographyParams
    typoComp = (
      <Typography variant={variant} align={align} {...typoRest}>
        {text}
      </Typography>
    )
  } else {
    typoComp = (
      <Typography variant={defaultVariant} align={defaultAlign}>
        {text}
      </Typography>
    )
  }

  return (
    <Paper className={classes.box} elevation={elevation} {...rest}>
      <Box {...BoxParams}>{typoComp}</Box>
    </Paper>
  )
}

export default SeparatorBox
