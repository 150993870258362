import { makeStyles } from '@material-ui/core/styles'
import image from 'assets/images/404.svg'
import HeroText from 'components/CustomComponents/HeroText'
import Layout from 'components/views/Layout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'

const imgWidth = '800px'

const useStyles = makeStyles((theme) => ({
  img: {
    width: imgWidth,
    paddingTop: theme.spacing(5),
  },
}))

function NotFoundPage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  // const handleBackClick = () => {
  //   history.push(getRoutePath('profile'))
  // }

  React.useEffect(() => {
    history.push(getRoutePath('publications'))
  }, [])

  return (
    <Layout>
      {/* <Box p={2}>
      <Typography variant="h3" component="p" gutterBottom>{t('404.title')}</Typography>
      <Typography variant="subtitle1" gutterBottom>{t('404.text')}</Typography>
      <Box mt={2}>
        <Button onClick={handleBackClick} variant="contained" color="primary">{t('404.button.text')}</Button>
      </Box>
    </Box> */}
      <HeroText
        title={t('404.title')}
        description={t('404.text')}
        btnText={t('404.button.text')}
        imageProps={{
          src: image,
          className: classes.img,
        }}
        // btnProps={{
        //   onClick: handleBackClick,
        // }}
      />
    </Layout>
  )
}

export default NotFoundPage
