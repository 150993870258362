import { SOCIETY_SERVER, USERS_SERVER } from 'config'

export const apiActions = {
  getCart: 'cart.getcart',
  getCategoryFilters: 'resourcetype.getfilters',
  getCoworkingSpace: 'coworkingspace.getallinfos',
  getCoworkingSpaces: 'coworkingspaces.getall',
  getAllCategories: 'coworkingspace.getcategories',
  getCategory: 'coworkingspace.category',
  getAllItems: 'coworkingspace.getcategoryitems',
  getItem: 'coworkingspace.categoryitem',
  getAllBusyTimes: 'coworkingspace.getbusytimes',
  getBusyTimesByItemId: 'coworkingspace.getbusytimesbyitemid',
  getPhotogroup: 'social.getphotogroup',
  paymentPay: 'payment.pay',
  getReservationInvoice: 'reservation.getinvoice',
  cancelReservationOrder: 'reservation.cancelorder',
  returnOrder: 'reservation.returnorder',
  getIncomingReservations: 'reservations.getincomingforuser',
  getOngoingReservations: 'reservations.getongoingforuser',
  getPastReservations: 'reservations.getpastforuser',
  getBetweenDates: 'reservations.getordersbetweendates',
  getForm: 'resourcetype.getform',
  getTotalPrice: 'resourcetype.gettotalprice',
  getTimes: 'resourcetype.gettimes',
  getDurations: 'resourcetype.getdurations',
  getUserTickets: 'resourcetype.getusertickets',
  getTheme: 'society.gettheme',
  getSociety: 'society.get',
  getUserProfile: 'users.getuserprofile',
  editUserProfile: 'users.edituserprofile',
  userLogout: 'users.logout',
  getAllActivities: 'activities.getallongoingactivities',
  getAllProspect: 'prospects.getall',
  getProspectById: 'prospects.getbyid',
  addProspect: 'prospects.addprospect',
  editProspectData: 'prospects.editprospectdata',
  editProspectConversion: 'prospects.editconversion',
  getAdminLists: 'prospects.getadminlist',
  editProspectOwner: 'prospects.editowner',
  createActivity: 'prospects.addactivitytoprospect',
  getTagsByProspectId: 'prospects.getcrmtags',
  addTag: 'prospects.addtagstoprospect',
  deleteTagById: 'prospects.deletetag',
  editActivity: 'prospects.editprospectactivity',
  closeActivity: 'prospects.closeprospectactivity',
  transformProspect: 'prospects.transformprospect',
  getInterestsCategories: 'prospects.getcategorieslist',
  getInterestsItems: 'prospects.getitemslist',
  editInterests: 'prospects.editinterests',
  getInterestsByProspectId: 'prospects.getprospectinterests',
  editInterestDate: 'prospects.editinteresteddate',
  deleteProspectActivity: 'prospects.deleteprospectactivity',
  ownerGetCAInfos: 'owner.getcainfos',
  ownerGetOccupancyRate: 'owner.getoccupancyrate',
  ownerGetEvolutions: 'owner.getevolutions',
  ownerGetOrdersByCoworkingSpaceId: 'owner.getordersbycoworkingspaceid',
  ownerGetCoworkingSpacesByOwnerId: 'owner.getcoworkingspacesbyownerid',
  ownerGetPlansByCoworkingSpaceId: 'owner.getplansbycoworkingspaceid',
  ownerGetCAByCategories: 'owner.getcabycategories',
  ownerGetOccupancyRateGraph: 'owner.getoccupancyrategraph',
  ownerGetPlanPartImage: 'owner.getplanpartimage',
  ownerGetPlanPartResourceRents: 'owner.getplanpartresourcerents',
  ownerGetPlanPartMap: 'owner.getplanpartmap',
  ownerUpdatePlanPartMap: 'owner.updateplanpartmap',
  ownerGetPlanInfos: 'owner.getplaninfos',
  coworkerExists: 'prospecttransform.coworkerexists',
  getCoworker: 'prospecttransform.getcoworker',
  getWalletBalance: 'prospecttransform.getwalletbalance',
  createCoworkerFromProspectData: 'prospecttransform.createcoworkerfromprospectdata',
  updateCoworkerFromProspectData: 'prospecttransform.updatecoworkerfromprospectdata',
  getMandatoryPhotoOption: 'prospecttransform.getmandatoryphotooption',
  bookResources: 'prospecttransform.bookresources',
  archiveProspect: 'prospects.archiveprospect',
  unarchiveProspect: 'prospects.unarchiveprospect',
  getCategoriesList: 'prospects.getcategorieslist',
  getItemsList: 'prospects.getitemslist',
  getAdminPlans: 'admin.getplansbycoworkingspaceid',
  getInvoices: 'invoices.getinvoices',
  getEventsFilters: 'resourcetype.geteventsfilters',
  getEvents: 'social.getevents',
  editUserGlobalInfos: 'users.edituserglobalinfos',
  editUserNotifications: 'users.editusernotifications',
  editUserPassword: 'users.edituserpassword',
  editUserInvoicesInfos: 'users.edituserinvoicesinfos',
  editUserSocietyInfos: 'users.editusersocietyinfos',
  editUserSocialInfos: 'users.editusersocialinfos',
  editUserProfessionalInfos: 'users.edituserprofessionalinfos',
  editUserProfilePicture: 'users.edituserprofilepicture',
  editUserFociAndDescription: 'users.edituserfocianddescription',
  editTrombinoscopePreferences: 'users.edittrombinoscopepreferences',
  getPublicationsMenu: 'publications.getpublicationsmenu',
  getPublications: 'publications.getpublications',
  getHomeMessage: 'home.gethomemessage',
  getEventsLastChances: 'home.geteventslastchances',
  getUserById: 'social.getuserbyid',
  addPost: 'publications.addpost',
  deletePost: 'publications.deletepost',
  editPost: 'publications.editpost',
  addComment: 'publications.addcomment',
  deleteComment: 'publications.deletecommentary',
  addChannel: 'publications.addusergroupchannel',
  getCoworkingSpaceByAdminId: 'admin.getcoworkingspacesbyadminid',
  addPlan: 'admin.addplan',
  editFontSizePlan: 'admin.editfontsize',
  getResourcesGivingCredits: 'print.getresourcesgivingcredits',
  addGuest: 'wifi.addguest',
  getGuestsList: 'wifi.getguestslist',
  reinitGuest: 'wifi.reinitguest',
  getAllPartners: 'partners.getallpartners',
  getTpes: 'payment.gettpes',
  checkPromotionalCode: 'payment.checkpromotionalcode',
  payCredits: 'payment.paycredits',
  getPlanOrderResourceRentList: 'plan.getorderresourcerentlist',
  deletePlanPart: 'admin.deleteplanpart',
  getPlatformLink: 'platformlink.get',
  getRegisterAdditionalDatas: 'users.getadditionaldatas',
  getAllSocietiesByFTELSocietyId: 'society.getsocietiesbyftelsocietyid',
  transfertCredit: 'wallet.transfertcredit',
  getCoworkersInSociety: 'society.getcoworkersinsociety',
  getDocumentsToValidate: 'documentstovalidate.getdocumentstovalidate',
  getAllDocumentsToValidate: 'documentstovalidate.getalldocumentstovalidate',
  validateDocuments: 'documentstovalidate.validatedocuments',
}

export const userActions = {
  auth: `${USERS_SERVER}/auth`,
  newToken: `${USERS_SERVER}/new-token`,
  register: `${USERS_SERVER}/register`,
  login: `${USERS_SERVER}/login`,
  logout: `${USERS_SERVER}/logout`,
}

export const societyActions = {
  getTheme: `${SOCIETY_SERVER}/get-theme`,
  getSociety: `${SOCIETY_SERVER}`,
}
