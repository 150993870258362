import { Box, Icon, makeStyles, Switch } from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles((theme) => ({
  switch: {
    height: 27,
    padding: 8,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(3px)',
      '&.Mui-checked': {
        color: theme.palette.primary.light,
        transform: 'translateX(28px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
  thumb: {
    backgroundColor: theme.palette.primary.main,
    width: 25,
    height: 25,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  track: {
    opacity: 1,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 20 / 2,
  },
  icon: {
    backgroundColor: 'white',
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: '50%',
    color: theme.palette.primary.main,
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}))

function AccountSwitch({ value, onChange }) {
  const classes = useStyles()
  const [checked, setChecked] = React.useState(value)

  const handleChange = (e) => {
    setChecked(e.target.checked)
    onChange(e)
  }

  return (
    <Switch
      onChange={(e) => handleChange(e)}
      checked={checked}
      classes={{
        root: classes.switch,
        thumb: classes.thumb,
        track: classes.track,
      }}
      icon={<CloseIcon className={classes.icon} />}
      checkedIcon={<CheckIcon className={classes.checkedIcon} />}
    />
  )
}

export default AccountSwitch
