import { Box, Button, InputAdornment, Popover, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import React from 'react'
import { HexColorPicker } from 'react-colorful'

const regColor = /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^\)]*\)/gi
const btnSize = 28
const useStyles = makeStyles((theme) => ({
  popover: {
    '& > .MuiPopover-paper': {
      overflow: 'visible',
    },
  },
  button: {
    borderRadius: btnSize / 2,
    width: btnSize,
    height: btnSize,
    minWidth: btnSize,
    minHeight: btnSize,
    padding: 0,
    borderWidth: 1,
    borderStyle: 'solid',
  },
}))

function ColorPicker({ label, value, onChange, variant = 'standard', fullWidth = false }) {
  const classes = useStyles()
  const [color, setColor] = React.useState(value)
  const [inputColor, setInputColor] = React.useState(color)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSetColor = (val) => {
    setInputColor(val)
    if (regColor.test(val)) {
      setColor(val)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  React.useEffect(() => {
    onChange(color)
  }, [color])

  return (
    <Box>
      <TextField
        variant={variant}
        label={label}
        value={inputColor}
        fullWidth={fullWidth}
        onChange={(e) => handleSetColor(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button
                onClick={handleClick}
                className={classes.button}
                style={{ backgroundColor: color, borderColor: darken(color, 0.2) }}
              />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popover}
      >
        <HexColorPicker color={color} onChange={handleSetColor} />
      </Popover>
    </Box>
  )
}

export default ColorPicker
