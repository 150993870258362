import { Box, Button, Dialog, Grid, Hidden, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import ShowPrice, { formatCost } from 'components/ShowPrice'
import Layout from 'components/views/Layout'
import ItemImagesGrid from 'components/views/NewReservationPage/ItemImagesGrid'
import ItemPage, { ItemDetails, ReservationDetails } from 'components/views/NewReservationPage/ItemPage'
import { DEFAULT_IMG } from 'config'
import { MoneyContext } from 'contexts/MoneyContext'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  iconDate: {
    color: theme.palette.primary.darker,
    marginRight: theme.spacing(1),
    fontSize: 15,
  },
  fixedButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    left: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      left: 100,
    },
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(10),
      left: theme.spacing(2),
    },
    '&.ghost': {
      position: 'relative',
      bottom: 0,
      right: 0,
      visibility: 'hidden',
    },
    '&.noBar': {
      [theme.breakpoints.down('sm')]: {
        bottom: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        bottom: theme.spacing(10),
      },
    },
  },
}))

function EventPage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const match = useRouteMatch()
  const { apiCall, user } = useContext(UserContext)
  const money = useContext(MoneyContext)

  const [nothingBookable, setNothingBookable] = React.useState(false)
  const [showMobileForm, setShowMobileForm] = React.useState(false)

  const coworkingSpaceId = match.params.coworkingspace
  const categoryId = match.params.category
  const itemId = match.params.event

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const { data: categoryInfos } = useSWR(`getCategory/${coworkingSpaceId}/${categoryId}`, () =>
    apiCall(apiActions.getCategory, { coworkingSpaceId, categoryId }).catch((e) => {
      throw Error(e)
    })
  )
  const { data: itemInfos } = useSWR(`getItem/${coworkingSpaceId}/${categoryId}/${itemId}`, () =>
    apiCall(apiActions.getItem, { coworkingSpaceId, categoryId, itemId }).catch((e) => {
      throw Error(e)
    })
  )
  const { data: fields } = useSWR(`getForm/${coworkingSpaceId}/${categoryId}/${itemId}`, () =>
    apiCall(apiActions.getForm, { coworkingSpaceId, categoryId, itemId }).then((r) => {
      if (r.Error) {
        //
      } else {
        setNothingBookable(r.NothingBookable)
        return r.data
      }
    })
  )

  const OrderButton = ({ noBar, ghost }) => {
    const theme = useTheme()
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

    const compClasses = [classes.fixedButton]
    if (ghost) {
      compClasses.push('ghost')
    }
    if (noBar) {
      compClasses.push('noBar')
    }

    return (
      <Box className={compClasses.join(' ')}>
        <Button color="secondary" onClick={() => setShowMobileForm(true)} type="submit" fullWidth variant="contained">
          {isSmDown ? t('reservations.details.order.mobile') : t('reservations.details.order')}
        </Button>
      </Box>
    )
  }

  const reservationForm = fields ? (
    <ReservationDetails
      categoryInfos={categoryInfos}
      nothingBookable={nothingBookable}
      fields={fields}
      itemInfos={itemInfos}
      coworkingSpaceId={coworkingSpaceId}
      categoryId={categoryId}
      itemId={itemId}
      isEvent
      dialogIsOpen={showMobileForm}
      onCloseDialog={() => setShowMobileForm(false)}
    />
  ) : (
    <LoadingElem />
  )

  if (!fields || !itemInfos || !categoryInfos) return <LoadingElem />

  return (
    <Layout
      pageTitle={t('events.page.title')}
      pageDescription={t('events.page.description')}
      breadcrumbsInfos={{ EventName: itemInfos?.Name }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <ItemImagesGrid images={itemInfos?.Image?.ImageURL ? [itemInfos?.Image?.ImageURL] : [DEFAULT_IMG]} />
            </Grid>
            <Grid item xs={12} md>
              <Typography gutterBottom variant="h5">
                <Box component="span" color="primary.darker">
                  {itemInfos?.Name}
                </Box>
              </Typography>
              <Box display="flex" alignItems="center" mb={4}>
                <Icon icon="agenda-jour" className={classes.iconDate} />
                <Typography variant="body1" color="textSecondary">
                  {moment(itemInfos?.EventDate).format('DD MMM, HH[h]mm')}
                </Typography>
              </Box>
              <Typography gutterBottom variant="h4">
                <Box component="span" color="primary.darker">
                  {t('common.description')}
                </Box>
              </Typography>
              <Box mb={2}>
                <Typography variant="subtitle1">
                  <Box
                    component="span"
                    color="primary.darker"
                    fontWeight="fontWeightMedium"
                    dangerouslySetInnerHTML={{ __html: itemInfos?.ShortDescription }}
                  />
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} xs={6}>
                  <ItemDetails
                    icon={<Icon icon="mon-profil" />}
                    title={t('event.animator')}
                    description={itemInfos?.Animator}
                  />
                </Grid>
                <Grid item lg={4} xs={12} sm={6}>
                  <ItemDetails
                    icon={<Icon icon={money.iconMoney} />}
                    title={t('reservations.item.infos.payment')}
                    description="A la réservation"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomPaper>
                <Box p={2}>
                  <Box display="flex" alignItems="center">
                    <Icon icon="maps" className={classes.iconDate} />
                    <Typography color="textSecondary" variant="subtitle1">
                      {itemInfos.CoworkingSpaceName}
                    </Typography>
                  </Box>
                </Box>
              </CustomPaper>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12}>
                <CustomPaper>{reservationForm}</CustomPaper>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Hidden mdUp>
          <div>
            <OrderButton noBar={user === null} />
            <OrderButton ghost />
            <Dialog keepMounted fullScreen={isXsDown} open={showMobileForm} onClose={() => setShowMobileForm(false)}>
              {reservationForm}
            </Dialog>
          </div>
        </Hidden>
      </Grid>
    </Layout>
  )
}

export default EventPage
