import { useTranslation } from 'react-i18next'
import Layout from 'components/views/Layout'
import AllPlans from 'area/owner/components/AllPlans'
import useSWR from 'swr'
import React, { useContext } from 'react'
import { apiActions } from '_actions/api_actions'
import { UserContext } from 'contexts/UserContext'
import CustomButton from 'components/CustomComponents/CustomButton'
import { Box } from '@material-ui/core'
import AddPlan from './AddPlan'

function AdminPlansPage() {
  const { t } = useTranslation()
  const { apiCall, user } = useContext(UserContext)

  const [csIds, setCsIds] = React.useState([])
  const [openAdd, setOpenAdd] = React.useState(false)

  const { data: cSpaces } = useSWR(`${user?.Id}/coworkingspaces`, () =>
    apiCall(apiActions.getCoworkingSpaceByAdminId, { AdminId: user?.Id }, 'get').then((r) => {
      if (r.Error) {
        //
      } else {
        return r
      }
    })
  )

  const onCSChange = (ids) => {
    setCsIds(ids)
  }

  return (
    <Layout
      pageTitle={t('admin-plans.page.title')}
      pageDescription={t('admin-plans.page.subtitle')}
      titleComponent={
        <CustomButton variant="outlined" color="secondary" onClick={() => setOpenAdd(true)}>
          <Box color="primary.darker">{t('admin-plan.add.plan')}</Box>
        </CustomButton>
      }
    >
      <AllPlans onCSChange={onCSChange} csIds={csIds} nameRoutePathOnClick={'admin-plan'} />
      {openAdd && <AddPlan onClose={() => setOpenAdd(false)} cSpaces={cSpaces} selectedCS={csIds[0]} />}
    </Layout>
  )
}

export default AdminPlansPage
