import { Grid, makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import { Pagination } from '@material-ui/lab'
import CustomTextField from 'components/CustomTextField'
import LoadingElem from 'components/LoadingElem'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  select: {
    opacity: 0.8,
    '& fieldset': {
      border: 0,
    },
  },
}))

type ListViewProps = {
  component: React.ComponentType<any>
  emptyComponent?: React.ComponentType<any>
  topLeftComponent?: React.ComponentType<any>
  titleComponent?: React.ComponentType<any>
  read: Function
  perPage?: number
  initPage?: number
  showPerPageSelect?: boolean
  perPageList?: number[]
  reload?: boolean
}

type DataProps = {
  page: number
  total: number
  data: any
}

const ListView: React.FC<ListViewProps> = function (props) {
  const {
    component,
    emptyComponent,
    topLeftComponent,
    titleComponent,
    read,
    initPage = 1,
    showPerPageSelect = true,
    perPageList = [5, 10, 20],
    perPage = perPageList[0],
    reload = false,
  } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = React.useState(true)
  const [page, setPage] = React.useState(initPage)
  const [itemPerPage, setItemPerPage] = React.useState(perPage)
  const [total, setTotal] = React.useState(0)
  const [data, setData] = React.useState<any | null>()

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page)
  }

  const handleChangeItemPerPage = (event: ChangeEvent<{ name?: string; value?: any }>) => {
    setItemPerPage(event.target.value)
  }

  React.useEffect(() => {
    read({
      perPage: itemPerPage,
      page: page,
    }).then((r: DataProps) => {
      setData(r.data)
      setTotal(r.total)
      if (r.data && r.data.length === 0 && page > 1) {
        setPage(initPage)
      }
      setIsLoading(false)
    })
  }, [page, itemPerPage, reload])

  if (isLoading || !data) return <LoadingElem />

  let topLeftElem
  if (topLeftComponent) {
    const TopLeftComp = topLeftComponent
    topLeftElem = <TopLeftComp />
  }

  const topComp = (
    <>
      {((showPerPageSelect && perPageList.length > 0) || topLeftElem) && (
        <div>
          <Grid container spacing={1} justifyContent="flex-end">
            {topLeftElem && (
              <Grid item xs={12} sm>
                {topLeftElem}
              </Grid>
            )}
            {perPageList.length > 0 && showPerPageSelect && (
              <Grid item xs={12} sm="auto">
                <Box textAlign="right">
                  <CustomTextField
                    select
                    value={itemPerPage}
                    onChange={handleChangeItemPerPage}
                    className={classes.select}
                    margin="dense"
                  >
                    {perPageList.map((pp: any, i: number) => (
                      <MenuItem key={'menuitem_' + i} value={pp}>
                        {pp}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Box>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  )

  if (data.length === 0) {
    if (emptyComponent) {
      const EmptyComp = emptyComponent
      return (
        <div>
          {topComp}
          <EmptyComp />
        </div>
      )
    } else {
      return (
        <div>
          {topComp}
          <Box py={2}>{t('empty.list')}</Box>
        </div>
      )
    }
  }

  const Cmp = component
  const lines = data.map((m: any, i: number) => <Cmp key={'cmp_' + i} {...m} />)
  const showPagination = total > itemPerPage

  let titleCmp
  if (titleComponent) {
    const TitleCmp = titleComponent
    titleCmp = <TitleCmp />
  }

  return (
    <div>
      {topComp}
      <div>
        {titleCmp}
        {lines}
      </div>
      {showPagination && (
        <Box mt={1}>
          <Pagination
            page={page}
            onChange={handleChangePage}
            count={Math.ceil(total / itemPerPage)}
            color="secondary"
          />
        </Box>
      )}
    </div>
  )
}

export default ListView
