import { FormControlLabel, Grid, IconButton, makeStyles, Switch } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import CustomLabel from 'components/CustomLabel'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import moment, { duration } from 'moment'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  switch: {
    marginBottom: theme.spacing(-0.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1.5),
    '& :not(.Mui-checked)': {
      color: theme.palette.grey[600],
    },
    '& .Mui-checked .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
    },
  },
  switchInput: {
    padding: theme.spacing(0) + 'px !important',
    paddingLeft: theme.spacing(0.5) + 'px !important',
  },
  formControl: {
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
  },
  undefined: {
    fontSize: theme.typography.caption.fontSize,
  },
}))

function FilterDate({
  methods,
  onChange,
  textLabel,
  undefinedDate,
  end,
  shouldDisableDate,
  disable,
  filterEnd,
  reset,
  durationType,
  isRollingMonth,
}) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [dateValue, setDateValue] = React.useState(null)
  const [checked, setChecked] = React.useState(undefinedDate && !localStorage.getItem('enddateitem'))
  const [disabled, setDisabled] = React.useState(false)

  React.useEffect(() => {
    setDateValue(null)
  }, [reset])

  React.useEffect(() => {
    if (!end && localStorage.getItem('startdateitem')) {
      setDateValue(localStorage.getItem('startdateitem'))
      onChange(moment(localStorage.getItem('startdateitem')).toDate())
    }
    if (end && localStorage.getItem('enddateitem')) {
      setDateValue(localStorage.getItem('enddateitem'))
      onChange(moment(localStorage.getItem('enddateitem')).toDate())
    }
  }, [localStorage.getItem('startdateitem'), localStorage.getItem('enddateitem')])

  React.useEffect(() => {
    if (filterEnd && !methods.getValues().startdate) {
      setDateValue(null)
      setDisabled(true)
    }
  }, [methods.getValues().startdate])

  React.useEffect(() => {
    setDisabled(disable)
  }, [disable])

  React.useEffect(() => {
    if (checked) {
      setDateValue(null)
      onChange(null)
      // if (end)
      //   setDisabled(true);
    } else {
      if (!disable) setDisabled(false)
    }
  }, [checked])

  React.useEffect(() => {
    if (undefinedDate && end && dateValue) {
      setChecked(false)
    }
    if (undefinedDate && end && !dateValue) {
      setChecked(true)
    }
  }, [dateValue])

  React.useEffect(() => {
    if (durationType && durationType === 'month' && end && !disabled && !dateValue) {
      let d = null
      if (isRollingMonth) d = moment().add(1, 'month').subtract(1, 'day')
      else d = moment().endOf('month')

      setDateValue(d)
      onChange(d.toDate())
    }
  }, [durationType])

  const handleChange = (val) => {
    setDateValue(val)
    onChange(val.toDate())
  }

  const handleClr = (e) => {
    setDateValue(null)
    e.stopPropagation()
    onChange(null)
  }

  return (
    <div>
      {undefinedDate && (
        <Grid container justifyContent="space-between">
          <Grid item>
            <CustomLabel>{t('filters.enddate')}</CustomLabel>
          </Grid>
          <Grid item>
            <FormControlLabel
              className={classes.formControl}
              checked={checked}
              onChange={() => setChecked(!checked)}
              value="start"
              control={
                <Switch
                  classes={{ root: classes.switch, switchBase: classes.switchInput }}
                  size="small"
                  color="primary"
                />
              }
              label={<CustomLabel className={classes.undefined}>{t('filters.duration.undefined')}</CustomLabel>}
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      )}
      {!checked && (
        <DatePicker
          label={!undefinedDate && (textLabel ? t(textLabel) : 'Date')}
          disabled={disabled}
          onOpen={() => undefinedDate && setChecked(false)}
          autoOk
          fullWidth
          color="primary"
          clearable="true"
          disablePast
          placeholder={t('filters.date.placeholder')}
          variant="inline"
          value={dateValue}
          shouldDisableDate={shouldDisableDate ?? null}
          format="DD/MM/yy"
          onChange={(e) => handleChange(e)}
          TextFieldComponent={(params) => <CustomTextField {...params} variant="outlined" />}
          PopoverProps={{
            elevation: 3,
          }}
          InputProps={{
            endAdornment: dateValue ? (
              <IconButton size="small" onClick={(e) => handleClr(e)}>
                <Icon icon="close" color="action" />
              </IconButton>
            ) : (
              <Icon icon="agenda-jour" color="action" />
            ),
          }}
        />
      )}
      {/* {methods.formState.errors.date && methods.formState.errors.date.types && (
        <p>{methods.formState.errors.date.types.required}</p>
      ) } */}
    </div>
  )
}

function Date({
  methods,
  filter,
  textLabel,
  disable,
  undefinedDate,
  end,
  onDateChange,
  shouldDisableDate,
  filterEnd,
  reset,
  durationType,
  isRollingMonth,
}) {
  const handleChange = (field, value) => {
    if (onDateChange) onDateChange(value)
    field.onChange(value)
  }

  return (
    <Controller
      control={methods.control}
      name={filter.Name}
      render={({ field }) => (
        <FilterDate
          end={end}
          methods={methods}
          textLabel={textLabel ?? filter.Key}
          shouldDisableDate={shouldDisableDate}
          undefinedDate={undefinedDate || filter.CanBeUndefined}
          onChange={(value) => handleChange(field, value)}
          onBlur={field.onBlur}
          disable={disable}
          filterEnd={filterEnd}
          reset={reset}
          durationType={durationType}
          isRollingMonth={isRollingMonth}
        />
      )}
    />
  )
}

export default Date
