import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@material-ui/core'
import CustomButton from 'components/CustomButton'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import RenderField from 'components/views/PaymentPage/RenderField'
import { UserContext } from 'contexts/UserContext'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { apiActions } from '_actions/api_actions'

function CreateChannel({ groupId, handleClose }) {
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const methods = useForm({
    defaultValues: {
      name: null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
      })
    ),
  })

  const onFormSubmit = (values) => {
    apiCall(apiActions.addChannel, { userGroupId: groupId }, 'put', {
      userGroupChannelName: values.name,
    }).then((r) => {
      if (!r.Error) {
        handleClose()
      }
    })
  }

  return (
    <CustomDialog maxWidth="lg" fullWidth open handleClose={handleClose} title={t('publications.create.channel.title')}>
      <form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <Box mb={2}>
          <RenderField methods={methods} name="name" label={t('publications.create.channel.title.label') + '*'} />
        </Box>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={3}>
            <CustomButton fullWidth variant="outlined" color="secondary" onClick={handleClose}>
              <Box color="primary.darker">{t('publications.create.channel.cancel')}</Box>
            </CustomButton>
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomButton fullWidth variant="contained" color="secondary" type="submit">
              {t('publications.create.channel.confirm')}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  )
}

export default CreateChannel
