import { basename } from 'config'
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import React from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { getRoutePath } from 'routes'
import { apiActions } from '_actions/api_actions'

const CoworkingSpaceContext = React.createContext()

const storageKey = 'coworkingSpace'
const fromLocalStorage = window.localStorage.getItem(storageKey)
const toLocalStorage = (val) => {
  if (val) {
    window.localStorage.setItem(storageKey, val)
  } else {
    window.localStorage.removeItem(storageKey)
  }
}

function CoworkingSpaceProvider({ children }) {
  const { society } = React.useContext(SocietyContext)
  const { user, actions, apiCall } = React.useContext(UserContext)
  const [coworkingSpace, setCoworkingSpace] = React.useState(undefined)
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch(`${basename}/new-reservation/:coworkingspace`)

  React.useEffect(() => {
    const cspaceId = match && match.params && match.params.coworkingspace
    let finalId = null

    if (cspaceId) {
      finalId = cspaceId
    } else if (fromLocalStorage) {
      finalId = fromLocalStorage
    } else if (user && user.CoworkingSpaceId) {
      finalId = user.CoworkingSpaceId
    } else if (society && society.CoworkingSpaces && society.CoworkingSpaces.length > 0) {
      finalId = society.CoworkingSpaces[0].Id
    }

    if (finalId) {
      apiCall(apiActions.getCoworkingSpace, { CoworkingSpaceId: finalId })
        .then((r) => {
          setCoworkingSpace(r)
        })
        .catch((e) => {
          history.push(getRoutePath('coworkingspaces'))
        })
    } else {
      setCoworkingSpace(null)
    }
  }, [location, user, society]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    toLocalStorage((coworkingSpace && coworkingSpace.Id) ?? null)
  }, [coworkingSpace])

  return (
    <CoworkingSpaceContext.Provider value={{ coworkingSpace, setCoworkingSpace }}>
      {children}
    </CoworkingSpaceContext.Provider>
  )
}

export { CoworkingSpaceProvider, CoworkingSpaceContext }
