import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function InfosDocuments() {
  const { t } = useTranslation()

  return (
    <div>
      <Box mb={1}>{t('documents.to.validate.infos.title')}</Box>
      <Box mb={2}>{t('documents.to.validate.infos.subtitle')}</Box>
      <Box mb={1} dangerouslySetInnerHTML={{ __html: t('documents.to.validate.infos.unvalidated') }} />
      <Box mb={2}>
        <div style={{ color: 'orange' }}>
          <Typography variant="subtitle1">{t('documents.to.validate.infos.orange')}</Typography>
        </div>
        {t('documents.to.validate.infos.orange.infos')}
      </Box>
      <Box mb={2}>
        <div style={{ color: 'red' }}>
          <Typography variant="subtitle1">{t('documents.to.validate.infos.red')}</Typography>
        </div>
        {t('documents.to.validate.infos.red.infos')}
      </Box>
      <Box dangerouslySetInnerHTML={{ __html: t('documents.to.validate.infos.black') }} />
      {t('documents.to.validate.infos.black.infos')}
    </div>
  )
}

export default InfosDocuments
