import { alpha, Box, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import { onlyUnique } from 'components/views/NewReservationPage/ItemsScheduler'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import randomColor from 'randomcolor'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: 450,
    width: '100%',
    '& > canvas': {
      height: '100% !important',
      width: '100% !important',
    },
  },
  boxLegend: {
    border: '1px solid ' + theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1) + 'px ' + theme.spacing(2) + 'px',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      display: 'inherit',
    },
  },
  icon: {
    color: theme.palette.primary.light,
  },
}))

let options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return ' ' + context.dataset.label + ' : ' + context.parsed.y + ' € HT'
        },
      },
    },
  },
}

const CAEvolutions = ({ filters }) => {
  const { t } = useTranslation()
  const { apiCall } = React.useContext(UserContext)
  const classes = useStyles()
  const [labels, setLabels] = React.useState(null)
  const [datasets, setDatasets] = React.useState(null)
  const [dataChart, setDataChart] = React.useState(null)

  const [legend, setLegend] = React.useState({})
  const ref = React.useRef(null)

  const { data: series } = useSWR('getCAEvolutions/' + JSON.stringify(filters), () =>
    apiCall(apiActions.ownerGetEvolutions, {}, 'post', filters).then((r) => {
      if (r.Error) {
        //
      } else {
        return r.series ?? []
      }
    })
  )

  React.useEffect(() => {
    if (filters?.coworkingSpaces?.length === 0) {
      setDataChart(null)
    }
  }, [filters])

  React.useEffect(() => {
    if (series) {
      setLabels(series.map((s) => s.data.map((d) => d.category))[0]?.filter(onlyUnique))

      let legends = series.map((cs) => {
        return {
          text: cs.name,
          fillStyle: cs.GraphColor ?? randomColor({ seed: cs.name }),
          lineWidth: 0,
          hidden: false,
        }
      })

      setLegend(legends)
    }
  }, [series])

  const dashed = (data, ctx, value) => {
    return data[ctx.p0DataIndex].provisional || data[ctx.p1DataIndex].provisional ? value : undefined
  }

  React.useEffect(() => {
    if (series && labels) {
      let newDatasets = []
      series.map((s) => {
        newDatasets.push({
          label: s.name,
          data: s.data.map((d) => d.value ?? d.provisional ?? NaN),
          borderColor: s.GraphColor ?? randomColor({ seed: s.name }),
          backgroundColor: alpha(s.GraphColor ?? randomColor({ seed: s.name }), 0.3),
          segment: {
            borderDash: (ctx) => dashed(s.data, ctx, [6, 6]),
          },
        })
      })
      setDatasets(newDatasets)
    }
  }, [labels])

  React.useEffect(() => {
    if (labels && datasets) {
      setDataChart({
        labels: labels,
        datasets: datasets,
      })
    }
  }, [datasets])

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        <Box color="primary.darker" component="span">
          {t('owner-dashboard.evolutions.title')}
        </Box>
      </Typography>
      <Box mb={2}>
        <Typography gutterBottom variant="subtitle2" color="textSecondary">
          {t('owner-dashboard.evolutions.text')}
        </Typography>
      </Box>
      <Typography variant="h3" color="textSecondary">
        {filters.startDate ? moment(filters.startDate).format('MMMM YYYY') : ''} -{' '}
        {filters.endDate ? moment(filters.endDate).format('MMMM YYYY') : ''}
      </Typography>
      {series ? (
        dataChart ? (
          <>
            <div className={classes.graphContainer}>
              <Line options={options} data={dataChart} ref={ref} />
            </div>
            <Box className={classes.boxLegend}>
              <Box mr={1} display="flex" alignItems="center">
                <Icon icon="legende" className={classes.icon} />
              </Box>
              <Box mr={4} display="flex" alignItems="center">
                <Typography variant="subtitle1">
                  <Box component="span" color="primary.darker">
                    {t('owner.dashboard.chart.legend')}
                  </Box>
                </Typography>
              </Box>
              {legend &&
                legend.map((l, index) => {
                  return (
                    <Box key={index} display="flex" alignItems="center" mr={4}>
                      <Box display="flex" alignItems="center" mr={1}>
                        <Icon icon="dot" style={{ color: l.fillStyle, fontSize: 12 }} />
                      </Box>
                      <Typography variant="subtitle1" color="textSecondary">
                        {l.text}
                      </Typography>
                    </Box>
                  )
                })}
            </Box>
          </>
        ) : (
          <Box p={2}>{t('owner-dashboard.evolutions.noserie')}</Box>
        )
      ) : (
        <Box p={2}>
          <LoadingElem minHeight="50px" />
        </Box>
      )}
    </Box>
  )
}

export default CAEvolutions
