import {
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  Grid,
  Hidden,
  Link as MuiLink,
  Popover,
  Typography,
} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { CSSGrid } from 'components/CustomComponents/CSSGrid'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import { useTransformUrlForMedia } from 'components/CustomComponents/MediaImage'
import PopoverElem from 'components/CustomComponents/PopoverElem'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import SquareButton from 'components/SquareButton'
import Layout from 'components/views/Layout'
import CoworkingSpaceInfos, {
  getGoogleMapUrlFromAddress,
  getItemFullAddress,
} from 'components/views/NewReservationPage/CoworkingSpaceInfos'
import { DEFAULT_IMG } from 'config'
import { CoworkingSpaceContext } from 'contexts/CoworkingSpaceContext'
import { UserContext } from 'contexts/UserContext'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { getRoutePath } from 'routes'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'

const mobileHeightImage = 120
const sizeMobileButton = 40
const colWidth = 310

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiCardActionArea-root': {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'flex-start',
      '& > div': {
        width: '100%',
      },
    },
  },
  media: {
    height: 150,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      height: mobileHeightImage,
    },
    // backgroundPosition: "-230px -230px",
    // backgroundSize: "inherit",
  },
  itemName: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(22),
    color: theme.palette.primary.darker,
    lineHeight: 1,
  },
  link: {
    '&, &:hover': {
      textDecoration: 'none',
    },
    flex: 1,
    '& > .MuiCardActionArea-root': {
      '&, & > .MuiCardContent-root': {
        height: '100%',
      },
    },
  },
  buttonMobile: {
    height: sizeMobileButton,
    width: sizeMobileButton,
  },
  mobileCardContent: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  select: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > div': {
      position: 'relative',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      '& + div:before': {
        content: "''",
        height: '50%',
        position: 'absolute',
        margin: 'auto',
        top: 0,
        left: 0,
        bottom: 0,
        right: 'auto',
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  dialog: {
    maxWidth: 700,
  },
}))

export function CoworkingSpace({ item, isDefault, onClick }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setOpen(false)
  }

  const cardMediaImage = useTransformUrlForMedia(item.Image.ImageURL ?? DEFAULT_IMG, {
    xs: {
      width: colWidth,
      retina: true,
      cx: item.Image.CropX,
      cy: item.Image.CropY,
      cWidth: item.Image.CropWidth,
      cHeight: item.Image.CropHeight,
    },
  })

  const googleMapAddress = getItemFullAddress(item)

  return (
    <CustomPaper selectable className={classes.card}>
      <MuiLink
        component={Link}
        onClick={(e) => onClick(e, item.Id)}
        to={getRoutePath('newreservation', { ':coworkingspace': item.Id })}
      >
        <CardMedia className={classes.media} image={cardMediaImage} />
      </MuiLink>
      <CardContent className={classes.mobileCardContent}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs="auto">
            <SquareButton
              component="a"
              target="_blank"
              href={getGoogleMapUrlFromAddress(googleMapAddress)}
              className={classes.buttonMobile}
              color="secondary"
            >
              <Icon icon="map" />
            </SquareButton>
          </Grid>
          <Grid item xs>
            <Box className={classes.infoCity}>
              <Typography variant="subtitle1">{item.CityName}</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {item.WayNumber ? item.WayNumber + ' ' : ''}
                {item.Address}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box textAlign="right">
              <SquareButton className={classes.buttonMobile} onClick={handleMoreClick}>
                <Icon icon="trois-petits-points" />
              </SquareButton>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setOpen(false)}
                elevation={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuList>
                  <PopoverElem
                    icon="horloge"
                    text={t('coworkingspaces.openingtimes')}
                    onClick={() => setDialogOpen(!dialogOpen)}
                  />
                  {item.PhoneNumber && (
                    <PopoverElem
                      icon="appeler"
                      text={t('coworkingspaces.call')}
                      component={MuiLink}
                      href={`tel:${item.PhoneNumber}`}
                    />
                  )}
                </MenuList>

                <Dialog
                  open={dialogOpen}
                  PaperProps={{
                    className: classes.dialog,
                  }}
                  fullWidth
                  fullScreen={isXsDown}
                  onClose={handleCloseDialog}
                >
                  <CoworkingSpaceInfos onCloseDialog={handleCloseDialog} id={item.Id} />
                </Dialog>
              </Popover>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <MuiLink
        className={classes.link}
        component={Link}
        onClick={(e) => onClick(e, item.Id)}
        to={getRoutePath('newreservation', { ':coworkingspace': item.Id })}
      >
        <CardActionArea>
          <CardContent>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body1" className={classes.itemName}>
                  {item.Name}
                </Typography>
                {item.Description && (
                  <Box mt={1}>
                    <Typography variant="subtitle1" component="div">
                      <Box
                        component="span"
                        color="text.secondary"
                        className={classes.html}
                        dangerouslySetInnerHTML={{ __html: item.Description }}
                      />
                    </Typography>
                  </Box>
                )}
                {isDefault && (
                  <Box mt={1}>
                    <Box color="success.main" display="flex" alignItems="center">
                      <Icon icon="favori" />
                      <Box ml={1}>
                        <Typography variant="body2">{t('coworkingspaces.page.default.space')}</Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </MuiLink>
    </CustomPaper>
  )
}

const allCitiesFilterKey = 'all_cities'
function CoworkingSpacesPage() {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const confirm = useConfirm()
  const { user, apiCall, cart } = React.useContext(UserContext)
  const { coworkingSpace, setCoworkingSpace } = React.useContext(CoworkingSpaceContext)
  const [filterCWSpace, setFilterCWSpace] = React.useState(allCitiesFilterKey)

  const { data } = useSWR(`getCoworkingSpaces/`, () =>
    apiCall(apiActions.getCoworkingSpaces).then((r) => {
      if (r.Error) {
        //
      } else {
        return r.data
      }
    })
  )

  const [filteredData, setFilteredData] = React.useState(data)

  const changeCoworkingSpace = (id) => {
    apiCall(apiActions.getCoworkingSpace, {
      CoworkingSpaceId: id,
    }).then((r) => {
      setCoworkingSpace(r)
      history.push(getRoutePath('newreservation', { ':coworkingspace': id }))
    })
  }

  const handleCoworkingSpaceClick = (e, id) => {
    e.preventDefault()

    const coworkingSpaceId = coworkingSpace ? coworkingSpace.Id : null
    const cartItems = cart.get()
    const cartItemsNb = cartItems ? cartItems.length : 0

    if (coworkingSpaceId !== id && cartItemsNb > 0) {
      confirm({ description: t('confirm.change.coworking.space') })
        .then(() => {
          cart.reset()
          changeCoworkingSpace(id)
        })
        .catch(() => {})
    } else {
      changeCoworkingSpace(id)
    }
  }

  React.useEffect(() => {
    if (filterCWSpace === allCitiesFilterKey) {
      setFilteredData(data)
    } else {
      setFilteredData(data.filter((d) => d.CityName === filterCWSpace))
    }
  }, [filterCWSpace])

  React.useEffect(() => {
    if (data && !filteredData) {
      setFilteredData(data)
    }
  }, [data])

  if (!data)
    return (
      <Layout
        title={t('coworking.spaces')}
        pageTitle={t('coworkingspaces.page.title')}
        pageDescription={t('coworkingspaces.page.text')}
      >
        <LoadingElem />
      </Layout>
    )

  if (data.length === 1) {
    if (user) {
      history.push(getRoutePath('index'))
    } else {
      history.push(getRoutePath(`newreservation`, { ':coworkingspace': data[0].Id }))
    }
  }

  const allCities = data.map((d) => d.CityName).filter((value, index, self) => value && self.indexOf(value) === index)

  return (
    <Layout
      title={t('coworking.spaces')}
      pageTitle={t('coworkingspaces.page.title')}
      pageDescription={t('coworkingspaces.page.text')}
    >
      {allCities.length > 1 && (
        <>
          <Hidden mdUp>
            <CustomTextField
              select
              margin="dense"
              value={filterCWSpace}
              onChange={(e) => setFilterCWSpace(e.target.value)}
              className={classes.select}
            >
              <MenuItem value={allCitiesFilterKey}>Tous</MenuItem>
              {allCities.map((c) => (
                <MenuItem key={'city_' + c} value={c}>
                  {c}
                </MenuItem>
              ))}
            </CustomTextField>
          </Hidden>
          <Hidden smDown>
            <div className={classes.tabs}>
              <div>
                <CustomButton
                  color={filterCWSpace === allCitiesFilterKey ? 'secondary' : 'primary'}
                  onClick={() => setFilterCWSpace(allCitiesFilterKey)}
                >
                  Tous
                </CustomButton>
              </div>
              {allCities.map((c) => (
                <div key={'city_tabs_' + c}>
                  <CustomButton
                    color={filterCWSpace === c ? 'secondary' : 'primary'}
                    onClick={() => setFilterCWSpace(c)}
                  >
                    {c}
                  </CustomButton>
                </div>
              ))}
            </div>
          </Hidden>
        </>
      )}
      <CSSGrid colWidth={colWidth} spacing={1}>
        {/* {society.CoworkingSpace.map((item) => ()} */}
        {filteredData &&
          filteredData.map((item) => (
            <div key={item.Id}>
              <CoworkingSpace
                item={item}
                isDefault={user && user.CoworkingSpaceId === item.Id}
                onClick={handleCoworkingSpaceClick}
              />
            </div>
          ))}
      </CSSGrid>
    </Layout>
  )
}

export default CoworkingSpacesPage
