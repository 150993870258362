import { Box, Button, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import image from 'assets/images/cart_empty.svg'
import CustomButton from 'components/CustomComponents/CustomButton'
import HeroText from 'components/CustomComponents/HeroText'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import { formatCost } from 'components/ShowPrice'
import Layout from 'components/views/Layout'
import RenderCards, { SubLine } from 'components/views/UserCartPage/RenderCards'
import { CoworkingSpaceContext } from 'contexts/CoworkingSpaceContext'
import { MoneyContext } from 'contexts/MoneyContext'
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { getRoutePath } from 'routes'
import { apiActions } from '_actions/api_actions'

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  greyButton: {
    opacity: 0.5,
    whiteSpace: 'nowrap',
  },
  emptyCart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 200,
  },
  btnContainer: {
    '& .MuiButtonBase-root': {
      width: '100%',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
  },
  supTtc: {
    textTransform: 'uppercase',
    fontSize: '.6em',
  },
  smallEuroIcon: {
    fontSize: '.8em',
  },
  image: {
    maxHeight: '60vh',
    maxWidth: '100%',
  },
  validateBtn: {
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  paperRegister: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    border: '1px solid ' + theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  registerButton: {
    width: 250,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      marginTop: theme.spacing(1),
    },
  },
}))

export const EmptyCart = () => {
  const { t } = useTranslation()

  return (
    <Layout title={t('cart.empty.title')}>
      <HeroText
        title={t('cart.empty.title')}
        imageProps={{
          src: image,
        }}
        btnUrl={getRoutePath('index')}
        btnText={t('cart.back.home')}
      />
    </Layout>
  )
}

function UserCartPage() {
  const classes = useStyles()

  const history = useHistory()
  const { user, cart, apiCall } = React.useContext(UserContext)
  const { coworkingSpace } = React.useContext(CoworkingSpaceContext)
  const { type } = React.useContext(MoneyContext)
  const { society } = React.useContext(SocietyContext)
  const money = useContext(MoneyContext)
  const allCurrencies = money?.allCurrencies

  const { t } = useTranslation()
  const [items, setItems] = React.useState(null)
  const [itemDeleted, setItemDeleted] = React.useState(false)
  const [canValidate, setCanValidate] = React.useState(true)

  const location = useLocation()

  React.useEffect(() => {
    if (items) {
      let can = false
      items.ItemsCategory.map((category) => {
        category.Items.map((i) => {
          if (!i.DisabledItem) {
            can = true
          }
        })
      })
      setCanValidate(can)
    }
  }, [items, itemDeleted])

  React.useEffect(() => {
    apiCall(apiActions.getCart, {}, 'post', {
      orders: cart.get(),
    }).then((response) => {
      setItems(response)
    })
  }, [cart, itemDeleted])

  const handleDelete = (newCart) => {
    cart.set(newCart)
    setItemDeleted(!itemDeleted)
  }

  const handleDeleteAll = () => {
    cart.reset()
    setItemDeleted(!itemDeleted)
  }

  const handleContinue = () => {
    if (coworkingSpace) {
      history.push(getRoutePath('newreservation', { ':coworkingspace': coworkingSpace.Id }))
    } else {
      history.push(getRoutePath('coworkingspaces'))
    }
  }

  const goToErrorPage = () => {
    history.push(getRoutePath('payment-error'))
  }

  const handleValidate = () => {
    let currentCart = cart.get()
    let filteredCart = currentCart.filter((c) => c.ticket === true && user)
    if (currentCart.length === filteredCart.length) {
      setCanValidate(false)
      apiCall(apiActions.paymentPay, {}, 'post', { cart: currentCart, paymentType: 'ticket' })
        .then((r) => {
          const ret = r.RedirectUrl
          if (!ret) {
            goToErrorPage()
          } else {
            if (ret.startsWith('http')) {
              window.location = ret
            } else {
              history.push(ret)
            }
          }
          setCanValidate(true)
        })
        .catch((r) => {
          goToErrorPage()
        })
    } else if (items?.Total?.EuroHT === 0 && items?.TotalPayInCredits === 0 && items?.Total?.Tickets === 0 && user) {
      setCanValidate(false)
      apiCall(apiActions.paymentPay, {}, 'post', { cart: currentCart, paymentType: 'onsite' })
        .then((r) => {
          const ret = r.RedirectUrl
          if (!ret) {
            goToErrorPage()
          } else {
            if (ret.startsWith('http')) {
              window.location = ret
            } else {
              history.push(ret, { free: true })
            }
          }
          setCanValidate(true)
        })
        .catch((r) => {
          goToErrorPage()
        })
    } else {
      history.push(getRoutePath('payment'), items.Total)
    }
  }

  if (!items)
    return (
      <Layout pageTitle={t('cart.page.title')} pageDescription={t('cart.page.description')}>
        <LoadingElem />
      </Layout>
    )
  if (items.ItemsCategory.length === 0) return <EmptyCart />

  return (
    <Layout pageTitle={t('cart.page.title')} pageDescription={t('cart.page.description')}>
      {!user && (
        <Box mb={4}>
          <Paper className={classes.paperRegister}>
            <div>
              <Typography variant="subtitle1">
                <Box component="span" color="primary.darker">
                  {t('cart.register.text')}
                </Box>
              </Typography>
            </div>
            <div>
              <CustomButton
                onClick={() => history.push(getRoutePath('register') + '?r=' + location.pathname)}
                className={classes.registerButton}
                color="primary"
                variant="contained"
              >
                {t('cart.register.button')}
              </CustomButton>
            </div>
          </Paper>
        </Box>
      )}
      <Box textAlign="right" mt={-2} mb={0.5}>
        <Button className={classes.greyButton} onClick={handleDeleteAll} endIcon={<Icon icon="supprimer" />}>
          {t('cart.cancel.order')}
        </Button>
      </Box>
      <Box margin="auto">
        {items.ItemsCategory.map((category, index) => (
          <div key={index}>
            <RenderCards onDelete={handleDelete} category={category} />
          </div>
        ))}

        <Box mb={2}>
          <Divider className={classes.divider} />
        </Box>

        <Box mt={2} mb={2}>
          <Grid container justifyContent="flex-end">
            <Grid item xs sm="auto">
              {/* <Box mb={2}>
                <Divider className={classes.divider} />
              </Box> */}
              <div>
                {type === 'credits' ? (
                  <SubLine
                    left={<Typography variant="h5">{t('cart.total')}</Typography>}
                    right={
                      <Typography variant="h4" className={classes.priceContainer}>
                        <Box component="span" display="flex" alignItems="center">
                          <span>{formatCost(items.Total.Credits)}</span>
                          &nbsp;
                          <Icon
                            fontSize="inherit"
                            icon={allCurrencies.filter((c) => c.value === 'credits')[0].iconName}
                          />
                          &nbsp;
                        </Box>
                      </Typography>
                    }
                  />
                ) : (
                  <>
                    <SubLine
                      left={t('ht')}
                      right={
                        <>
                          <span>{formatCost(items.Total.EuroHT)}</span>
                          &nbsp;
                          <Icon className={classes.smallEuroIcon} fontSize="inherit" icon={money.iconMoney} />
                        </>
                      }
                    />
                    <SubLine
                      left={t('vat')}
                      right={
                        <>
                          <span>{society.VAT * 100}</span>&nbsp;%
                        </>
                      }
                    />
                    <SubLine
                      left={<Typography variant="h5">{t('cart.total')}</Typography>}
                      right={
                        <Typography variant="h4" className={classes.priceContainer}>
                          <span>{formatCost(items.Total.EuroTTC)}</span>
                          &nbsp;
                          <Icon className={classes.smallEuroIcon} fontSize="inherit" icon={money.iconMoney} />
                          &nbsp;
                          <sup className={classes.supTtc}>{t('ttc')}</sup>
                        </Typography>
                      }
                    />
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>

        <Grid className={classes.btnContainer} container alignItems="center" spacing={1} justifyContent="flex-end">
          <Grid item xs={12} sm="auto" className={classes.validateBtn}>
            <Button variant="contained" color="secondary" disabled={!canValidate} onClick={handleValidate}>
              {t('cart.validate')}
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button variant="outlined" color="secondary" onClick={handleContinue}>
              {t('cart.continue')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default UserCartPage
