import { Box } from '@material-ui/core'

function CenteredBox({ children, darker, ...rest }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" color={darker && 'primary.darker'} {...rest}>
      {children}
    </Box>
  )
}

export default CenteredBox
