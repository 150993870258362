import { Avatar, Badge, Box, Button, Fade, Grid, Menu, MenuItem, Paper, Switch } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import MuiLink from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import { alpha, darken } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import { AccountCircle } from '@material-ui/icons'
import CustomButton from 'components/CustomComponents/CustomButton'
import { MediaImage, useTransformUrlForMedia } from 'components/CustomComponents/MediaImage'
import SimpleBreadcrumbs, { getBreadcrumbsRoutes } from 'components/CustomComponents/SimpleBreadcrumbs'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import { formatCost, ToShowIcon } from 'components/ShowPrice'
import SquareButton from 'components/SquareButton'
import useCreateMenu from 'components/useCreateMenu'
import BackCustomer from 'components/views/BackCustomer'
import BackToV1 from 'components/views/BackToV1'
import UserDrawer from 'components/views/UserDrawer'
import { CoworkingSpaceContext } from 'contexts/CoworkingSpaceContext'
import { CustomThemeContext } from 'contexts/CustomThemeContext'
import { MoneyContext } from 'contexts/MoneyContext'
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { getRoutePath } from 'routes'

const drawerWidth = 250
const drawerWidthTablet = 100

const logoMaxWidth = 120
const logoMaxHeight = 60

const logoMaxWidthMobile = 80
const logoMaxHeightMobile = 40

const maxWidthForExternal = 1480
const paddingExternal = 40

const useStyles = makeStyles((theme) => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    layoutRoot: {
      display: 'flex',
      height: '100%',
      width: '100%',
      margin: 'auto',
      maxWidth: maxWidthForExternal,
      '&.with-drawer': {
        maxWidth: 'none',
        [theme.breakpoints.up('md')]: {
          paddingRight: theme.spacing(6),
        },
        // [theme.breakpoints.only('sm')]: {
        //   maxWidth: maxWidthForExternal + drawerWidthTablet,
        // },
        // [theme.breakpoints.up('md')]: {
        //   maxWidth: maxWidthForExternal + drawerWidth,
        // },
      },
    },
    pageTitle: {
      color: theme.palette.primary.darker,
    },
    btnAvatar: {
      padding: theme.spacing(0.6),
      minWidth: 0,
      '& .MuiButton-startIcon': {
        marginRight: 0,
        marginLeft: 0,
      },
      '& .MuiButton-endIcon': {
        marginLeft: theme.spacing(0.5),
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& .MuiButton-startIcon': {
          marginRight: theme.spacing(1),
          marginLeft: -theme.spacing(0.5),
        },
        '& .MuiButton-endIcon': {
          marginLeft: theme.spacing(1),
        },
      },
    },
    avatar: {
      border: `1px solid ${darken(theme.palette.background.paper, 0.075)}`,
      borderRadius: '50%',
      padding: 0,
      '& .MuiAvatar-root': {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
    },
    menu: {
      [theme.breakpoints.only('sm')]: {
        '& .MuiList-padding': {
          padding: 0,
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1.5rem',
        },
        '& .MuiButtonBase-root': {
          marginBottom: theme.spacing(1),
        },
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& > div': {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          '&:first-child': {
            marginTop: 0,
          },
          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
      '& .MuiListItem-root': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.lighter, 0.6),
        },
        '& .MuiTouchRipple-root': {
          color: theme.palette.primary.main,
        },
        '&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover': {
          backgroundColor: theme.palette.primary.lighter,
          '& .MuiListItemText-root, & .MuiListItemIcon-root': {
            color: theme.palette.primary.main,
          },
        },
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
        },
      },
    },
    menuCategory: {
      color: theme.palette.grey[500],
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidthTablet,
        flexShrink: 0,
      },
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      left: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: drawerWidthTablet,
        background: 'transparent',
        padding: theme.spacing(2),
        border: 'none',
        overflow: 'hidden',
      },
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
      },
    },
    drawerBg: {
      overflow: 'auto',
      height: '100%',
      '& .MuiListItem-root, & .MuiListItemText-root': {
        color: theme.palette.text.primary,
      },
      [theme.breakpoints.only('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiListItemIcon-root': {
          paddingRight: 0,
        },
        overflow: 'initial',
      },
    },
    main: {
      flexGrow: 1,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      '& > main': {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      },
    },
    content: {
      position: 'relative',
      height: '100%',
    },
    titleMobile: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
    appbar: {
      width: '100%',
      margin: 'auto',
      right: 'auto',
      backgroundColor: theme.palette.background.default,
      borderBottom: `none`,
      '& .MuiButton-root': {
        minHeight: theme.spacing(5.5),
      },
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      '& > .MuiBox-root': {
        '& > .MuiPaper-root': {
          transition: `all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
          width: '100%',
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          display: 'flex',
          [theme.breakpoints.down('xs')]: {
            borderRadius: 0,
          },
          [theme.breakpoints.up('sm')]: {
            padding: 0,
          },
        },
      },
      '&.hidden': {
        visibility: 'hidden',
        paddingLeft: 0,
      },
      '&.bgScroll': {
        '& > .MuiBox-root': {
          '& > .MuiPaper-root': {
            boxShadow: theme.shadows[2],
          },
        },
      },
      '&:not(.bgScroll)': {
        '& > .MuiBox-root': {
          '& > .MuiPaper-root': {
            background: 'transparent',
            borderColor: 'transparent',
            boxShadow: theme.shadows[0],
          },
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: 'calc(100% - ' + theme.spacing(4) + 'px)',
        paddingTop: theme.spacing(2),
        // paddingLeft:drawerWidthTablet,
        '&.noPadLeft': {
          paddingLeft: 0,
        },
        '&.bgScroll': {
          '& > .MuiBox-root': {
            '& > .MuiPaper-root': {
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              '& .MuiPaper-root, & .MuiInputBase-root > fieldset,  & .MuiButton-contained': {
                borderColor: 'transparent',
              },
            },
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        // paddingLeft: drawerWidth,
        // paddingRight: paddingExternal,
      },
    },
    listIcon: {
      minWidth: 'auto',
      paddingRight: theme.spacing(1),
      color: theme.palette.text.icon,
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    spot: {
      display: 'inline-block',
      background:
        theme.palette.type === 'dark'
          ? theme.palette.getContrastText(theme.palette.background.paper)
          : theme.palette.primary.main,
      opacity: 0.5,
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: '50%',
    },
    breadcrumbs: {
      '& .MuiBreadcrumbs-separator': {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
      },
      '& .MuiBreadcrumbs-separator, & .MuiTypography-root': {
        color:
          theme.palette.type === 'dark'
            ? theme.palette.getContrastText(theme.palette.background.paper)
            : theme.palette.primary.main,
        opacity: theme.palette.type === 'dark' ? 0.85 : 1,
      },
    },
    currencySelect: {
      background: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      margin: 0,
      '& fieldset': {
        top: 0,
      },
      '&, & > div': {
        height: '100%',
      },
    },
    badge: {
      '& > .MuiBadge-badge': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        border: '1px solid ' + theme.palette.primary.main,
        fontWeight: 'bolder',
        right: theme.spacing(0.5),
        bottom: theme.spacing(0.5),
      },
    },
    mobileNav: {
      position: 'fixed',
      zIndex: theme.zIndex.appBar + 1,
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      right: theme.spacing(1),
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      '& .selected': {
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.primary.main,
      },
      '&.ghost': {
        position: 'relative',
        visibility: 'hidden',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    toolbarContainer: {
      alignItems: 'stretch',
      '& > .MuiGrid-item > div': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
    },
    mainContent: {
      flex: 1,
      display: 'flex',
      // height: "100%",
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    userCredits: {
      '& + svg': {
        marginLeft: '0.2em',
      },
    },
    switch: {
      color: theme.palette.primary.main,
      '& :not(.Mui-checked)': {
        color: theme.palette.primary.main,
      },
      '& .Mui-checked .MuiSwitch-thumb': {
        color: theme.palette.primary.main,
      },
      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    withoutCredits: {
      '& .MuiButton-startIcon': {
        marginRight: -theme.spacing(1),
        marginLeft: -theme.spacing(1),
      },
    },
  }
})

function ElevationScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: 0,
    className: children.props.className + ' ' + (trigger ? 'bgScroll' : ''),
  })
}

function Layout({
  title,
  children,
  className,
  pageTitle,
  pageDescription,
  hideMenu,
  breadcrumbsInfos,
  titleComponent,
}) {
  const { t } = useTranslation()
  const history = useHistory()
  const match = useRouteMatch()
  const [showUserDrawer, setShowUserDrawer] = React.useState(false)
  const customTheme = React.useContext(CustomThemeContext)
  const { type, setType } = React.useContext(MoneyContext)
  const { user, cart } = React.useContext(UserContext)
  const { society } = React.useContext(SocietyContext)
  const { coworkingSpace } = React.useContext(CoworkingSpaceContext)
  const money = React.useContext(MoneyContext)
  const allCurrencies = money?.allCurrencies

  const hidemenu = hideMenu ?? false
  const showBackToV1 = coworkingSpace && coworkingSpace.BackToV1

  const baseMenu = useCreateMenu(hidemenu)
  const [menu, setMenu] = React.useState()

  const classes = useStyles()

  const changePage = (url, mock) => {
    if (mock) {
      window.location = url
    } else {
      history.push(url)
    }
  }

  const mockPage = (e, url) => {
    e.preventDefault()
    window.location = url
  }

  const handleClick = (e, cat) => {
    setMenu([cat])
  }

  const handleLogin = () => {
    history.push(getRoutePath('login') + '?r=' + history.location.pathname)
  }

  const handleSignup = () => {
    history.push(getRoutePath('register') + '?r=' + history.location.pathname)
  }

  const basePathName = history.location.pathname.split('/')[1]

  React.useEffect(() => {
    let newMenu = null
    baseMenu &&
      baseMenu.map((m) => {
        if (m.submenus.filter((s) => s.url?.split('/')[1] === basePathName)?.length > 0) {
          newMenu = baseMenu
          setMenu(baseMenu)
        } else {
          if (m.subcategories && m.subcategories.length > 0) {
            m.subcategories.map((s) => {
              if (s.submenus.filter((p) => p.url?.split('/')[1] === basePathName)?.length > 0) {
                newMenu = [s]
              }
            })
          }
        }
      })
    if (!newMenu) newMenu = baseMenu
    setMenu(newMenu)
  }, [])

  const DrawerContent = () => {
    return (
      <Paper elevation={2} className={classes.drawerBg}>
        <div>
          <Hidden smDown>
            <Box className={classes.toolbar} mb={2}>
              {customTheme.logo && (
                <Box pt={2} pb={2} pl={3} pr={3}>
                  <MediaImage
                    src={customTheme.logo}
                    mediaParams={{ xs: { width: drawerWidth, retina: true } }}
                    width="100%"
                  />
                </Box>
              )}
            </Box>
          </Hidden>
          {baseMenu && menu && JSON.stringify(baseMenu) !== JSON.stringify(menu) && (
            <Box mb={1}>
              <Button onClick={() => setMenu(baseMenu)}>
                <Icon icon="fleche-gauche" color="primary" />
                <Hidden smDown>
                  <Typography variant="caption" color="primary">
                    {t('menu.back')}
                  </Typography>
                </Hidden>
              </Button>
            </Box>
          )}
          <Box className={classes.menu}>
            {/* Lien vers Retour au bureau pour ITG */}
            <ListItem
              component={'a'}
              dense
              href="https://hub.itg.fr/bureau_consultant/home"
              button
              key={'menutext_' + t('menu.backhome.dashboard')}
            >
              <Hidden smDown>
                <ListItemText>{t('menu.backhome.dashboard')}</ListItemText>
              </Hidden>
            </ListItem>
            {menu &&
              menu.map((item) => (
                <Box key={'menukey_' + item.category}>
                  <Hidden smDown>
                    <Typography variant="overline" className={classes.menuCategory}>
                      {item.category}
                    </Typography>
                  </Hidden>
                  <List>
                    {item.subcategories?.length > 0 &&
                      item.subcategories.map((cat) => {
                        return (
                          <ListItem dense button onClick={(e) => handleClick(e, cat)} key={'menutext_' + cat.category}>
                            <ListItemIcon className={classes.listIcon}>{cat.icon}</ListItemIcon>
                            <Hidden smDown>
                              <ListItemText>{cat.category}</ListItemText>
                            </Hidden>
                          </ListItem>
                        )
                      })}
                    {item.submenus.map((menu) => {
                      const splitted = menu?.url?.split('/')
                      let selected = false
                      if (splitted && splitted.length > 1) selected = basePathName === menu.url.split('/')[1]
                      return (
                        <ListItem
                          component={menu.mock ? 'a' : Link}
                          dense
                          href={menu.url}
                          to={menu.url}
                          selected={selected}
                          button
                          onClick={(e) => changePage(menu.url, menu.mock)}
                          key={'menutext_' + menu.text}
                        >
                          <ListItemIcon className={classes.listIcon}>{menu.icon}</ListItemIcon>
                          <Hidden smDown>
                            <ListItemText>{menu.text}</ListItemText>
                          </Hidden>
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>
              ))}
          </Box>
        </div>
      </Paper>
    )
  }

  const breadRoutes = getBreadcrumbsRoutes(
    match,
    t,
    [],
    { ...breadcrumbsInfos, coworkingSpaceName: coworkingSpace && coworkingSpace.Name },
    society
  )
  const isFirstPage = breadRoutes.length === 1
  const beforeLastRoute = breadRoutes[breadRoutes.length - 1]
  const showBackBtn = !isFirstPage && beforeLastRoute && beforeLastRoute.component === 'link'

  const userName = user ? user.FirstName + ' ' + user.LastName : ''
  const cartItems = cart.get()
  const cartItemsNb = cartItems ? cartItems.length : 0

  const userImageURL = useTransformUrlForMedia(user ? user.ImageURL : null, {
    xs: {
      width: 32,
      height: 32,
      retina: true,
    },
  })

  const ToolbarComp = () => {
    return (
      <Box>
        <Paper elevation={0}>
          <Grid container className={classes.toolbarContainer} spacing={1}>
            {hidemenu && customTheme.logo && (
              <Hidden xsDown>
                <Grid item xs="auto">
                  <div>
                    <MuiLink component={Link} to={getRoutePath('index')}>
                      <MediaImage
                        alt="logo"
                        style={{ maxWidth: logoMaxWidth, maxHeight: logoMaxHeight }}
                        src={customTheme.logo}
                        mediaParams={{ xs: { width: logoMaxWidth, height: logoMaxHeight, mode: 'max', retina: true } }}
                      />
                    </MuiLink>
                  </div>
                </Grid>
              </Hidden>
            )}

            {/* Fil d'arianne */}
            <SimpleBreadcrumbs
              forLayout
              infos={{ ...breadcrumbsInfos, coworkingSpaceName: coworkingSpace && coworkingSpace.Name }}
            />

            {/* Logo mobile */}
            <Hidden smUp>
              {customTheme.favicon ? (
                <Grid item xs>
                  <Box justifyContent={showBackBtn ? 'center' : 'flex-start'}>
                    <MuiLink component={Link} to={getRoutePath('index')}>
                      <MediaImage
                        alt="logo"
                        style={{ maxWidth: logoMaxWidthMobile, maxHeight: logoMaxHeightMobile }}
                        src={customTheme.favicon}
                        mediaParams={{
                          xs: { width: logoMaxWidthMobile, height: logoMaxHeightMobile, mode: 'max', retina: true },
                        }}
                      />
                    </MuiLink>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs></Grid>
              )}
            </Hidden>
            <Hidden xsDown>
              <Grid item xs></Grid>
            </Hidden>

            {/* Select monnaie à utiliser */}
            {/* {user && user.CanHaveCredits && society.Payments.Credits && (
              <Hidden xsDown>
                <Grid item xs="auto">
                  <div>
                    <CustomTextField
                      select
                      margin="dense"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      className={classes.currencySelect}
                    >
                      {allCurrencies &&
                        allCurrencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {t(option.label)}
                          </MenuItem>
                        ))}
                    </CustomTextField>
                  </div>
                </Grid>
              </Hidden>
            )} */}

            {/* Switch monnaie à utiliser */}
            {/* {(!user || (user && (!user.CanHaveCredits || !society.Payments.Credits))) && (
              <Hidden xsDown>
                <Grid item xs="auto">
                  <div>
                    <Grid container alignItems="center">
                      <Grid item xs="auto">
                        <Box mr={1}>{t('header.htttc.text')} :</Box>
                      </Grid>
                      <Grid item xs>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            {t('ht')}
                          </Grid>
                          <Grid item xs>
                            <Switch
                              className={classes.switch}
                              color="primary"
                              size="small"
                              checked={type === 'ttc'}
                              onChange={(e) => setType(type === 'ht' ? 'ttc' : 'ht')}
                            />
                          </Grid>
                          <Grid item xs>
                            {t('ttc')}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Hidden>
            )} */}

            {/* Panier */}
            {/* <Grid item xs="auto">
              <div>
                <Box className={classes.cartBtn}>
                  <Badge
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    className={classes.badge}
                    badgeContent={cartItemsNb}
                    color="secondary"
                  >
                    <CustomButton
                      component={Link}
                      to={getRoutePath('cart')}
                      square={true}
                      variant="contained"
                      onClick={() => changePage(getRoutePath('cart'))}
                      color={cartItemsNb > 0 ? 'primary' : 'default'}
                    >
                      <Icon icon="panier" />
                    </CustomButton>
                  </Badge>
                </Box>
              </div>
            </Grid> */}

            {/* Bouton Avatar de l'utilisateur connecté  */}
            {user && (
              <Grid item xs="auto">
                <div>
                  <CustomButton
                    variant="contained"
                    className={
                      classes.btnAvatar +
                      ' ' +
                      (!user.CanHaveCredits || !society.Payments.Credits ? classes.withoutCredits : '')
                    }
                    onClick={() => setShowUserDrawer(true)}
                    startIcon={
                      <div className={classes.avatar}>
                        <Avatar alt={userName} src={userImageURL}>
                          {userName[0]}
                        </Avatar>
                      </div>
                    }
                    // endIcon={(
                    //   <Hidden xsDown><Icon icon="fleche-bas"/></Hidden>
                    // )}
                  >
                    {user.CanHaveCredits && society.Payments.Credits && (
                      <Hidden xsDown>
                        <Box component="span" display="flex" alignItems="center">
                          <span>{formatCost(user.Credits)}</span>
                          &nbsp;
                          <Icon
                            fontSize="inherit"
                            icon={allCurrencies && allCurrencies.filter((c) => c.value === 'credits')[0].iconName}
                          />
                          &nbsp;
                        </Box>
                      </Hidden>
                    )}
                  </CustomButton>
                </div>
              </Grid>
            )}

            {/* Boutons "Se connecter" et "S'inscrire" */}
            {!user && (
              <Hidden xsDown>
                <Grid item xs="auto">
                  <div>
                    <Button onClick={handleLogin} color="secondary" variant="contained">
                      {t('header.btn.login')}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs="auto">
                  <div>
                    <Button onClick={handleSignup} color="secondary" variant="outlined">
                      {t('header.btn.signup')}
                    </Button>
                  </div>
                </Grid>
              </Hidden>
            )}

            {/* Bouton "Se connecter" lorsque deconnecté */}
            {!user && (
              <Hidden smUp>
                <Grid item xs="auto">
                  <div>
                    <CustomButton square onClick={handleLogin}>
                      <AccountCircle />
                    </CustomButton>
                  </div>
                </Grid>
              </Hidden>
            )}
          </Grid>
        </Paper>
      </Box>
    )
  }

  const MobileNav = ({ ghost = false }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [selectedMenu, setSelectedMenu] = React.useState(null)

    const compClasses = [classes.mobileNav]

    const handleClick = (event, cat, changeAnchor = true) => {
      if (changeAnchor) setAnchorEl(event.currentTarget)
      setSelectedMenu([cat])
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    if (ghost) {
      compClasses.push('ghost')
    }

    return (
      <Paper elevation={2} className={compClasses} component="nav">
        <SquareButton
          className={basePathName === 'index' ? 'selected' : ''}
          noBorder
          onClick={() => changePage(getRoutePath('publications'))}
        >
          <Icon icon="home" />
        </SquareButton>
        {baseMenu.map((cat) => {
          if (cat.category !== '')
            return (
              <>
                <SquareButton noBorder key={'mobilenav_' + cat.category} onClick={(e) => handleClick(e, cat)}>
                  {cat.icon}
                </SquareButton>
              </>
            )
          else
            return (
              cat.submenus &&
              cat.submenus
                .filter((s) => s.name !== 'index')
                ?.map((s, index) => {
                  return (
                    <SquareButton noBorder key={'mobilenav_' + cat.category} onClick={() => changePage(s.url, s.mock)}>
                      {s.icon}
                    </SquareButton>
                  )
                })
            )
        })}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {selectedMenu &&
            selectedMenu.map((m) => (
              <>
                {m.subcategories?.length > 0 &&
                  m.subcategories.map((c) => {
                    return (
                      <MenuItem onClick={(e) => handleClick(e, c, false)}>
                        <Box display="flex" alignItems="center">
                          <Box display="flex" alignItems="center" mr={1}>
                            {c.icon}
                          </Box>
                          <div>{c.category}</div>
                        </Box>
                      </MenuItem>
                    )
                  })}
                {m.submenus.map((s) => {
                  const splitted = s?.url?.split('/')
                  let selected = false
                  if (splitted && splitted.length > 1) selected = basePathName === s.url.split('/')[1]
                  return (
                    <MenuItem className={selected ? 'selected' : ''} onClick={() => changePage(s.url, s.mock)}>
                      <Box display="flex" alignItems="center">
                        <Box display="flex" alignItems="center" mr={1}>
                          {s.icon}
                        </Box>
                        <div>{s.text}</div>
                      </Box>
                    </MenuItem>
                  )
                })}
              </>
            ))}
        </Menu>
      </Paper>
    )
  }

  return (
    <Box className={classes.layoutRoot + ' with-drawer ' + (className ? className : '')}>
      <Helmet
        defaultTitle={`${society.Name} ${coworkingSpace ? '- ' + coworkingSpace.Name : ''}`}
        titleTemplate={`${society.Name} ${coworkingSpace ? '- ' + coworkingSpace.Name : ''} - %s`}
      >
        <title>{title}</title>
      </Helmet>
      {!hidemenu && (
        <nav className={classes.drawer}>
          <Hidden xsDown>
            <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
              <DrawerContent />
            </Drawer>
          </Hidden>
        </nav>
      )}
      <div id="main-container" className={classes.main}>
        <main>
          <ElevationScroll>
            <AppBar position="sticky" className={classes.appbar + ' mui-fixed ' + (!hidemenu ? '' : ' noPadLeft')}>
              <ToolbarComp />
            </AppBar>
          </ElevationScroll>

          <Fade in={true}>
            <div className={classes.mainContent}>
              {/* {showBackToV1 && (
                <Hidden smDown>
                  <BackCustomer />
                  <BackToV1 />
                </Hidden>
              )} */}
              {(pageTitle || pageDescription) && (
                <Box mb={3}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs>
                      {pageTitle && (
                        <Typography className={classes.pageTitle} variant="h1">
                          {pageTitle}
                        </Typography>
                      )}
                      {pageDescription && (
                        <Typography variant="subtitle1" color="textSecondary">
                          <Box component="span" display="flex" alignItems="center">
                            {pageDescription}
                          </Box>
                        </Typography>
                      )}
                    </Grid>
                    {titleComponent && (
                      <Box display="flex" alignItems="center">
                        {titleComponent}
                      </Box>
                    )}
                  </Grid>
                </Box>
              )}
              <Box className={classes.content}>{children}</Box>
            </div>
          </Fade>
        </main>

        {user && <UserDrawer open={showUserDrawer} onClose={() => setShowUserDrawer(false)} user={user} />}

        {!hidemenu && (
          <Hidden smUp>
            <MobileNav />
            <MobileNav ghost />
          </Hidden>
        )}
      </div>
    </Box>
  )
}

export default Layout
