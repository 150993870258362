import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@material-ui/core'
import CustomButton from 'components/CustomButton'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import RenderField from 'components/views/PaymentPage/RenderField'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function CreateGroup({ handleClose }) {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      title: null,
      description: null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
        description: Yup.string().nullable(),
      })
    ),
  })

  const onFormSubmit = (values) => {
    console.log('values', values)
  }

  return (
    <CustomDialog maxWidth="lg" fullWidth open handleClose={handleClose} title={t('publications.create.group.title')}>
      <form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <Box mb={2}>
          <RenderField methods={methods} name="title" label={t('publications.create.group.title.label') + '*'} />
        </Box>
        <Box mb={2}>
          <RenderField methods={methods} name="description" label={t('publications.create.group.description.label')} />
        </Box>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={3}>
            <CustomButton fullWidth variant="outlined" color="secondary" onClick={handleClose}>
              <Box color="primary.darker">{t('publications.create.group.cancel')}</Box>
            </CustomButton>
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomButton fullWidth variant="contained" color="secondary" type="submit">
              {t('publications.create.group.confirm')}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  )
}

export default CreateGroup
