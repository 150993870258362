import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import React from 'react'

const MoneyContext = React.createContext()
const fromLocalStorage = window.localStorage.getItem('showpricetype')

function MoneyContextProvider({ children }) {
  const { user } = React.useContext(UserContext)
  const { society } = React.useContext(SocietyContext)

  const defaultValue = user == null ? 'ht' : 'credits'
  const [iconMoney, setIconMoney] = React.useState(society?.IconMoney)
  const [type, setType] = React.useState(fromLocalStorage ?? defaultValue)
  const [allCurrencies, setAllCurrencies] = React.useState()

  React.useEffect(() => {
    window.localStorage.setItem('showpricetype', type)
  }, [type])

  React.useEffect(() => {
    society && setIconMoney(society?.IconMoney)
    society &&
      setAllCurrencies([
        {
          value: 'ttc',
          label: `currency.euro.ttc`,
          iconName: society.IconMoney,
        },
        {
          value: 'ht',
          label: `currency.euro.ht`,
          iconName: society.IconMoney,
        },
        {
          value: 'credits',
          label: 'currency.credits',
          iconName: 'credits',
        },
      ])
  }, [society])

  return (
    <MoneyContext.Provider value={{ type: type, setType: setType, iconMoney: iconMoney, allCurrencies: allCurrencies }}>
      {children}
    </MoneyContext.Provider>
  )
}

export { MoneyContextProvider, MoneyContext }
