import App from 'components/App'
import { basename } from 'config'
import { CoworkingSpaceProvider } from 'contexts/CoworkingSpaceContext'
import { CustomThemeProvider } from 'contexts/CustomThemeContext'
import { ErrorContextProvider } from 'contexts/ErrorContext'
import { LoadingProvider } from 'contexts/LoadingContext'
import { MoneyContextProvider } from 'contexts/MoneyContext'
import { SocietyProvider } from 'contexts/SocietyContext'
import { UserContextProvider } from 'contexts/UserContext'
import 'core-js'
import 'helper/log'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/ie9'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorker from 'serviceWorker'

const RedirectToHTTPS = ({ children }) => {
  const location = window.location
  const isHttps = location.protocol === 'https:'
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

  if (isDev || isHttps) return children

  location.replace(`https:${location.href.substring(location.protocol.length)}`)
  return null
}

ReactDOM.render(
  <RedirectToHTTPS>
    <Router>
      <CustomThemeProvider>
        <SocietyProvider>
          <ErrorContextProvider>
            <UserContextProvider>
              <CoworkingSpaceProvider>
                <MoneyContextProvider>
                  <LoadingProvider>
                    <App />
                  </LoadingProvider>
                </MoneyContextProvider>
              </CoworkingSpaceProvider>
            </UserContextProvider>
          </ErrorContextProvider>
        </SocietyProvider>
      </CustomThemeProvider>
    </Router>
  </RedirectToHTTPS>,
  document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  scope: basename + '/',
})
