import { alpha, Box, Button, Grid, Hidden, makeStyles, MenuItem, Select } from '@material-ui/core'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import LoadingElem from 'components/LoadingElem'
import EndActivity from 'components/views/CRMPages/ProspectPage/EndActivity'
import Layout from 'components/views/Layout'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { apiActions } from '_actions/api_actions'
import ProspectActivities from './ProspectActivities'
import ProspectInfo from './ProspectInfo'

const useStyles = makeStyles((theme) => ({
  lost: {
    borderColor: theme.palette.error.main,
    width: 120,
    height: 50,
    '& .MuiButton-label': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.light, 0.2),
      borderColor: theme.palette.error.main,
    },
  },
  won: {
    borderColor: theme.palette.success.main,
    width: 120,
    height: 50,
    '& .MuiButton-label': {
      color: theme.palette.success.main,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.light, 0.2),
      borderColor: theme.palette.success.main,
    },
  },
  buttonOwner: {
    padding: 'inherit',
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    '&:before': {
      borderBottom: 'inherit',
    },
    '&:after': {
      borderBottom: 'inherit',
    },
    boxShadow: 'none',
  },
}))

function ProspectPage({ prospectId }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [isWon, setIsWon] = React.useState(false)
  const [infosMobileOpen, setInfosMobileOpen] = React.useState(false)
  const { apiCall } = useContext(UserContext)

  const { data: prospect, mutate } = useSWR(`prospects-detail/${prospectId}`, () =>
    apiCall(apiActions.getProspectById, { ProspectId: prospectId }, 'get').then((r) => {
      return r
    })
  )

  const { data: adminLists } = useSWR(`prospects-detail/getAdminLists`, () =>
    apiCall(apiActions.getAdminLists, 'get').then((r) => {
      return r.data
    })
  )

  const handleChangeOwner = (e) => {
    apiCall(apiActions.editProspectOwner, { ProspectId: prospectId, NewOwnerId: e.target.value }, 'put').then((r) => {
      mutate()
    })
  }

  if (!prospect) return <LoadingElem />

  const pageDescription =
    t('crm.prospect.created.date', { date: moment(prospect.CreatedDate).format('DD/MM/YY') }) +
    ' · ' +
    t('crm.prospect.responsible')

  return (
    <Layout
      pageTitle={prospect.FirstName + ' ' + prospect.LastName}
      pageDescription={
        <>
          {pageDescription}
          <Select
            disableUnderline
            defaultValue={prospect?.Owner?.Id}
            onChange={handleChangeOwner}
            className={classes.buttonOwner}
          >
            {adminLists &&
              adminLists.length > 0 &&
              Object.entries(adminLists).map(([key, value]) => (
                <MenuItem key={key} value={value.Id}>
                  {value.Name}
                </MenuItem>
              ))}
          </Select>
        </>
      }
      breadcrumbsInfos={{ prospectName: prospect.FirstName + ' ' + prospect.LastName }}
      titleComponent={
        prospect.Status === 200 ? (
          <>
            <Box mr={1}>
              <Button
                onClick={() => {
                  setDialogOpen(true)
                  setIsWon(false)
                }}
                fullWidth
                color="primary"
                className={classes.lost}
                variant="outlined"
              >
                {t('crm.prospect.lost')}
              </Button>
            </Box>
            <div>
              <Button
                onClick={() => {
                  setDialogOpen(true)
                  setIsWon(true)
                }}
                fullWidth
                color="primary"
                className={classes.won}
                variant="outlined"
              >
                {t('crm.prospect.won')}
              </Button>
            </div>
          </>
        ) : (
          <></>
        )
      }
    >
      <Hidden smDown>
        <Grid container spacing={2}>
          <Grid item md={4} lg={3}>
            <ProspectInfo refreshData={() => mutate()} prospect={prospect} />
          </Grid>
          <Grid item md={8} lg={9}>
            <ProspectActivities refreshData={() => mutate()} prospect={prospect} />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Box mb={2}>
          <Button variant="outlined" color="secondary" onClick={() => setInfosMobileOpen(true)}>
            {t('crm.prospect.infos')}
          </Button>
          <CustomDialog
            open={infosMobileOpen}
            maxWidth="lg"
            fullWidth
            handleClose={() => setInfosMobileOpen(false)}
            title={t('crm.prospect.infos')}
          >
            <ProspectInfo refreshData={() => mutate()} prospect={prospect} />
          </CustomDialog>
        </Box>
        <div>
          <ProspectActivities refreshData={() => mutate()} prospect={prospect} />
        </div>
      </Hidden>
      <CustomDialog
        open={dialogOpen}
        maxWidth="lg"
        fullWidth
        title={isWon ? t('crm.prospect.won.title') : t('crm.prospect.lost.title')}
        handleClose={() => setDialogOpen(false)}
      >
        <EndActivity
          status={isWon ? '210' : '220'}
          refreshData={() => mutate()}
          isWon={isWon}
          prospect={prospect}
          handleClose={() => setDialogOpen(false)}
        />
      </CustomDialog>
    </Layout>
  )
}

export default ProspectPage
