import { Mutex } from 'async-mutex'
import axios from 'axios'
import 'helper/string'
import { userActions } from '_actions/api_actions'

export function withAuth(opt) {
  var toret = opt
  if (toret) {
    toret.withCredentials = true
    toret.headers = { ...opt.headers }
  } else {
    toret = {
      withCredentials: true,
    }
  }

  return toret
}

const mutex = new Mutex()
export function authUser() {
  return mutex.runExclusive(() => {
    return axios.post(userActions.auth, null, withAuth()).then((response) => {
      storeAuth(response.data)
      return response.data
    })
  })
}

export function newToken() {
  return axios.post(userActions.newToken, null, withAuth()).then((response) => {
    storeAuth(response.data)
    return response.data
  })
}

export function registerUser(dataToSubmit) {
  return axios.post(userActions.register, dataToSubmit, { withCredentials: true }).then((response) => {
    return response.data
  })
}

export function loginUser(dataToSubmit) {
  return axios.post(userActions.login, dataToSubmit, { withCredentials: true }).then((response) => {
    storeAuth(response.data)
    return response.data
  })
}

export function logoutUser() {
  return postWithAuth(userActions.logout).then((response) => {
    storeAuth(null)

    return response.data
  })
}

export const tempAuth = {
  auth: null,
  timeout: null,
}

export const storeAuth = (toStore) => {
  tempAuth.auth = toStore
  //tempAuth.timeout = moment().add(30, 's').toDate()
}

const mutex2 = new Mutex()
export function auth() {
  return mutex2.runExclusive(() => {
    return new Promise((resolve) => {
      if (tempAuth && tempAuth.auth) {
        resolve(tempAuth.auth)
      } else {
        authUser().then((auth) => {
          if (!auth.needANewToken) {
            resolve(auth)
          } else {
            newToken().then((resp) => {
              if (!resp.error) {
                resolve(resp)
              } else {
                throw resp
              }
            })
          }
        })
      }
    })
  })
}

export function postWithAuth(url, data, opt) {
  return new Promise((resolve, reject) => {
    auth()
      .then((r) => axios.post(url, data, withAuth(opt)).then((r) => (r.status === 200 ? resolve(r) : reject(r))))
      .catch((r) => reject(r))
  })
}

export async function getWithAuth(url, opt) {
  return new Promise((resolve, reject) => {
    auth()
      .then((r) => axios.get(url, withAuth(opt)).then((r) => (r.status === 200 ? resolve(r) : reject(r))))
      .catch((r) => reject(r))
  })
}

export async function deleteWithAuth(url, opt) {
  return new Promise((resolve, reject) => {
    auth()
      .then((r) => axios.delete(url, withAuth(opt)).then((r) => (r.status === 200 ? resolve(r) : reject(r))))
      .catch((r) => reject(r))
  })
}

export async function putWithAuth(url, data, opt) {
  return new Promise((resolve, reject) => {
    auth()
      .then((r) => axios.put(url, data, withAuth(opt)).then((r) => (r.status === 200 ? resolve(r) : reject(r))))
      .catch((r) => reject(r))
  })
}
