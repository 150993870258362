import { lighten } from '@material-ui/core'

export const primary = '#253FE9'
export const secondary = '#F7BB17'
export const error = '#EB5E55'
export const success = '#63CC8A'
export const type = 'light'

export const fontFamily = {
  title: '"Montserrat", "Arial", serif',
  text: '"Lato", sans-serif',
}

export const htmlFontSize = 16
export const fontSize = 14
const remCalc = (px) => {
  return px / htmlFontSize
}

// Create a theme instance.
const theme = {
  palette: {
    type: type,
    primary: { main: primary },
    secondary: { main: secondary },
    tonalOffset: 0.2,
    error: { main: error },
    success: { main: success },
    background: {
      default: lighten(primary, 0.98),
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    htmlFontSize: htmlFontSize,
    fontSize: fontSize,
    fontFamily: fontFamily.text,
    h1: {
      fontFamily: fontFamily.title,
      fontWeight: 500,
    },
    h2: {
      fontFamily: fontFamily.title,
      fontWeight: 700,
    },
    h3: {
      fontFamily: fontFamily.title,
      fontWeight: 600,
    },
    h4: {
      fontFamily: fontFamily.title,
      fontWeight: 700,
    },
    h5: {
      fontFamily: fontFamily.title,
      fontWeight: 500,
    },
    h6: {
      fontFamily: fontFamily.title,
      fontWeight: 500,
    },
    button: {
      fontFamily: fontFamily.title,
      fontWeight: 600,
      fontSize: `${remCalc(fontSize)}rem`,
      letterSpacing: -0.5,
      textTransform: 'none',
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: `${remCalc(fontSize)}rem`,
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: `${remCalc(12)}rem`,
    },
    overline: {
      fontWeight: 700,
      fontSize: `${remCalc(fontSize)}rem`,
      lineHeight: 1.4,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiTypography: {
      variantMapping: {
        h1: 'p',
        h2: 'p',
        h3: 'p',
        h4: 'p',
        h5: 'p',
        h6: 'p',
        subtitle1: 'p',
        subtitle2: 'p',
        body1: 'span',
        body2: 'span',
      },
    },
  },
  overrides: {
    MuiButton: {
      sizeLarge: {
        padding: '16px 22px',
      },
      outlined: {
        '&, &:hover': {
          //borderWidth:2,
        },
      },
      outlinedPrimary: {
        '&, &:hover': {
          //borderWidth:2,
        },
      },
      outlinedSecondary: {
        '&, &:hover': {
          //borderWidth:2,
        },
      },
    },
    MuiPaper: {
      outlined: {
        //borderColor:"transparent"
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: 280,
      },
    },
    MuiPagination: {
      ul: {
        justifyContent: 'center',
      },
    },
  },
}

export default theme
