import { InputLabel } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => {
  return {
    label: {
      color: alpha(theme.palette.primary.darker, 0.7),
      fontWeight: 600,
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
      '&.Mui-focused': {
        color: alpha(theme.palette.primary.darker, 0.85),
      },
    },
  }
})

export { useStyles }

function CustomLabel(props) {
  const classes = useStyles()

  return <InputLabel {...props} className={classes.label + ' ' + (props.className ?? '')} />
}

export default CustomLabel
