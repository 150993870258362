import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Title from 'area/social/components/Title'
import CustomChoiceButtons from 'components/CustomComponents/CustomChoiceButton'
import CustomForm from 'components/CustomComponents/CustomForm'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import CustomSnackBar from 'components/CustomComponents/CustomSnackBar'
import CustomLabel from 'components/CustomLabel'
import CustomTextField from 'components/CustomTextField'
import Icon from 'components/IcoMoon/Icon'
import RenderField from 'components/views/PaymentPage/RenderField'
import { DEFAULT_IMG } from 'config'
import { CoworkingSpaceContext } from 'contexts/CoworkingSpaceContext'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { apiActions } from '_actions/api_actions'

const imgHeight = 200
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  img: {
    borderRadius: theme.shape.borderRadius,
    height: imgHeight,
    width: '100%',
    marginTop: theme.spacing(2),
  },
  favIcon: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}))

const GlobalInfos = ({ defaultValues }) => {
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const [message, setMessage] = React.useState(null)

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        Civility: Yup.string().nullable(),
        Sexe: Yup.string().nullable(),
        FirstName: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        LastName: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
        BirthDate: Yup.date().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
      })
    ),
    shouldFocusError: false,
  })

  const onFormSubmit = (values) => {
    apiCall(apiActions.editUserGlobalInfos, {}, 'post', {
      Civility: values.Civility,
      Sexe: values.Sexe,
      FirstName: values.FirstName,
      LastName: values.LastName,
      BirthDate: moment(values.BirthDate).format(),
    }).then((r) => {
      methods.reset(values)
      setMessage(t('common.saved'))
    })
  }

  return (
    <CustomForm
      title={t('my-account.infos.global.title')}
      methods={methods}
      validationButtonText={t('common.form.validate.change')}
      onFormSubmit={onFormSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            control={methods.control}
            name="Civility"
            render={({ field }) => {
              return (
                <CustomChoiceButtons
                  value={field.value ?? 'Mme'}
                  label={t('prospect.transform.account.form.civility.title') + '*'}
                  color="primary"
                  variant="outlined"
                  buttonNames={['Mme', 'Mr']}
                  buttonLabels={[
                    t('prospect.transform.account.form.civility.miss'),
                    t('prospect.transform.account.form.civility.mister'),
                  ]}
                  onClickButton={(name) => methods.setValue(field.name, name[0], { shouldDirty: true })}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={methods.control}
            name="Sexe"
            render={({ field }) => {
              return (
                <CustomChoiceButtons
                  value={field.value ?? 'F'}
                  label={t('prospect.transform.account.form.sex.title') + '*'}
                  color="primary"
                  variant="outlined"
                  buttonNames={['F', 'M']}
                  buttonLabels={[
                    t('prospect.transform.account.form.sex.female'),
                    t('prospect.transform.account.form.sex.male'),
                  ]}
                  onClickButton={(name) => methods.setValue(field.name, name[0], { shouldDirty: true })}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RenderField
            methods={methods}
            name="FirstName"
            disabled
            fullWidth
            label={t('prospect.transform.account.form.firstname.title') + '*'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RenderField
            methods={methods}
            disabled
            name="LastName"
            fullWidth
            label={t('prospect.transform.account.form.lastname.title') + '*'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomLabel>{t('prospect.transform.account.form.birthdate.title') + '*'}</CustomLabel>
          <Controller
            control={methods.control}
            name="BirthDate"
            render={({ field }) => {
              return (
                <KeyboardDatePicker
                  value={field.value ?? null}
                  variant="inline"
                  autoOk
                  inputVariant="outlined"
                  views={['year', 'month', 'date']}
                  openTo="year"
                  fullWidth
                  color="primary"
                  format="DD/MM/YYYY"
                  onChange={(value) => {
                    methods.setValue(field.name, value, { shouldDirty: true })
                  }}
                  helperText={methods.formState?.errors?.Birthdate?.message ?? ''}
                />
              )
            }}
          />
        </Grid>
      </Grid>
      <CustomSnackBar
        open={message !== null}
        message={message}
        autoHideDuration={2000}
        onClose={() => setMessage(null)}
      />
    </CustomForm>
  )
}

const PersonalInfos = ({ defaultValues }) => {
  const { t } = useTranslation()
  const { apiCall } = useContext(UserContext)

  const [message, setMessage] = React.useState(null)

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        Email: Yup.string()
          .email(t('register.form.email.error'))
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        MobilePhone: Yup.string()
          .required(t('register.form.required.error'))
          .typeError(t('register.form.required.error')),
        Address: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
        City: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
        Zipcode: Yup.string().required(t('register.form.required.error')).typeError(t('register.form.required.error')),
      })
    ),
    shouldFocusError: false,
  })

  const onFormSubmit = (values) => {
    apiCall(apiActions.editUserProfile, {}, 'post', {
      Email: values.Email,
      MobilePhone: values.MobilePhone,
      Address: values.Address,
      City: values.City,
      Zipcode: values.Zipcode,
    }).then((r) => {
      methods.reset(values)
      setMessage(t('common.saved'))
    })
  }

  return (
    <CustomForm
      title={t('my-account.infos.personal.title')}
      methods={methods}
      validationButtonText={t('common.form.validate.change')}
      onFormSubmit={onFormSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <RenderField
            methods={methods}
            name="Email"
            fullWidth
            label={t('prospect.transform.account.form.mail.title') + '*'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RenderField
            methods={methods}
            name="MobilePhone"
            label={t('prospect.transform.account.form.phone.title') + '*'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RenderField
                methods={methods}
                name="Address"
                label={t('prospect.transform.account.form.address.title') + '*'}
                placeholder={t('prospect.transform.account.form.address.placeholder')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderField
                methods={methods}
                name="Zipcode"
                placeholder={t('prospect.transform.account.form.zipcode.placeholder')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderField
                methods={methods}
                name="City"
                placeholder={t('prospect.transform.account.form.city.placeholder')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomSnackBar
        open={message !== null}
        message={message}
        autoHideDuration={2000}
        onClose={() => setMessage(null)}
      />
    </CustomForm>
  )
}

const CWSpaceInfos = ({ defaultValues }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { coworkingSpace } = useContext(CoworkingSpaceContext)

  return (
    <CustomPaper className={classes.container}>
      <Box px={2}>
        <Title title={t('my-account.infos.cwspace.title')} />
        <img src={coworkingSpace?.ImageURL ?? DEFAULT_IMG} className={classes.img} />
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h3">{coworkingSpace?.Name}</Typography>
          <Icon icon="favori" className={classes.favIcon} />
        </Box>
        <Box mb={2}>
          <CustomTextField
            disabled
            fullWidth
            value={defaultValues?.SwipeCardId}
            name="SwipeCardId"
            label={t('my-account.infos.cwspace.swipecardid.label')}
          />
        </Box>
        <Box mb={2}>
          <CustomTextField
            disabled
            fullWidth
            value={defaultValues?.PrintCode}
            name="PrintCode"
            label={t('my-account.infos.cwspace.printcode.label')}
          />
        </Box>
        {defaultValues?.QRCode && (
          <>
            <CustomLabel>{t('my-account.infos.cwspace.qrcode.label')}</CustomLabel>
            <img src={`data:image/jpg;base64,${defaultValues?.QRCode}`} height={150} width={150} />
          </>
        )}
      </Box>
    </CustomPaper>
  )
}

function Informations({ defaultValues }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GlobalInfos defaultValues={defaultValues} />
          </Grid>
          <Grid item xs={12}>
            <PersonalInfos defaultValues={defaultValues} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        <CWSpaceInfos defaultValues={defaultValues} />
      </Grid>
    </Grid>
  )
}

export default Informations
