import {
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Hidden,
  Link as MuiLink,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { lighten, makeStyles } from '@material-ui/core/styles'
import SquaredImg from 'area/social/components/SquaredImg'
import image from 'assets/images/no_items.svg'
import { CSSGrid } from 'components/CustomComponents/CSSGrid'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import HeroText from 'components/CustomComponents/HeroText'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import ShowPrice from 'components/ShowPrice'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getRoutePath } from 'routes'

const avatarSize = 35
const imageHeight = 120
const mobileImageHeight = 80
const colWidth = 290
const mobileColWidth = 230

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
  },
  cardActionArea: {
    height: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  media: {
    position: 'relative',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  gridSubtitle: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.7),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  prices: {
    padding: theme.spacing(0.75),
    color: theme.palette.secondary.main,
    fontSize: '1.5rem !important',
    fontWeight: 'bold',
    flexGrow: 1,
    '& + .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
      fontSize: 20,
    },
    '& + .MuiBox-root': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  infoCapacity: {
    backgroundColor: theme.palette.primary.darker,
    color: theme.palette.getContrastText(theme.palette.primary.darker),
    '& > svg': {
      marginLeft: theme.spacing(0.25),
      fontSize: '1em',
    },
    position: 'absolute',
    bottom: 0,
    width: 35,
    height: 35,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconTicket: {
    backgroundColor: 'white',
    width: 35,
    height: 35,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid' + theme.palette.secondary.main,
    position: 'absolute',
    bottom: 0,
    left: theme.spacing(7),
  },
  avatarGroup: {
    '& .MuiAvatarGroup-avatar': {
      border: 0,
      borderRadius: theme.shape.borderRadius,
      width: avatarSize,
      height: avatarSize,
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.darker,
      boxShadow: theme.shadows[1],
    },
  },
  renderPriceContainer: {
    '& > .item': {
      marginBottom: theme.spacing(0.65),
      [theme.breakpoints.up('lg')]: {
        paddingRight: theme.spacing(1),
        '&:nth-child(even)': {
          borderLeft: '2px dashed ' + theme.palette.secondary.main,
        },
      },
    },
  },
  pricesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > .MuiBox-root': {
      display: 'flex',
    },
  },
  priceContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexGrow: 1,
    paddingTop: 0,
  },
  link: {
    display: 'block',
    height: '100%',
    '&, &:hover': {
      textDecoration: 'none',
    },
    flex: 1,
    '& > .MuiCardActionArea-root': {
      '&, & > .MuiCardContent-root': {
        //height:"100%"
      },
    },
  },
  paddingName: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(0.5),
    },
  },
  // boxPriceFlex: {
  //   borderLeft: "2px dashed" + theme.palette.secondary.main,
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   height: "100%",
  // },
  borderLeft: {
    height: '100%',
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderLeft: '2px dashed ' + theme.palette.secondary.main,
    [theme.breakpoints.only('xs')]: {
      borderLeft: 'none',
      borderTop: '2px dashed ' + theme.palette.secondary.main,
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infosContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  flexOne: {
    flex: 1,
  },
  displayFlexCenter: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      overflow: 'auto',
    },
  },
  image: {
    maxHeight: '40vh',
    maxWidth: '100%',
  },
  btnContainer: {
    '& .MuiButtonBase-root': {
      width: '100%',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
  },
}))

function RenderPrices({ item, flex }) {
  const classes = useStyles()

  return (
    <>
      {!flex ? (
        <Grid container className={classes.renderPriceContainer}>
          {item.Prices.map((prices, index) => (
            <Grid item xs={12} lg={6} key={`prices_${index}`} className="item">
              <Box className={classes.pricesContainer}>
                <Box flex="2">
                  <ShowPrice
                    className={classes.prices}
                    credits={prices.Credits}
                    ttc={prices.EuroTTC}
                    ht={prices.EuroHT}
                  />
                </Box>
                <Box display="flex" alignItems="center" flex="1">
                  <Box textAlign="center" mr={0.25}>
                    /
                  </Box>
                  <Box
                    color="text.secondary"
                    fontSize="caption.fontSize"
                    fontWeight="caption.fontWeight"
                    lineHeight="1"
                  >
                    {prices.Description}
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Box className={classes.borderLeft}>
            <ShowPrice
              className={classes.prices}
              credits={item.Prices[0].Credits}
              ttc={item.Prices[0].EuroTTC}
              ht={item.Prices[0].EuroHT}
            />
          </Box>
        </>
      )}
    </>
  )
}

function DescAndPrices({ item, flex }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      {!flex ? (
        <>
          <Hidden only="xs">
            <Box mb={1}>
              {item.ShortDescription && (
                <Typography variant="body2" color="textSecondary" component="div">
                  <Box dangerouslySetInnerHTML={{ __html: item.ShortDescription }}></Box>
                </Typography>
              )}
            </Box>
          </Hidden>
          <Box className={classes.priceContent}>
            <Typography variant="subtitle1" gutterBottom>
              <Box color="primary.darker" component="span">
                {t('reservations.items.prices')}
              </Box>
            </Typography>
            <RenderPrices item={item} />
          </Box>
        </>
      ) : (
        <Grid container spacing={2} className={classes.flexOne} alignItems="stretch">
          <Grid item xs={12} sm={7} className={classes.displayFlexCenter}>
            {item.ShortDescription && (
              <Typography variant="body2" color="textSecondary" component="div">
                <Box dangerouslySetInnerHTML={{ __html: item.ShortDescription }}></Box>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={5}>
            <RenderPrices flex={flex} item={item} />
          </Grid>
        </Grid>
      )}
    </>
  )
}

function RenderTickets({ tickets }) {
  const { t } = useTranslation()

  return (
    <div>
      <Typography variant="subtitle1">
        <Box component="span" color="primary.darker">
          {t('reservations.items.pack.detail')}
        </Box>
      </Typography>
      <Grid container spacing={2}>
        {tickets &&
          tickets.map(
            (ticket, index) =>
              ticket.NbTickets > 0 && (
                <Grid key={index} item xs={4}>
                  <Box display="flex">
                    <div>
                      <Typography variant="subtitle1">
                        <Box color="primary.darker" component="span">
                          {ticket.NbTickets}
                        </Box>
                      </Typography>
                    </div>
                    <Box color="primary.darker" display="flex" alignItems="center" pl={1}>
                      <Icon icon="tickets" />
                    </Box>
                  </Box>
                  <div>
                    <Typography variant="caption">
                      <Box color="primary.darker">{t(ticket.KeyType)}</Box>
                    </Typography>
                  </div>
                </Grid>
              )
          )}
      </Grid>
    </div>
  )
}

const Capacity = ({ item }) => {
  const classes = useStyles()

  if (item.NbUsers === 0) return null
  return (
    <Box className={classes.infoCapacity}>
      {item.NbUsers}
      <Icon icon="mon-profil" />
    </Box>
  )
}

const IconTicket = ({ item }) => {
  const classes = useStyles()

  if (!item.ShowTicketIcon) return null
  return (
    <Box className={classes.iconTicket}>
      <Icon icon="tickets" color="secondary" />
    </Box>
  )
}

const Infos = ({ item, catType }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.infosContainer}>
      <Box className={classes.paddingName}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <Typography variant="h5">
              <Box color="primary.darker" fontWeight="fontWeightMedium" component="span">
                {item.Name}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <DescAndPrices flex={!(catType === 'rent' || catType === 'rent_all_duration')} item={item} />
      {catType === 'pack' && <RenderTickets tickets={item.Tickets} />}
      {catType === 40 && item.PapercutCredits && (
        <Box mt={1}>
          <Typography variant="h6" color="secondary">
            {t('papercut.nb.credits', { count: item.PapercutCredits })}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export function Item({ catType, item, onClick, categoryId, coworkingSpaceId, visited }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <CustomPaper selectable className={classes.card}>
      <MuiLink
        className={classes.link}
        component={Link}
        onClick={(e) => onClick(e, item.Id)}
        to={getRoutePath('newreservation-item', {
          ':coworkingspace': coworkingSpaceId,
          ':cwcategory': categoryId,
          ':cwitem': item.Id,
        })}
      >
        <CardActionArea className={classes.cardActionArea}>
          <CardMedia className={classes.media}>
            <SquaredImg image={item.Image} container />
            <Capacity item={item} />
            <IconTicket item={item} />
          </CardMedia>
          <CardContent className={classes.flexOne}>
            <Infos catType={catType} item={item} />
          </CardContent>
          {visited && (
            <Box px={2} pb={2}>
              <Typography variant="subtitle1" color="textSecondary">
                <Box width="100%" component="span" display="flex" alignItems="center" justifyContent="center">
                  <Icon fontSize="inherit" icon="deja_reserve" />
                  &nbsp;
                  <span>{t('reservations.items.visited')}</span>
                </Box>
              </Typography>
            </Box>
          )}
        </CardActionArea>
      </MuiLink>
    </CustomPaper>
  )
}

function ItemsGrid({ catType, onItemClick, visited, unvisited, categoryId, coworkingSpaceId, resetFilters }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  if (!visited || !unvisited) return <LoadingElem />

  const haveVisited = visited && visited.length > 0
  const haveUnvisited = unvisited && unvisited.length > 0

  if (!haveVisited && !haveUnvisited)
    return (
      <HeroText
        imageProps={{
          src: image,
          className: classes.image,
        }}
        description={t('items.empty')}
        btnProps={{
          variant: 'outlined',
          onClick: resetFilters,
        }}
        btnText={t('items.delete.filters')}
      />
    )

  return (
    <div>
      <CSSGrid colWidth={isXsDown ? mobileColWidth : colWidth} spacing={1}>
        {haveVisited &&
          visited.map((it) => (
            <div key={'item_' + it.Id}>
              <Item
                catType={catType}
                onClick={onItemClick}
                item={it}
                categoryId={categoryId}
                coworkingSpaceId={coworkingSpaceId}
                visited
              />
            </div>
          ))}
        {haveUnvisited &&
          unvisited.map((it) => (
            <div key={'item_' + it.Id}>
              <Item
                catType={catType}
                onClick={onItemClick}
                item={it}
                categoryId={categoryId}
                coworkingSpaceId={coworkingSpaceId}
              />
            </div>
          ))}
      </CSSGrid>
    </div>
  )
}

export default ItemsGrid
