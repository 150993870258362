import { Box, Chip, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import UploadDropZone from '@rpldy/upload-drop-zone'
import Uploady, { UploadyContext, useItemFinishListener } from '@rpldy/uploady'
import SquareButton from 'components/SquareButton'
import RenderField from 'components/views/PaymentPage/RenderField'
import React, { useCallback, useContext } from 'react'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import CloseIcon from '@material-ui/icons/Close'
import CustomPaper from 'components/CustomComponents/CustomPaper'
import { Controller } from 'react-hook-form'
import CustomLabel from 'components/CustomLabel'

const useStyles = makeStyles((theme) => ({
  textArea: {
    outline: '0px solid transparent',
    '& p': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(3),
  },
}))

const UploadyComponent = ({ methods, name }) => {
  const uploady = useContext(UploadyContext)
  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const [imgs, setImgs] = React.useState(methods.getValues()[name] ?? [])

  const onClick = useCallback(() => {
    uploady.showFileUpload()
  }, [uploady])

  useItemFinishListener((item) => {
    let newImgs = [...imgs]
    let url = item.uploadResponse.data
    let n = item.file?.name
    newImgs.push({ url: url, name: n, extension: '', previewurl: '' })
    setImgs(newImgs)
    methods.setValue(name, newImgs)
  })

  const handleClickImg = (url) => {
    window.open(url, '_blank')
  }

  const handleDelete = (img) => {
    let newImgs = [...imgs]
    newImgs.map((i, index) => {
      if (JSON.stringify(i) === JSON.stringify(img)) {
        newImgs.splice(index, 1)
      }
    })
    setImgs(newImgs)
    methods.setValue(name, newImgs)
  }

  return (
    <Box display={isXsDown ? 'block ' : 'flex'} justifyContent="space-between" alignItems="center">
      <Box display="flex" flexWrap="wrap">
        {imgs &&
          imgs.map((img, index) => {
            return (
              <Box p={0.5} key={index}>
                <Chip
                  deleteIcon={CloseIcon}
                  color="primary"
                  onDelete={() => handleDelete(img)}
                  onClick={() => handleClickImg(img.url)}
                  label={img.name}
                />
              </Box>
            )
          })}
      </Box>
      <SquareButton variant="outlined" color="primary" onClick={onClick}>
        <AttachFileIcon />
      </SquareButton>
    </Box>
  )
}

function TextWithAttachments({ methods, textName, attachmentsName, label, edit, ...rest }) {
  const classes = useStyles()

  let uploadUrl = 'https://localhost/media/media/save'
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'production')
    uploadUrl = 'https://media.fteledition.fr/media/save'

  return (
    <Box width="100%">
      <RenderField {...rest} methods={methods} name={textName} label={label} multiline />
      <Uploady destination={{ url: uploadUrl }}>
        <UploadDropZone>
          <UploadyComponent methods={methods} name={attachmentsName} />
        </UploadDropZone>
      </Uploady>
    </Box>
  )
}

export default TextWithAttachments
