import { alpha, Box, Fade, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import FullHeightCenter from 'components/FullHeightCenter'
import { SERVER_URL } from 'config'
// import i18n from 'i18n';
import { CustomThemeContext } from 'contexts/CustomThemeContext'
import { SocietyContext } from 'contexts/SocietyContext'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import moment from 'moment'
import 'moment/min/locales.min'
import React from 'react'
import { initReactI18next } from 'react-i18next'
import { CoworkingSpaceContext } from './CoworkingSpaceContext'
import { UserContext } from './UserContext'

const lineWidth = 300
const imgWidth = lineWidth / 3

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const useStyles = makeStyles((theme) => ({
  progress: {
    width: lineWidth,
    margin: 'auto',
  },
  progressImg: {
    width: imgWidth,
  },
}))

const LoadingContext = React.createContext()

export const LoadingComp = ({ image, primary }) => {
  const classes = useStyles()

  const classesPrimary = makeStyles((theme) => {
    if (!primary) return {}
    return {
      progress: {
        backgroundColor: alpha(primary, 0.2),
        '&>.MuiLinearProgress-barColorPrimary': {
          backgroundColor: primary,
        },
      },
    }
  })()

  return (
    <FullHeightCenter>
      <Fade in={image && primary ? true : false}>
        <Box textAlign="center">
          <Box mb={1}>
            <Box mb={1}>
              <img
                src={image + `?upscale=false&animationprocessmode=first&autorotate=true&width=${imgWidth}&mode=max`}
                alt=""
                className={classes.progressImg}
              />
            </Box>
            <LinearProgress className={classes.progress + ' ' + classesPrimary.progress} />
          </Box>
          <Typography variant="body2" color="textSecondary">
            Accès à votre plateforme...
          </Typography>
        </Box>
      </Fade>
    </FullHeightCenter>
  )
}

let loadingTimeout
let showIsLoadingTimeout
function LoadingProvider({ children }) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [showIsLoading, setShowIsLoading] = React.useState(false)
  const [i18nLoaded, set18nLoaded] = React.useState(false)

  const { theme, logo, primary } = React.useContext(CustomThemeContext)
  const { society } = React.useContext(SocietyContext)
  const { coworkingSpace } = React.useContext(CoworkingSpaceContext)
  const { user, actions } = React.useContext(UserContext)

  const isContentLoading =
    !society || !theme || !coworkingSpace || typeof actions === 'undefined' || typeof user === 'undefined'

  React.useEffect(() => {
    setIsLoading(isContentLoading || !i18nLoaded)
  }, [isContentLoading, i18nLoaded])

  React.useEffect(() => {
    clearTimeout(loadingTimeout)
    clearTimeout(showIsLoadingTimeout)
    if (isLoading) {
      showIsLoadingTimeout = setTimeout(() => {
        setShowIsLoading(true)
      }, 1000 * 1) // On affiche que la page charge au bout de 1 seconde de véritable chargement

      loadingTimeout = setTimeout(() => {
        window.location.reload()
      }, 1000 * 30) // On recharge la page au bout de 30 secondes si celle-ci n'as pas encore finis de charger, pour combler le timeout serveur
    } else {
      setShowIsLoading(false)
    }
  }, [isLoading])

  React.useEffect(() => {
    // On charge les langues en dernier
    if (!isContentLoading) {
      i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init(
          {
            lng: society?.DefaultLang,
            initImmediate: false,
            debug: isDev,
            fallbackLng: false,
            keySeparator: false,
            interpolation: {
              prefix: '[[',
              suffix: ']]',
              escapeValue: false, // react already safes from xss
            },
            backend: {
              loadPath: `${SERVER_URL('/api/translation/[[lng]]')}`,
            },
            detection: {
              order: ['localStorage'],
              caches: ['localStorage', 'cookie'],
              cookieMinutes: 80,
            },
            react: {
              wait: false,
              useSuspense: false,
            },
            load: 'currentOnly',
          },
          () => set18nLoaded(true)
        )

      moment.locale(window.localStorage.lang ?? society.DefaultLang)
      moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss'
    }
  }, [isContentLoading])

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {showIsLoading ? <LoadingComp image={logo} primary={primary} /> : isLoading ? null : children}
    </LoadingContext.Provider>
  )
}

export { LoadingProvider, LoadingContext }
