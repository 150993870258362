import { Box, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  dot: {
    color: theme.palette.success.main,
    fontSize: 10,
    marginRight: theme.spacing(2),
  },
  foci: {
    '& p': {
      width: '100%',
      flexWrap: 'wrap',
      maxHeight: 200,
      overflowY: 'clip',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 3,
      marginBottom: theme.spacing(2),
    },
  },
  present: {
    position: 'absolute',
    bottom: 4,
  },
}))

function CardPhotoGroup({ item, view }) {
  const classes = useStyles()
  const { t } = useTranslation()

  if (!item) return <LoadingElem />
  return (
    <Grid container spacing={1}>
      <Grid item xs={view === 'list' ? 9 : 12}>
        <Typography gutterBottom={view !== 'list'} align={view === 'list' ? 'left' : 'center'} variant="h4">
          <Box color="primary.darker" component="span">
            {item.FirstName + ' ' + item.LastName}
          </Box>
        </Typography>
        {item.Job && (
          <Box mb={2}>
            <Typography
              gutterBottom={view !== 'list'}
              align={view === 'list' ? 'left' : 'center'}
              variant="subtitle1"
              color="textSecondary"
            >
              {item.Job + (item.SocietyName ? ' ' + t('photogroup.at.society', { societyName: item.SocietyName }) : '')}
            </Typography>
          </Box>
        )}
      </Grid>
      {item.Foci && item.Foci.length > 0 && view !== 'list' && (
        <Grid item xs={12} className={classes.foci}>
          <Tooltip title={item.Foci.join(', ')}>
            <Box display="flex" textAlign="center" justifyContent="center">
              <Box textAlign="center" flex="1">
                <Icon icon="interets" />
              </Box>
              <Box flex="6">
                <Typography variant="subtitle1" color="textSecondary">
                  {item.Foci.join(', ')}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {item.IsInCoworkingSpace && (
        <Grid item xs={12} className={classes.present}>
          <Box display="flex" color="primary.darker" alignItems="center" component="span">
            <Icon icon="dot" className={classes.dot} />
            &nbsp;
            <Typography variant="subtitle2">{t('photogroup.is.entrance')}</Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default CardPhotoGroup
