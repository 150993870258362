import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { DatePicker } from '@material-ui/pickers'
import CustomDialog from 'components/CustomComponents/CustomDialog'
import CustomLabel from 'components/CustomLabel'
import Icon from 'components/IcoMoon/Icon'
import LoadingElem from 'components/LoadingElem'
import RenderField from 'components/views/PaymentPage/RenderField'
import { DEFAULT_IMG } from 'config'
import { SocietyContext } from 'contexts/SocietyContext'
import { UserContext } from 'contexts/UserContext'
import moment from 'moment'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Prompt } from 'react-router'
import useSWR from 'swr'
import * as Yup from 'yup'
import { apiActions } from '_actions/api_actions'

const widthDate = 110

const imgHeight = 150
const mobileImgHeight = 100

const useStyles = makeStyles((theme) => ({
  boxTag: {
    borderRadius: theme.shape.borderRadius,
    width: 'fit-content',
    maxWidth: '100%',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: '2px solid ' + theme.palette.primary.light,
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  input: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    color: theme.palette.primary.darker,
    fontWeight: 'bold',
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  success: {
    color: theme.palette.success.main,
  },
  select: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.subtitle1.fontWeight,
  },
  textFieldTag: {
    padding: theme.spacing(1),
    '& input': {
      padding: theme.spacing(0.5),
    },
  },
  card: {
    '& img': {
      height: imgHeight,
      width: '100%',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('xs')]: {
        height: mobileImgHeight,
      },
    },
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  saveButtons: {
    marginTop: theme.spacing(3),
  },
  datepicker: {
    width: widthDate,
    color: theme.palette.secondary.main,
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.subtitle1.fontWeight,
    '& > input': {
      padding: theme.spacing(0.5),
      display: 'flex',
      justifyContent: 'center',
      '&:focus': {
        outline: 'inherit',
      },
    },
  },
  snackbar: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.darker,
    fontWeight: theme.typography.subtitle1.fontWeight,
    display: 'flex',
    justifyContent: 'center',
  },
  moreButton: {
    border: '1px solid ' + theme.palette.secondary.main,
    padding: theme.spacing(0.5),
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '0.8rem',
    },
  },
  img: {
    objectFit: 'cover',
  },
  griditem: {
    flexGrow: 'inherit',
  },
  chip: {
    display: 'flex',
    margin: theme.spacing(0.25),
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.subtitle1.fontWeight,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    maxWidth: '100%',
    '& > svg': {
      color: theme.palette.secondary.main,
    },
  },
  backButton: {
    borderRadius: theme.shape.borderRadius,
  },
  fixedContent: {
    position: 'sticky',
    bottom: theme.spacing(0),
    padding: theme.spacing(2),
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8),
  },
  iconButton: {
    border: '1px solid ' + theme.palette.secondary.main,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(0.25),
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& svg': {
      fontSize: 10,
      color: 'white',
    },
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.darker,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
  },
  pointer: {
    cursor: 'pointer',
  },
}))

const Tag = ({ tag, valueComponent }) => {
  const classes = useStyles()

  return (
    <Box p={1} mb={1} className={classes.boxTag}>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Box mr={1}>
          <Typography variant="subtitle1" color="textSecondary">
            {tag}
          </Typography>
        </Box>
        <Box display="block" maxWidth="80%">
          {valueComponent}
        </Box>
      </Box>
    </Box>
  )
}

const Info = ({ methods, name, title }) => {
  return (
    <Box mb={2}>
      <RenderField
        InputProps={{
          endAdornment: methods.formState.dirtyFields.name && (
            <CircularProgress thickness={3} size={30} color="primary" />
          ),
        }}
        methods={methods}
        label={title}
        name={name}
      />
    </Box>
  )
}

const MultipleTags = ({ prospect, refreshData }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [openInput, setOpenInput] = React.useState(false)

  const { apiCall } = useContext(UserContext)

  const { data: tags, mutate } = useSWR(`${prospect.Id}/tags`, () =>
    apiCall(apiActions.getTagsByProspectId, { ProspectId: prospect.Id }).then((r) => r.data)
  )

  const handleDelete = (tagId) => {
    apiCall(apiActions.deleteTagById, { ProspectId: prospect.Id, TagId: tagId }, 'delete').then((r) => {
      mutate()
      refreshData()
    })
  }

  const handleAdd = (e) => {
    if (e.key === 'Enter') {
      apiCall(apiActions.addTag, { ProspectId: prospect.Id }, 'post', { Name: e.target.value }).then((r) => {
        setOpenInput(false)
        mutate()
        refreshData()
      })
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center" p={0.5}>
        <Box mr={1} display="flex" flexWrap="wrap" maxWidth="100%">
          {tags && tags.length > 0
            ? tags.map((tag) => {
                return (
                  <Tooltip title={tag.Name} classes={{ tooltip: classes.tooltip }} leaveDelay={0}>
                    <Chip
                      variant="outlined"
                      className={classes.chip}
                      key={tag.Id}
                      label={tag.Name}
                      onDelete={() => handleDelete(tag.Id)}
                    />
                  </Tooltip>
                )
              })
            : !openInput && (
                <Typography variant="subtitle1" color="secondary">
                  {t('crm.prospects.no.tags')}
                </Typography>
              )}
        </Box>
        {!openInput && (
          <IconButton className={classes.moreButton} color="secondary" size="small" onClick={() => setOpenInput(true)}>
            <Icon icon="plus" />
          </IconButton>
        )}
      </Box>
      {openInput && (
        <Box display="block">
          <TextField
            fullWidth
            className={classes.textFieldTag}
            variant="outlined"
            color="secondary"
            onKeyPress={(e) => handleAdd(e)}
          />
        </Box>
      )}
    </>
  )
}

const InterestsDialogContent = ({ refreshData, handleClose, prospectId, interests, mutate }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [selectedCards, setSelectedCards] = React.useState(interests ? interests.map((i) => i.Id) : [])
  const [nameSelected, setNameSelected] = React.useState(interests ? interests.map((i) => i.Name) : [])

  const { society } = useContext(SocietyContext)
  const { apiCall, user } = useContext(UserContext)
  const [coworkingSpaceSelected, setCoworkingSpaceSelected] = React.useState(user?.CoworkingSpaceId)
  const [interestsList, setInterestsList] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const [queryName, setQueryName] = React.useState('')
  const [items, setItems] = React.useState('')

  const [catId, setCatId] = React.useState(null)

  React.useEffect(() => {
    setLoading(true)
    apiCall(apiActions.getInterestsCategories, {}, 'post', {
      CoworkingSpaceId: coworkingSpaceSelected,
    }).then((r) => {
      setInterestsList(r.data)
      if (queryName !== '') setQueryName(queryName + ' ')
      setLoading(false)
    })
  }, [coworkingSpaceSelected])

  React.useEffect(() => {
    setLoading(true)
    if (catId) {
      apiCall(apiActions.getInterestsItems, {}, 'post', {
        CoworkingSpaceId: coworkingSpaceSelected,
        CategoryId: catId,
        QueryName: queryName,
      }).then((r) => {
        setItems(r.data)
        setLoading(false)
      })
    } else {
      apiCall(apiActions.getCategoriesList, {}, 'post', {
        CoworkingSpaceId: coworkingSpaceSelected,
        QueryName: queryName,
      }).then((r) => {
        if (!r.Error) {
          setInterestsList(r.data)
          setLoading(false)
        }
      })
    }
  }, [catId, queryName])

  const handleClick = (id, name) => {
    let newSelectedCards = [...selectedCards]
    let newNameSelected = [...nameSelected]
    if (selectedCards.indexOf(id) < 0) {
      newSelectedCards.push(id)
      newNameSelected.push(name)
    } else {
      newSelectedCards = newSelectedCards.filter(function (t) {
        return t !== id
      })
      newNameSelected = newNameSelected.filter(function (t) {
        return t !== name
      })
    }
    setSelectedCards(newSelectedCards)
    setNameSelected(newNameSelected)
  }

  const handleCategoryClick = (id) => {
    setCatId(id)
  }

  async function handleSave() {
    apiCall(apiActions.editInterests, { ProspectId: prospectId }, 'put', {
      ResourceRentInterestIds: selectedCards,
    }).then((r) => {
      if (!r.Error) {
        mutate()
        refreshData()
        handleClose()
      }
    })
  }

  const handleSelectChange = (e) => {
    setCoworkingSpaceSelected(e.target.value)
  }

  return (
    <>
      {catId && (
        <Box display="flex" mb={1}>
          <IconButton
            onClick={() => {
              setCatId(null)
            }}
            className={classes.backButton}
          >
            <Box color="primary.darker" display="flex" alignItems="center">
              <Icon icon="fleche-gauche" />
            </Box>
            <Typography variant="subtitle1">
              <Box component="span" color="primary.darker">
                {t('interests.back.categories')}
              </Box>
            </Typography>
          </IconButton>
        </Box>
      )}
      <Grid container spacing={1}>
        {!catId && (
          <Grid item xs={12} md={7}>
            <Select
              onChange={(e) => handleSelectChange(e)}
              defaultValue={coworkingSpaceSelected}
              fullWidth
              variant="outlined"
            >
              {society?.CoworkingSpaces.map((r) => {
                return (
                  <MenuItem key={r.Id} value={r.Id}>
                    {r.Name}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
        )}
        <Grid item xs={12} md>
          <OutlinedInput
            onBlur={(e) => setQueryName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') setQueryName(e.target.value)
            }}
            fullWidth
            placeholder={t('crm.prospect.search.location')}
          />
        </Grid>
      </Grid>
      {loading ? (
        <LoadingElem />
      ) : (
        <>
          <Box mb={2}>
            <Grid container spacing={2}>
              {catId ? (
                items && items.length > 0 ? (
                  items.map((m) => {
                    return (
                      <Grid key={m.Id} item xs={12} sm={6} md={3}>
                        <Card
                          className={classes.card + (selectedCards.indexOf(m.Id) > -1 ? ' ' + classes.selected : '')}
                          onClick={() => handleClick(m.Id, m.Name)}
                        >
                          <CardActionArea>
                            <Box p={2}>
                              <img className={classes.img} src={m.PhotoUrl ?? DEFAULT_IMG} />
                              <Typography>{m.Name}</Typography>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )
                  })
                ) : (
                  <Box p={2} display="flex" justifyContent="center" width="100%">
                    <Typography variant="h4">{t('interests.no.location')}</Typography>
                  </Box>
                )
              ) : interestsList && interestsList.length > 0 ? (
                interestsList.map((m) => {
                  return (
                    <Grid key={m.Id} item xs={12} sm={6} md={3}>
                      <Card className={classes.card}>
                        <CardActionArea onClick={() => handleCategoryClick(m.Id)}>
                          <Box p={2}>
                            <img className={classes.img} src={m.PhotoUrl ?? DEFAULT_IMG} />
                            <Typography>{m.Name}</Typography>
                          </Box>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  )
                })
              ) : (
                <Box p={2} display="flex" justifyContent="center" width="100%">
                  <Typography variant="h4">{t('interests.no.location')}</Typography>
                </Box>
              )}
            </Grid>
          </Box>
          <Paper className={classes.fixedContent}>
            <Box mb={1} display="flex" justifyContent="center">
              <Typography variant="h5">{t('crm.prospect.selected.locations')}&nbsp;</Typography>
            </Box>
            <Box mb={2}>
              <Grid container spacing={1} justifyContent="flex-start">
                {nameSelected.map((n, index) => {
                  return (
                    <Grid key={index} className={classes.griditem} item xs>
                      <Chip
                        size="small"
                        label={n}
                        variant="outlined"
                        color="secondary"
                        onDelete={() => handleClick(selectedCards[index], n)}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
            <Box display="flex" justifyContent="center">
              <Button onClick={handleSave} variant="contained" color="primary">
                {t('crm.prospect.interests.save')}
              </Button>
            </Box>
          </Paper>
        </>
      )}
    </>
  )
}

function getSocietyList(prospect) {
  return [
    { name: 'societyName', title: 'crm.prospect.form.society.name', value: prospect.SocietyName },
    { name: 'societyAddress', title: 'crm.prospect.form.society.address', value: prospect.SocietyAddress },
    { name: 'societyZipcode', title: 'crm.prospect.form.society.zipcode', value: prospect.SocietyZipcode },
    { name: 'societyCity', title: 'crm.prospect.form.society.city', value: prospect.SocietyCity },
    { name: 'siret', title: 'crm.prospect.form.society.siret', value: prospect.Siret },
    { name: 'societyPhone', title: 'crm.prospect.form.society.phone', value: prospect.SocietyPhone },
    { name: 'societySector', title: 'crm.prospect.form.society.sector', value: prospect.SocietySector },
  ]
}

function getProspectList(prospect) {
  return [
    { name: 'firstName', title: 'crm.fieldname.firstname', value: prospect.FirstName },
    { name: 'lastName', title: 'crm.fieldname.lastname', value: prospect.LastName },
    { name: 'email', title: 'crm.prospect.form.mail', value: prospect.Email },
    { name: 'phone', title: 'crm.prospect.form.phone.fix', value: prospect.Phone },
    { name: 'mobilePhone', title: 'crm.prospect.form.phone.mobile', value: prospect.MobilePhone },
    { name: 'address', title: 'crm.prospect.form.address', value: prospect.Address },
    { name: 'zipcode', title: 'crm.prospect.form.zipcode', value: prospect.Zipcode },
    { name: 'city', title: 'crm.prospect.form.city', value: prospect.City },
    { name: 'job', title: 'crm.prospect.form.function', value: prospect.Job },
  ]
}

const SelectSociety = ({ prospect, changeCS }) => {
  const { society } = useContext(SocietyContext)
  const [csId, setCsId] = React.useState(prospect?.CoworkingSpaceId ?? '')
  const { t } = useTranslation()

  const handleCSChange = (e) => {
    setCsId(e.target.value)
    changeCS(e.target.value)
  }

  return (
    <>
      <CustomLabel>{t('crm.prospect.form.coworkingspace')}</CustomLabel>
      <Select fullWidth onChange={(e) => handleCSChange(e)} value={csId} variant="outlined">
        {society.CoworkingSpaces?.map((c) => {
          return (
            <MenuItem key={c.Id} value={c.Id}>
              {c.Name}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
}

let timeout
function ProspectInfo({ prospect, refreshData }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [openInterestDialog, setOpenInterestDialog] = React.useState(false)
  const [sendValues, setSendValues] = React.useState(null)
  const [cantQuit, setCantQuit] = React.useState(false)
  const [date, setDate] = React.useState(prospect?.InterestDate)
  const { apiCall } = useContext(UserContext)

  const { data: interests, mutate } = useSWR(`${prospect.Id}/getInterests`, () =>
    apiCall(apiActions.getInterestsByProspectId, { ProspectId: prospect.Id }, 'get').then((r) => {
      return r.data
    })
  )

  const status = prospect.Status
  const isOngoing = status === 200
  const isWin = status === 210
  const isArchived = status === 230

  const methods = useForm({
    defaultValues: {
      coworkingSpaceId: prospect.CoworkingSpaceId,
      contactOrigin: prospect.ContactOrigin,
      id: prospect.Id,
      societyName: prospect.SocietyName,
      societyAddress: prospect.SocietyAddress,
      societyZipcode: prospect.SocietyZipcode,
      societyCity: prospect.SocietyCity,
      siret: prospect.Siret,
      societyPhone: prospect.SocietyPhone,
      societySector: prospect.SocietySector,
      firstName: prospect.FirstName,
      lastName: prospect.LastName,
      email: prospect.Email,
      phone: prospect.Phone,
      mobilePhone: prospect.MobilePhone,
      address: prospect.Address,
      zipcode: prospect.Zipcode,
      city: prospect.City,
      job: prospect.Job,
    },
    resolver: yupResolver(
      Yup.object().shape({
        coworkingSpaceId: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        firstName: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
        lastName: Yup.string().required(t('common.field.required')).typeError(t('common.field.required')),
      })
    ),
  })

  const onFormSubmit = (values) => {
    clearTimeout(timeout)
    setSendValues(null)
    setCantQuit(false)
    if (Object.keys(methods.formState.dirtyFields).length > 0) {
      setCantQuit(true)
      timeout = setTimeout(() => {
        setSendValues(values)
      }, 400)
    }
  }

  React.useEffect(() => {
    if (sendValues) {
      setTimeout(() => {
        apiCall(apiActions.editProspectData, { ProspectId: prospect.Id }, 'put', sendValues).then((r) => {
          methods.reset(sendValues)
          refreshData()
          setSendValues(null)
          setCantQuit(false)
        })
      }, 1000)
    }
  }, [sendValues])

  const handleConversionChange = (e) => {
    apiCall(apiActions.editProspectConversion, { ProspectId: prospect.Id, NewConversion: e.target.value }, 'put').then(
      (r) => {
        refreshData()
      }
    )
  }

  const handleDateChange = (v) => {
    setDate(v)
    apiCall(apiActions.editInterestDate, { ProspectId: prospect.Id }, 'put', {
      InterestedDate: moment(v).toDate(),
    }).then((r) => {
      refreshData()
    })
  }

  const handleChangeCS = (csId) => {
    methods.setValue('coworkingSpaceId', csId, { shouldDirty: true })
  }

  window.onbeforeunload = function () {
    if (cantQuit) return t('crm.prompt.leave')
    else return null
  }

  const societyList = getSocietyList(prospect)
  const prospectList = getProspectList(prospect)

  const ShowInterests = () => {
    return (
      <Box display="flex" alignItems="center" p={0.5}>
        <Box mr={1}>
          <Typography color="secondary" variant="subtitle1">
            {interests && interests.length > 0
              ? interests.map((i) => i.Name)?.join(', ')
              : t('crm.prospects.no.interests')}
          </Typography>
        </Box>
        <IconButton
          className={classes.moreButton}
          color="secondary"
          size="small"
          onClick={() => setOpenInterestDialog(true)}
        >
          <Icon icon="plus" />
        </IconButton>
      </Box>
    )
  }

  return (
    <Paper>
      <Prompt when={Object.keys(methods.formState.dirtyFields).length > 0} message={t('crm.prompt.leave')} />
      <Box p={3} pb={1}>
        <Tag
          tag={t('crm.prospect.infos.tags')}
          valueComponent={<MultipleTags prospect={prospect} refreshData={refreshData} />}
        />
        <Tag tag={t('crm.prospect.infos.interests')} valueComponent={<ShowInterests />} />
        {isOngoing && (
          <>
            <Tag
              tag={t('crm.prospect.infos.date')}
              valueComponent={
                <DatePicker
                  InputProps={{
                    className: classes.datepicker,
                    disableUnderline: true,
                    endAdornment: date && (
                      <Icon
                        className={classes.pointer}
                        icon="close"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDateChange(null)
                        }}
                      />
                    ),
                  }}
                  onChange={(v) => handleDateChange(v)}
                  value={date}
                  defaultValue={prospect.InterestDate}
                  autoOk
                  color="secondary"
                  variant="inline"
                  labelFunc={(date, i) => {
                    if (date) return moment(date).format('DD/MM/YYYY')
                    else return t('crm.prospect.interest.no.date')
                  }}
                />
              }
            />
            <Tag
              tag={t('crm.prospect.infos.conversion')}
              valueComponent={
                <Select
                  disableUnderline
                  onChange={handleConversionChange}
                  defaultValue={prospect.Conversion}
                  className={classes.select}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value={10}>{t('prospect.conversion.very_cold')}</MenuItem>
                  <MenuItem value={20}>{t('prospect.conversion.cold')}</MenuItem>
                  <MenuItem value={30}>{t('prospect.conversion.medium')}</MenuItem>
                  <MenuItem value={40}>{t('prospect.conversion.hot')}</MenuItem>
                  <MenuItem value={50}>{t('prospect.conversion.very_hot')}</MenuItem>
                </Select>
              }
            />
          </>
        )}
        {!isOngoing && (
          <Tag
            tag={t('crm.prospect.infos.archived')}
            valueComponent={
              !isArchived ? (
                <Box p={0.5}>
                  <Typography
                    className={isWin ? classes.success : ''}
                    variant="subtitle1"
                    color={isWin ? 'success' : 'error'}
                  >
                    {isWin ? t('crm.prospect.infos.archived.won') : t('crm.prospect.infos.archived.lost')}
                  </Typography>
                </Box>
              ) : (
                <></>
              )
            }
          />
        )}
      </Box>
      <Divider className={classes.divider} />
      <Box p={3}>
        <form onBlur={methods.handleSubmit(onFormSubmit)} onFocus={() => clearTimeout(timeout)}>
          <Box mb={3}>
            <Typography gutterBottom variant="h4">
              <Box component="span" fontWeight="fontWeightBold" color="primary.darker">
                {t('crm.prospect.form.general.title')}
              </Box>
            </Typography>
            <Box p={1}>
              <Box mb={2}>
                <SelectSociety prospect={prospect} changeCS={handleChangeCS} />
              </Box>
              <Info methods={methods} name="contactOrigin" title={t('crm.prospect.form.contactorigin')} />
            </Box>
            <Typography gutterBottom variant="h4">
              <Box component="span" fontWeight="fontWeightBold" color="primary.darker">
                {t('crm.prospect.form.society.title')}
              </Box>
            </Typography>
            <Box p={1}>
              {societyList.map((s) => {
                return <Info methods={methods} name={s.name} key={s.title} title={t(s.title)} />
              })}
            </Box>
          </Box>
          <Typography gutterBottom variant="h4">
            <Box component="span" fontWeight="fontWeightBold" color="primary.darker">
              {t('crm.prospect.form.prospect.title')}
            </Box>
          </Typography>
          <Box p={1}>
            {prospectList.map((p) => {
              return <Info methods={methods} name={p.name} key={p.title} title={t(p.title)} />
            })}
          </Box>
        </form>
      </Box>
      {openInterestDialog && (
        <CustomDialog
          title={t('crm.interests.add')}
          maxWidth="lg"
          withFooter
          fullWidth
          handleClose={() => setOpenInterestDialog(false)}
          open={openInterestDialog}
        >
          <InterestsDialogContent
            mutate={mutate}
            handleClose={() => setOpenInterestDialog(false)}
            prospectId={prospect.Id}
            refreshData={refreshData}
            interests={interests}
          />
        </CustomDialog>
      )}
      <Snackbar
        ContentProps={{ className: classes.snackbar }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={sendValues}
        message={t('crm.prompt.save')}
      />
    </Paper>
  )
}

export default ProspectInfo
