import { makeStyles, Typography } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from 'components/IcoMoon/Icon'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  popOverElem: {
    color: theme.palette.primary.darker,
    '& .MuiListItemIcon-root': {
      minWidth: 28,
      color: theme.palette.primary.darker,
      '& svg': {
        fontSize: '1.1em',
      },
    },
  },
}))

const PopoverElem = (props) => {
  const { icon, text, className, ...rest } = props
  const classes = useStyles()

  return (
    <MenuItem className={className + ' ' + classes.popOverElem} {...rest}>
      {icon && (
        <ListItemIcon>
          <Icon icon={icon} />
        </ListItemIcon>
      )}
      <Typography variant="body2">{text}</Typography>
    </MenuItem>
  )
}

export default PopoverElem
